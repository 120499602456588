import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

import { Eye, Edit, Trash2, Link, Link2Off, Send, Copy } from 'lucide-react';
import { useDocument } from '../hooks/useDocument';
import { toast } from 'react-toastify';
import { useInvoices } from '../hooks/useInvoices';
import InvoiceProgressTracker from './InvoiceProgressTracker';
import SendOptionsModal from './SendOptionsModal';

const InvoiceViewer = ({ invoice, onClose, onEdit }) => {
  // 1. Add local state for toggling preview
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [showSendOptions, setShowSendOptions] = useState(false);
  const [showPublishedLink, setShowPublishedLink] = useState(false);
  const [publicLink, setPublicLink] = useState('');
  const { publishDocument, unpublishDocument, document } = useDocument(invoice?.document_id);
  const nav = useNavigate();
  const { deleteInvoice, isDeleting } = useInvoices(invoice.client_id);
  const previewRef = useRef(null);




  // Add delete handler
  const handleDelete = async () => {
    if (!window.confirm('Are you sure you want to delete this invoice? This cannot be undone.')) {
      return;
    }

    try {
      await deleteInvoice(invoice._id);
      onClose(); // Close the viewer after successful deletion
    } catch (error) {
      console.error('Error deleting invoice:', error);
      // Toast is handled by the mutation
    }
  };
  
  

  // Handle manual send
const handleManualSend = async () => {
    try {
        const publishedDoc = await publishDocument(invoice.document_id);
        
        // Get the token from the response
        const token = publishedDoc.shareable_link.split('/').pop();
        const shareableUrl = `${window.location.origin}/share/${token}`;
        
        setPublicLink(shareableUrl);
        setShowPublishedLink(true);
        setShowSendOptions(false);
        
        toast.success('Public link generated successfully');
    } catch (error) {
        console.error('Error publishing document:', error);
        toast.error('Failed to generate public link');
    }
    };

    // Add handler for unpublishing
const handleUnpublish = async () => {
    try {
      await unpublishDocument(invoice.document_id);
      setShowPublishedLink(false);
      setPublicLink('');
      toast.success('Link unpublished successfully');
    } catch (error) {
      console.error('Error unpublishing document:', error);
      toast.error('Failed to unpublish link');
    }
  };


    // Effect to show published link if document already has one
  useEffect(() => {
    if (document?.shareable_link) {
      const token = document.shareable_link.split('/').pop();
      const shareableUrl = `${window.location.origin}/share/${token}`;
      setPublicLink(shareableUrl);
      setShowPublishedLink(true);
    }
  }, [document]);


  // Render recurring invoice details
  const renderRecurringDetails = () => {
    if (invoice.invoice_type !== 'recurring') return null;

    return (
      <div className="mt-6 bg-grayteck-50 dark:bg-darkbg-700 rounded-lg p-4">
        <h3 className="text-lg font-medium text-grayteck-900 dark:text-grayteck-100 mb-4">
          Recurring Invoice Details
        </h3>
        
        {invoice.recurring_settings && (
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-sm text-grayteck-600 dark:text-grayteck-400">Frequency</p>
              <p className="text-grayteck-900 dark:text-grayteck-100">
                {invoice.recurring_settings.frequency.charAt(0).toUpperCase() + 
                 invoice.recurring_settings.frequency.slice(1)}
              </p>
            </div>
            
            <div>
              <p className="text-sm text-grayteck-600 dark:text-grayteck-400">Start Date</p>
              <p className="text-grayteck-900 dark:text-grayteck-100">
                {new Date(invoice.recurring_settings.start_date).toLocaleDateString()}
              </p>
            </div>
            
            {invoice.recurring_settings.end_date && (
              <div>
                <p className="text-sm text-grayteck-600 dark:text-grayteck-400">End Date</p>
                <p className="text-grayteck-900 dark:text-grayteck-100">
                  {new Date(invoice.recurring_settings.end_date).toLocaleDateString()}
                </p>
              </div>
            )}
            
            <div>
              <p className="text-sm text-grayteck-600 dark:text-grayteck-400">Next Invoice</p>
              <p className="text-grayteck-900 dark:text-grayteck-100">
                {new Date(invoice.recurring_settings.next_invoice_date).toLocaleDateString()}
              </p>
            </div>
            
            <div>
              <p className="text-sm text-grayteck-600 dark:text-grayteck-400">Status</p>
              <span className={`px-3 py-1 rounded-full text-xs font-medium ${
                invoice.recurring_settings.active
                  ? 'bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-300'
                  : 'bg-red-100 text-red-800 dark:bg-red-900/30 dark:text-red-300'
              }`}>
                {invoice.recurring_settings.active ? 'Active' : 'Inactive'}
              </span>
            </div>
          </div>
        )}
      </div>
    );
  };

  // Render subscription details
  const renderSubscriptionDetails = () => {
    if (invoice.invoice_type !== 'subscription') return null;

    return (
      <div className="mt-6 bg-grayteck-50 dark:bg-darkbg-700 rounded-lg p-4">
        <h3 className="text-lg font-medium text-grayteck-900 dark:text-grayteck-100 mb-4">
          Subscription Details
        </h3>
        
        {invoice.subscription_settings && (
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-sm text-grayteck-600 dark:text-grayteck-400">Billing Frequency</p>
              <p className="text-grayteck-900 dark:text-grayteck-100">
                {invoice.subscription_settings.frequency.charAt(0).toUpperCase() + 
                 invoice.subscription_settings.frequency.slice(1)}
              </p>
            </div>
            
            <div>
              <p className="text-sm text-grayteck-600 dark:text-grayteck-400">Start Date</p>
              <p className="text-grayteck-900 dark:text-grayteck-100">
                {new Date(invoice.subscription_settings.start_date).toLocaleDateString()}
              </p>
            </div>

            {/* New section for next charge date */}
            {invoice.subscription_settings.next_charge_date && (
                <div>
                <p className="text-sm text-grayteck-600 dark:text-grayteck-400">Next Charge Date</p>
                <p className="text-grayteck-900 dark:text-grayteck-100">
                    {new Date(invoice.subscription_settings.next_charge_date).toLocaleDateString()}
                </p>
                </div>
            )}
            
            {invoice.subscription_settings.trial_end_date && (
              <div>
                <p className="text-sm text-grayteck-600 dark:text-grayteck-400">Trial End Date</p>
                <p className="text-grayteck-900 dark:text-grayteck-100">
                  {new Date(invoice.subscription_settings.trial_end_date).toLocaleDateString()}
                </p>
              </div>
            )}
            
            <div>
              <p className="text-sm text-grayteck-600 dark:text-grayteck-400">Auto Collection</p>
              <span className={`px-3 py-1 rounded-full text-xs font-medium ${
                invoice.subscription_settings.auto_collection
                  ? 'bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-300'
                  : 'bg-red-100 text-red-800 dark:bg-red-900/30 dark:text-red-300'
              }`}>
                {invoice.subscription_settings.auto_collection ? 'Enabled' : 'Disabled'}
              </span>
            </div>
            
            {invoice.subscription_settings.stripe_subscription_id && (
              <div>
                <p className="text-sm text-grayteck-600 dark:text-grayteck-400">Stripe Subscription ID</p>
                <p className="text-grayteck-900 dark:text-grayteck-100 truncate">
                  {invoice.subscription_settings.stripe_subscription_id}
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  
  // 3. If in preview mode, show a "client-facing" full-screen preview
  //    similar to what you do in `DocumentEditor`.
  
    return (
      <div className="bg-white dark:bg-darkbg-800 rounded-lg p-8 max-w-4xl mx-auto">
        <div className="flex justify-between items-start mb-8">
          <div>
            <h2 className="text-2xl font-bold text-grayteck-900 dark:text-grayteck-100">
              Invoice #{invoice.invoice_number}
            </h2>
            <p className="text-grayteck-600 dark:text-grayteck-400">
              Issued: {new Date(invoice.issue_date).toLocaleDateString()}
            </p>
            <p className="text-grayteck-600 dark:text-grayteck-400">
              Due: {new Date(invoice.due_date).toLocaleDateString()}
            </p>
          </div>
        <span className={`px-4 py-2 rounded-full text-sm font-bold ${
          invoice.status === 'paid' 
            ? 'bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-300'
            : invoice.status === 'pending'
            ? 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/30 dark:text-yellow-300'
            : invoice.status === 'opened'
            ? 'bg-blue-100 text-blue-800 dark:bg-blue-900/30 dark:text-blue-300'
            : invoice.status === 'overdue'
            ? 'bg-red-100 text-red-800 dark:bg-red-900/30 dark:text-red-300'
            : 'bg-grayteck-100 text-grayteck-800 dark:bg-grayteck-900/30 dark:text-grayteck-300'
        }`}>
            {invoice.status}
          </span>
        </div>
  
        {/* Items Table */}
        <table className="w-full mb-8">
          <thead>
            <tr className="border-b border-grayteck-200 dark:border-grayteck-700">
                <th className="text-left py-3 text-grayteck-600 dark:text-grayteck-400">Description</th>
                <th className="text-right py-3 text-grayteck-600 dark:text-grayteck-400">Quantity</th>
                <th className="text-right py-3 text-grayteck-600 dark:text-grayteck-400">Unit Price</th>
                <th className="text-right py-3 text-grayteck-600 dark:text-grayteck-400">Amount</th>
            </tr>
          </thead>
          <tbody>
            {invoice.items.map((item, index) => (
                <tr key={index} className="border-b border-grayteck-200 dark:border-grayteck-700">
                    <td className="py-3 text-grayteck-900 dark:text-grayteck-100">{item.description}</td>
                    <td className="text-right py-3 text-grayteck-900 dark:text-grayteck-100">{item.quantity}</td>
                    <td className="text-right py-3 text-grayteck-900 dark:text-grayteck-100">${item.unit_price.toFixed(2)}</td>
                    <td className="text-right py-3 text-grayteck-900 dark:text-grayteck-100">${item.amount.toFixed(2)}</td>
                </tr>
            ))}
          </tbody>
        </table>
  
        {/* Totals */}
        <div className="text-right mb-4 space-y-2">
            <div className="text-grayteck-600 dark:text-grayteck-400">
                Subtotal: ${invoice.subtotal.toFixed(2)}
            </div>
            
            {/* Display discount if it exists */}
            {invoice.discount_percentage > 0 && (
              <div className="text-green-600 dark:text-green-400">
                Discount ({invoice.discount_percentage}%): -${((invoice.subtotal * invoice.discount_percentage) / 100).toFixed(2)}
              </div>
            )}
            
            {/* Show subtotal after discount if there is a discount */}
            {invoice.discount_percentage > 0 && (
              <div className="text-grayteck-600 dark:text-grayteck-400">
                Subtotal after discount: ${(invoice.subtotal - (invoice.subtotal * invoice.discount_percentage / 100)).toFixed(2)}
              </div>
            )}
            
            <div className="text-grayteck-600 dark:text-grayteck-400">
                Tax: ${invoice.tax.toFixed(2)}
            </div>
            <div className="text-xl font-bold text-grayteck-900 dark:text-grayteck-100">
                Total: ${invoice.total.toFixed(2)}
            </div>
        </div>
  
        {/* Notes */}
        {invoice.notes && (
          <div className="mb-8">
            <h3 className="font-medium mb-2 text-grayteck-900 dark:text-grayteck-100">Notes</h3>
            <p className="text-grayteck-600 dark:text-grayteck-400">{invoice.notes}</p>
          </div>
        )}

        {renderRecurringDetails()}
        {renderSubscriptionDetails()}

        {/* Invoice Progress Tracker */}
        <InvoiceProgressTracker invoice={invoice} />


        {/* Published Link Section - Render in main modal */}
        {showPublishedLink && publicLink && (
            <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            className="mt-6 p-4 bg-grayteck-50 dark:bg-darkbg-700 rounded-lg border border-grayteck-200 dark:border-grayteck-700"
            >
            <div className="flex justify-between items-center mb-2">
                <span className="text-sm font-medium text-grayteck-900 dark:text-grayteck-100">
                Published Link
                </span>
                <span className="px-2 py-1 text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-300 rounded-full">
                Active
                </span>
            </div>
            <div className="flex items-center space-x-2">
                <input
                type="text"
                value={publicLink}
                readOnly
                className="flex-1 bg-white dark:bg-darkbg-600 text-grayteck-900 dark:text-grayteck-100 
                        border border-grayteck-300 dark:border-grayteck-600 rounded-md px-3 py-2 text-sm"
                />
                <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => {
                    navigator.clipboard.writeText(publicLink);
                    toast.success('Link copied to clipboard');
                }}
                className="p-2 bg-grayteck-100 dark:bg-darkbg-600 rounded-md 
                        hover:bg-grayteck-200 dark:hover:bg-darkbg-500"
                >
                <Copy className="w-5 h-5 text-grayteck-600 dark:text-grayteck-400" />
                </motion.button>

                <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => {
                    if (window.confirm('Are you sure you want to unpublish this link? The link will no longer be accessible.')) {
                    handleUnpublish();
                    }
                }}
                className="p-2 bg-red-100 dark:bg-red-900/30 rounded-md 
                        hover:bg-red-200 dark:hover:bg-red-900/50"
                title="Unpublish Link"
                >
                <Link2Off className="w-5 h-5 text-red-600 dark:text-red-400" />
                </motion.button>
            </div>
            </motion.div>
        )}
  
        {/* Close Button */}
        {/* // Inside InvoiceViewer component, replace the existing buttons div with: */}

        {/* Action Buttons */}
        <div className="flex justify-end space-x-3">
        {/* Edit Button */}
        <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            // onClick={() => nav(`/invoices/${invoice._id}/edit`)}
            onClick={() => onEdit(invoice)} // Add onEdit prop handler
            className="p-2 text-grayteck-600 hover:text-grayteck-900 dark:text-grayteck-400 
                    dark:hover:text-grayteck-200 transition-colors rounded-md
                    hover:bg-grayteck-100 dark:hover:bg-darkbg-700"
            title="Edit Invoice"
        >
            <Edit className="w-5 h-5" />
        </motion.button>

        {/* Send Invoice Button */}
        <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setShowSendOptions(true)}
            className="p-2 text-blue-600 hover:text-blue-700 dark:text-blue-400 
                    dark:hover:text-blue-300 transition-colors rounded-md
                    hover:bg-blue-100 dark:hover:bg-blue-900/30"
            title="Send Invoice"
        >
            <Send className="w-5 h-5" />
        </motion.button>

        {/* Preview Button */}
        <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            // onClick={() => nav(`/invoices/${invoice._id}/preview`)}
            onClick={() => {nav(`/documents/${invoice.document_id}/preview`)}}
            className="p-2 text-blue-600 hover:text-blue-700 dark:text-blue-400 
                    dark:hover:text-blue-300 transition-colors rounded-md
                    hover:bg-blue-100 dark:hover:bg-blue-900/30"
            title="Preview Invoice"
        >
            <Eye className="w-5 h-5" />
        </motion.button>

        {/* Delete Button */}
        <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
    onClick={handleDelete}
    disabled={isDeleting || invoice.status === 'paid'}
    className={`p-2 text-red-600 hover:text-red-700 dark:text-red-400 
                    dark:hover:text-red-300 transition-colors rounded-md
              hover:bg-red-100 dark:hover:bg-red-900/30
              ${(isDeleting || invoice.status === 'paid') ? 'opacity-50 cursor-not-allowed' : ''}`}
    title={invoice.status === 'paid' ? 'Paid invoices cannot be deleted' : 'Delete Invoice'}
        >
    {isDeleting ? (
      <div className="w-5 h-5 border-2 border-red-600 border-t-transparent rounded-full animate-spin" />
    ) : (
            <Trash2 className="w-5 h-5" />
        )}
  </motion.button>
        </div>
        {/* Send Options Modal */}
<SendOptionsModal 
  isOpen={showSendOptions} 
  onClose={() => setShowSendOptions(false)} 
  invoice={invoice}
/>
      </div>
    );
  };
  
//   export { InvoiceForm, PaymentForm, InvoiceList, InvoiceViewer };
  export default InvoiceViewer