// src/functions/axiosInstance.js
import axios from "axios";
import authService from "../services/AuthService";

// Create a base axios instance without interceptors
export const createBaseAxiosInstance = (options = {}) => {
  return axios.create({
    ...options,
    headers: {
      "Content-Type": "application/json",
      ...(options.headers || {}),
    },
  });
};

// Create an axios instance with auth interceptors
export const createAxiosInstance = (baseURL) => {
  const instance = axios.create({
    baseURL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  // Request interceptor
  instance.interceptors.request.use(
    async (config) => {
      // Don't add token for auth endpoints (except refresh)
      if (
        config.url.includes("/auth/") &&
        !config.url.includes("/auth/refresh")
      ) {
        return config;
      }

      // If a refresh is already in progress, wait for it to complete before proceeding
      if (authService.isRefreshing && authService.getRefreshPromise()) {
        try {
          console.log(
            "Token refresh in progress, waiting before proceeding with request"
          );
          const newToken = await authService.getRefreshPromise();
          config.headers["Authorization"] = `Bearer ${newToken}`;
          return config;
        } catch (error) {
          console.error("Waiting for token refresh failed:", error);
          // Continue with request, it will fail and be caught by response interceptor
        }
      }

      // Check if token is expired and needs refresh before request
      if (
        authService.shouldRefreshToken() &&
        !config.url.includes("/auth/refresh")
      ) {
        try {
          // Preemptively refresh the token
          console.log("Token expired, refreshing before request");
          const newToken = await authService.refreshToken();
          config.headers["Authorization"] = `Bearer ${newToken}`;
          return config;
        } catch (error) {
          console.error("Preemptive token refresh failed:", error);
          // Continue with request, it will fail and be caught by response interceptor
        }
      }

      // Add token to request if available
      const token = authService.getToken();
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Response interceptor
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      // Handle 401 Unauthorized errors (expired token)
      if (
        error.response?.status === 401 &&
        !originalRequest._retry &&
        !originalRequest.url.includes("/auth/token") &&
        !originalRequest.url.includes("/auth/refresh")
      ) {
        originalRequest._retry = true;

        try {
          return await authService.handleFailedRequest(originalRequest);
        } catch (refreshError) {
          // If refresh fails, it will automatically trigger logout via authService
          return Promise.reject(refreshError);
        }
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

// Export default instances
export const apiAxios = createAxiosInstance(
  // `${process.env.REACT_APP_API_LOCAL_BASE_URL}/api`
  `${process.env.REACT_APP_API_PROD_BASE_URL}/api`
);

export const authAxios = createAxiosInstance(
  // `${process.env.REACT_APP_API_LOCAL_BASE_URL}/auth`
  `${process.env.REACT_APP_API_PROD_BASE_URL}/auth`
);
