import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Send, Link as LinkIcon } from 'lucide-react';
import { toast } from 'react-toastify';
import { useDocument } from '../hooks/useDocument';

const SendOptionsModal = ({ 
  isOpen, 
  onClose, 
  invoice,
  onPublishComplete
}) => {
  // Use the useDocument hook to get the publishDocument mutation
  const { publishDocument } = useDocument();

  // Effect to call onPublishComplete when modal is closed
  useEffect(() => {
    if (!isOpen && onPublishComplete) {
      onPublishComplete();
    }
  }, [isOpen, onPublishComplete]);

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black/50 flex items-center justify-center z-50"
          onClick={(e) => {
            if (e.target === e.currentTarget) onClose();
          }}
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            className="bg-white dark:bg-darkbg-800 rounded-lg p-6 w-[400px] shadow-xl"
          >
            <h3 className="text-lg font-medium text-grayteck-900 dark:text-grayteck-100 mb-4">
              Send Invoice
            </h3>

            <div className="space-y-4">
              {/* Automatic Send Option */}
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => {
                  // Handle automatic send
                  onClose();
                }}
                className="w-full flex items-center p-4 bg-grayteck-50 dark:bg-darkbg-700 
                         rounded-lg hover:bg-grayteck-100 dark:hover:bg-darkbg-600
                         transition-colors duration-200"
              >
                <div className="p-2 bg-blue-100 dark:bg-blue-900/30 rounded-full mr-4">
                  <Send className="w-5 h-5 text-blue-600 dark:text-blue-400" />
                </div>
                <div className="flex-1 text-left">
                  <h4 className="font-medium text-grayteck-900 dark:text-grayteck-100">
                    Send Automatically
                  </h4>
                  <p className="text-sm text-grayteck-500 dark:text-grayteck-400">
                    Send invoice directly to client's email
                  </p>
                </div>
              </motion.button>

              {/* Manual Send Option */}
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={async () => {
                  console.log('invoice:', invoice);
                  try {
                    // Use the publishDocument mutation from the useDocument hook
                    // This will automatically invalidate the necessary queries and update state
                    await publishDocument(invoice.document_id);
                    onClose();
                    // Call the callback to notify publishing is complete
                    if (onPublishComplete) {
                      onPublishComplete();
                    }
                    // Toast is handled by the mutation
                  } catch (error) {
                    // Error toast is also handled by the mutation
                    console.error('Error publishing document:', error);
                    if (onPublishComplete) {
                      onPublishComplete();
                    }
                  }
                }}
                className="w-full flex items-center p-4 bg-grayteck-50 dark:bg-darkbg-700 
                         rounded-lg hover:bg-grayteck-100 dark:hover:bg-darkbg-600
                         transition-colors duration-200"
              >
                <div className="p-2 bg-purple-100 dark:bg-purple-900/30 rounded-full mr-4">
                  <LinkIcon className="w-5 h-5 text-purple-600 dark:text-purple-400" />
                </div>
                <div className="flex-1 text-left">
                  <h4 className="font-medium text-grayteck-900 dark:text-grayteck-100">
                    Get Public Link
                  </h4>
                  <p className="text-sm text-grayteck-500 dark:text-grayteck-400">
                    Generate a shareable link for manual sending
                  </p>
                </div>
              </motion.button>
            </div>

            {/* Close Button */}
            <div className="mt-6 flex justify-end">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => {
                  onClose();
                  if (onPublishComplete) onPublishComplete();
                }}
                className="px-4 py-2 text-grayteck-600 hover:text-grayteck-900 
                         dark:text-grayteck-400 dark:hover:text-grayteck-200"
              >
                Cancel
              </motion.button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SendOptionsModal;