import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDocument } from "../hooks/useDocument";
import InvoiceContent from "./InvoiceContent";

const PreviewDocument = () => {
  const { documentId } = useParams(); // Get the document ID from the route params
  const navigate = useNavigate();

  // Fetch the document using the hook
  const { document, isLoading, isError, error } = useDocument(documentId);

  useEffect(() => {
    if (isError) {
      console.error("Error fetching document:", error);
    }
  }, [isError, error]);

  if (isLoading) {
    return <div>Loading document preview...</div>;
  }

  if (isError || !document) {
    return (
      <div>
        <p>Failed to load the document.</p>
        <button
          onClick={() => navigate(-1)}
          className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600"
        >
          Back
        </button>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-gray-50 dark:bg-darkbg-900 overflow-auto z-50">
      {/* Back button */}
      <button
        onClick={() => navigate(-1)}
        className="fixed top-4 left-4 px-4 py-2 bg-white dark:bg-darkbg-800 shadow-lg rounded-md flex items-center gap-2 z-50 hover:bg-gray-50 dark:hover:bg-darkbg-700 transition-colors"
      >
        Back
      </button>

      {/* Render the document content with copy functionality */}
      <div className="max-w-[1200px] mx-auto mt-8 p-8 bg-white dark:bg-darkbg-800 shadow-xl rounded-lg">
        <InvoiceContent htmlContent={document.content} />
      </div>
    </div>
  );
};

export default PreviewDocument;
