import { createAxiosInstance } from "./axiosInstance";

// const apiAxios = createAxiosInstance(`${process.env.REACT_APP_API_LOCAL_BASE_URL}/api`);
const apiAxios = createAxiosInstance(
  `${process.env.REACT_APP_API_PROD_BASE_URL}/api`
  // `${process.env.REACT_APP_API_LOCAL_BASE_URL}/api`
);

// Add a request interceptor to include the token in all requests
apiAxios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle 401 errors
apiAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Clear the token and redirect to login
      localStorage.removeItem("access_token");
      window.dispatchEvent(new CustomEvent("unauthorized"));
    }
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle 401 errors
// apiAxios.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response && error.response.status === 401) {
//       // Clear the token and redirect to login
//       localStorage.removeItem('access_token');
//       window.dispatchEvent(new CustomEvent('unauthorized'));
//     }
//     return Promise.reject(error);
//   }
// );

export const getAllDocuments = async ({
  page = 0,
  limit = 10,
  filters = {},
  exclude_types = [], // Add parameter to exclude document types
} = {}) => {
  try {
    const queryParams = new URLSearchParams({
      skip: (page * limit).toString(),
      limit: limit.toString(),
      search: filters.search || "",
      status: filters.status || "all",
    });

    // Add exclude_types parameter if provided
    if (exclude_types.length > 0) {
      queryParams.append("exclude_types", exclude_types.join(","));
    }

    const response = await apiAxios.get(`/documents?${queryParams}`);
    console.log("API Response:", response.data); // Log the full response

    return {
      items: response.data.items || [],
      total: response.data.total || 0,
      page: response.data.page || 0,
      total_pages: response.data.total_pages || 0,
      has_more: response.data.has_more || false,
    };
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

// New Fetcher Function: Get Documents by Current Client ID
export const getMyDocuments = async (exclude_types = []) => {
  try {
    // Convert exclude_types to URL parameters
    const queryParams = new URLSearchParams();
    if (exclude_types.length > 0) {
      queryParams.append("exclude_types", exclude_types.join(","));
    }

    const response = await apiAxios.get(
      `/documents/all/my-documents?${queryParams}`
    );
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const getDocument = async (id) => {
  try {
    const response = await apiAxios.get(`/documents/${id}`);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const createDocument = async (documentData) => {
  try {
    const response = await apiAxios.post("/documents", documentData);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const updateDocument = async ({ id, ...documentData }) => {
  try {
    console.log("fetcher", id);
    console.log("fetcher", documentData);
    const response = await apiAxios.put(`/documents/${id}`, documentData);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const deleteDocument = async (id, type) => {
  try {
    const response = await apiAxios.delete(`/documents/${type}/${id}`);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const publishDocument = async (id) => {
  try {
    const response = await apiAxios.post(`/documents/${id}/publish`);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const getPublicDocument = async (id) => {
  try {
    const response = await apiAxios.get(`/documents/public/${id}`);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

export const getSharedDocument = async (token) => {
  try {
    const response = await apiAxios.get(`/documents/share/${token}`, {
      headers: {
        Accept: "application/json", // Changed from text/html
      },
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

//   export const signDocument = async (token, signerName) => {
//     try {
//       const response = await apiAxios.post(`/documents/sign/${token}`, {
//         signer_name: signerName,
//         signature_date: new Date().toISOString()
//       });
//       return response.data;
//     } catch (error) {
//       throw error.response ? error.response.data : error.message;
//     }
//   };

// api_fethcers.js

export const signDocument = async (token, signatureData) => {
  try {
    const response = await apiAxios.post(`/documents/sign/${token}`, {
      signer_name: signatureData.signer_name,
      signature_img: signatureData.signature_img,
      signature_date: signatureData.signature_date,
    });
    return response.data;
  } catch (error) {
    // Enhanced error handling
    if (error.response) {
      const errorMessage =
        error.response.data.detail || error.response.data.message;
      throw new Error(errorMessage);
    }
    throw new Error("Failed to connect to the server");
  }
};

export const downloadDocumentAsPDF = async (id) => {
  try {
    const response = await apiAxios.get(`/documents/download/${id}`, {
      responseType: "blob", // Ensure we handle binary data
    });

    // Create a link element, trigger download
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `document-${id}.pdf`); // Set the file name for download
    document.body.appendChild(link);
    link.click();

    // Clean up the URL object
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Download PDF error:", error);
    throw error.response ? error.response.data : error.message;
  }
};

export const unpublishDocument = async (id) => {
  try {
    const response = await apiAxios.post(`/documents/${id}/unpublish`);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

export const createClient = async (clientData) => {
  try {
    const response = await apiAxios.post("/clients", clientData);
    return response.data;
  } catch (error) {
    if (error.response?.data) {
      throw new Error(error.response.data.detail || "Failed to create client");
    }
    throw error;
  }
};

export const getClients = async ({
  page = 0,
  limit = 10,
  filters = {},
} = {}) => {
  try {
    const skip = page * limit;
    const queryParams = new URLSearchParams({
      skip: skip.toString(),
      limit: limit.toString(),
      search: filters.search || "",
      status: filters.status || "all",
      client_type: filters.client_type || "all",
    });

    const response = await apiAxios.get(`/clients?${queryParams}`);

    return {
      items: response.data.items || [],
      total: response.data.total || 0,
      page: response.data.page || page,
      total_pages: response.data.total_pages || 0,
      has_more: response.data.has_more || false,
    };
  } catch (error) {
    if (!error.response) {
      throw new Error("Network error occurred. Please check your connection.");
    }

    if (error.response?.data) {
      throw new Error(error.response.data.detail || "Failed to fetch clients");
    }

    throw new Error("An unexpected error occurred while fetching clients");
  }
};

// Add this new fetcher function
export const getClient = async (id) => {
  try {
    const response = await apiAxios.get(`/clients/${id}`);
    return response.data;
  } catch (error) {
    // Enhanced error handling
    if (error.response) {
      const errorMessage =
        error.response.data.detail || error.response.data.message;
      throw new Error(errorMessage);
    }
    throw new Error("Failed to connect to the server");
  }
};

export const createNote = async (clientId, noteContent) => {
  try {
    const response = await apiAxios.post(`/clients/${clientId}/notes`, {
      content: noteContent,
    });
    return response.data;
  } catch (error) {
    if (error.response?.data) {
      throw new Error(error.response.data.detail || "Failed to create note");
    }
    throw new Error("Failed to connect to the server");
  }
};

// Available Services API Functions
export const getAvailableServices = async () => {
  try {
    const response = await apiAxios.get("/services/available");
    return response.data;
  } catch (error) {
    throw error.response?.data || error.message || error.detail;
  }
};

export const createAvailableService = async (serviceData) => {
  try {
    const response = await apiAxios.post("/services/available", serviceData);
    return response.data;
  } catch (error) {
    throw error.response?.data || error.message || error.detail;
  }
};

export const updateAvailableService = async ({ id, ...data }) => {
  try {
    const response = await apiAxios.put(`/services/available/${id}`, data);
    return response.data;
  } catch (error) {
    throw error.response?.data || error.message || error.detail;
  }
};

export const deleteAvailableService = async (id) => {
  try {
    const response = await apiAxios.delete(`/services/available/${id}`);
    return response.data;
  } catch (error) {
    throw error.response?.data || error.message || error.detail;
  }
};

export const addServiceToClient = async (clientId, serviceData) => {
  try {
    const response = await apiAxios.post(
      `/clients/${clientId}/services`,
      serviceData
    );
    return response.data;
  } catch (error) {
    throw error.response?.data || error.message || error.detail;
  }
};

// Add this to your api_fetchers.js
export const deleteServiceFromClient = async (clientId, serviceId) => {
  try {
    const response = await apiAxios.delete(
      `/clients/${clientId}/services/${serviceId}`
    );
    return response.data;
  } catch (error) {
    if (error.response?.data) {
      throw new Error(error.response.data.detail || "Failed to delete service");
    }
    throw new Error("Failed to connect to the server");
  }
};

// Update the api_fetchers.js file
export const updateClientPortalAccess = async (clientId, enable) => {
  try {
    const response = await apiAxios.patch(
      `/clients/${clientId}/portal-access`,
      {
        enable: enable,
      }
    );
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

// Add this to your api_fetchers.js
export const deleteClient = async (clientId) => {
  try {
    const response = await apiAxios.delete(`/clients/${clientId}`);
    return response.data;
  } catch (error) {
    if (error.response?.data) {
      throw new Error(error.response.data.detail || "Failed to delete client");
    }
    throw new Error("Failed to connect to the server");
  }
};

// In api_fetchers.js
export const updateServiceToClient = async (
  clientId,
  serviceId,
  serviceData
) => {
  try {
    const response = await apiAxios.put(
      `/clients/${clientId}/services/${serviceId}`,
      serviceData
    );
    return response.data;
  } catch (error) {
    if (error.response?.data) {
      throw new Error(error.response.data.detail || "Failed to update service");
    }
    throw new Error("Failed to connect to the server");
  }
};

export const getInvoices = async ({
  clientId,
  page = 0,
  limit = 20,
  status = "all",
}) => {
  try {
    const queryParams = new URLSearchParams({
      client_id: clientId,
      skip: (page * limit).toString(),
      limit: limit.toString(),
      status: status,
    });

    const response = await apiAxios.get(`/invoices/?${queryParams}`);
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const getInvoicesForPortal = async ({
  clientId,
  page = 0,
  limit = 20,
}) => {
  try {
    const queryParams = new URLSearchParams({
      client_id: clientId,
      skip: (page * limit).toString(),
      limit: limit.toString(),
      portal: "true", // Indicate that this request is for the client portal
    });

    const response = await apiAxios.get(`/invoices/?${queryParams}`);
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const getClientInvoices = async ({ page = 0, limit = 20 } = {}) => {
  try {
    const queryParams = new URLSearchParams({
      skip: (page * limit).toString(), // Calculate the number of records to skip
      limit: limit.toString(), // Number of records to retrieve
    });

    // Make a GET request to the client-specific invoices route
    const response = await apiAxios.get(
      `/invoices/client/invoices/?${queryParams}`
    );

    // Structure the response to match your frontend's expected format
    return {
      items: response.data.items || [],
      total: response.data.total || 0,
      page: response.data.page || 0,
      total_pages: response.data.total_pages || 0,
      has_more: response.data.has_more || false,
    };
  } catch (error) {
    // Enhanced error handling
    if (error.response?.data) {
      throw new Error(
        error.response.data.detail || "Failed to fetch client invoices"
      );
    }
    throw new Error(error.message || "An unexpected error occurred");
  }
};

export const createInvoice = async (invoiceData) => {
  const response = await apiAxios.post("/invoices", invoiceData);
  return response.data;
};

export const updateInvoice = async ({ id, ...invoiceData }) => {
  try {
    const response = await apiAxios.put(`/invoices/${id}`, invoiceData);
    return response.data;
  } catch (error) {
    if (error.response?.data) {
      throw new Error(error.response.data.detail || "Failed to update invoice");
    }
    throw error;
  }
};

export const deleteInvoice = async (invoiceId) => {
  try {
    const response = await apiAxios.delete(`/invoices/${invoiceId}`);
    return response.data;
  } catch (error) {
    if (error.response?.data) {
      throw new Error(error.response.data.detail || "Failed to delete invoice");
    }
    throw error;
  }
};

export const getDocumentShareUrl = async (document_id) => {
  try {
    const response = await apiAxios.get(`/documents/share-url/${document_id}`);
    return response.data; // Expected to return { share_url: string, token: string }
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

export const getClientDocuments = async (clientId, options = {}) => {
  try {
    const params = new URLSearchParams({
      ...(options.excludeTypes && {
        exclude_types: options.excludeTypes.join(","),
      }),
    });

    const response = await apiAxios.get(
      `/documents/client/${clientId}/documents?${params}`
    );
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const createEngagement = async (clientId, engagementData) => {
  try {
    // POST /clients/{client_id}/engagements
    const response = await apiAxios.post(
      `/clients/${clientId}/engagements`,
      engagementData
    );
    return response.data; // Should be an EngagementInDB object
  } catch (error) {
    if (error.response?.data) {
      throw new Error(
        error.response.data.detail || "Failed to create engagement"
      );
    }
    throw new Error("Failed to connect to the server");
  }
};

export const updateEngagement = async (
  clientId,
  engagementId,
  engagementData
) => {
  try {
    const response = await apiAxios.put(
      `/clients/${clientId}/engagements/${engagementId}`,
      engagementData
    );
    return response.data; // This should be the updated EngagementInDB
  } catch (error) {
    if (error.response?.data) {
      throw new Error(
        error.response.data.detail || "Failed to update engagement"
      );
    }
    throw new Error("Failed to connect to the server");
  }
};

export const uploadTranscriptToEngagement = async ({
  clientId,
  engagementId,
  transcriptFile,
  speakers = {},
}) => {
  const formData = new FormData();
  formData.append("file", transcriptFile);
  // Convert the speakers dict to JSON string
  formData.append("speakers", JSON.stringify(speakers));

  const response = await apiAxios.post(
    `/clients/${clientId}/engagements/${engagementId}/transcripts`,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
  return response.data; // e.g. { detail, transcript_path, speakers_applied }
};

export const deleteTranscriptFromEngagement = async ({
  clientId,
  engagementId,
  transcriptId,
}) => {
  // Example route: DELETE /clients/{clientId}/engagements/{engagementId}/transcripts/{transcriptId}
  // Adjust if your route is different
  const response = await apiAxios.delete(
    `/clients/${clientId}/engagements/${engagementId}/transcripts/${transcriptId}`
  );
  return response.data; // e.g. returns updated EngagementInDB
};

// api_fetchers.js

export const getTranscriptText = async (
  clientId,
  engagementId,
  transcriptId
) => {
  try {
    const response = await apiAxios.get(
      `/clients/${clientId}/engagements/${engagementId}/transcripts/${transcriptId}/text`
    );
    // The API returns { transcript_text: "..." }
    return response.data; // e.g. { transcript_text: "..." }
  } catch (error) {
    if (error.response?.data) {
      throw new Error(
        error.response.data.detail || "Failed to get transcript text"
      );
    }
    throw new Error("Failed to connect to the server");
  }
};

export const generateNotesFromTranscript = async ({
  clientId,
  engagementId,
  transcriptId,
}) => {
  try {
    const response = await apiAxios.post(
      `/clients/${clientId}/engagements/${engagementId}/analyze-transcript/${transcriptId}`
    );
    return response.data; // Returns the updated EngagementInDB with structured notes
  } catch (error) {
    if (error.response?.data) {
      throw new Error(
        error.response.data.detail || "Failed to generate notes from transcript"
      );
    }
    throw new Error("Failed to connect to the server");
  }
};

export const deleteEngagement = async (clientId, engagementId) => {
  try {
    const response = await apiAxios.delete(
      `/clients/${clientId}/engagements/${engagementId}`
    );
    return response.data;
  } catch (error) {
    if (error.response?.data) {
      throw new Error(
        error.response.data.detail || "Failed to delete engagement"
      );
    }
    throw new Error("Failed to connect to the server");
  }
};

export const updateClient = async ({ clientId, clientData }) => {
  try {
    const response = await apiAxios.put(`/clients/${clientId}`, clientData);
    return response.data;
  } catch (error) {
    if (error.response?.data) {
      throw new Error(error.response.data.detail || "Failed to update client");
    }
    throw new Error("Failed to connect to the server");
  }
};
