// src/components/ClientPortalInvoiceDashboard.jsx

import React, { useMemo, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Search, Check, AlertTriangle, Clock, Eye, FileDownload, ExternalLink, FileText } from 'lucide-react'; // Added FileDownload and ExternalLink icons
import { useClientInvoices } from '../hooks/useInvoices';  // Or wherever your invoices hook is
import { useAuth } from '../hooks/useAuth';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useHeader } from '../context/HeaderContext';
import PaginatedInvoiceTable from './PaginatedInvoiceTable';
import { useClientDocs } from '../hooks/useClientDocs';

/**
 * This dashboard displays your client's Invoices in a style similar to your existing
 * ClientPortalDashboard component. The user can filter by status, search, and see
 * invoice details such as creation date, due date, status, etc.
 */
const ClientPortalInvoiceDashboard = () => {
  // Tab state: 'all', 'pending', 'paid', or 'overdue'
  const [activeTab, setActiveTab] = useState('all');
  
  // Search term for invoice_number or notes
  const [searchTerm, setSearchTerm] = useState('');
  
  // Pagination state for client portal
  const [page, setPage] = useState(0);
  const limit = 20; // Invoices per page
  
  // Router & Auth
  const navigate = useNavigate();

  // Auth hook and user data (if needed)
  const { logout } = useAuth();
  const user = JSON.parse(localStorage.getItem('docusign_user')) || {};

  // Header context (for putting a search bar in the header)
  const { setExtraHeaderContent } = useHeader();

  // 1) Pull client portal invoices from the updated useClientInvoices hook
  // Mapping the returned values to match existing variable names
  const {
    clientInvoices: clientPortalInvoices,
    pagination: clientPortalPagination,
    isLoading: isLoadingClientPortal,
    isError: isErrorClientPortal,
    error: errorClientPortal,
    refetch
  } = useClientInvoices({ page, limit });


  // 2) Use the useClientDocs hook to get the downloadDocument and getShareableLink mutations
  const {
    downloadDocument,
    isDownloading,
    downloadError,
    getShareableLink,
    isFetchingShareableLink,
    fetchShareableLinkError
  } = useClientDocs();
  
  // 2) Insert a search bar into the header
  useEffect(() => {
    const searchBar = (
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Search className="h-4 w-4 text-grayteck-400" />
        </div>
        <input
          type="text"
          placeholder="Search Invoices"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="pl-9 pr-4 py-2 rounded-lg border border-grayteck-300 dark:border-grayteck-600
                     bg-white dark:bg-darkbg-700 text-grayteck-900 dark:text-grayteck-100
                     focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300 w-64"
        />
      </div>
    );
    setExtraHeaderContent(searchBar);
    return () => {
      setExtraHeaderContent(null);
    };
  }, [searchTerm, setExtraHeaderContent]);

  // 3) Helpers for invoice status
  const getInvoiceStatusLabel = (status) => {
    switch (status) {
      case 'draft':     return 'Draft';
      case 'pending':
      case 'opened':    // Treat 'opened' as 'pending'
        return 'Pending';
      case 'paid':      return 'Paid';
      case 'overdue':   return 'Overdue';
      case 'cancelled': return 'Cancelled';
      default:          return 'Unknown';
    }
  };
  

  const getStatusColor = (status) => {
    switch (status) {
      case 'paid':
        return 'bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-300';
      case 'pending':
        return 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/30 dark:text-yellow-300';
      case 'opened':
        return 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/30 dark:text-yellow-300';
      case 'overdue':
        return 'bg-red-100 text-red-800 dark:bg-red-900/30 dark:text-red-300';
      case 'cancelled':
        return 'bg-grayteck-100 text-grayteck-800 dark:bg-grayteck-900/30 dark:text-grayteck-300';
      case 'draft':
      default:
        return 'bg-grayteck-100 text-grayteck-800 dark:bg-grayteck-900/30 dark:text-grayteck-300';
    }
  };

  // 4) Stats: count 'all', 'pending', 'paid', 'overdue'
  const stats = useMemo(() => {
    if (!clientPortalInvoices) {
      return { all: 0, pending: 0, paid: 0, overdue: 0 };
    }
    return clientPortalInvoices.reduce(
      (acc, inv) => {
        acc.all++;
        if (inv.status === 'pending')  acc.pending++;
        if (inv.status === 'opened')  acc.pending++;
        if (inv.status === 'paid')     acc.paid++;
        if (inv.status === 'overdue')  acc.overdue++;
        return acc;
      },
      { all: 0, pending: 0, paid: 0, overdue: 0 }
    );
  }, [clientPortalInvoices]);

  // 5) Filter the invoices by activeTab and searchTerm
  const filteredInvoices = useMemo(() => {
    if (!clientPortalInvoices) return [];

    return clientPortalInvoices.filter((invoice) => {
      // Filter by active tab
      if (activeTab === 'pending' && invoice.status !== 'pending' && invoice.status !== 'opened') return false;
      if (activeTab === 'paid'     && invoice.status !== 'paid')    return false;
      if (activeTab === 'overdue'  && invoice.status !== 'overdue') return false;
      // 'all' => no filter by status

      // Filter by search term (invoice_number or notes)
      if (searchTerm) {
        const haystack = (
          (invoice.invoice_number || '') +
          ' ' +
          (invoice.notes || '')
        ).toLowerCase();
        if (!haystack.includes(searchTerm.toLowerCase())) {
          return false;
        }
      }

      return true;
    });
  }, [clientPortalInvoices, activeTab, searchTerm]);

  // 6) Loading / Error states for client portal
  if (isLoadingClientPortal) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-grayteck-600"></div>
      </div>
    );
  }

  if (isErrorClientPortal) {
    return (
      <div className="text-center mt-10 text-red-500">
        Error loading your invoices: {errorClientPortal?.message}
      </div>
    );
  }

  // 8) Handler for Open External Link
  const handleOpenExternalLink = async (documentId) => {
    try {
      const data = await getShareableLink(documentId);
      if (data && data.share_url) {
        window.open(data.share_url, '_blank');
      } else {
        toast.error('Shareable link not available');
      }
    } catch (error) {
      console.error('Error fetching shareable link:', error);
      // Error toast is already handled in the mutation
    }
  };

  // 9) Handler for Download Invoice
  const handleDownloadInvoice = async (invoiceDocId) => {
    try {
      await downloadDocument(invoiceDocId);
    } catch (error) {
      console.error('Download failed:', error);
    }
  };

  // 10) Render Stats + Paginated Table
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Stats Cards */}
      <div className="grid grid-cols-4 gap-6 mb-8">
        {[
          { id: 'all', title: 'All Invoices',  count: stats.all,     icon: FileText,    color: 'gray' },
          { id: 'pending', title: 'Pending',   count: stats.pending, icon: Clock,       color: 'yellow' },
          { id: 'paid',    title: 'Paid',      count: stats.paid,    icon: Check,       color: 'green' },
          { id: 'overdue', title: 'Overdue',   count: stats.overdue, icon: AlertTriangle,color: 'red' }
        ].map((stat) => (
          <motion.button
            key={stat.id}
            onClick={() => setActiveTab(stat.id)}
            whileHover={{ y: -4 }}
            whileTap={{ y: 0 }}
            className={`group relative overflow-hidden rounded-lg ${
              activeTab === stat.id
                ? 'border-2 border-blue-500 dark:border-blue-400'
                : 'border border-grayteck-200 dark:border-grayteck-700'
            }`}
          >
            {/* If you want an active indicator on the left: */}
            {activeTab === stat.id && (
              <motion.div
                layoutId="activeIndicator"
                className="absolute left-0 top-0 w-1 h-full bg-blue-500"
              />
            )}

            <div
              className={`
                relative p-6 transition-all duration-200
                ${
                  activeTab === stat.id
                    ? 'bg-white dark:bg-darkbg-800'
                    : 'bg-grayteck-50 dark:bg-darkbg-700 hover:bg-white dark:hover:bg-darkbg-800'
                }
              `}
            >
              <div className="flex items-center justify-between">
                <div>
                  <p
                    className={`text-sm font-medium mb-1 transition-colors ${
                      activeTab === stat.id
                        ? 'text-grayteck-900 dark:text-grayteck-100'
                        : 'text-grayteck-600 dark:text-grayteck-400'
                    }`}
                  >
                    {stat.title}
                  </p>
                  <p className="text-3xl font-bold text-grayteck-900 dark:text-grayteck-100">
                    {stat.count}
                  </p>
                </div>
                <div
                  className={`
                    p-3 rounded-lg transition-colors
                    ${
                      activeTab === stat.id
                        ? `bg-${stat.color}-100 dark:bg-${stat.color}-900/30`
                        : 'bg-white dark:bg-darkbg-600 group-hover:bg-grayteck-100 dark:group-hover:bg-darkbg-700'
                    }
                  `}
                >
                  <stat.icon
                    className={`
                      w-6 h-6 transition-colors
                      ${
                        activeTab === stat.id
                          ? `text-${stat.color}-600 dark:text-${stat.color}-400`
                          : 'text-grayteck-400 dark:text-grayteck-500'
                      }
                    `}
                  />
                </div>
              </div>
            </div>
          </motion.button>
        ))}
      </div>

      {/* Paginated Invoice Table */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="relative bg-white dark:bg-darkbg-800 rounded-lg shadow-lg overflow-hidden"
      >
        <PaginatedInvoiceTable
          invoices={filteredInvoices}
          isLoading={isLoadingClientPortal || isDownloading || isFetchingShareableLink}
          pagination={clientPortalPagination}
          onPageChange={(newPage) => setPage(newPage)}
          getInvoiceStatusColor={getStatusColor}
          onViewDetails={(invoice) => {
            // Example: navigate to a details page or open a modal
            navigate(`/client-portal/invoices/${invoice._id}`);
          }}
          onUnpublish={(invoice) => {
            // If you have an unpublish mutation, call it here
            toast.info(`Unpublish Invoice #${invoice.invoice_number}`);
          }}
          onSendInvoice={(invoice) => {
            // If you have a send mutation, call it here
            toast.info(`Send Invoice #${invoice.invoice_number}`);
          }}
          onOpenExternalLink={handleOpenExternalLink} // Pass the open external link handler
          onDownloadInvoice={handleDownloadInvoice} // Pass the download handler
          context="client" // Specify the context as 'client'
        />
      </motion.div>

      {/* Handle Download and Shareable Link Errors */}
      {(downloadError || fetchShareableLinkError) && (
        <div className="mt-4 text-center text-red-500">
          {downloadError && `Error downloading invoice: ${downloadError.message}`}
          {fetchShareableLinkError && `Error fetching shareable link: ${fetchShareableLinkError.message}`}
        </div>
      )}
    </div>
  );
};

export default ClientPortalInvoiceDashboard;
