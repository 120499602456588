import React from 'react';
import { motion } from 'framer-motion';
import { DollarSign, Clock, AlertTriangle, Plus } from 'lucide-react';
import PaginatedInvoiceTable from './PaginatedInvoiceTable';


const BillingSection = ({
  // The data and callbacks you need:
  invoices,          // array of invoice objects
  stats,             // object { totalPaid, totalPending, overdue, upcomingPayments }
  isLoading,         // for invoice table loading
  isPublishing,      // loading state for publishing
  isUnpublishing,    // loading state for unpublishing
  publishingInvoiceId, // ID of invoice being published
  unpublishingInvoiceId, // ID of invoice being unpublished
  onCreateInvoice,   // callback when user clicks "Create Invoice" button
  onViewInvoice,     // callback for "View Details" on a specific invoice
  onSendInvoice,     // callback if user wants to send invoice
  onUnpublish,       // callback to unpublish an invoice
  getInvoiceStatusColor, // function that returns color classes based on invoice status

  // If you are also doing paging here:
  currentPage,       // or pass in some pagination object
  onPageChange,      // if you want the table to handle page changes
  ITEMS_PER_PAGE,    // or you can keep this logic inside the parent, your choice
  onEditInvoice,

  // If you also want "onEditInvoice", you can add that here
  // ...
}) => {
  if (!stats) return null; // or just fail gracefully if no stats

  return (
    <div className="space-y-6">
      {/* 1) Billing Overview Cards (Paid, Pending, Overdue) */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* ----- Total Revenue ----- */}
        <div className="bg-white dark:bg-darkbg-800 rounded-lg p-6 shadow-md">
          <div className="flex justify-between items-start">
            <div>
              <p className="text-sm font-medium text-grayteck-600 dark:text-grayteck-400">
                Total Revenue
              </p>
              <p className="mt-2 text-3xl font-bold text-grayteck-900 dark:text-grayteck-100">
                ${stats.totalPaid.toFixed(2)}
              </p>
            </div>
            <div className="p-3 bg-green-100 dark:bg-green-900/30 rounded-full">
              <DollarSign className="w-6 h-6 text-green-600 dark:text-green-400" />
            </div>
          </div>
          <div className="mt-4 text-sm text-grayteck-600 dark:text-grayteck-400">
            Total paid invoices to date
          </div>
        </div>

        {/* ----- Pending Payments ----- */}
        <div className="bg-white dark:bg-darkbg-800 rounded-lg p-6 shadow-md">
          <div className="flex justify-between items-start">
            <div>
              <p className="text-sm font-medium text-grayteck-600 dark:text-grayteck-400">
                Pending Payments
              </p>
              <p className="mt-2 text-3xl font-bold text-grayteck-900 dark:text-grayteck-100">
                ${stats.totalPending.toFixed(2)}
              </p>
            </div>
            <div className="p-3 bg-yellow-100 dark:bg-yellow-900/30 rounded-full">
              <Clock className="w-6 h-6 text-yellow-600 dark:text-yellow-400" />
            </div>
          </div>
          <div className="mt-4 text-sm text-grayteck-600 dark:text-grayteck-400">
            {stats.upcomingPayments.length} pending invoices
          </div>
        </div>

        {/* ----- Overdue Payments ----- */}
        <div className="bg-white dark:bg-darkbg-800 rounded-lg p-6 shadow-md">
          <div className="flex justify-between items-start">
            <div>
              <p className="text-sm font-medium text-grayteck-600 dark:text-grayteck-400">
                Overdue Payments
              </p>
              <p className="mt-2 text-3xl font-bold text-grayteck-900 dark:text-grayteck-100">
                ${stats.overdue.toFixed(2)}
              </p>
            </div>
            <div className="p-3 bg-red-100 dark:bg-red-900/30 rounded-full">
              <AlertTriangle className="w-6 h-6 text-red-600 dark:text-red-400" />
            </div>
          </div>
          <div className="mt-4 text-sm text-grayteck-600 dark:text-grayteck-400">
            Requires immediate attention
          </div>
        </div>
      </div>

      {/* 2) Invoice List + "Create Invoice" Button */}
      <div className="bg-white dark:bg-darkbg-800 rounded-lg shadow-md">
        <div className="p-6 border-b border-grayteck-200 dark:border-grayteck-700">
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-medium text-grayteck-900 dark:text-grayteck-100">
              Invoices
            </h3>
          <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            onClick={onCreateInvoice}
              className="px-4 py-2 bg-grayteck-600 text-white rounded-md hover:bg-grayteck-700 
                         flex items-center space-x-2"
          >
            <Plus className="w-5 h-5" />
            <span>Create Invoice</span>
          </motion.button>
          </div>
        </div>

        {/* Your Paginated Invoice Table */}
        <PaginatedInvoiceTable
          invoices={invoices}
          isLoading={isLoading}
          isPublishing={isPublishing}
          isUnpublishing={isUnpublishing}
          publishingInvoiceId={publishingInvoiceId}
          unpublishingInvoiceId={unpublishingInvoiceId}
          pagination={{
            // If you want to pass them:
            currentPage,
            totalPages: Math.ceil((invoices?.length || 0) / (ITEMS_PER_PAGE || 20)),
            totalItems: invoices?.length || 0,
            hasMore:
              ((currentPage || 0) + 1) * (ITEMS_PER_PAGE || 20) < (invoices?.length || 0),
          }}
          onPageChange={onPageChange}
          onViewDetails={onViewInvoice}
          onUnpublish={onUnpublish}
          onSendInvoice={onSendInvoice}
          getInvoiceStatusColor={getInvoiceStatusColor}
        />
      </div>
    </div>
  );
};

export default BillingSection;
