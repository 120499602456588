// src/pages/TokenTestPage.js
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { RefreshCw, Check, AlertTriangle, FileText, Home, LogOut } from 'lucide-react';
import { toast } from 'react-toastify';
import { authStorage } from '../functions/auth';
import { getAllDocuments } from '../functions/api_fethcers';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { useAuthContext } from '../context/AuthContext';
import authService from '../services/AuthService';

const TokenRefreshTest = () => {
  const [accessToken, setAccessToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const [expiryTime, setExpiryTime] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [lastRefresh, setLastRefresh] = useState(null);
  const [testRequestStatus, setTestRequestStatus] = useState(null);

  const navigate = useNavigate();
  const authContext = useAuthContext();
  const { logout, refresh, isRefreshing, refreshError } = useAuth();
  
  // Function to update token info
  const updateTokenInfo = () => {
    const authData = authStorage.get();
    if (authData) {
      setAccessToken(authData.accessToken);
      setRefreshToken(authData.refreshToken);
      setExpiryTime(new Date(authData.expiresAt));
      
      // Calculate time remaining
      const remaining = new Date(authData.expiresAt) - new Date();
      setTimeRemaining(Math.max(0, remaining));
    }
  };

  // Update token info on mount and every second
  useEffect(() => {
    updateTokenInfo();
    const interval = setInterval(updateTokenInfo, 1000);
    
    // Listen for refresh events
    const handleRefreshSuccess = () => {
      setLastRefresh(new Date());
      updateTokenInfo();
      toast.success('Token refreshed successfully');
    };
    
    window.addEventListener(authService.events.REFRESH_SUCCESS, handleRefreshSuccess);
    
    return () => {
      clearInterval(interval);
      window.removeEventListener(authService.events.REFRESH_SUCCESS, handleRefreshSuccess);
    };
  }, []);

  // Navigation handlers
  const handleHome = () => navigate('/');
  const handleLogout = async () => {
    try {
      await logout.mutateAsync();
    } catch (error) {
      console.error('Logout failed:', error);
      toast.error('Logout failed');
    }
  };

  // Format time remaining
  const formatTimeRemaining = (ms) => {
    if (!ms) return 'Token expired';
    const seconds = Math.floor(ms / 1000);
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  // Function to manually trigger token refresh
  const handleManualRefresh = async () => {
    try {
      await refresh.mutateAsync();
      setLastRefresh(new Date());
      updateTokenInfo();
    } catch (error) {
      console.error('Manual refresh failed:', error);
    }
  };

  // Test protected API request
  const handleTestRequest = async () => {
    try {
      setTestRequestStatus('loading');
      await getAllDocuments();
      setTestRequestStatus('success');
      toast.success('API request successful');
    } catch (error) {
      console.error('API request failed:', error);
      setTestRequestStatus('error');
      toast.error('API request failed');
    } finally {
      setTimeout(() => setTestRequestStatus(null), 3000);
    }
  };

  const handleForceExpire = () => {
    // Simulate token expiration by setting expiry to now
    const authData = authStorage.get();
    if (authData) {
      authData.expiresAt = new Date().toISOString();
      authStorage.set(authData);
      updateTokenInfo();
      toast.info('Token expiration forced');
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white dark:bg-darkbg-800 rounded-lg shadow-lg">
      {/* Add Navigation Buttons at the top */}
      <div className="flex justify-between mb-6">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleHome}
          className="px-4 py-2 bg-grayteck-600 text-white rounded-md flex items-center space-x-2 hover:bg-grayteck-700 transition-colors"
        >
          <Home className="w-4 h-4" />
          <span>Home</span>
        </motion.button>

        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleLogout}
          className="px-4 py-2 bg-red-600 text-white rounded-md flex items-center space-x-2 hover:bg-red-700 transition-colors"
        >
          <LogOut className="w-4 h-4" />
          <span>Logout</span>
        </motion.button>
      </div>
      
      <h2 className="text-2xl font-bold mb-6 text-grayteck-900 dark:text-grayteck-100">
        Token Refresh Test
      </h2>

      {/* Token Information */}
      <div className="space-y-4 mb-6">
        <div>
          <h3 className="text-sm font-medium text-grayteck-500 dark:text-grayteck-400 mb-1">
            Access Token
          </h3>
          <div className="p-2 bg-grayteck-50 dark:bg-darkbg-700 rounded-md">
            <p className="text-xs font-mono break-all">
              {accessToken || 'No token'}
            </p>
          </div>
        </div>

        <div>
          <h3 className="text-sm font-medium text-grayteck-500 dark:text-grayteck-400 mb-1">
            Refresh Token
          </h3>
          <div className="p-2 bg-grayteck-50 dark:bg-darkbg-700 rounded-md">
            <p className="text-xs font-mono break-all">
              {refreshToken || 'No token'}
            </p>
          </div>
        </div>

        {/* Time Information */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <h3 className="text-sm font-medium text-grayteck-500 dark:text-grayteck-400 mb-1">
              Time Remaining
            </h3>
            <div className="p-2 bg-grayteck-50 dark:bg-darkbg-700 rounded-md">
              <p className={`font-mono ${timeRemaining > 60000 ? 'text-green-500' : 'text-red-500'}`}>
                {formatTimeRemaining(timeRemaining)}
              </p>
            </div>
          </div>

          <div>
            <h3 className="text-sm font-medium text-grayteck-500 dark:text-grayteck-400 mb-1">
              Last Refresh
            </h3>
            <div className="p-2 bg-grayteck-50 dark:bg-darkbg-700 rounded-md">
              <p className="font-mono">
                {lastRefresh ? lastRefresh.toLocaleTimeString() : 'Never'}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Action Buttons */}
      <div className="space-y-4">
        {/* Manual Refresh Button */}
        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={handleManualRefresh}
          disabled={isRefreshing}
          className={`w-full p-3 rounded-lg flex items-center justify-center space-x-2 
            ${isRefreshing ? 'bg-grayteck-400' : 
              refresh.isSuccess ? 'bg-green-500' :
              refreshError ? 'bg-red-500' :
              'bg-grayteck-600 hover:bg-grayteck-700'} 
            text-white transition-colors duration-200`}
        >
          {isRefreshing ? (
            <>
              <RefreshCw className="w-5 h-5 animate-spin" />
              <span>Refreshing...</span>
            </>
          ) : refresh.isSuccess ? (
            <>
              <Check className="w-5 h-5" />
              <span>Refresh Successful!</span>
            </>
          ) : refreshError ? (
            <>
              <AlertTriangle className="w-5 h-5" />
              <span>Refresh Failed</span>
            </>
          ) : (
            <>
              <RefreshCw className="w-5 h-5" />
              <span>Refresh Token</span>
            </>
          )}
        </motion.button>

        {/* Force Expire Button */}
        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={handleForceExpire}
          className="w-full p-3 rounded-lg flex items-center justify-center space-x-2 bg-yellow-500 hover:bg-yellow-600 text-white transition-colors duration-200"
        >
          <AlertTriangle className="w-5 h-5" />
          <span>Force Token Expiration</span>
        </motion.button>

        {/* Test API Request Button */}
        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={handleTestRequest}
          disabled={testRequestStatus === 'loading'}
          className={`w-full p-3 rounded-lg flex items-center justify-center space-x-2 
            ${testRequestStatus === 'loading' ? 'bg-grayteck-400' : 
              testRequestStatus === 'success' ? 'bg-green-500' :
              testRequestStatus === 'error' ? 'bg-red-500' :
              'bg-grayteck-600 hover:bg-grayteck-700'} 
            text-white transition-colors duration-200`}
        >
          {testRequestStatus === 'loading' ? (
            <>
              <FileText className="w-5 h-5 animate-spin" />
              <span>Making Request...</span>
            </>
          ) : testRequestStatus === 'success' ? (
            <>
              <Check className="w-5 h-5" />
              <span>Request Successful!</span>
            </>
          ) : testRequestStatus === 'error' ? (
            <>
              <AlertTriangle className="w-5 h-5" />
              <span>Request Failed</span>
            </>
          ) : (
            <>
              <FileText className="w-5 h-5" />
              <span>Test Protected Request</span>
            </>
          )}
        </motion.button>
      </div>
    </div>
  );
};

export default TokenRefreshTest;