// Import necessary components
import React from 'react';
import { Tooltip } from 'react-tooltip';
import { Briefcase } from 'lucide-react'; // Example icon

const StatCard = ({ label, count, IconComponent, tooltipId, tooltipContent }) => (
  <div className="bg-white dark:bg-darkbg-800 rounded-lg p-6 shadow-md">
    <div className="flex items-center justify-between">
      {/* Text and Number Section */}
      <div>
        {/* Label: Hidden below 891px */}
        <p className="hidden custom:block text-sm text-grayteck-600 dark:text-grayteck-400">
          {label}
        </p>
        {/* Number: Always visible */}
        <p className="mt-1 text-2xl font-semibold text-grayteck-900 dark:text-grayteck-100">
          {count}
        </p>
      </div>
      
      {/* Icon Section */}
      <div className="p-3 bg-blue-100 dark:bg-blue-900/30 rounded-full">
        <IconComponent 
          className="w-6 h-6 text-blue-600 dark:text-blue-400" 
          aria-label={label} 
          title={label} 
          data-tooltip-id={tooltipId} 
          data-tooltip-content={tooltipContent} 
        />
        <Tooltip id={tooltipId} place="top" effect="solid" />
      </div>
    </div>
  </div>
);

export default StatCard;
