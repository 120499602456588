// src/services/AuthService.js
import { use } from "react";
import { authStorage } from "../functions/auth";

class AuthService {
  constructor() {
    this._refreshPromise = null;
    this.isRefreshing = false;
    this.requestQueue = [];
    this.authStateListeners = [];

    // Event names
    this.events = {
      LOGIN: "auth:login",
      LOGOUT: "auth:logout",
      REFRESH_SUCCESS: "auth:refresh:success",
      REFRESH_ERROR: "auth:refresh:error",
      TOKEN_EXPIRED: "auth:token:expired",
    };
  }

  // Subscribe to auth state changes
  subscribeToAuthState(callback) {
    this.authStateListeners.push(callback);
    return () => {
      this.authStateListeners = this.authStateListeners.filter(
        (cb) => cb !== callback
      );
    };
  }

  // Notify all subscribers of auth state change
  notifyAuthStateChange(authState) {
    this.authStateListeners.forEach((listener) => {
      try {
        listener(authState);
      } catch (error) {
        console.error("Error in auth state listener", error);
      }
    });
  }

  // Handle auth events
  dispatchAuthEvent(eventName, detail = {}) {
    console.log(`Dispatching auth event: ${eventName}`, detail);
    window.dispatchEvent(new CustomEvent(eventName, { detail }));

    if (eventName === this.events.LOGOUT) {
      this.notifyAuthStateChange({ isAuthenticated: false });
    } else if (eventName === this.events.LOGIN) {
      this.notifyAuthStateChange({ isAuthenticated: true });
    }
  }

  // Check if user is authenticated
  isAuthenticated() {
    // Check for valid token regardless of refresh status
    const token = authStorage.getAccessToken();
    return !!token && !authStorage.isTokenExpired();
  }

  getCurrentUser() {
    // If we're not authenticated, return null
    if (!this.isAuthenticated()) {
      console.log("Not authenticated, returning null");
      return null;
    }

    // Retrieve user data from storage
    const userData = authStorage.getUserData();

    // If no user data exists but we have a valid token,
    // return a minimal user object
    if (!userData && this.getToken()) {
      return {
        username: "User", // Default username
        userType: "unknown", // Default type
      };
    }

    return userData;
  }

  // Check if token needs refresh (expires soon)
  shouldRefreshToken() {
    if (!authStorage.getAccessToken()) return false;
    return authStorage.isTokenExpired();
  }

  // Get current access token
  getToken() {
    return authStorage.getAccessToken();
  }

  // Handle login
  async login(username, password) {
    // Import dynamically to avoid circular dependencies
    const { loginUser } = await import("../functions/auth_fethcers");

    const response = await loginUser(username, password);

    // Additional user info if needed
    const userData = {
      username,
      lastLogin: new Date().toISOString(),
      userType: response.userType || "admin", // Adjust if your API returns user info
    };
    authStorage.setUserData(userData);

    // Dispatch login event
    this.dispatchAuthEvent(this.events.LOGIN, { username });

    return response;
  }

  // Refresh token - only one refresh operation runs at a time
  async refreshToken() {
    // If already refreshing, return the existing promise
    if (this.isRefreshing) {
      console.log(
        "Token refresh already in progress, returning existing promise"
      );
      return this._refreshPromise;
    }

    // No refresh token available
    const refreshToken = authStorage.getRefreshToken();
    if (!refreshToken) {
      console.error("No refresh token available");
      this.logout({ reason: "no-refresh-token" });
      return Promise.reject(new Error("No refresh token available"));
    }

    // Start refreshing
    console.log("Starting token refresh");
    this.isRefreshing = true;

    // Notify auth state change to show that refresh is in progress
    this.notifyAuthStateChange({ isLoading: true });

    // Create refresh promise
    this._refreshPromise = new Promise(async (resolve, reject) => {
      // Add a timeout to prevent hanging forever
      const timeoutId = setTimeout(() => {
        console.error("Token refresh timed out after 10 seconds");
        reject(new Error("Token refresh timed out"));
      }, 10000); // 10 second timeout

      try {
        // Import dynamically to avoid circular dependencies
        const { refreshAccessToken } = await import(
          "../functions/auth_fethcers"
        );
        console.log("Calling refreshAccessToken API");
        const response = await refreshAccessToken(refreshToken);

        // Clear the timeout since we got a response
        clearTimeout(timeoutId);

        const { access_token, refresh_token, expires_in } = response;
        const expiresAt = new Date(
          Date.now() + expires_in * 1000
        ).toISOString();

        console.log("Token refresh successful, updating storage");

        // Store new tokens
        authStorage.set({
          accessToken: access_token,
          refreshToken: refresh_token,
          expiresAt,
        });

        // Process queued requests
        this.processQueue(access_token);

        // Dispatch success event
        this.dispatchAuthEvent(this.events.REFRESH_SUCCESS, {
          expires_in,
          expiresAt,
        });

        resolve(access_token);
      } catch (error) {
        console.error("Token refresh failed:", error);

        // Fail all queued requests
        this.failQueue(error);

        // Dispatch error event
        this.dispatchAuthEvent(this.events.REFRESH_ERROR, { error });

        // Logout on refresh error
        this.logout({ reason: "refresh-failed", error });

        reject(error);
      } finally {
        // Ensure the timeout is cleared to prevent memory leaks
        clearTimeout(timeoutId);

        // Reset refresh state
        this.isRefreshing = false;
        this._refreshPromise = null;

        // Update loading state
        this.notifyAuthStateChange({ isLoading: false });
      }
    });

    return this._refreshPromise;
  }

  // Process all queued requests after successful token refresh
  async processQueue(token) {
    console.log(`Processing ${this.requestQueue.length} queued requests`);
    const queue = [...this.requestQueue];
    this.requestQueue = [];

    for (const request of queue) {
      const { config, resolve, reject } = request;

      try {
        // Update token and retry request
        config.headers["Authorization"] = `Bearer ${token}`;

        // Dynamic import to avoid circular dependencies
        const { default: axios } = await import("axios");

        const response = await axios(config);
        resolve(response);
      } catch (error) {
        console.error("Error processing queued request:", error);
        reject(error);
      }
    }
  }

  // Fail all queued requests
  failQueue(error) {
    console.log(`Failing ${this.requestQueue.length} queued requests`);
    this.requestQueue.forEach(({ reject }) => {
      reject(error);
    });
    this.requestQueue = [];
  }

  // Add request to queue when token is being refreshed
  enqueueRequest(request) {
    console.log("Enqueueing request while token is being refreshed");
    return new Promise((resolve, reject) => {
      this.requestQueue.push({
        config: request,
        resolve,
        reject,
      });
    });
  }

  // Handle failed requests with token refresh and retry
  async handleFailedRequest(originalRequest) {
    // Skip auth requests to prevent infinite loops
    if (originalRequest.url.includes("/auth/")) {
      return Promise.reject(new Error("Authentication failed"));
    }

    // If already refreshing, queue the request
    if (this.isRefreshing) {
      console.log("Token refresh in progress, adding request to queue");
      return this.enqueueRequest(originalRequest);
    }

    // Start token refresh
    try {
      console.log("Starting token refresh for failed request");
      const token = await this.refreshToken();

      // Update request and retry
      originalRequest.headers["Authorization"] = `Bearer ${token}`;

      // Dynamic import to avoid circular dependencies
      const { default: axios } = await import("axios");

      console.log("Retrying request with new token");
      return axios(originalRequest);
    } catch (error) {
      console.error("Failed to refresh token for failed request:", error);
      return Promise.reject(error);
    }
  }

  // Logout user
  async logout(options = {}) {
    try {
      // Call logout API if we have a refresh token
      const refreshToken = authStorage.getRefreshToken();
      if (refreshToken) {
        // Import dynamically to avoid circular dependencies
        const { logoutUser } = await import("../functions/auth_fethcers");
        try {
          await logoutUser();
        } catch (error) {
          console.error("Logout API call failed:", error);
        }
      }
    } finally {
      // Always clear local storage and state
      this.cleanupOnLogout(options);
    }
  }

  // Clean up on logout
  cleanupOnLogout(options = {}) {
    // Clear tokens
    authStorage.clear();

    // Clear any ongoing refresh
    this.isRefreshing = false;
    this.refreshPromise = null;

    // Clear request queue with auth errors
    this.requestQueue.forEach((request) => {
      request.reject(new Error("Authentication failed"));
    });
    this.requestQueue = [];

    // Dispatch logout event
    this.dispatchAuthEvent(this.events.LOGOUT, options);
  }

  // Singleton instance getter
  static getInstance() {
    if (!this.instance) {
      this.instance = new AuthService();
    }
    return this.instance;
  }
}

// Add method to access the refresh promise
AuthService.getInstance().getRefreshPromise = function () {
  return this._refreshPromise;
};

// Create singleton instance
export default AuthService.getInstance();
