import React, { useEffect, useRef, useState, useCallback } from 'react';
import { loadStripe } from '@stripe/stripe-js';

const PaymentForm = ({ 
  clientSecret,
  setupIntentSecret,
  invoiceId,
  invoiceType,
  email,
  name,
  amount,
  stripePk
}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const stripeRef = useRef(null);
  const elementsRef = useRef(null);
  const cardRef = useRef(null);
  const formMountedRef = useRef(false);

  // Single initialization effect
  useEffect(() => {
    let mounted = true;

    const initializeStripe = async () => {
      try {
        // Only initialize once
        if (!stripeRef.current) {
        const stripeInstance = await loadStripe(stripePk);
          if (mounted && stripeInstance) {
            stripeRef.current = stripeInstance;
            const elementsInstance = stripeInstance.elements();
            elementsRef.current = elementsInstance;
            
            // Create and mount card element
            const cardElement = elementsInstance.create('card', {
        style: {
          base: {
            fontSize: '16px',
            color: '#32325d',
                  fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
            '::placeholder': {
              color: '#aab7c4'
            }
          },
          invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
          }
        }
      });

            // Mount card element
            const container = document.querySelector('#card-element');
            if (container && !formMountedRef.current) {
      container.innerHTML = '';
      cardElement.mount('#card-element');
              cardRef.current = cardElement;
              formMountedRef.current = true;

              // Add change listener
              cardElement.on('change', (event) => {
                if (event.error) {
                  setError(event.error.message);
                } else {
                  setError(null);
                }
              });
            }
          }
        }
      } catch (err) {
        console.error('Stripe initialization error:', err);
        setError('Failed to initialize payment system');
      }
    };

    initializeStripe();

    return () => {
      mounted = false;
      if (cardRef.current) {
        cardRef.current.destroy();
      }
    };
  }, [stripePk]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripeRef.current || !cardRef.current) {
      setError('Payment system not initialized');
      return;
    }

    setIsProcessing(true);
    setError(null);

    try {
      // Create payment method
      const result = await stripeRef.current.createPaymentMethod({
        type: 'card',
        card: cardRef.current,
        billing_details: {
          email,
          name
        }
      });

      if (result.error) {
        throw result.error;
      }

      const { paymentMethod } = result;

      if (invoiceType === 'subscription') {
        // Handle subscription payment
        const { error: confirmError } = await stripeRef.current.confirmCardPayment(
          clientSecret,
          { payment_method: paymentMethod.id }
        );

        if (confirmError) {
          throw confirmError;
        }

        if (setupIntentSecret) {
          const { error: setupError } = await stripeRef.current.confirmCardSetup(
            setupIntentSecret,
            { payment_method: paymentMethod.id }
          );

          if (setupError) {
            throw setupError;
          }
        }

        await updatePaymentStatus(paymentMethod.id, 'subscription');
      } else {
        // Handle one-time payment
        const { error: confirmError } = await stripeRef.current.confirmCardPayment(
          clientSecret,
          { payment_method: paymentMethod.id }
        );

        if (confirmError) {
          throw confirmError;
        }

        await updatePaymentStatus(paymentMethod.id, 'one-time');
      }

      setSuccess(true);
      setTimeout(() => window.location.reload(), 2000);
    } catch (error) {
      console.error('Payment error:', error);
      setError(error.message || 'Payment failed. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  const updatePaymentStatus = async (paymentMethodId, type) => {
      const response = await fetch(`http://localhost:8000/api/invoices/${invoiceId}/payment-status`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        payment_method_id: paymentMethodId,
        status: 'paid',
        type
      })
    });

    if (!response.ok) {
      throw new Error('Failed to update payment status');
    }
  };

  return (
    <div className="payment-container mt-8 bg-white rounded-lg p-6 shadow-md">
      <div className="mb-6">
        <h3 className="text-xl font-semibold text-gray-900">Pay Invoice</h3>
        <div className="mt-1">
          <span className="text-2xl font-bold text-gray-900">${amount}</span>
        </div>
      </div>

    <form onSubmit={handleSubmit} id="payment-form" className="space-y-6">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Card Information
              </label>
              {/* Empty container for card element */}
              <div id="card-element" className="mt-1 p-4 border rounded-md bg-gray-50 empty:hidden" />
              {error && (
                <div className="mt-2 text-sm text-red-600" role="alert">
                  {error}
                </div>
              )}
            </div>
          </div>

          <div className="flex items-center justify-between pt-4">
            <div className="text-sm text-gray-500 flex items-center">
              <svg className="h-5 w-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"/>
              </svg>
              Secured by Stripe
            </div>
            <button 
              type="submit"
              disabled={isProcessing}
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
            >
              {isProcessing ? 'Processing...' : `Pay $${amount}`}
            </button>
          </div>
        </form>

      {isProcessing && (
        <div className="flex flex-col items-center justify-center p-8">
          <svg className="animate-spin h-10 w-10 text-blue-600 mb-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
          </svg>
          <p className="text-lg text-gray-700">Processing your payment...</p>
          <p className="text-sm text-gray-500 mt-2">Please do not close this window.</p>
        </div>
      )}

      {success && (
        <div className="p-4 rounded-md bg-green-50">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-green-800">Payment Successful!</h3>
              <div className="mt-2 text-sm text-green-700">
                <p>Thank you for your payment. A receipt has been sent to your email.</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentForm;