import React, { useState, useEffect, useMemo } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import {
  // ... other icons
  Send,
  LinkIcon,
  AlertCircle,
  Activity,
  DollarSign,
  Briefcase,
  MessageSquare,
  FileCheck,
  Plus,
  X,
  Upload,
  FileText,
  Edit2,
  Trash2,
} from "lucide-react";

import { toast } from "react-toastify";

import { useClient } from "../hooks/useClient";

import { useInvoices } from "../hooks/useInvoices";

import { useAvailableServices } from "../hooks/useServices";

import { useDocument } from "../hooks/useDocument";

// Import your new modular components:

import OverViewSection from "./OverviewSection";

import ServicesSection from "./ServicesSection";

import EngagementSection from "./EngagementSection";

import NotesSection from "./NotesSection";

import BillingSection from "./BillingSection";

// Import modals

import ServiceModal from "./ServiceModal";

import InvoiceFormModal from "./InvoiceFormModal";

import InvoiceViewer from "./InvoiceViewer";
import SendOptionsModal from "./SendOptionsModal";

import Modal from "./Modal";

import PaginatedInvoiceTable from "./PaginatedInvoiceTable";

import { parseISO, format } from "date-fns";

import TranscriptModal from "./TranscriptModal";

const ClientDetailMod = () => {
  const location = useLocation();

  const nav = useNavigate();

  const { clientId } = useParams();

  // Query parameters (for active tab, etc.)

  const params = new URLSearchParams(location.search);

  const [activeTab, setActiveTab] = useState(params.get("tab") || "overview");

  // 2) State for actions menu & documents pagination

  const [isActionsOpen, setIsActionsOpen] = useState(false);

  const [documentPage, setDocumentPage] = useState(0);

  const [editingId, setEditingId] = useState(null);

  const [editForm, setEditForm] = useState({});

  const [editingEngagementId, setEditingEngagementId] = useState(null);

  const [showTranscriptModal, setShowTranscriptModal] = useState(false);

  const [transcriptEngagement, setTranscriptEngagement] = useState(null);

  const [structuredNotes, setStructuredNotes] = useState({
    summary: "",

    topics: [
      {
        id: 1,

        title: "Key Discussion Points",

        notes: ["Add your first note here"],
      },
    ],
  });

  // 3) Fetch data from your hooks

  const {
    client,
    updateClient,
    isUpdatingClient,
    updateClientError,
    updateEngagement,
    isUpdatingEngagement,
    createEngagement,
    isCreatingEngagement,
    createEngagementError,
    isLoading,
    isError,
    error,
    createNote,
    isCreatingNote,
    createNoteError,
    uploadTranscript,
    isUploadingTranscript,
    uploadTranscriptError,
    generateNotesFromTranscript,
    isGeneratingNotes,
    generateNotesError,
    deleteTranscript,
    fetchTranscriptText,
    addService,
    deleteService,
    deleteEngagement,
    isDeletingEngagement,
    deleteEngagementError,
    // ...
    clientDocuments,
    isLoadingDocuments,
    documentsError,
    updatePortalAccess,
    deleteClient,
    updateService,
    // ...
  } = useClient(clientId);

  const {
    invoices,

    createInvoice,

    updateInvoice,

    isLoading: isInvoiceLoading,
  } = useInvoices(client?._id);

  const { publishDocument, unpublishDocument, isPublishing, isUnpublishing } = useDocument(/* ... */);

  const { data: availableServices = [] } = useAvailableServices();

  // Local state for modals / forms
  const [publishingInvoiceId, setPublishingInvoiceId] = useState(null);
  const [unpublishingInvoiceId, setUnpublishingInvoiceId] = useState(null);
  
  const [showServiceModal, setShowServiceModal] = useState(false);

  const [editingService, setEditingService] = useState(null);

  const [showCreateInvoiceModal, setShowCreateInvoiceModal] = useState(false);

  const [editingInvoice, setEditingInvoice] = useState(null);

  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const [showInvoiceModal, setShowInvoiceModal] = useState(false);

  const [showSendOptions, setShowSendOptions] = useState(false);

  // Engagement modal

  const [showAddEngagementModal, setShowAddEngagementModal] = useState(false);

  // Full form state for creating a new engagement

  const [newEngagementForm, setNewEngagementForm] = useState({
    type: "",

    date: new Date().toISOString().slice(0, 16), // For <input type="datetime-local"/>

    description: "",

    outcome: "",

    new_status: "",

    next_steps: { status: "", step: "" },

    service: "",

    human_notes: "",
  });

  // Validation for Engagement form

  const [engagementValidation, setEngagementValidation] = useState({
    type: false,

    date: false,

    description: false,

    outcome: false,

    messages: {},
  });

  // For "Meeting Notes"

  const [showNotesInput, setShowNotesInput] = useState(false);

  const [notesType, setNotesType] = useState(null); // 'type' | 'upload' | null

  const [meetingNotes, setMeetingNotes] = useState("");

  const [uploadedFile, setUploadedFile] = useState(null);

  // Local state for notes

  const [newNote, setNewNote] = useState("");

  const handleUploadTranscript = (eng) => {
    setTranscriptEngagement(eng);

    setShowTranscriptModal(true);
  };

  // in your file upload onChange:

  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    if (!file) return;

    // 1) Keep track of which file they selected

    setUploadedFile(file);

    // 2) Read the file content as text

    const reader = new FileReader();

    reader.onload = (evt) => {
      const textContent = evt.target.result; // The .txt file contents

      // 3) Put that text into meetingNotes so it appears in the textarea

      setMeetingNotes(textContent);
    };

    reader.readAsText(file);
  };

  const handleCloseEngagementModal = () => {
    setShowAddEngagementModal(false);

    setEditingEngagementId(null);

    setNewEngagementForm({
      type: "",

      date: new Date().toISOString().slice(0, 16),

      description: "",

      outcome: "",

      new_status: "",

      next_steps: { status: "", step: "" },

      service: "",

      human_notes: "",
    });

    setShowNotesInput(false);

    setNotesType(null);

    setMeetingNotes("");

    setUploadedFile(null);

    setStructuredNotes({
      summary: "",

      topics: [
        {
          id: 1,

          title: "Key Discussion Points",

          notes: ["Add your first note here"],
        },
      ],
    });
  };

  const handleEditEngagementClick = (eng) => {
    // eng is your existing engagement object from the timeline

    // E.g. fill the form:

    setNewEngagementForm({
      type: eng.type ?? "",

      date: eng.date
        ? new Date(eng.date).toISOString().slice(0, 16)
        : new Date().toISOString().slice(0, 16),

      description: eng.description ?? "",

      outcome: eng.outcome ?? "",

      new_status: eng.new_status ?? "",

      next_steps: {
        status: eng.next_steps?.status ?? "",

        step: eng.next_steps?.step ?? "",
      },

      service: eng.service ?? "",

      human_notes: eng.human_notes || "",
    });

    console.log("clicked engagement:", eng);

    if (eng.structured_notes) {
      // We have structured notes

      setShowNotesInput(true);

      setNotesType("type");

      try {
        const parsedNotes = JSON.parse(eng.structured_notes);

        setStructuredNotes(parsedNotes);
      } catch (e) {
        console.error("Failed to parse structured notes:", e);

        // Initialize a basic structure if parsing fails

        setStructuredNotes({
          summary: "",

          topics: [
            {
              id: 1,

              title: "Notes",

              notes: ["Unable to parse original notes"],
            },
          ],
        });
      }
    } else if (eng.human_notes) {
      // Fall back to legacy plain text notes

      setShowNotesInput(true);

      setNotesType("type");

      setMeetingNotes(eng.human_notes);
    }

    // Keep track of which engagement we're editing

    setEditingEngagementId(eng.id);

    // or setEditingEngagementId(eng._id) if your data uses _id

    // Show the same "Add Engagement" modal, but in "edit" mode

    setShowAddEngagementModal(true);
  };

  // 3) onSave handler

  const handleSaveEngagement = async (engagementId) => {
    // This is where you'd call your updateEngagement mutation

    // or do some logic to patch the existing engagement in the DB.

    // For example:

    try {
      // Build an updated engagement object from editForm

      const updatedEngagement = { ...editForm };

      // Clean up any optional fields if empty, just like before

      if (!updatedEngagement.new_status) {
        delete updatedEngagement.new_status;
      }

      if (updatedEngagement.next_steps) {
        if (!updatedEngagement.next_steps.status) {
          delete updatedEngagement.next_steps.status;
        }

        if (!updatedEngagement.next_steps.step) {
          delete updatedEngagement.next_steps.step;
        }

        if (
          !updatedEngagement.next_steps.status &&
          !updatedEngagement.next_steps.step
        ) {
          delete updatedEngagement.next_steps;
        }
      }

      // TODO: call something like:

      // await updateEngagement({

      //   clientId,

      //   engagementId,

      //   engagementData: updatedEngagement

      // });

      // Clear edit mode

      setEditingId(null);

      setEditForm({});
    } catch (err) {
      console.error("Failed to save engagement update:", err);
    }
  };

  // 4) onCancel handler

  const handleCancelEdit = () => {
    setEditingId(null);

    setEditForm({});
  };

  const handleAddEngagement = async () => {
    // Simple front-end validation

    const errors = {
      type: !newEngagementForm.type,

      date: !newEngagementForm.date,

      description: !newEngagementForm.description,

      outcome: !newEngagementForm.outcome,

      messages: {},
    };

    if (errors.type) {
      errors.messages.type = "Please select an engagement type";
    }

    if (errors.date) {
      errors.messages.date = "Please select a date & time";
    }

    if (errors.description) {
      errors.messages.description = "Please enter a description";
    }

    if (errors.outcome) {
      errors.messages.outcome = "Please select an outcome";
    }

    // If any field is missing, set errors and stop

    if (errors.type || errors.date || errors.description || errors.outcome) {
      setEngagementValidation(errors);

      return;
    }

    // Clear errors if no issues

    setEngagementValidation({
      type: false,

      date: false,

      description: false,

      outcome: false,

      messages: {},
    });

    try {
      // 2) Actually call our createEngagement mutation

      // 2) Build a copy of the form data

      const engagementData = {
        ...newEngagementForm,

        // Add your typed notes or transcripts only if present

        human_notes: notesType === "type" ? "" : meetingNotes || undefined,

        structured_notes:
          notesType === "type" ? JSON.stringify(structuredNotes) : undefined,

        transcript_path: undefined,
      };

      // 3) Remove empty enum fields if they're optional

      //    (or any other optional fields you want to omit)

      if (!engagementData.new_status) {
        delete engagementData.new_status;
      }

      if (engagementData.next_steps) {
        if (!engagementData.next_steps.status) {
          delete engagementData.next_steps.status;
        }

        if (!engagementData.next_steps.step) {
          delete engagementData.next_steps.step;
        }

        // If next_steps is entirely empty, you might remove it:

        if (
          !engagementData.next_steps.status &&
          !engagementData.next_steps.step
        ) {
          delete engagementData.next_steps;
        }
      }

      // 4) Call our createEngagement mutation with the cleaned data

      if (editingEngagementId) {
        // We're updating an existing engagement

        await updateEngagement({
          clientId,

          engagementId: editingEngagementId,

          engagementData,
        });
      } else {
        // We're creating a brand-new engagement

        await createEngagement({
          clientId,

          engagementData,
        });
      }

      // After successful creation, close the modal + reset

      setShowAddEngagementModal(false);

      setEditingEngagementId(null);

      setNewEngagementForm({
        type: "",

        date: new Date().toISOString().slice(0, 16),

        description: "",

        outcome: "",

        new_status: "",

        next_steps: { status: "", step: "" },

        service: "",

        human_notes: "",
      });

      setShowNotesInput(false);

      setNotesType(null);

      setMeetingNotes("");

      setUploadedFile(null);
    } catch (error) {
      console.error("Failed to add engagement:", error);

      // e.g. toast.error('Failed to add engagement');
    }
  };

  const handleCloseModal = () => {
    setShowServiceModal(false);

    setEditingService(null);
  };

  // Example: EngagementSection calls onTranscriptClick(...) when icon is clicked

  // This function is for viewing an existing transcript, not currently implemented

  const handleTranscriptClick = async ({ engagementId, transcriptId }) => {
    try {
      // Set the engagement to view

      const engagementToView = client?.engagement_tracking.find(
        (eng) => eng.id === engagementId
      );

      if (engagementToView) {
        setTranscriptEngagement(engagementToView);

        setShowTranscriptModal(true);
      } else {
        console.error("Engagement not found:", engagementId);

        toast.error("Could not find the requested engagement");
      }
    } catch (err) {
      console.error("Failed to load transcript:", err);

      toast.error("Error loading transcript");
    }
  };

  // This is a placeholder function, actual implementation will be in the TranscriptModal

  const handleGenerateNotes = async () => {
    try {
      toast.info("Note generation functionality will be implemented soon");

      // In the future, this will call the note generation API

      // using the transcript ID received from the transcript save
    } catch (err) {
      console.error("Failed to generate notes:", err);

      toast.error("Error generating notes");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const tab = params.get("tab");

    if (tab) setActiveTab(tab);
  }, [location.search]);

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);

    const params = new URLSearchParams(location.search);

    params.set("tab", tabId);

    nav(`${location.pathname}?${params.toString()}`, { replace: true });
  };

  // 8) Helpers for OverViewSection

  // getStatusColor: returns classes for a given status

  const getStatusColor = (status) => {
    const colors = {
      active:
        "bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-300",

      paused:
        "bg-yellow-100 text-yellow-800 dark:bg-yellow-900/30 dark:text-yellow-300",

      pending:
        "bg-blue-100 text-blue-800 dark:bg-blue-900/30 dark:text-blue-300",

      opened:
        "bg-blue-100 text-blue-800 dark:bg-blue-900/30 dark:text-blue-300",

      lead: "bg-purple-100 text-purple-800 dark:bg-purple-900/30 dark:text-purple-300",

      inactive:
        "bg-gray-100 text-gray-800 dark:bg-gray-900/30 dark:text-grayteck-300",
    };

    return colors[status] || colors.inactive;
  };

  // getLastStatusChangeDate: example from your original code

  const getLastStatusChangeDate = (theClient) => {
    if (!theClient) return new Date();

    const { status, created_at, engagement_tracking } = theClient;

    const statusChanges = engagement_tracking

      ?.filter((e) => e.type === "status_change" && e.new_status === status)

      ?.sort((a, b) => new Date(b.date) - new Date(a.date));

    if (statusChanges && statusChanges.length > 0) {
      return new Date(statusChanges[0].date);
    }

    return new Date(created_at);
  };

  // calculateDaysSince

  const calculateDaysSince = (date) => {
    const now = new Date();

    const pastDate = new Date(date);

    const diffTime = Math.abs(now - pastDate);

    return Math.floor(diffTime / (1000 * 60 * 60 * 24));
  };

  // capitalize

  const capitalize = (word) => {
    if (!word) return "";

    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  function parseAsUtcDateFns(dateString) {
    // same idea: if no 'Z' or offset, append 'Z'

    let s = dateString.trim();

    if (!/Z$|[+\-]\d{2}:\d{2}$/.test(s)) {
      s += "Z";
    }

    // parseISO can interpret it.

    // By default parseISO -> local if there's no offset,

    // but we've forced a trailing Z, so it sees UTC

    return parseISO(s);
  }

  // formatDate

  // If you want a simpler approach, just do:

  const formatDate = (date) => {
    if (!date) return "";

    const dateObj = parseAsUtcDateFns(date);

    // return new Date(date).toLocaleString();

    return format(dateObj, "MM/dd/yyyy p");

    // or your date-fns version

    // return format(new Date(date), 'PPPpp');
  };

  // 9) Stats & derived values for Billing, etc.

  const stats = useMemo(() => {
    if (!invoices) {
      return {
        totalPaid: 0,

        totalPending: 0,

        overdue: 0,

        upcomingPayments: [],
      };
    }

    // Example aggregator

    return invoices.reduce(
      (acc, invoice) => {
        if (invoice.status === "paid") acc.totalPaid += invoice.total;
        else if (invoice.status === "pending" || invoice.status === "opened") {
          acc.totalPending += invoice.total;

          if (new Date(invoice.due_date) > new Date()) {
            acc.upcomingPayments.push(invoice);
          }
        }

        if (invoice.status === "overdue") {
          acc.overdue += invoice.total;
        }

        return acc;
      },

      {
        totalPaid: 0,

        totalPending: 0,

        overdue: 0,

        upcomingPayments: [],
      }
    );
  }, [invoices]);

  // Example function to show how we handle creating notes

  const handleAddNote = async () => {
    if (!newNote.trim()) return;

    try {
      await createNote({ clientId, noteContent: newNote.trim() });

      setNewNote("");
    } catch (err) {
      alert(`Error adding note: ${err.message}`);
    }
  };

  // Example for toggling portal

  const handlePortalAccessToggle = async () => {
    if (!client) return;

    try {
      await updatePortalAccess({
        clientId,

        enable: !client.portal_access?.enabled,
      });
    } catch (err) {
      console.error("Failed to toggle portal access", err);
    }
  };

  // Example handleAddService, handleUpdateService (passed to children)

  const handleAddServiceSubmit = async (serviceData) => {
    try {
      await addService({ clientId, serviceData });

      setShowServiceModal(false);
    } catch (error) {
      console.error("Error adding service:", error);
    }
  };

  const handleUpdateService = async ({ serviceId, updates }) => {
    try {
      console.log("serviceId", serviceId);

      console.log("updates", updates);

      // If the new status is "interested", remove pricing

      if (updates.status === "interested") {
        updates.pricing = null;

        updates.pricingModel = null;
      }

      await updateService({
        clientId,

        serviceId,

        serviceData: updates,
      });
    } catch (error) {
      console.error("Error updating service:", error);
    }
  };

  // Example delete

  const handleDeleteClient = async () => {
    if (!client) return;

    if (window.confirm("Are you sure you want to delete this client?")) {
      try {
        await deleteClient(clientId);

        nav("/clients"); // or wherever you navigate afterwards
      } catch (err) {
        console.error("Error deleting client:", err);
      }
    }
  };

  // For the invoice modals

  const handleEditInvoice = (invoice) => {
    setEditingInvoice(invoice);

    setShowCreateInvoiceModal(true);

    setShowInvoiceModal(false);
  };

  const handleViewInvoice = (invoice) => {
    setSelectedInvoice(invoice);

    setShowInvoiceModal(true);
  };

  // Then define a helper:

  const handleDeleteService = async ({ clientId, serviceId }) => {
    try {
      // If you want an extra confirm prompt, do it here

      await deleteService({ clientId, serviceId });
    } catch (error) {
      console.error("Error deleting service:", error);
    }
  };

  if (isLoading) return <div>Loading ...</div>;

  if (isError) return <div>Error: {error?.message}</div>;

  if (!client) return <div>Client not found.</div>;

  // We define the tabs

  const tabs = [
    { id: "overview", label: "Overview", icon: /* e.g. Activity */ Activity },

    { id: "services", label: "Services", icon: Briefcase },

    { id: "engagement", label: "Engagement", icon: MessageSquare },

    { id: "notes", label: "Notes", icon: FileCheck },

    { id: "billing", label: "Billing", icon: DollarSign },
  ];

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-darkbg-900">
      <div className="max-w-7xl mx-auto p-4">
        {/* Tabs */}

        <div className="flex space-x-1 bg-white dark:bg-darkbg-800 p-1 rounded-lg shadow-md mb-6">
          {tabs.map((tab) => {
            const Icon = tab.icon;

            return (
              <button
                key={tab.id}
                onClick={() => handleTabChange(tab.id)}
                className={`flex-1 flex items-center justify-center px-4 py-2 text-sm font-medium rounded-md


                           transition-colors duration-200 ${
                             activeTab === tab.id
                               ? "bg-grayteck-600 text-white"
                               : "text-grayteck-600 dark:text-grayteck-400 hover:text-grayteck-900 dark:hover:text-grayteck-200"
                           }`}
              >
                <Icon className="w-5 h-5 mr-2" />

                {tab.label}
              </button>
            );
          })}
        </div>

        {/* Tab Content */}

        <AnimatePresence mode="wait">
          {activeTab === "overview" && (
            <motion.div
              key="overview"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.2 }}
            >
              <OverViewSection
                client={client}
                isActionsOpen={isActionsOpen}
                setIsActionsOpen={setIsActionsOpen}
                handleDeleteClient={handleDeleteClient}
                handlePortalAccessToggle={handlePortalAccessToggle}
                getStatusColor={getStatusColor}
                calculateDaysSince={calculateDaysSince}
                getLastStatusChangeDate={getLastStatusChangeDate}
                capitalize={capitalize}
                formatDate={formatDate}
                nav={nav}
                isLoadingDocuments={isLoadingDocuments}
                documentsError={documentsError}
                clientDocuments={clientDocuments}
                documentPage={documentPage}
                setDocumentPage={setDocumentPage}
                onUpdateClient={updateClient}
              />
            </motion.div>
          )}

          {activeTab === "services" && (
            <motion.div
              key="services"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <ServicesSection
                client={client}
                availableServices={availableServices}
                formatDate={formatDate}
                // onAddService={() => setShowServiceModal(true)}

                onAddService={() => {
                  setEditingService(null);

                  setShowServiceModal(true);
                }}
                onEditService={(svc) => {
                  setEditingService(svc);

                  setShowServiceModal(true);
                }}
                onDeleteService={handleDeleteService}

                // pass other needed props
              />
            </motion.div>
          )}

          {activeTab === "engagement" && (
            <motion.div
              key="engagement"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <EngagementSection
                client={client}
                // showAddModal={() => setShowAddEngagementModal(true)}

                onAddEngagementClick={() => setShowAddEngagementModal(true)}
                onEdit={handleEditEngagementClick} // <-- pass our new function
                onUploadTranscript={handleUploadTranscript} // pass down
                getStatusColor={getStatusColor}
                formatDate={formatDate}
                // Pass our edit-related props

                editingId={editingId}
                editForm={editForm}
                setEditForm={setEditForm}
                onSave={handleSaveEngagement}
                onCancel={handleCancelEdit}
                updateEngagement={updateEngagement} // Add this prop
                onDelete={(engagement) => {
                  if (engagement && engagement.id) {
                    deleteEngagement({
                      clientId: client._id,
                      engagementId: engagement.id,
                    }).catch((error) => {
                      console.error("Failed to delete engagement:", error);
                      toast.error("Failed to delete engagement");
                    });
                  }
                }}
                // pass any needed callbacks
              />
            </motion.div>
          )}

          {activeTab === "notes" && (
            <motion.div
              key="notes"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <NotesSection
                client={client}
                newNote={newNote}
                setNewNote={setNewNote}
                handleAddNote={handleAddNote}
                isCreatingNote={isCreatingNote}
                createNoteError={createNoteError}
                formatDate={formatDate}
                getStatusColor={getStatusColor}
              />
            </motion.div>
          )}

          {activeTab === "billing" && (
            <motion.div
              key="billing"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <BillingSection
                invoices={invoices}
                stats={stats}
                isLoading={isInvoiceLoading}
                isPublishing={isPublishing}
                isUnpublishing={isUnpublishing}
                publishingInvoiceId={publishingInvoiceId}
                unpublishingInvoiceId={unpublishingInvoiceId}
                onCreateInvoice={() => {
                  setShowCreateInvoiceModal(true);
                  setEditingInvoice(null);
                }}
                onViewInvoice={handleViewInvoice}
                onEditInvoice={handleEditInvoice}
                onSendInvoice={(inv) => {
                  console.log("Invoice being sent:", inv); // Debug log
                  setSelectedInvoice(inv);
                  setPublishingInvoiceId(inv._id); // Try using _id instead of id
                  setShowSendOptions(true);
                }}
                onUnpublish={async (inv) => {
                  console.log("Invoice being unpublished:", inv); // Debug log
                  try {
                    setUnpublishingInvoiceId(inv._id); // Use _id instead of id
                    await unpublishDocument(inv.document_id);
                    setUnpublishingInvoiceId(null);
                  } catch (err) {
                    console.error("Error unpublishing", err);
                    setUnpublishingInvoiceId(null);
                  }
                }}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {/* --------------- SERVICE MODAL --------------- */}

      {showServiceModal && (
        <ServiceModal
          client={client}
          isOpen={showServiceModal}
          onClose={handleCloseModal}
          editingService={editingService}
          onSubmit={async (serviceData) => {
            try {
              if (editingService) {
                // "editingService" means user is updating

                console.log("editingService:", serviceData);

                await handleUpdateService({
                  serviceId: editingService.id,

                  updates: serviceData,
                });
              } else {
                // Otherwise, user is creating a new service

                await addService({
                  clientId: client._id,

                  serviceData,
                });
              }

              handleCloseModal();
            } catch (error) {
              console.error("Error saving service:", error);
            }
          }}
        />
      )}

      {/* --------------- INVOICE FORM MODAL --------------- */}

      {showCreateInvoiceModal && (
        <InvoiceFormModal
          isOpen={showCreateInvoiceModal}
          onClose={() => {
            setShowCreateInvoiceModal(false);

            setEditingInvoice(null);
          }}
          client={client}
          initialData={editingInvoice}
          onSubmit={async (data) => {
            if (editingInvoice) {
              await updateInvoice({ id: editingInvoice._id, ...data });
            } else {
              await createInvoice(data);
            }

            setShowCreateInvoiceModal(false);

            setEditingInvoice(null);
          }}
        />
      )}

      {/* --------------- INVOICE VIEWER MODAL --------------- */}

      {showInvoiceModal && selectedInvoice && (
        <Modal isOpen={true} onClose={() => setShowInvoiceModal(false)}>
          <InvoiceViewer
            invoice={selectedInvoice}
            onClose={() => setShowInvoiceModal(false)}
            onEdit={handleEditInvoice}
          />
        </Modal>
      )}

      {/* --------------- SEND OPTIONS MODAL --------------- */}
      <SendOptionsModal 
        isOpen={showSendOptions && !!selectedInvoice}
        onClose={() => setShowSendOptions(false)}
        invoice={selectedInvoice}
        onPublishComplete={() => setPublishingInvoiceId(null)}
      />
      {/* {showSendOptions && selectedInvoice && (
        <SendOptionsModal
          isOpen={true}
          onClose={() => setShowSendOptions(false)}
          invoice={selectedInvoice}
          onPublishSuccess={(response) => {
            // If we need to refresh data or update UI after successful publishing
            toast.success('Invoice published successfully');
            // You might want to refetch invoices here if needed
          }}
        />
      )} */}

      {/* --------------- ADD ENGAGEMENT MODAL --------------- */}

      {showAddEngagementModal && (
        <Modal isOpen={true} onClose={handleCloseEngagementModal}>
          {/* --- The main form --- */}

          <div className="grid grid-cols-2 gap-4">
            {/* Type */}

            <div>
              <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-1">
                Type
              </label>

              <select
                value={newEngagementForm.type}
                onChange={(e) => {
                  setNewEngagementForm((prev) => ({
                    ...prev,

                    type: e.target.value,
                  }));

                  // Clear error on change

                  setEngagementValidation((prev) => ({ ...prev, type: false }));
                }}
                className={`w-full p-2 border rounded-md bg-white dark:bg-darkbg-700


                  text-grayteck-900 dark:text-grayteck-100 


                  ${
                    engagementValidation.type
                      ? "border-red-500 focus:ring-red-500"
                      : "border-grayteck-300 dark:border-grayteck-600 focus:ring-grayteck-500"
                  }


                `}
                required
              >
                <option value="">Select Type</option>

                <option value="meeting">Meeting</option>

                <option value="call">Call</option>

                <option value="email">Email</option>

                <option value="demo">Demo</option>

                <option value="implementation">Implementation</option>

                <option value="status_change">Status Change</option>
              </select>

              {engagementValidation.type && (
                <div className="flex items-center text-red-500 text-sm mt-1">
                  <AlertCircle className="w-4 h-4 mr-2" />

                  <span>{engagementValidation.messages.type}</span>
                </div>
              )}
            </div>

            {/* Date & Time */}

            <div>
              <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-1">
                Date & Time
              </label>

              <input
                type="datetime-local"
                value={newEngagementForm.date}
                onChange={(e) => {
                  setNewEngagementForm((prev) => ({
                    ...prev,

                    date: e.target.value,
                  }));

                  setEngagementValidation((prev) => ({ ...prev, date: false }));
                }}
                className={`w-full p-2 border rounded-md bg-white dark:bg-darkbg-700 


                  text-grayteck-900 dark:text-grayteck-100


                  ${
                    engagementValidation.date
                      ? "border-red-500 focus:ring-red-500"
                      : "border-grayteck-300 dark:border-grayteck-600 focus:ring-grayteck-500"
                  }


                `}
                required
              />

              {engagementValidation.date && (
                <div className="flex items-center text-red-500 text-sm mt-1">
                  <AlertCircle className="w-4 h-4 mr-2" />

                  <span>{engagementValidation.messages.date}</span>
                </div>
              )}
            </div>
          </div>

          {/* If Type === 'status_change', show the new status */}

          {newEngagementForm.type === "status_change" && (
            <div>
              <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-1">
                New Status
              </label>

              <select
                value={newEngagementForm.new_status}
                onChange={(e) =>
                  setNewEngagementForm((prev) => ({
                    ...prev,

                    new_status: e.target.value,
                  }))
                }
                className="w-full p-2 border rounded-md bg-white dark:bg-darkbg-700 


                       border-grayteck-300 dark:border-grayteck-600 


                       text-grayteck-900 dark:text-grayteck-100"
              >
                <option value="">Select New Status</option>

                <option value="lead">Lead</option>

                <option value="active">Active</option>

                <option value="paused">Paused</option>

                <option value="terminated">Terminated</option>

                <option value="completed">Completed</option>
              </select>
            </div>
          )}

          {/* Description */}

          <div>
            <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-1">
              Description
            </label>

            <textarea
              value={newEngagementForm.description}
              onChange={(e) => {
                setNewEngagementForm((prev) => ({
                  ...prev,

                  description: e.target.value,
                }));

                setEngagementValidation((prev) => ({
                  ...prev,

                  description: false,
                }));
              }}
              rows={3}
              className={`w-full p-2 border rounded-md bg-white dark:bg-darkbg-700 


                text-grayteck-900 dark:text-grayteck-100


                ${
                  engagementValidation.description
                    ? "border-red-500 focus:ring-red-500"
                    : "border-grayteck-300 dark:border-grayteck-600 focus:ring-grayteck-500"
                }


              `}
              placeholder="Enter engagement description"
              required
            />

            {engagementValidation.description && (
              <div className="flex items-center text-red-500 text-sm mt-1">
                <AlertCircle className="w-4 h-4 mr-2" />

                <span>{engagementValidation.messages.description}</span>
              </div>
            )}
          </div>

          {/* Outcome & Related Service */}

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-1">
                Outcome
              </label>

              <select
                value={newEngagementForm.outcome}
                onChange={(e) => {
                  setNewEngagementForm((prev) => ({
                    ...prev,

                    outcome: e.target.value,
                  }));

                  setEngagementValidation((prev) => ({
                    ...prev,

                    outcome: false,
                  }));
                }}
                className={`w-full p-2 border rounded-md bg-white dark:bg-darkbg-700


                  text-grayteck-900 dark:text-grayteck-100


                  ${
                    engagementValidation.outcome
                      ? "border-red-500 focus:ring-red-500"
                      : "border-grayteck-300 dark:border-grayteck-600 focus:ring-grayteck-500"
                  }


                `}
                required
              >
                <option value="">Select Outcome</option>

                <option value="Positive - decided to proceed with implementation">
                  Positive - Proceeding
                </option>

                <option value="Negative - declined the offer">
                  Negative - Declined
                </option>

                <option value="Neutral - needs more time to decide">
                  Neutral - Need Time
                </option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-1">
                Related Service
              </label>

              <input
                type="text"
                value={newEngagementForm.service}
                onChange={(e) =>
                  setNewEngagementForm((prev) => ({
                    ...prev,

                    service: e.target.value,
                  }))
                }
                className="w-full p-2 border rounded-md bg-white dark:bg-darkbg-700 


                       border-grayteck-300 dark:border-grayteck-600 


                       text-grayteck-900 dark:text-grayteck-100"
                placeholder="Enter related service"
              />
            </div>
          </div>

          {/* Next Step Status & Next Step */}

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-1">
                Next Step Status
              </label>

              <select
                value={newEngagementForm.next_steps.status}
                onChange={(e) =>
                  setNewEngagementForm((prev) => ({
                    ...prev,

                    next_steps: { ...prev.next_steps, status: e.target.value },
                  }))
                }
                className="w-full p-2 border rounded-md bg-white dark:bg-darkbg-700 


                       border-grayteck-300 dark:border-grayteck-600 


                       text-grayteck-900 dark:text-grayteck-100"
              >
                <option value="">Select Status</option>

                <option value="Pending">Pending</option>

                <option value="Completed">Completed</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-1">
                Next Step
              </label>

              <select
                value={newEngagementForm.next_steps.step}
                onChange={(e) =>
                  setNewEngagementForm((prev) => ({
                    ...prev,

                    next_steps: { ...prev.next_steps, step: e.target.value },
                  }))
                }
                className="w-full p-2 border rounded-md bg-white dark:bg-darkbg-700 


                       border-grayteck-300 dark:border-grayteck-600 


                       text-grayteck-900 dark:text-grayteck-100"
              >
                <option value="">Select Next Step</option>

                <option value="Follow up">Follow up</option>

                <option value="Follow up as per schedule">
                  Follow up as scheduled
                </option>

                <option value="Send follow-up email">
                  Send follow-up email
                </option>

                <option value="Schedule product/service demo">
                  Schedule demo
                </option>

                <option value="Schedule implementation call">
                  Schedule implementation
                </option>

                <option value="Draft proposal document">Draft proposal</option>

                <option value="Send proposal document">Send proposal</option>

                <option value="Send invoice">Send invoice</option>

                <option value="Send contract">Send contract</option>
              </select>
            </div>
          </div>

          {/* MEETING NOTES area */}

          <div className="mt-6 border-t border-grayteck-200 dark:border-grayteck-700 pt-6">
            <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-2">
              Meeting Notes
            </label>

            {!showNotesInput ? (
              <div className="flex space-x-4">
                {/* Type Notes button */}

                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => {
                    setShowNotesInput(true);

                    setNotesType("type");
                  }}
                  className="flex-1 px-4 py-3 bg-white dark:bg-darkbg-700 border-2 border-grayteck-300 


                         dark:border-grayteck-600 rounded-lg hover:bg-grayteck-50 


                         dark:hover:bg-darkbg-600 transition-colors"
                >
                  <div className="flex items-center justify-center">
                    <Edit2 className="w-5 h-5 text-grayteck-600 dark:text-grayteck-400 mr-2" />

                    <span className="text-grayteck-700 dark:text-grayteck-300">
                      Type Notes
                    </span>
                  </div>
                </motion.button>

                {/* Upload Notes button */}

                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => {
                    setShowNotesInput(true);

                    setNotesType("upload");
                  }}
                  className="flex-1 px-4 py-3 bg-white dark:bg-darkbg-700 border-2 border-grayteck-300 


                         dark:border-grayteck-600 rounded-lg hover:bg-grayteck-50 


                         dark:hover:bg-darkbg-600 transition-colors"
                >
                  <div className="flex items-center justify-center">
                    <Upload className="w-5 h-5 text-grayteck-600 dark:text-grayteck-400 mr-2" />

                    <span className="text-grayteck-700 dark:text-grayteck-300">
                      Upload Notes
                    </span>
                  </div>
                </motion.button>
              </div>
            ) : (
              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <span className="text-sm text-grayteck-600 dark:text-grayteck-400">
                    {notesType === "type"
                      ? "Type your notes"
                      : "Upload notes file"}
                  </span>

                  <button
                    onClick={() => {
                      setShowNotesInput(false);

                      setNotesType(null);

                      setMeetingNotes("");

                      setUploadedFile(null);
                    }}
                    className="text-sm text-grayteck-500 hover:text-grayteck-700 


                           dark:text-grayteck-400 dark:hover:text-grayteck-200"
                  >
                    <X className="w-5 h-5" />
                  </button>
                </div>

                {notesType === "type" ? (
                  <div className="space-y-4">
                    {/* Summary Section */}

                    <div>
                      <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-1">
                        Summary
                      </label>

                      <textarea
                        value={structuredNotes.summary}
                        onChange={(e) =>
                          setStructuredNotes((prev) => ({
                            ...prev,

                            summary: e.target.value,
                          }))
                        }
                        rows={3}
                        className="w-full p-3 border rounded-md bg-white dark:bg-darkbg-700


                              border-grayteck-300 dark:border-grayteck-600


                              text-grayteck-900 dark:text-grayteck-100


                              focus:ring-2 focus:ring-grayteck-500 focus:border-transparent"
                        placeholder="Enter a brief summary of the meeting..."
                      />
                    </div>

                    {/* Topics Section */}

                    <div>
                      <div className="flex justify-between items-center mb-2">
                        <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300">
                          Topics
                        </label>

                        <button
                          type="button"
                          onClick={() => {
                            const newId =
                              Math.max(
                                0,

                                ...structuredNotes.topics.map((t) => t.id)
                              ) + 1;

                            setStructuredNotes((prev) => ({
                              ...prev,

                              topics: [
                                ...prev.topics,

                                {
                                  id: newId,

                                  title: "New Topic",

                                  notes: ["Add notes here"],
                                },
                              ],
                            }));
                          }}
                          className="text-xs px-2 py-1 flex items-center bg-grayteck-100 dark:bg-darkbg-600


                                text-grayteck-700 dark:text-grayteck-300 rounded"
                        >
                          <Plus className="w-3 h-3 mr-1" />
                          Add Topic
                        </button>
                      </div>

                      {structuredNotes.topics.map((topic, topicIndex) => (
                        <div
                          key={topic.id}
                          className="mb-3 border border-grayteck-200 dark:border-grayteck-700 rounded-md p-3"
                        >
                          <div className="flex justify-between items-center mb-2">
                            <input
                              type="text"
                              value={topic.title}
                              onChange={(e) => {
                                const newTopics = [...structuredNotes.topics];

                                newTopics[topicIndex].title = e.target.value;

                                setStructuredNotes((prev) => ({
                                  ...prev,

                                  topics: newTopics,
                                }));
                              }}
                              className="font-medium bg-transparent border-b border-grayteck-300 dark:border-grayteck-600 focus:border-grayteck-500 focus:outline-none w-full text-grayteck-900 dark:text-grayteck-100"
                              placeholder="Topic title"
                            />

                            {structuredNotes.topics.length > 1 && (
                              <button
                                onClick={() => {
                                  setStructuredNotes((prev) => ({
                                    ...prev,

                                    topics: prev.topics.filter(
                                      (_, i) => i !== topicIndex
                                    ),
                                  }));
                                }}
                                className="text-red-500 hover:text-red-700"
                              >
                                <Trash2 className="w-4 h-4" />
                              </button>
                            )}
                          </div>

                          <ul className="space-y-2 mt-2">
                            {topic.notes.map((note, noteIndex) => (
                              <li key={noteIndex} className="flex items-start">
                                <span className="mt-1 w-2 h-2 rounded-full bg-grayteck-500 mr-2 flex-shrink-0"></span>

                                <textarea
                                  value={note}
                                  onChange={(e) => {
                                    const newTopics = [
                                      ...structuredNotes.topics,
                                    ];

                                    newTopics[topicIndex].notes[noteIndex] =
                                      e.target.value;

                                    setStructuredNotes((prev) => ({
                                      ...prev,

                                      topics: newTopics,
                                    }));
                                  }}
                                  className="flex-1 bg-transparent border border-grayteck-200 dark:border-grayteck-700


                                          focus:border-grayteck-500 focus:outline-none min-h-[60px] p-2 rounded-md
                                          text-grayteck-900 dark:text-grayteck-100"
                                  placeholder="Enter note point"
                                />

                                {topic.notes.length > 1 && (
                                  <button
                                    onClick={() => {
                                      const newTopics = [
                                        ...structuredNotes.topics,
                                      ];

                                      newTopics[topicIndex].notes = newTopics[
                                        topicIndex
                                      ].notes.filter((_, i) => i !== noteIndex);

                                      setStructuredNotes((prev) => ({
                                        ...prev,

                                        topics: newTopics,
                                      }));
                                    }}
                                    className="text-red-500 hover:text-red-700 ml-2"
                                  >
                                    <Trash2 className="w-3 h-3" />
                                  </button>
                                )}
                              </li>
                            ))}

                            <li>
                              <button
                                onClick={() => {
                                  const newTopics = [...structuredNotes.topics];

                                  newTopics[topicIndex].notes.push("");

                                  setStructuredNotes((prev) => ({
                                    ...prev,

                                    topics: newTopics,
                                  }));
                                }}
                                className="ml-4 flex items-center text-xs"
                              >
                                <Plus className="w-3 h-3 mr-1" />
                                Add note
                              </button>
                            </li>
                          </ul>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="relative">
                      <input
                        type="file"
                        accept=".txt"
                        onChange={handleFileUpload}
                        className="hidden"
                        id="file-upload"
                      />

                      <label
                        htmlFor="file-upload"
                        className="flex items-center justify-center w-full p-4 border-2 border-dashed 


                             border-grayteck-300 dark:border-grayteck-600 rounded-lg 


                             cursor-pointer hover:border-grayteck-500 transition-colors"
                      >
                        {uploadedFile ? (
                          <div className="flex items-center space-x-2">
                            <FileText className="w-5 h-5 text-grayteck-600 dark:text-grayteck-400" />

                            <span className="text-grayteck-600 dark:text-grayteck-400">
                              {uploadedFile.name}
                            </span>
                          </div>
                        ) : (
                          <div className="text-center">
                            <Upload className="w-8 h-8 text-grayteck-600 dark:text-grayteck-400 mx-auto mb-2" />

                            <p className="text-grayteck-600 dark:text-grayteck-400">
                              Drop your .txt file here, or click to browse
                            </p>
                          </div>
                        )}
                      </label>
                    </div>

                    {/* After uploading, show the text in the same 'meetingNotes' */}

                    {meetingNotes && (
                      <textarea
                        value={meetingNotes}
                        onChange={(e) => setMeetingNotes(e.target.value)}
                        rows={6}
                        className="w-full p-3 border rounded-md bg-white dark:bg-darkbg-700 


                            border-grayteck-300 dark:border-grayteck-600 


                            text-grayteck-900 dark:text-grayteck-100 


                            focus:ring-2 focus:ring-grayteck-500 focus:border-transparent mt-4"
                        placeholder="Preview/edit your uploaded text..."
                      />
                    )}
                  </>
                )}
              </div>
            )}
          </div>

          {/* Action Buttons */}

          <div className="flex justify-end space-x-4 mt-6">
            <button
              onClick={handleCloseEngagementModal}
              className="px-4 py-2 text-grayteck-600 hover:text-grayteck-900 


                     dark:text-grayteck-400 dark:hover:text-grayteck-200"
            >
              Cancel
            </button>

            <button
              onClick={handleAddEngagement}
              className="px-4 py-2 bg-grayteck-600 text-white rounded-md hover:bg-grayteck-700 flex items-center space-x-2"
              disabled={isCreatingEngagement}
            >
              <Plus className="w-5 h-5" />

              <span>
                {" "}
                {editingEngagementId
                  ? "Update Engagement"
                  : "Add Engagement"}{" "}
              </span>
            </button>
          </div>

          {/* </div> */}
        </Modal>
      )}

      {/* 2) The TranscriptModal at the bottom */}

      {showTranscriptModal && transcriptEngagement && (
        <TranscriptModal
          isOpen={true}
          onClose={() => setShowTranscriptModal(false)}
          engagement={transcriptEngagement}
          onSubmitTranscript={({
            engagementId,
            transcriptFile,
            speakers,
            generateNotes,
          }) => {
            console.log("Uploading transcript with speakers:", speakers);
            uploadTranscript({
              clientId,
              engagementId,
              transcriptFile,
              speakers,
            })
              .then((response) => {
                // Log detailed response for debugging
                console.log("Transcript upload response:", response);
                console.log("Engagement ID:", engagementId);
                console.log("Transcript info:", response?.transcript_path);

                // Success notification for first step
                toast.success("Transcript saved successfully!");

                // Check if we should generate notes (second step)
                if (generateNotes && response?.transcript_id) {
                  toast.info(
                    "Generating notes from transcript... This may take a minute."
                  );

                  // Call the note generation endpoint
                  generateNotesFromTranscript({
                    clientId,
                    engagementId,
                    transcriptId: response.transcript_id,
                  })
                    .then((notesResponse) => {
                      console.log("Notes generation response:", notesResponse);
                      toast.success(
                        "Notes generated successfully from transcript!"
                      );
                    })
                    .catch((notesError) => {
                      console.error("Notes generation error:", notesError);
                      toast.error(
                        notesError.message ||
                          "Failed to generate notes from transcript"
                      );
                    });
                }

                // Close the modal
                setShowTranscriptModal(false);
              })
              .catch((error) => {
                console.error("Transcript upload error details:", error);
              });
          }}
          isUploadingTranscript={isUploadingTranscript}
          uploadError={uploadTranscriptError}
        />
      )}
    </div>
  );
};

export default ClientDetailMod;
