// src/functions/auth_fethcers.js
import { authAxios } from "./axiosInstance";
import { authStorage } from "./auth";
import axios from "axios";

// Create a plain axios instance without interceptors for refresh token requests
// to avoid circular dependencies
const plainAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API_PROD_BASE_URL}/auth`,
  // baseURL: `${process.env.REACT_APP_API_LOCAL_BASE_URL}/auth`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const registerUser = async (username, email, password) => {
  try {
    const response = await authAxios.post("/register", {
      username,
      email,
      password,
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

export const loginUser = async (username, password) => {
  try {
    // Create form data exactly as FastAPI expects it
    const params = new URLSearchParams({
      // grant_type: 'password',  // OAuth2 requires this
      username: username,
      password: password,
    });

    const response = await authAxios.post("/token", params, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    console.log("Login response:", response.data);

    const { access_token, refresh_token, token_type, expires_in } =
      response.data;

    // Store auth data
    const expiresAt = new Date(
      Date.now() + (expires_in || 1800) * 1000
    ).toISOString();
    authStorage.set({
      accessToken: access_token,
      refreshToken: refresh_token,
      tokenType: token_type,
      expiresAt,
    });

    return response.data;
  } catch (error) {
    console.error("Login error:", error.response?.data || error.message);
    throw error.response?.data || error;
  }
};

// Use plainAxios for refresh token requests to avoid circular dependencies
export const refreshAccessToken = async (refreshToken) => {
  try {
    console.log("Using direct axios call for token refresh");
    const response = await plainAxios.post("/refresh", {
      current_token: refreshToken,
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

export const logoutUser = async () => {
  try {
    const refreshToken = authStorage.getRefreshToken();
    if (refreshToken) {
      await authAxios.post("/logout", { refresh_token: refreshToken });
    }
    authStorage.clear();
  } catch (error) {
    authStorage.clear();
    throw error.response ? error.response.data : error.message;
  }
};

export const getCurrentUser = async () => {
  try {
    const token = localStorage.getItem("access_token");
    const response = await authAxios.get("/me", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

export const sendAccessCode = async (email) => {
  try {
    const response = await authAxios.post("/client/access-code", { email });
    // The response might just indicate success; no tokens are stored yet.
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

export const verifyAccessCode = async (email, access_code) => {
  try {
    const response = await authAxios.post("/client/verify-access-code", {
      email,
      access_code,
    });

    const { access_token, refresh_token, token_type, expires_in } =
      response.data;
    const expiresAt = new Date(
      Date.now() + (expires_in || 1800) * 1000
    ).toISOString();

    authStorage.set({
      accessToken: access_token,
      refreshToken: refresh_token,
      tokenType: token_type,
      expiresAt,
    });

    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};
