import React from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  addServiceToClient,
  createClient,
  getClients,
  createNote,
  getClient,
  deleteServiceFromClient,
  updateClientPortalAccess,
  deleteClient,
  updateServiceToClient,
  getClientDocuments,
  createEngagement,
  updateEngagement,
  uploadTranscriptToEngagement,
  deleteTranscriptFromEngagement,
  getTranscriptText,
  generateNotesFromTranscript,
  deleteEngagement,
  updateClient,
} from "../functions/api_fethcers";
import { toast } from "react-toastify";

// Hook for managing multiple clients
export const useClients = ({ page = 0, limit = 10, filters = {} } = {}) => {
  const queryClient = useQueryClient();

  // Get all clients query with pagination
  const {
    data: paginatedData,
    isLoading: isLoadingClients,
    isError: isErrorClients,
    error: clientsError,
    refetch: refetchClients,
  } = useQuery(
    ["clients", { page, limit, filters }], // Include filters in the query key
    () => getClients({ page, limit, filters }),
    {
      staleTime: 300000, // 5 minutes
      cacheTime: 3600000, // 1 hour
      keepPreviousData: true, // Keep previous page data while fetching next page
    }
  );

  // Create client mutation
  const createClientMutation = useMutation(
    (newClient) => createClient(newClient),
    {
      onSuccess: (data) => {
        // Invalidate and refetch
        queryClient.invalidateQueries(["clients"]);

        // Optionally update the cache directly for the first page
        queryClient.setQueryData(
          ["clients", { page: 0, limit, filters }],
          (old) => {
            if (!old) return old;
            return {
              ...old,
              items: [data, ...old.items.slice(0, -1)],
              total: old.total + 1,
              total_pages: Math.ceil((old.total + 1) / limit),
            };
          }
        );
      },
      onError: (error) => {
        console.error("Error creating client:", error);
      },
    }
  );

  // Create note mutation
  const createNoteMutation = useMutation(
    ({ clientId, noteContent }) => createNote(clientId, noteContent),
    {
      onSuccess: (data, variables) => {
        const { clientId } = variables;
        // Invalidate the specific client query to refetch updated client data
        queryClient.invalidateQueries(["client", clientId]);
        // Update the clients cache
        queryClient.setQueryData(
          ["clients", { page, limit, filters }],
          (oldData) => {
            if (!oldData) return oldData;
            return {
              ...oldData,
              items: oldData.items.map((client) => {
                if (client._id === clientId) {
                  return {
                    ...client,
                    notes: client.notes ? [...client.notes, data] : [data],
                  };
                }
                return client;
              }),
            };
          }
        );
      },
      onError: (error) => {
        console.error("Error creating note:", error);
      },
    }
  );

  // Pre-fetch next page
  const hasNextPage = paginatedData?.has_more ?? false;
  const nextPage = page + 1;

  React.useEffect(() => {
    if (hasNextPage) {
      queryClient.prefetchQuery(
        ["clients", { page: nextPage, limit, filters }],
        () => getClients({ page: nextPage, limit, filters })
      );
    }
  }, [hasNextPage, nextPage, limit, filters, queryClient]);

  return {
    // Queries
    clients: paginatedData?.items ?? [],
    pagination: {
      currentPage: paginatedData?.page ?? 0,
      totalPages: paginatedData?.total_pages ?? 0,
      totalItems: paginatedData?.total ?? 0,
      hasMore: paginatedData?.has_more ?? false,
    },
    isLoadingClients,
    isErrorClients,
    clientsError,
    refetchClients,

    createNote: createNoteMutation.mutateAsync,
    isCreatingNote: createNoteMutation.isLoading,
    createNoteError: createNoteMutation.error,

    // Mutations
    createClient: createClientMutation.mutateAsync,
    isCreatingClient: createClientMutation.isLoading,
    createClientError: createClientMutation.error,

    // Helper state
    isLoading:
      isLoadingClients ||
      createClientMutation.isLoading ||
      createNoteMutation.isLoading,
    isError:
      isErrorClients ||
      createClientMutation.isError ||
      createNoteMutation.isError,
  };
};

export const useClient = (clientId) => {
  const queryClient = useQueryClient();

  // Fetch a single client
  const {
    data: client,
    isLoading,
    isError,
    error,
  } = useQuery(["client", clientId], () => getClient(clientId), {
    enabled: !!clientId, // Only run if clientId is truthy
    staleTime: 300000, // 5 minutes
    cacheTime: 3600000, // 1 hour
  });

  // Add the documents query here
  const {
    data: clientDocuments,
    isLoading: isLoadingClientDocuments,
    error: clientDocumentsError,
  } = useQuery(
    ["clientDocuments", clientId],
    () => getClientDocuments(clientId),
    {
      enabled: !!clientId,
      staleTime: 300000,
      cacheTime: 3600000,
    }
  );

  // Mutation to create a note
  const createNoteMutation = useMutation(
    ({ clientId, noteContent }) => createNote(clientId, noteContent),
    {
      onSuccess: (data, variables) => {
        const { clientId } = variables;
        // Invalidate and refetch the client data
        queryClient.invalidateQueries(["client", clientId]);
        // Optionally, update the cache directly
        queryClient.setQueryData(["client", clientId], (oldClient) => {
          if (!oldClient) return oldClient;
          return {
            ...oldClient,
            notes: oldClient.notes ? [data, ...oldClient.notes] : [data],
          };
        });
      },
      onError: (error) => {
        console.error("Error creating note:", error);
      },
    }
  );

  // 1) Add the createEngagement mutation
  const createEngagementMutation = useMutation(
    ({ clientId, engagementData }) =>
      createEngagement(clientId, engagementData),
    {
      onSuccess: (newEngagement, variables) => {
        const { clientId } = variables;
        // Invalidate or update the client data so we see new engagement in UI
        queryClient.invalidateQueries(["client", clientId]);
        queryClient.invalidateQueries("clients");

        // Or do an optimistic update:
        queryClient.setQueryData(["client", clientId], (oldClient) => {
          if (!oldClient) return oldClient;
          const updatedEngagements = [
            ...(oldClient.engagement_tracking || []),
            newEngagement,
          ];
          return {
            ...oldClient,
            engagement_tracking: updatedEngagements,
          };
        });
      },
      onError: (error) => {
        console.error("Failed to create engagement:", error);
        // Optionally show a toast
      },
    }
  );

  // Create the update engagement mutation
  const updateEngagementMutation = useMutation(
    ({ clientId, engagementId, engagementData }) =>
      updateEngagement(clientId, engagementId, engagementData),
    {
      onSuccess: (updatedEngagement, variables) => {
        const { clientId } = variables;

        // 1) Invalidate the 'client' query so we refetch updated data
        queryClient.invalidateQueries(["client", clientId]);
        queryClient.invalidateQueries("clients");

        // 2) (Optional) direct cache update if you want to avoid re-fetch:
        queryClient.setQueryData(["client", clientId], (oldClient) => {
          if (!oldClient) return oldClient;
          // oldClient is the entire client object
          // find & replace the updated engagement in the engagement_tracking array
          const updatedTracking = oldClient.engagement_tracking?.map((eng) => {
            if (eng.id === updatedEngagement.id) {
              return updatedEngagement;
            }
            return eng;
          });
          return {
            ...oldClient,
            engagement_tracking: updatedTracking,
          };
        });
      },
      onError: (error) => {
        console.error("Failed to update engagement:", error);
        // Optionally toast an error
      },
    }
  );

  // Create a custom mutation for uploading a transcript
  const uploadTranscriptMutation = useMutation(
    ({ clientId, engagementId, transcriptFile, speakers }) =>
      uploadTranscriptToEngagement({
        clientId,
        engagementId,
        transcriptFile,
        speakers,
      }),
    {
      onSuccess: (data, variables) => {
        const { clientId } = variables;
        // Invalidate or update the 'client' so we see the updated engagement
        queryClient.invalidateQueries(["client", clientId]);
        queryClient.invalidateQueries("clients");
        toast.success("Transcript uploaded successfully");
      },
      onError: (error) => {
        console.error("Failed to upload transcript:", error);
        toast.error(error.message || "Failed to upload transcript");
      },
    }
  );

  // Create a custom mutation for deleting a transcript
  const deleteTranscriptMutation = useMutation(
    ({ clientId, engagementId, transcriptId }) =>
      deleteTranscriptFromEngagement({ clientId, engagementId, transcriptId }),
    {
      onSuccess: (updatedEngagement, variables) => {
        const { clientId } = variables;
        // Invalidate or update cache
        queryClient.invalidateQueries(["client", clientId]);
        queryClient.invalidateQueries("clients");
        toast.success("Transcript deleted successfully");
      },
      onError: (error) => {
        console.error("Failed to delete transcript:", error);
        toast.error(error.message || "Failed to delete transcript");
      },
    }
  );

  // Create a custom mutation for generating notes from a transcript
  const generateNotesMutation = useMutation(
    ({ clientId, engagementId, transcriptId }) =>
      generateNotesFromTranscript({ clientId, engagementId, transcriptId }),
    {
      onSuccess: (data, variables) => {
        const { clientId } = variables;
        // Invalidate or update the 'client' so we see the updated engagement notes
        queryClient.invalidateQueries(["client", clientId]);
        queryClient.invalidateQueries("clients");
        toast.success("Notes generated successfully from transcript");
      },
      onError: (error) => {
        console.error("Failed to generate notes from transcript:", error);
        toast.error(
          error.message || "Failed to generate notes from transcript"
        );
      },
    }
  );
  
  // Add delete engagement mutation
  const deleteEngagementMutation = useMutation(
    ({ clientId, engagementId }) => deleteEngagement(clientId, engagementId),
    {
      onSuccess: (data, variables) => {
        const { clientId } = variables;
        // Invalidate the client query to refetch updated data
        queryClient.invalidateQueries(["client", clientId]);
        queryClient.invalidateQueries("clients");
        
        // Optional optimistic update
        queryClient.setQueryData(["client", clientId], (oldClient) => {
          if (!oldClient) return oldClient;
          return {
            ...oldClient,
            engagement_tracking: oldClient.engagement_tracking.filter(
              (eng) => eng.id !== variables.engagementId
            ),
          };
        });
        
        toast.success("Engagement deleted successfully");
      },
      onError: (error) => {
        console.error("Failed to delete engagement:", error);
        toast.error(error.message || "Failed to delete engagement");
      },
    }
  );

  // Add service mutation
  const addServiceMutation = useMutation(
    ({ clientId, serviceData }) => addServiceToClient(clientId, serviceData),
    {
      onSuccess: (updatedClient) => {
        // Update the specific client in the cache
        queryClient.invalidateQueries(["client", clientId]);
        queryClient.invalidateQueries("clients");
        toast.success("Service added successfully");
      },
      onError: (error) => {
        console.error("Failed to add service:", error);
      },
    }
  );

  const deleteServiceMutation = useMutation(
    ({ clientId, serviceId }) => deleteServiceFromClient(clientId, serviceId),
    {
      onSuccess: (updatedClient) => {
        // Update the cache
        queryClient.invalidateQueries(["client", clientId]);
        queryClient.invalidateQueries("clients");
        toast.success("Service deleted successfully");
      },
      onError: (error) => {
        console.error("Failed to delete service:", error);
        toast.error(error.message || "Failed to delete service");
      },
    }
  );

  // Add portal access mutation
  const updatePortalAccessMutation = useMutation(
    ({ clientId, enable }) => updateClientPortalAccess(clientId, enable),
    {
      onSuccess: (updatedClient) => {
        // Update the cache
        queryClient.invalidateQueries(["client", clientId]);
        queryClient.invalidateQueries("clients");
        toast.success(
          `Portal access ${
            updatedClient.portal_access.enabled ? "enabled" : "disabled"
          } successfully`
        );
      },
      onError: (error) => {
        console.error("Failed to update portal access:", error);
        toast.error(error.message || "Failed to update portal access");
      },
    }
  );

  const deleteClientMutation = useMutation(
    (clientId) => deleteClient(clientId),
    {
      onSuccess: () => {
        // Invalidate all client queries to refetch the list
        queryClient.invalidateQueries("clients");
        toast.success("Client deleted successfully");
        // Optionally navigate away or handle post-deletion UI
      },
      onError: (error) => {
        console.error("Failed to delete client:", error);
        toast.error(error.message || "Failed to delete client");
      },
    }
  );

  const updateServiceMutation = useMutation(
    ({ clientId, serviceId, serviceData }) =>
      updateServiceToClient(clientId, serviceId, serviceData),
    {
      onSuccess: (updatedClient) => {
        // Update the specific client in the cache
        queryClient.invalidateQueries(["client", clientId]);
        queryClient.invalidateQueries("clients");
        toast.success("Service updated successfully");
      },
      onError: (error) => {
        console.error("Failed to update service:", error);
        toast.error(error.message || "Failed to update service");
      },
    }
  );

  const fetchTranscriptText = async ({ engagementId, transcriptId }) => {
    // We already have clientId from the hook context, or pass it as needed
    return getTranscriptText(clientId, engagementId, transcriptId);
  };

  // Create a client update mutation
  const updateClientMutation = useMutation(
    ({ clientId, clientData }) => updateClient({ clientId, clientData }),
    {
      onSuccess: (updatedClient) => {
        // Update the cache
        queryClient.invalidateQueries(["client", clientId]);
        queryClient.invalidateQueries("clients");
        toast.success("Client updated successfully");
      },
      onError: (error) => {
        console.error("Failed to update client:", error);
        toast.error(error.message || "Failed to update client");
      },
    }
  );

  return {
    client,
    isLoading,
    isError,
    error,
    updateEngagement: updateEngagementMutation.mutateAsync,
    isUpdatingEngagement: updateEngagementMutation.isLoading,
    updateEngagementError: updateEngagementMutation.error,
    createEngagement: createEngagementMutation.mutateAsync,
    isCreatingEngagement: createEngagementMutation.isLoading,
    createEngagementError: createEngagementMutation.error,
    uploadTranscript: uploadTranscriptMutation.mutateAsync,
    isUploadingTranscript: uploadTranscriptMutation.isLoading,
    uploadTranscriptError: uploadTranscriptMutation.error,
    deleteTranscript: deleteTranscriptMutation.mutateAsync,
    isDeletingTranscript: deleteTranscriptMutation.isLoading,
    deleteTranscriptError: deleteTranscriptMutation.error,
    createNote: createNoteMutation.mutateAsync,
    isCreatingNote: createNoteMutation.isLoading,
    createNoteError: createNoteMutation.error,
    addService: addServiceMutation.mutateAsync,
    isAddingService: addServiceMutation.isLoading,
    addServiceError: addServiceMutation.error,
    deleteService: deleteServiceMutation.mutateAsync,
    isDeletingService: deleteServiceMutation.isLoading,
    deleteServiceError: deleteServiceMutation.error,
    updatePortalAccess: updatePortalAccessMutation.mutateAsync,
    isUpdatingPortalAccess: updatePortalAccessMutation.isLoading,
    updatePortalAccessError: updatePortalAccessMutation.error,
    deleteClient: deleteClientMutation.mutateAsync,
    isDeletingClient: deleteClientMutation.isLoading,
    deleteClientError: deleteClientMutation.error,
    updateService: updateServiceMutation.mutateAsync,
    isUpdatingService: updateServiceMutation.isLoading,
    updateServiceError: updateServiceMutation.error,
    fetchTranscriptText,
    // existing return values
    generateNotesFromTranscript: generateNotesMutation.mutateAsync,
    isGeneratingNotes: generateNotesMutation.isLoading,
    generateNotesError: generateNotesMutation.error,
    // New delete engagement functionality
    deleteEngagement: deleteEngagementMutation.mutateAsync,
    isDeletingEngagement: deleteEngagementMutation.isLoading,
    deleteEngagementError: deleteEngagementMutation.error,
    // Add client update mutation
    updateClient: updateClientMutation.mutateAsync,
    isUpdatingClient: updateClientMutation.isLoading,
    updateClientError: updateClientMutation.error,

    // Add new document-related returns
    clientDocuments,
    isLoadingClientDocuments,
    clientDocumentsError,
  };
};
