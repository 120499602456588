import { useState } from 'react';

export const useClipboard = () => {
  const [copyStatus, setCopyStatus] = useState({ success: false, method: null });

  const copyToClipboard = async (text, method) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopyStatus({ success: true, method });

      // Reset status after 2 seconds
      setTimeout(() => {
        setCopyStatus({ success: false, method: null });
      }, 2000);

      return true;
    } catch (err) {
      console.error('Failed to copy:', err);
      setCopyStatus({ success: false, method: null });
      return false;
    }
  };

  return { copyToClipboard, copyStatus };
};

export default useClipboard;