import React, { useState, useContext } from 'react';
import { NavLink, useNavigate} from 'react-router-dom';
import { ThemeContext } from '../context/ThemeContext';
import { SunIcon, MoonIcon } from '@heroicons/react/24/solid';
import gt_logo from '../imgs/logo_transparent_background_edit.png';

const Logo = () => (
  <div className="flex items-center">
    <img src={gt_logo} alt="GrayTeck Logo" className="h-8 w-auto mr-2" />
    {/* Added text-grayteck-900 for light mode and dark:text-grayteck-100 for dark mode */}
    <span className="font-bold text-xl text-grayteck-900 dark:text-grayteck-100">GrayTeck</span>
  </div>
);

const ThemeToggle = () => {
  const { darkMode, toggleDarkMode } = useContext(ThemeContext);
  return (
    <button
      onClick={toggleDarkMode}
      className="p-2 rounded-full bg-grayteck-200 dark:bg-darkbg-600 hover:bg-grayteck-300 dark:hover:bg-darkbg-500 transition-colors duration-200"
    >
      {darkMode ? (
        <SunIcon className="h-6 w-6 text-yellow-500" />
      ) : (
        <MoonIcon className="h-6 w-6 text-grayteck-800" />
      )}
    </button>
  );
};

const Navbar = ({ user, onLogout, onAddClient }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const nav = useNavigate();

  const toggleDropdown = () => setDropdownOpen(!isDropdownOpen);

  return (
    <header className="bg-grayteck-100 dark:bg-darkbg-800 p-4 flex justify-between items-center">
      <div className="flex items-center space-x-8">
        <Logo />
        <nav className="hidden sm:flex space-x-4">
          <NavLink
            to="/"
            end
            className={({ isActive }) =>
              `px-3 py-2 rounded-md ${
                isActive
                  ? 'bg-grayteck-300 dark:bg-darkbg-700 text-grayteck-900 dark:text-grayteck-100'
                  : 'text-grayteck-700 dark:text-grayteck-200 hover:bg-grayteck-200 dark:hover:bg-darkbg-700'
              }`
            }
          >
            Home
          </NavLink>
          <NavLink
            to="/clients"
            className={({ isActive }) =>
              `px-3 py-2 rounded-md ${
                isActive
                  ? 'bg-grayteck-300 dark:bg-darkbg-700 text-grayteck-900 dark:text-grayteck-100'
                  : 'text-grayteck-700 dark:text-grayteck-200 hover:bg-grayteck-200 dark:hover:bg-darkbg-700'
              }`
            }
          >
            Clients
          </NavLink>
          <NavLink
            to="/create"
            className={({ isActive }) =>
              `px-3 py-2 rounded-md ${
                isActive
                  ? 'bg-grayteck-300 dark:bg-darkbg-700 text-grayteck-900 dark:text-grayteck-100'
                  : 'text-grayteck-700 dark:text-grayteck-200 hover:bg-grayteck-200 dark:hover:bg-darkbg-700'
              }`
            }
          >
            Create Document
          </NavLink>
        </nav>
      </div>
      <div className="flex items-center space-x-4 relative">
        <span className="text-sm text-grayteck-800 dark:text-grayteck-200">Welcome, {user?.username}!</span>
        <div className="relative">
          <button
            onClick={toggleDropdown}
            className="p-2 rounded-full hover:bg-grayteck-200 dark:hover:bg-darkbg-700 transition-colors duration-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6 text-grayteck-600 dark:text-grayteck-200"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zm0 5.25a.75.75 0 110-1.5.75.75 0 010 1.5zm0 5.25a.75.75 0 110-1.5.75.75 0 010 1.5z"
              />
            </svg>
          </button>
          {isDropdownOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-darkbg-700 rounded-md shadow-lg z-10">
              <button
                onClick={() => {
                  setDropdownOpen(false);
                  onAddClient();
                }}
                className="block w-full px-4 py-2 text-left text-sm text-grayteck-700 dark:text-grayteck-200 hover:bg-grayteck-100 dark:hover:bg-darkbg-600"
              >
                Create New Client
              </button>
              <button
                onClick={() => {
                  setDropdownOpen(false);
                  nav('/settings');
                }}
                className="block w-full px-4 py-2 text-left text-sm text-grayteck-700 dark:text-grayteck-200 hover:bg-grayteck-100 dark:hover:bg-darkbg-600"
              >
                Settings
              </button>
              <button
                onClick={() => {
                  setDropdownOpen(false);
                  onLogout();
                }}
                className="block w-full px-4 py-2 text-left text-sm text-grayteck-700 dark:text-grayteck-200 hover:bg-grayteck-100 dark:hover:bg-darkbg-600"
              >
                Logout
              </button>
            </div>
          )}
        </div>
        <ThemeToggle />
      </div>
    </header>
  );
};

export default Navbar;
