import React, { useContext, useState } from 'react';
import { motion } from 'framer-motion';
import { 
  User, 
  Bell, 
  Shield, 
  Moon, 
  Save,
  CheckCircle,
  Search,
  FileSignature,
  MessageCircle,
  Settings,
  LogOut
} from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { toast } from 'react-toastify';
import { ThemeContext } from '../context/ThemeContext'; // Import ThemeContext

const Section = ({ title, children, icon: Icon }) => (
  <div className="bg-white dark:bg-darkbg-800 rounded-lg shadow-md p-6 mb-6">
    <div className="flex items-center mb-4 pb-2 border-b border-grayteck-200 dark:border-grayteck-700">
      <Icon className="w-5 h-5 text-grayteck-600 dark:text-grayteck-400 mr-2" />
      <h2 className="text-lg font-semibold text-grayteck-900 dark:text-grayteck-100">{title}</h2>
    </div>
    {children}
  </div>
);

const ToggleSwitch = ({ enabled, onChange, label }) => (
  <div className="flex items-center justify-between py-2">
    <span className="text-sm text-grayteck-700 dark:text-grayteck-300">{label}</span>
    <button
      onClick={() => onChange(!enabled)}
      className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-grayteck-500 focus:ring-offset-2 ${
        enabled ? 'bg-grayteck-600' : 'bg-grayteck-200 dark:bg-darkbg-700'
      }`}
    >
      <span
        className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform duration-200 ease-in-out ${
          enabled ? 'translate-x-6' : 'translate-x-1'
        }`}
      />
    </button>
  </div>
);

const ClientPortalSettings = () => {
  const { darkMode, toggleDarkMode } = useContext(ThemeContext); // Access darkMode and toggleDarkMode
  const user = JSON.parse(localStorage.getItem('docusign_user')) || {};
  const [isSaving, setIsSaving] = useState(false);
  const [settings, setSettings] = useState({
    emailNotifications: true,
    smsNotifications: false,
    documentUpdates: true,
    signatureReminders: true,
    twoFactorAuth: false,
    darkMode: false,
  });

  const handleSaveSettings = async () => {
    setIsSaving(true);
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      toast.success('Settings saved successfully!');
    } catch (error) {
      toast.error('Failed to save settings. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleToggle = (key) => {
    setSettings(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      className="max-w-4xl mx-auto"
      >
        <Section title="Profile Information" icon={User}>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-1">
                Name
              </label>
              <input
                type="text"
                value={user?.name || ''}
                className="w-full p-2 border rounded-md bg-grayteck-50 dark:bg-darkbg-700 border-grayteck-300 dark:border-grayteck-600 text-grayteck-900 dark:text-grayteck-100"
                placeholder="Your name"
                readOnly
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-1">
                Email
              </label>
              <input
                type="email"
                value={user?.email || ''}
                className="w-full p-2 border rounded-md bg-grayteck-50 dark:bg-darkbg-700 border-grayteck-300 dark:border-grayteck-600 text-grayteck-900 dark:text-grayteck-100"
                placeholder="Your email"
                readOnly
              />
            </div>
          </div>
        </Section>

        <Section title="Notifications" icon={Bell}>
          <ToggleSwitch
            enabled={settings.emailNotifications}
            onChange={() => handleToggle('emailNotifications')}
            label="Email Notifications"
          />
          <ToggleSwitch
            enabled={settings.smsNotifications}
            onChange={() => handleToggle('smsNotifications')}
            label="SMS Notifications"
          />
          <ToggleSwitch
            enabled={settings.documentUpdates}
            onChange={() => handleToggle('documentUpdates')}
            label="Document Update Alerts"
          />
          <ToggleSwitch
            enabled={settings.signatureReminders}
            onChange={() => handleToggle('signatureReminders')}
            label="Signature Reminders"
          />
        </Section>

        <Section title="Preferences" icon={Moon}>
          <ToggleSwitch
          enabled={darkMode} // Use global dark mode state
          onChange={toggleDarkMode} // Use global toggle function
            label="Dark Mode"
          />
        </Section>

        <motion.div
          className="mt-8 flex justify-end"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          <button
            onClick={handleSaveSettings}
            disabled={isSaving}
            className="flex items-center px-6 py-2 bg-grayteck-600 text-white rounded-md hover:bg-grayteck-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-grayteck-500 transition-colors duration-200"
          >
            {isSaving ? (
              <>
                <CheckCircle className="w-5 h-5 mr-2" />
                Saving...
              </>
            ) : (
              <>
                <Save className="w-5 h-5 mr-2" />
                Save Changes
              </>
            )}
          </button>
        </motion.div>
      </motion.div>
  );
};

export default ClientPortalSettings;