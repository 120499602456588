// components/AvailableServicesManager.js
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Plus, Edit2, Trash2, Save, X } from 'lucide-react';
import { useAvailableServices } from '../hooks/useServices';
import Spinner from './Spinner'; // Import the Spinner component

const ServiceForm = ({ onSubmit, onCancel, initialData = null, isSaving = false }) => {
  const [formData, setFormData] = useState({
    name: initialData?.name || '',
    service_type: initialData?.service_type || '',
    description: initialData?.description || ''
  });

  // Add useEffect to handle initialData changes
useEffect(() => {
    if (initialData) {
      setFormData({
        name: initialData.name || '',
        service_type: initialData.service_type || '',
        description: initialData.description || ''
      });
    }
  }, [initialData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {/* Service Name Field */}
      <div className="space-y-2">
        <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300">
          Service Name
        </label>
        <input
          type="text"
          value={formData.name}
          onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
          className="block w-full px-4 py-3 rounded-md bg-white dark:bg-darkbg-700 border border-grayteck-300 dark:border-grayteck-600 text-grayteck-900 dark:text-grayteck-100 focus:ring-2 focus:ring-grayteck-500 focus:border-transparent"
          placeholder="e.g., Voice AI Assistant, Custom Website Development"
          required
        />
      </div>

      {/* Pricing Type Field */}
      <div className="space-y-2">
        <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300">
          Pricing Type
        </label>
        <select
          value={formData.service_type}
          onChange={(e) => setFormData(prev => ({ ...prev, service_type: e.target.value }))}
          className="block w-full px-4 py-3 rounded-md bg-white dark:bg-darkbg-700 border border-grayteck-300 dark:border-grayteck-600 text-grayteck-900 dark:text-grayteck-100 focus:ring-2 focus:ring-grayteck-500 focus:border-transparent"
          required
        >
          <option value="" disabled>Select a pricing model</option>
          <option value="subscription">Subscription Based</option>
          <option value="project">Project Based</option>
        </select>
      </div>

      {/* Description Field */}
      <div className="space-y-2">
        <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300">
          Description
        </label>
        <textarea
          value={formData.description}
          onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
          className="block w-full px-4 py-3 rounded-md bg-white dark:bg-darkbg-700 border border-grayteck-300 dark:border-grayteck-600 text-grayteck-900 dark:text-grayteck-100 focus:ring-2 focus:ring-grayteck-500 focus:border-transparent"
          placeholder="Provide a detailed description of the service and its features..."
          rows="4"
          required
        />
      </div>

      {/* Action Buttons */}
      <div className="flex justify-end space-x-3 pt-4 border-t border-grayteck-200 dark:border-grayteck-700">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 text-sm font-medium text-grayteck-700 bg-white dark:bg-darkbg-700 dark:text-grayteck-300 border border-grayteck-300 dark:border-grayteck-600 rounded-md hover:bg-grayteck-50 dark:hover:bg-darkbg-600 focus:outline-none focus:ring-2 focus:ring-grayteck-500"
          disabled={isSaving}
        >
          Cancel
        </button>
        <button
          type="submit"
          className={`px-4 py-2 text-sm font-medium text-white rounded-md focus:outline-none focus:ring-2 focus:ring-grayteck-500 focus:ring-offset-2 ${
            isSaving ? 'bg-grayteck-400 cursor-not-allowed' : 'bg-grayteck-600 hover:bg-grayteck-700'
          }`}
          disabled={isSaving}
        >
          {isSaving ? 'Saving...' : 'Save'}
        </button>
      </div>
    </form>
  );
};

const AvailableServicesManager = () => {
  const {
    data: services,
    isLoading,
    createService,
    updateService,
    deleteService
  } = useAvailableServices();

  const [isAddingNew, setIsAddingNew] = useState(false);
  const [editingId, setEditingId] = useState(null);
  const [deletingIds, setDeletingIds] = useState(new Set());
  const [savingIds, setSavingIds] = useState(new Set());


  const handleCreate = async (data) => {
    await createService(data);
    setIsAddingNew(false);
  };

  const handleUpdate = async (data) => {
    await updateService({ id: editingId, ...data });
    setEditingId(null);
  };

const handleDelete = async (id) => {
  if (window.confirm('Are you sure you want to delete this service?')) {
    setDeletingIds(prev => new Set(prev).add(id)); // Add ID to deletingIds
    try {
      await deleteService(id);
    } catch (error) {
      console.error('Deletion failed:', error);
      // Optional: Display error to the user
    } finally {
      setDeletingIds(prev => {
        const newSet = new Set(prev);
        newSet.delete(id); // Remove ID after operation
        return newSet;
      });
    }
  }
};


  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="space-y-6">
      {/* Add New Button */}
      {!isAddingNew && (
        <button
          onClick={() => setIsAddingNew(true)}
          className="flex items-center px-4 py-2 text-sm font-medium text-white bg-grayteck-600 rounded-md hover:bg-grayteck-700"
        >
          <Plus className="w-4 h-4 mr-2" />
          Add New Service
        </button>
      )}

      {/* Add New Form */}
      <AnimatePresence>
        {isAddingNew && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="bg-white dark:bg-darkbg-700 rounded-lg p-4 shadow-md"
          >
            <ServiceForm
              onSubmit={handleCreate}
              onCancel={() => setIsAddingNew(false)}
              isSaving={savingIds.has('create')}
            />
          </motion.div>
        )}
      </AnimatePresence>

      {/* Services List */}
      <div className="space-y-4">
        {services?.map(service => (
          <div
            key={service.id}
            className="bg-white dark:bg-darkbg-700 rounded-lg p-4 shadow-md"
          >
            {editingId === service.id ? (
              <ServiceForm
                initialData={service}
                onSubmit={handleUpdate}
                onCancel={() => setEditingId(null)}
                isSaving={savingIds.has(service.id)}
              />
            ) : (
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="text-lg font-medium text-grayteck-900 dark:text-grayteck-100">
                    {service.name}
                  </h3>
                  <p className="text-sm text-grayteck-500 dark:text-grayteck-400">
                    Type: {service.service_type}
                  </p>
                  <p className="mt-2 text-grayteck-600 dark:text-grayteck-300">
                    {service.description}
                  </p>
                </div>
                <div className="flex space-x-2">
                  <button
                    onClick={() => setEditingId(service.id)}
                    className="p-2 text-grayteck-600 hover:text-grayteck-900 dark:text-grayteck-400 dark:hover:text-grayteck-200"
                  >
                    <Edit2 className="w-4 h-4" />
                  </button>
                  <button
                    onClick={() => handleDelete(service.id)}
                    className="p-2 text-red-600 hover:text-red-900 dark:text-red-400 dark:hover:text-red-200"
                    disabled={deletingIds.has(service.id)}
                  >
                    {deletingIds.has(service.id) ? <Spinner /> : <Trash2 className="w-4 h-4" />}
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AvailableServicesManager;