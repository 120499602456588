import React, { useState, useEffect, useRef } from 'react';
import { FileText, Calendar, Clock, ChevronDown, ChevronUp } from 'lucide-react';

const TranscriptsMenu = ({ transcripts, openTranscript }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  // Use only the actual transcripts
  const displayTranscripts = transcripts || [];

  // Format date to a readable string
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
  };

  // Format time to 12-hour format
  const formatTime = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
  };

  // Extract original filename from path
  const getFilenameFromPath = (path) => {
    if (!path) return '';
    // Handle both Windows and Unix paths
    const parts = path.split(/[\\\/]/);
    const fullFilename = parts[parts.length - 1];
    
    // The format appears to be: clientId_engagementId_originalFilename
    // Split by underscore and get everything after the second underscore
    const filenameParts = fullFilename.split('_');
    if (filenameParts.length >= 3) {
      // Join all parts after the second underscore, in case the original filename had underscores
      return filenameParts.slice(2).join('_');
    }
    return fullFilename; // Fallback to the full filename if pattern doesn't match
  };

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const menuStyles = {
    menu: {
      position: 'absolute',
      right: 0,
      width: '16rem',
      maxHeight: '300px',
      overflowY: 'auto',
      backgroundColor: 'white',
      borderRadius: '0.375rem',
      boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
      border: '1px solid #e5e7eb',
      zIndex: 1000,
      marginTop: '0.5rem',
    },
    darkMenu: {
      backgroundColor: '#1f2937',
      borderColor: '#4b5563',
    }
  };

  return (
    <div className="relative" ref={menuRef} style={{ zIndex: 50 }}>
      {/* Trigger button */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="p-2 rounded-md bg-grayteck-100 text-grayteck-600 hover:bg-grayteck-200 
                   dark:bg-grayteck-800 dark:text-grayteck-300 dark:hover:bg-grayteck-700 
                   flex items-center space-x-1 transition-colors"
      >
        <FileText className="w-4 h-4" />
        <span className="text-sm font-medium">Transcripts</span>
        {isOpen ? 
          <ChevronUp className="w-4 h-4" /> : 
          <ChevronDown className="w-4 h-4" />
        }
      </button>

      {/* Dropdown menu */}
      {isOpen && (
        <div
          style={{
            ...menuStyles.menu,
            ...(document.documentElement.classList.contains('dark') ? menuStyles.darkMenu : {})
          }}
        >
          <div>
            {displayTranscripts.length > 0 ? (
              <ul className="divide-y divide-grayteck-100 dark:divide-grayteck-700">
                {displayTranscripts.map((transcript, index) => (
                  <li key={transcript.id || index}>
                    <button
                      onClick={() => {
                        openTranscript?.(transcript);
                        setIsOpen(false);
                      }}
                      className="w-full text-left py-3 px-4 focus:outline-none hover:bg-gray-50 dark:hover:bg-darkbg-700"
                    >
                      <div className="flex items-start space-x-3">
                        <FileText className="w-5 h-5 text-grayteck-500 dark:text-grayteck-400 mt-0.5" />
                        <div className="flex-1 min-w-0">
                          <p className="text-sm font-medium text-grayteck-900 dark:text-grayteck-100 truncate">
                            {transcript.name || (transcript.path ? getFilenameFromPath(transcript.path) : 'Transcript')}
                          </p>
                          <div className="flex items-center mt-1 text-xs text-grayteck-500 dark:text-grayteck-400">
                            {transcript.date && (
                              <>
                                <Calendar className="w-3 h-3 mr-1" />
                                <span className="mr-3">{formatDate(transcript.date)}</span>
                                <Clock className="w-3 h-3 mr-1" />
                                <span>{formatTime(transcript.date)}</span>
                              </>
                            )}
                          </div>
                          {transcript.size && (
                            <p className="text-xs text-grayteck-400 dark:text-grayteck-500 mt-1">
                              {transcript.size}
                            </p>
                          )}
                        </div>
                      </div>
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="py-4 px-4 text-center text-grayteck-500 dark:text-grayteck-400">
                <p>No transcripts available</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TranscriptsMenu;