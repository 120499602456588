import React from 'react';
import { motion } from 'framer-motion';
import {
  Plus,
  Edit,
  Trash2,
  Clock,
  AlertCircle,
  XCircle,
  MessageSquare,
  CalendarCheck,
  DollarSign,
} from 'lucide-react';

/**
 * ServicesSection - The complete "Services" tab from your original ClientDetail code.
 *
 * PROPS you should pass from the parent:
 * -------------------------------------------------------------------
 * @param {Object}   client            - Full client object (contains "services" array).
 * @param {Array}    availableServices - (Optional) The list of all possible services, if needed.
 * @param {Function} formatDate        - Helper for date formatting (e.g. next billing date).
 *
 * For the action buttons:
 * @param {Function} onAddService()          - Called when user clicks "Add Service".
 * @param {Function} onEditService(service)  - Called when user clicks "Edit Service".
 * @param {Function} onDeleteService(params) - Called when user confirms "Delete Service". 
 *      -> Typically you'd do: onDeleteService({ clientId: client._id, serviceId: service.id })
 *
 * NOTE: If you want to handle communication or other actions, you can pass additional callbacks.
 */
const ServicesSection = ({
  client,
  availableServices = [],
  formatDate = (date) => (date ? new Date(date).toLocaleString() : ''),
  onAddService,
  onEditService,
  onDeleteService,
}) => {
  if (!client) return null;

  // Helper to format service codes like "sms-ai" => "Sms Ai"
  const formatServiceName = (serviceCode = '') =>
    serviceCode
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

  // Extract all services
  const services = client.services || [];

  // Filter by status
  const activeServices = services.filter((svc) => svc.status === 'active');
  const interestedServices = services.filter((svc) => svc.status === 'interested');
  const pendingServices = services.filter((svc) => svc.status === 'pending');
  const reviewServices = services.filter((svc) => svc.status === 'review');
  const completedServices = services.filter((svc) => svc.status === 'completed');
  const pausedServices = services.filter((svc) => svc.status === 'paused');
  const terminatedServices = services.filter((svc) => svc.status === 'terminated');


  const getCornerClass = (status) => {
    switch (status) {
      case 'active':
        return 'bg-green-500/20';
      case 'pending':
        return 'bg-yellow-500/20';
      case 'review':
        return 'bg-blue-500/20';
      case 'paused':
        return 'bg-red-500/20';
      case 'completed':
        return 'bg-purple-500/20';      // pick a color
      case 'terminated':
        return 'bg-grayteck-500/20';    // pick a color
      case 'interested':
        return 'bg-purple-500/20';      // or whichever color you like
      default:
        return 'bg-grayteck-500/20';
    }
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'active':
        return 'bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-300';
      case 'pending':
        return 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/30 dark:text-yellow-300';
      case 'review':
        return 'bg-blue-100 text-blue-800 dark:bg-blue-900/30 dark:text-blue-300';
      case 'paused':
        return 'bg-red-100 text-red-800 dark:bg-red-900/30 dark:text-red-300';
      case 'completed':
        return 'bg-purple-100 text-purple-800 dark:bg-purple-900/30 dark:text-purple-300';
      case 'terminated':
        return 'bg-grayteck-100 text-grayteck-800 dark:bg-grayteck-900/30 dark:text-grayteck-300';
      case 'interested':
        return 'bg-purple-100 text-purple-800 dark:bg-purple-900/30 dark:text-purple-300';
      default:
        return 'bg-grayteck-100 text-grayteck-800 dark:bg-grayteck-900/30 dark:text-grayteck-300';
    }
  };


  // 4) Function to render a single grid of services with a given heading
  const renderServiceGrid = (heading, serviceList) => {
    if (!serviceList || serviceList.length === 0) return null;

    return (
      <div className="mt-8">
        <h4 className="text-lg font-medium text-grayteck-900 dark:text-grayteck-100 mb-2">
          {heading}
        </h4>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          {serviceList.map((service, index) => {
            const cornerClass = getCornerClass(service.status);
            const badgeClass = getStatusBadgeClass(service.status);
            return (
              <motion.div
                key={service.id || index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="bg-white dark:bg-darkbg-800 rounded-lg p-6 shadow-md relative overflow-hidden"
              >
                {/* Color corner background */}
                <div
                  className={`absolute top-0 right-0 w-20 h-20 transform translate-x-10 -translate-y-10 rotate-45 ${cornerClass}`}
                />

                <div className="relative">
                  {/* Service Header */}
                  <div className="flex items-center justify-between mb-4">
                    <h4 className="text-lg font-medium text-grayteck-900 dark:text-grayteck-100">
                      {formatServiceName(service.service_code)}
                    </h4>
                  </div>

                  {/* Service Status Badge */}
                  <div className="mb-4">
                    <span className={`px-3 py-1 rounded-full text-sm font-medium ${badgeClass}`}>
                      {service.status.charAt(0).toUpperCase() + service.status.slice(1)}
                    </span>
                  </div>

                  {/* Subscription Pricing */}
                  {service.pricing?.interval && (
                    <div className="space-y-3">
                      <div className="flex items-center text-grayteck-600 dark:text-grayteck-400">
                        <Clock className="w-4 h-4 mr-2" />
                        <span>
                          Interval: {service.pricing.interval} - $
                          {service.pricing.interval_price || 0}
                        </span>
                      </div>

                      <div className="flex items-center text-grayteck-600 dark:text-grayteck-400">
                        <CalendarCheck className="w-4 h-4 mr-2" />
                        <span>Tier: {service.pricing.tier || 'standard'}</span>
                      </div>

                      <div className="flex items-center text-grayteck-600 dark:text-grayteck-400">
                        <CalendarCheck className="w-4 h-4 mr-2" />
                        <span>
                          Next Billing Date:{' '}
                          {service.pricing.next_billing_date
                            ? formatDate(service.pricing.next_billing_date)
                            : 'Not Set'}
                        </span>
                      </div>
                    </div>
                  )}

                  {/* Project Pricing */}
                  {service.pricing?.pay_structure && (
                    <div className="space-y-3 mt-3">
                      <div className="flex items-center text-grayteck-600 dark:text-grayteck-400">
                        <Clock className="w-4 h-4 mr-2" />
                        <span>Pay Structure: {service.pricing.pay_structure}</span>
                      </div>

                      {service.pricing.pay_structure === 'split' ? (
                        <>
                          <div className="flex items-center text-grayteck-600 dark:text-grayteck-400">
                            <DollarSign className="w-4 h-4 mr-2" />
                            <span>
                              Deposit Price: $
                              {(
                                (service.pricing.price || 0) *
                                (service.pricing.pay_structure_details?.[0] / 100 || 0)
                              ).toFixed(2)}
                            </span>
                          </div>
                          <div className="flex items-center text-grayteck-600 dark:text-grayteck-400">
                            <DollarSign className="w-4 h-4 mr-2" />
                            <span>
                              Completion Price: $
                              {(
                                (service.pricing.price || 0) *
                                (service.pricing.pay_structure_details?.[1] / 100 || 0)
                              ).toFixed(2)}
                            </span>
                          </div>
                          <div className="flex items-center text-grayteck-600 dark:text-grayteck-400">
                            <DollarSign className="w-4 h-4 mr-2" />
                            <span>Total Price: ${service.pricing.price || 0}</span>
                          </div>
                        </>
                      ) : (
                        <div className="flex items-center text-grayteck-600 dark:text-grayteck-400">
                          <DollarSign className="w-4 h-4 mr-2" />
                          <span>Price: ${service.pricing.price || 0}</span>
                        </div>
                      )}

                      <div className="flex items-center text-grayteck-600 dark:text-grayteck-400">
                        <CalendarCheck className="w-4 h-4 mr-2" />
                        <span>
                          Project Name: {service.pricing.project_name || 'N/A'}
                        </span>
                      </div>
                    </div>
                  )}

                  {/* Service Health (example) */}
                  <div className="mt-4 pt-4 border-t border-grayteck-200 dark:border-grayteck-700">
                    <div className="flex justify-between items-center mb-2">
                      <span className="text-sm text-grayteck-600 dark:text-grayteck-400">
                        Service Health
                      </span>
                      <div className="flex items-center space-x-1">
                        {[1, 2, 3, 4, 5].map((_, idx) => (
                          <div
                            key={idx}
                            className={`w-1.5 h-6 rounded-sm ${
                              idx < (service.health_score || 3)
                                ? 'bg-green-500'
                                : 'bg-grayteck-200 dark:bg-grayteck-700'
                            }`}
                          />
                        ))}
                      </div>
                    </div>
                  </div>

                  {/* Service Notes */}
                  {service.notes && (
                    <div className="mt-4 pt-4 border-t border-grayteck-200 dark:border-grayteck-700">
                      <p className="text-sm text-grayteck-600 dark:text-grayteck-400">
                        {service.notes}
                      </p>
                    </div>
                  )}

                  {/* Action Buttons */}
                  <div
                    className="mt-4 pt-4 border-t border-grayteck-200 dark:border-grayteck-700 
                               flex justify-between items-center"
                  >
                    <span className="text-sm font-medium text-grayteck-600 dark:text-grayteck-400">
                      Actions
                    </span>
                    <div className="flex space-x-2">
                      {/* Edit Service */}
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={() => onEditService && onEditService(service)}
                        className="p-2 text-grayteck-600 hover:text-grayteck-900 
                                   dark:text-grayteck-400 dark:hover:text-grayteck-200 
                                   transition-colors rounded-md
                                   hover:bg-grayteck-100 dark:hover:bg-darkbg-700"
                        title="Edit Service"
                      >
                        <Edit className="w-5 h-5" />
                      </motion.button>

                      {/* Communication History (placeholder) */}
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={() => {
                          // handleCommunicationHistory(service)
                        }}
                        className="p-2 text-blue-600 hover:text-blue-700 dark:text-blue-400 
                                   dark:hover:text-blue-300 transition-colors rounded-md
                                   hover:bg-blue-100 dark:hover:bg-blue-900/30"
                        title="Communication History"
                      >
                        <MessageSquare className="w-5 h-5" />
                      </motion.button>

                      {/* Delete Service */}
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={() => {
                          if (
                            window.confirm('Are you sure you want to delete this service?')
                          ) {
                            onDeleteService &&
                              onDeleteService({
                                clientId: client._id,
                                serviceId: service.id,
                              });
                          }
                        }}
                        className="p-2 text-red-600 hover:text-red-700 dark:text-red-400 
                                   dark:hover:text-red-300 transition-colors rounded-md
                                   hover:bg-red-100 dark:hover:bg-red-900/30"
                        title="Delete Service"
                      >
                        <Trash2 className="w-5 h-5" />
                      </motion.button>
                    </div>
                  </div>
                </div>
              </motion.div>
            );
          })}
        </div>
      </div>
    );
  };


  return (
    <div className="space-y-6">
      {/* ---------------------------------------------------------------------- */}
      {/* 1) Services Header with "Add Service" button */}
      {/* ---------------------------------------------------------------------- */}
      <div className="flex justify-between items-center">
        <div>
          <h3 className="text-xl font-semibold text-grayteck-900 dark:text-grayteck-100">
            Services Management
          </h3>
          <p className="text-sm text-grayteck-600 dark:text-grayteck-400 mt-1">
            {services.length || 0} total services
          </p>
        </div>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => onAddService && onAddService()}
          className="px-4 py-2 bg-grayteck-600 text-white rounded-md hover:bg-grayteck-700 
                     flex items-center space-x-2 transition-colors duration-200"
        >
          <Plus className="w-5 h-5" />
          <span>Add Service</span>
        </motion.button>
      </div>

      {/* ---------------------------------------------------------------------- */}
      {/* 2) Services Stats Overview (Active, Pending, In Review, Paused) */}
      {/* ---------------------------------------------------------------------- */}
      <div className="grid grid-cols-4 gap-4">
        {/* Active */}
        <div className="bg-white dark:bg-darkbg-800 rounded-lg p-4 shadow-md">
          <div className="flex items-center space-x-3">
            <div className="p-2 bg-green-100 dark:bg-green-900/30 rounded-lg">
              <CalendarCheck className="w-5 h-5 text-green-600 dark:text-green-400" />
            </div>
            <div>
              <p className="text-sm text-grayteck-600 dark:text-grayteck-400">Active</p>
              <p className="text-xl font-semibold text-grayteck-900 dark:text-grayteck-100">
                {activeServices.length}
              </p>
            </div>
          </div>
        </div>

        {/* Pending */}
        <div className="bg-white dark:bg-darkbg-800 rounded-lg p-4 shadow-md">
          <div className="flex items-center space-x-3">
            <div className="p-2 bg-yellow-100 dark:bg-yellow-900/30 rounded-lg">
              <Clock className="w-5 h-5 text-yellow-600 dark:text-yellow-400" />
            </div>
            <div>
              <p className="text-sm text-grayteck-600 dark:text-grayteck-400">Pending</p>
              <p className="text-xl font-semibold text-grayteck-900 dark:text-grayteck-100">
                {pendingServices.length}
              </p>
            </div>
          </div>
        </div>

        {/* In Review */}
        <div className="bg-white dark:bg-darkbg-800 rounded-lg p-4 shadow-md">
          <div className="flex items-center space-x-3">
            <div className="p-2 bg-blue-100 dark:bg-blue-900/30 rounded-lg">
              <AlertCircle className="w-5 h-5 text-blue-600 dark:text-blue-400" />
            </div>
            <div>
              <p className="text-sm text-grayteck-600 dark:text-grayteck-400">In Review</p>
              <p className="text-xl font-semibold text-grayteck-900 dark:text-grayteck-100">
                {reviewServices.length}
              </p>
            </div>
          </div>
        </div>

        {/* Paused */}
        <div className="bg-white dark:bg-darkbg-800 rounded-lg p-4 shadow-md">
          <div className="flex items-center space-x-3">
            <div className="p-2 bg-red-100 dark:bg-red-900/30 rounded-lg">
              <XCircle className="w-5 h-5 text-red-600 dark:text-red-400" />
            </div>
            <div>
              <p className="text-sm text-grayteck-600 dark:text-grayteck-400">Paused</p>
              <p className="text-xl font-semibold text-grayteck-900 dark:text-grayteck-100">
                {pausedServices.length}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* --- Now we show each status category if it has any services --- */}
      {renderServiceGrid('Active Services', activeServices)}
      {renderServiceGrid('Pending Services', pendingServices)}
      {renderServiceGrid('In Review Services', reviewServices)}
      {renderServiceGrid('Paused Services', pausedServices)}
      {renderServiceGrid('Completed Services', completedServices)}
      {renderServiceGrid('Terminated Services', terminatedServices)}
      {renderServiceGrid('Interested Services', interestedServices)}
    </div>
  );
};

export default ServicesSection;
