import React from 'react';
import { PlusIcon } from '@heroicons/react/24/solid';

const ClientDropdown = ({ 
  selectedClient, 
  onClientSelect, 
  onAddNew,
  clients = [],
  isLoading,
  isError 
}) => {
  if (isLoading) {
    return (
      <div className="relative w-full">
        <select
          disabled
          className="w-full rounded-md border-grayteck-300 shadow-sm focus:border-grayteck-500 focus:ring-grayteck-500 dark:bg-darkbg-700 dark:border-darkbg-600 dark:text-grayteck-100 bg-gray-50 text-grayteck-900 p-2 text-sm sm:text-base"
        >
          <option>Loading clients...</option>
        </select>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="relative w-full">
        <select
          disabled
          className="w-full rounded-md border-red-300 shadow-sm focus:border-red-500 focus:ring-red-500 dark:bg-darkbg-700 dark:border-red-600 dark:text-red-100 bg-gray-50 text-red-900 p-2 text-sm sm:text-base"
        >
          <option>Failed to load clients</option>
        </select>
      </div>
    );
  }

  return (
    <div className="relative w-full">
      <select
        value={selectedClient}
        onChange={(e) => {
          const selectedValue = e.target.value;
          if (selectedValue === 'add_new') {
            onClientSelect('add_new', null);
          } else if (selectedValue === '') {
            // Handle empty selection
            onClientSelect('', null);
          } else {
            const selectedClient = clients.find(client => client._id === selectedValue);
            onClientSelect(selectedValue, selectedClient);
          }
        }}
        className="w-full rounded-md border-grayteck-300 shadow-sm focus:border-grayteck-500 focus:ring-grayteck-500 dark:bg-darkbg-700 dark:border-darkbg-600 dark:text-grayteck-100 bg-gray-50 text-grayteck-900 p-2 text-sm sm:text-base"
      >
        <option value="">Select a client</option>
        {clients && clients.length > 0 && clients.map((client) => (
          <option key={client._id} value={client._id}>
            {client.name}
          </option>
        ))}
        <option value="add_new" className="font-medium text-blue-600 dark:text-blue-400">
          ➕ Add New Client...
        </option>
      </select>
    </div>
  );
};

export default ClientDropdown;