import React, { useState } from "react";
import { X } from "lucide-react";

const EditClientForm = ({ client, onClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    name: client?.name || "",
    status: client?.status || "active",
    client_type: client?.client_type || "individual",
    contact_info: {
      primary_contact: client?.contact_info?.primary_contact || "",
      position: client?.contact_info?.position || "",
      email: client?.contact_info?.email || "",
      phone: client?.contact_info?.phone || "",
    },
    company_info:
      client?.client_type === "business"
        ? {
            website: client?.company_info?.website || "",
            industry: client?.company_info?.industry || "",
            size: client?.company_info?.size || "",
            location: {
              city: client?.company_info?.location?.city || "",
              state: client?.company_info?.location?.state || "",
              country: client?.company_info?.location?.country || "",
            },
          }
        : undefined,
    source: client?.source || "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.includes(".")) {
      const [section, field] = name.split(".");
      setFormData((prev) => ({
        ...prev,
        [section]: {
          ...prev[section],
          [field]: value,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleLocationChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      company_info: {
        ...prev.company_info,
        location: {
          ...prev.company_info?.location,
          [name]: value,
        },
      },
    }));
  };

  const handleTypeChange = (e) => {
    const newType = e.target.value;
    setFormData((prev) => ({
      ...prev,
      client_type: newType,
      company_info:
        newType === "business"
          ? {
              website: "",
              industry: "",
              size: "",
              location: { city: "", state: "", country: "" },
            }
          : undefined,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-gray-600 bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white dark:bg-darkbg-800 rounded-lg shadow-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center p-6 border-b border-gray-200 dark:border-gray-700">
          <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
            Edit Client
          </h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500 dark:text-gray-500 dark:hover:text-gray-400"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          {/* Basic Info Section */}
          <div className="space-y-4">
            <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">
              Basic Information
            </h3>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Name Field */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Client Name*
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                  className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md 
                            bg-white dark:bg-darkbg-700 text-gray-900 dark:text-gray-100"
                />
              </div>

              {/* Status Field */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Status
                </label>
                <select
                  name="status"
                  value={formData.status}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md 
                            bg-white dark:bg-darkbg-700 text-gray-900 dark:text-gray-100"
                >
                  <option value="lead">Lead</option>
                  <option value="client">Client</option>
                  <option value="prospect">Prospect</option>
                  <option value="paused">Paused</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>

              {/* Client Type Field */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Client Type
                </label>
                <select
                  name="client_type"
                  value={formData.client_type}
                  onChange={handleTypeChange}
                  className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md 
                            bg-white dark:bg-darkbg-700 text-gray-900 dark:text-gray-100"
                >
                  <option value="individual">Individual</option>
                  <option value="business">Business</option>
                </select>
              </div>

              {/* Source Field */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Source
                </label>
                <select
                  name="source"
                  value={formData.source}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md 
                            bg-white dark:bg-darkbg-700 text-gray-900 dark:text-gray-100"
                >
                  <option value="">Select Source</option>
                  <option value="referral">Referral</option>
                  <option value="website">Website</option>
                  <option value="social">Social Media</option>
                  <option value="direct">Direct Contact</option>
                  <option value="cold-outreach">Cold-Outreach</option>
                  <option value="warm-outreach">Warm-Outreach</option>
                </select>
              </div>
            </div>
          </div>

          {/* Contact Information Section */}
          <div className="space-y-4">
            <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">
              Contact Information
            </h3>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Primary Contact Field */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Primary Contact
                </label>
                <input
                  type="text"
                  name="contact_info.primary_contact"
                  value={formData.contact_info.primary_contact}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md 
                            bg-white dark:bg-darkbg-700 text-gray-900 dark:text-gray-100"
                />
              </div>

              {/* Position Field */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Position
                </label>
                <input
                  type="text"
                  name="contact_info.position"
                  value={formData.contact_info.position}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md 
                            bg-white dark:bg-darkbg-700 text-gray-900 dark:text-gray-100"
                />
              </div>

              {/* Email Field */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Email
                </label>
                <input
                  type="email"
                  name="contact_info.email"
                  value={formData.contact_info.email}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md 
                            bg-white dark:bg-darkbg-700 text-gray-900 dark:text-gray-100"
                />
              </div>

              {/* Phone Field */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Phone
                </label>
                <input
                  type="text"
                  name="contact_info.phone"
                  value={formData.contact_info.phone}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md 
                            bg-white dark:bg-darkbg-700 text-gray-900 dark:text-gray-100"
                />
              </div>
            </div>
          </div>

          {/* Company Information Section - Only show if client type is business */}
          {formData.client_type === "business" && (
            <div className="space-y-4">
              <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">
                Company Information
              </h3>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* Website Field */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Website
                  </label>
                  <input
                    type="text"
                    name="website"
                    value={formData.company_info?.website || ""}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        company_info: {
                          ...prev.company_info,
                          website: e.target.value,
                        },
                      }))
                    }
                    className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md 
                              bg-white dark:bg-darkbg-700 text-gray-900 dark:text-gray-100"
                  />
                </div>

                {/* Industry Field */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Industry
                  </label>
                  <input
                    type="text"
                    name="industry"
                    value={formData.company_info?.industry || ""}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        company_info: {
                          ...prev.company_info,
                          industry: e.target.value,
                        },
                      }))
                    }
                    className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md 
                              bg-white dark:bg-darkbg-700 text-gray-900 dark:text-gray-100"
                  />
                </div>

                {/* Company Size Field */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Company Size
                  </label>
                  <input
                    type="text"
                    name="size"
                    value={formData.company_info?.size || ""}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        company_info: {
                          ...prev.company_info,
                          size: e.target.value,
                        },
                      }))
                    }
                    className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md 
                              bg-white dark:bg-darkbg-700 text-gray-900 dark:text-gray-100"
                    placeholder="Number of employees"
                  />
                </div>

                {/* Location Heading */}
                <div className="col-span-full mt-2">
                  <h4 className="text-md font-medium text-gray-700 dark:text-gray-300">
                    Location
                  </h4>
                </div>

                {/* City Field */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    City
                  </label>
                  <input
                    type="text"
                    name="city"
                    value={formData.company_info?.location?.city || ""}
                    onChange={handleLocationChange}
                    className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md 
                              bg-white dark:bg-darkbg-700 text-gray-900 dark:text-gray-100"
                  />
                </div>

                {/* State/Province Field */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    State/Province
                  </label>
                  <input
                    type="text"
                    name="state"
                    value={formData.company_info?.location?.state || ""}
                    onChange={handleLocationChange}
                    className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md 
                              bg-white dark:bg-darkbg-700 text-gray-900 dark:text-gray-100"
                  />
                </div>

                {/* Country Field */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Country
                  </label>
                  <input
                    type="text"
                    name="country"
                    value={formData.company_info?.location?.country || ""}
                    onChange={handleLocationChange}
                    className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md 
                              bg-white dark:bg-darkbg-700 text-gray-900 dark:text-gray-100"
                  />
                </div>
              </div>
            </div>
          )}

          {/* Form Actions */}
          <div className="flex justify-end space-x-4 pt-4 border-t border-gray-200 dark:border-gray-700">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-100"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-grayteck-600 text-white rounded-md hover:bg-grayteck-700 transition-colors"
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditClientForm;
