// hooks/useAvailableServices.js
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { 
  getAvailableServices,
  createAvailableService,
  updateAvailableService,
  deleteAvailableService
} from '../functions/api_fethcers';

export const useAvailableServices = () => {
  const queryClient = useQueryClient();

  // Query for fetching services
  const servicesQuery = useQuery(
    'availableServices', 
    getAvailableServices,
    {
      staleTime: 300000, // 5 minutes
      cacheTime: 3600000, // 1 hour
      retry: 1,
      onError: (error) => {
        toast.error(error.message || 'Failed to fetch services');
      }
    }
  );

  // Create mutation
  const createMutation = useMutation(createAvailableService, {
    onSuccess: (data) => {
      queryClient.setQueryData('availableServices', (old) => {
        return old ? [...old, data] : [data];
      });
      toast.success('Service created successfully');
    },
    onError: (error) => {
      toast.error(error.message || error.detail || error || 'Failed to create service');
    }
  });

  // Update mutation
  const updateMutation = useMutation(updateAvailableService, {
    onMutate: async ({ id, ...newData }) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries('availableServices');

      // Snapshot the previous value
      const previousServices = queryClient.getQueryData('availableServices');

      // Optimistically update to the new value
      queryClient.setQueryData('availableServices', (old) => {
        return old?.map(service => 
          service._id === id ? { ...service, ...newData } : service
        );
      });

      return { previousServices };
    },
    onSuccess: (data) => {
      toast.success('Service updated successfully');
    },
    onError: (error, variables, context) => {
      // Rollback on error
      if (context?.previousServices) {
        queryClient.setQueryData('availableServices', context.previousServices);
      }
      toast.error(error.response?.data?.detail || 'Failed to update service');
    },
    onSettled: () => {
      // Always refetch after error or success
      queryClient.invalidateQueries('availableServices');
    }
  });

  // Delete mutation
  const deleteMutation = useMutation(deleteAvailableService, {
    onMutate: async (id) => {
      await queryClient.cancelQueries('availableServices');
      const previousServices = queryClient.getQueryData('availableServices');

      queryClient.setQueryData('availableServices', (old) =>
        old?.filter(service => service._id !== id)
      );

      return { previousServices };
    },
    onSuccess: () => {
      toast.success('Service deleted successfully');
    },
    onError: (error, variables, context) => {
      if (context?.previousServices) {
        queryClient.setQueryData('availableServices', context.previousServices);
      }
      toast.error(error.message || error.response?.data?.detail || 'Failed to delete service');
    },
    onSettled: () => {
      queryClient.invalidateQueries('availableServices');
    }
  });

  return {
    ...servicesQuery,
    createService: createMutation.mutateAsync,
    updateService: updateMutation.mutateAsync,
    deleteService: deleteMutation.mutateAsync,
    isCreating: createMutation.isLoading,
    isUpdating: updateMutation.isLoading,
    isDeleting: deleteMutation.isLoading
  };
};