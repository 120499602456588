import React from 'react';
import { motion } from 'framer-motion';
import { 
  Briefcase, Clock, DollarSign, AlertCircle, CheckCircle,
  FileText, Calendar, Activity, BarChart, ExternalLink 
} from 'lucide-react';
import { useClientDocs } from '../hooks/useClientDocs';
import { format } from 'date-fns';

const ClientPortalServices = () => {
  const { services, isLoading, isError } = useClientDocs();

  const formatDate = (dateString) => {
    return format(new Date(dateString), 'PP');
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };

  const getStatusColor = (status) => {
    const colors = {
      active: 'bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-300',
      pending: 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/30 dark:text-yellow-300',
      paused: 'bg-red-100 text-red-800 dark:bg-red-900/30 dark:text-red-300',
      completed: 'bg-blue-100 text-blue-800 dark:bg-blue-900/30 dark:text-blue-300',
    };
    return colors[status] || 'bg-gray-100 text-gray-800 dark:bg-gray-900/30 dark:text-gray-300';
  };

  const formatServiceName = (serviceCode = '') =>
    serviceCode
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-grayteck-600"></div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="text-center py-12">
        <p className="text-red-600 dark:text-red-400">Error loading services. Please try again later.</p>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Header Section */}
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-grayteck-900 dark:text-grayteck-100">
          Your Services
        </h1>
        <p className="mt-2 text-grayteck-600 dark:text-grayteck-400">
          View and manage your active services and subscriptions
        </p>
      </div>

      {/* Service Statistics */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white dark:bg-darkbg-800 p-6 rounded-lg shadow-md"
        >
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-grayteck-600 dark:text-grayteck-400">Active Services</p>
              <p className="mt-2 text-3xl font-semibold text-grayteck-900 dark:text-grayteck-100">
                {services?.filter(s => s.status === 'active').length || 0}
              </p>
            </div>
            <div className="p-3 bg-green-100 dark:bg-green-900/30 rounded-full">
              <CheckCircle className="w-6 h-6 text-green-600 dark:text-green-400" />
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="bg-white dark:bg-darkbg-800 p-6 rounded-lg shadow-md"
        >
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-grayteck-600 dark:text-grayteck-400">Pending Services</p>
              <p className="mt-2 text-3xl font-semibold text-grayteck-900 dark:text-grayteck-100">
                {services?.filter(s => s.status === 'pending').length || 0}
              </p>
            </div>
            <div className="p-3 bg-yellow-100 dark:bg-yellow-900/30 rounded-full">
              <Clock className="w-6 h-6 text-yellow-600 dark:text-yellow-400" />
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="bg-white dark:bg-darkbg-800 p-6 rounded-lg shadow-md"
        >
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-grayteck-600 dark:text-grayteck-400">Total Active Value</p>
              <p className="mt-2 text-3xl font-semibold text-grayteck-900 dark:text-grayteck-100">
                {formatCurrency(
                  services?.reduce((total, service) => {
                    if (service.status === 'active' && service.pricing) {
                      return total + (service.pricing.interval_price || service.pricing.price || 0);
                    }
                    return total;
                  }, 0) || 0
                )}
              </p>
            </div>
            <div className="p-3 bg-blue-100 dark:bg-blue-900/30 rounded-full">
              <DollarSign className="w-6 h-6 text-blue-600 dark:text-blue-400" />
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="bg-white dark:bg-darkbg-800 p-6 rounded-lg shadow-md"
        >
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-grayteck-600 dark:text-grayteck-400">Service Health</p>
              <p className="mt-2 text-3xl font-semibold text-green-600 dark:text-green-400">Good</p>
            </div>
            <div className="p-3 bg-green-100 dark:bg-green-900/30 rounded-full">
              <Activity className="w-6 h-6 text-green-600 dark:text-green-400" />
            </div>
          </div>
        </motion.div>
      </div>

      {/* Active Services Section */}
      <div className="mb-12">
        <h2 className="text-xl font-semibold text-grayteck-900 dark:text-grayteck-100 mb-6">
          Active Services
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {services?.filter(service => service.status === 'active').map((service, index) => (
            <motion.div
              key={service.id || index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-white dark:bg-darkbg-800 rounded-lg shadow-md overflow-hidden"
            >
              <div className="p-6">
                <div className="flex justify-between items-start mb-4">
                  <div>
                    <h3 className="text-lg font-semibold text-grayteck-900 dark:text-grayteck-100">
                      {formatServiceName(service.service_code)}
                    </h3>
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                      getStatusColor(service.status)
                    }`}>
                      {service.status.charAt(0).toUpperCase() + service.status.slice(1)}
                    </span>
                  </div>
                  <button className="text-grayteck-600 hover:text-grayteck-900 dark:text-grayteck-400 dark:hover:text-grayteck-200">
                    <ExternalLink className="w-5 h-5" />
                  </button>
                </div>

                {/* Service Details */}
                <div className="space-y-4">
                  {service.pricing?.interval && (
                    <div className="space-y-2">
                      <div className="flex items-center text-grayteck-600 dark:text-grayteck-400">
                        <Clock className="w-4 h-4 mr-2" />
                        <span>
                          {service.pricing.interval.charAt(0).toUpperCase() + 
                           service.pricing.interval.slice(1)} - {formatCurrency(service.pricing.interval_price)}
                        </span>
                      </div>
                      <div className="flex items-center text-grayteck-600 dark:text-grayteck-400">
                        <BarChart className="w-4 h-4 mr-2" />
                        <span>Tier: {service.pricing.tier}</span>
                      </div>
                      {service.pricing.next_billing_date && (
                        <div className="flex items-center text-grayteck-600 dark:text-grayteck-400">
                          <Calendar className="w-4 h-4 mr-2" />
                          <span>Next Billing: {formatDate(service.pricing.next_billing_date)}</span>
                        </div>
                      )}
                    </div>
                  )}

                  {service.pricing?.pay_structure && (
                    <div className="space-y-2">
                      <div className="flex items-center text-grayteck-600 dark:text-grayteck-400">
                        <DollarSign className="w-4 h-4 mr-2" />
                        <span>Project Price: {formatCurrency(service.pricing.price)}</span>
                      </div>
                      <div className="flex items-center text-grayteck-600 dark:text-grayteck-400">
                        <FileText className="w-4 h-4 mr-2" />
                        <span>Project: {service.pricing.project_name || 'Unnamed Project'}</span>
                      </div>
                    </div>
                  )}

                  {/* Service Health Indicator */}
                  <div className="pt-4 border-t border-grayteck-200 dark:border-grayteck-700">
                    <div className="flex items-center justify-between">
                      <span className="text-sm text-grayteck-600 dark:text-grayteck-400">Service Health</span>
                      <div className="flex items-center">
                        <span className="w-2 h-2 rounded-full bg-green-500 mr-2"></span>
                        <span className="text-sm text-grayteck-600 dark:text-grayteck-400">Good</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Pending Services Section */}
      <div className="mb-12">
        <h2 className="text-xl font-semibold text-grayteck-900 dark:text-grayteck-100 mb-6">
          Pending Services
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {services?.filter(service => service.status === 'pending').map((service, index) => (
            <motion.div
              key={service.id || index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-white dark:bg-darkbg-800 rounded-lg shadow-md overflow-hidden border-l-4 border-yellow-500 dark:border-yellow-600"
            >
              <div className="p-6">
                <div className="flex justify-between items-start mb-4">
                  <div>
                    <h3 className="text-lg font-semibold text-grayteck-900 dark:text-grayteck-100">
                      {formatServiceName(service.service_code)}
                    </h3>
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                      getStatusColor(service.status)
                    }`}>
                      {service.status.charAt(0).toUpperCase() + service.status.slice(1)}
                    </span>
                  </div>
                  <button className="text-grayteck-600 hover:text-grayteck-900 dark:text-grayteck-400 dark:hover:text-grayteck-200">
                    <ExternalLink className="w-5 h-5" />
                  </button>
                </div>

                {/* Pending Service Details */}
                <div className="space-y-4">
                  {service.pricing?.interval && (
                    <div className="space-y-2">
                      <div className="flex items-center text-grayteck-600 dark:text-grayteck-400">
                        <Clock className="w-4 h-4 mr-2" />
                        <span>
                          {service.pricing.interval.charAt(0).toUpperCase() + 
                           service.pricing.interval.slice(1)} - {formatCurrency(service.pricing.interval_price)}
                        </span>
                      </div>
                      <div className="flex items-center text-grayteck-600 dark:text-grayteck-400">
                        <BarChart className="w-4 h-4 mr-2" />
                        <span>Proposed Tier: {service.pricing.tier}</span>
                      </div>
                    </div>
                  )}

                  {service.pricing?.pay_structure && (
                    <div className="space-y-2">
                      <div className="flex items-center text-grayteck-600 dark:text-grayteck-400">
                        <DollarSign className="w-4 h-4 mr-2" />
                        <span>Proposed Price: {formatCurrency(service.pricing.price)}</span>
                      </div>
                      <div className="flex items-center text-grayteck-600 dark:text-grayteck-400">
                        <FileText className="w-4 h-4 mr-2" />
                        <span>Project: {service.pricing.project_name || 'Unnamed Project'}</span>
                      </div>
                    </div>
                  )}

                  {/* Pending Status Information */}
                  <div className="pt-4 border-t border-grayteck-200 dark:border-grayteck-700">
                    <div className="flex items-center space-x-2">
                      <AlertCircle className="w-5 h-5 text-yellow-500" />
                      <span className="text-sm text-grayteck-600 dark:text-grayteck-400">
                        Awaiting Approval - Submitted {service.start_date ? formatDate(service.start_date) : 'Recently'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          ))}

          {services?.filter(service => service.status === 'pending').length === 0 && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="col-span-2 text-center py-8 bg-white dark:bg-darkbg-800 rounded-lg shadow-md"
            >
              <Clock className="w-8 h-8 text-grayteck-400 mx-auto mb-4" />
              <p className="text-grayteck-600 dark:text-grayteck-400">
                No pending services at the moment
              </p>
            </motion.div>
          )}
        </div>
      </div>

      {/* Service History Section */}
      <div>
        <h2 className="text-xl font-semibold text-grayteck-900 dark:text-grayteck-100 mb-6">
          Service History
        </h2>
        <div className="bg-white dark:bg-darkbg-800 rounded-lg shadow-md overflow-hidden">
          <div className="overflow-x-auto">
            <table className="min-w-full">
              <thead>
                <tr className="bg-grayteck-50 dark:bg-darkbg-700">
                  <th className="px-6 py-3 text-left text-xs font-medium text-grayteck-500 dark:text-grayteck-400 uppercase tracking-wider">
                    Service
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-grayteck-500 dark:text-grayteck-400 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-grayteck-500 dark:text-grayteck-400 uppercase tracking-wider">
                    Start Date
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-grayteck-500 dark:text-grayteck-400 uppercase tracking-wider">
                    Latest Activity
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-grayteck-200 dark:divide-grayteck-700">
                {services?.map((service, index) => (
                  <tr key={index} className="hover:bg-grayteck-50 dark:hover:bg-darkbg-700">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-grayteck-600 dark:text-grayteck-400">
                      {formatDate(new Date())} {/* Replace with actual activity date when available */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Upcoming Service Events */}
      <div className="mt-12">
        <h2 className="text-xl font-semibold text-grayteck-900 dark:text-grayteck-100 mb-6">
          Upcoming Service Events
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {services?.filter(service => service.pricing?.next_billing_date)
            .sort((a, b) => new Date(a.pricing.next_billing_date) - new Date(b.pricing.next_billing_date))
            .slice(0, 3)
            .map((service, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-white dark:bg-darkbg-800 p-6 rounded-lg shadow-md"
            >
              <div className="flex items-center justify-between mb-4">
                <div className="p-3 bg-blue-100 dark:bg-blue-900/30 rounded-full">
                  <Calendar className="w-6 h-6 text-blue-600 dark:text-blue-400" />
                </div>
                <span className="text-sm text-grayteck-600 dark:text-grayteck-400">
                  {formatDate(service.pricing.next_billing_date)}
                </span>
              </div>
              <h3 className="text-lg font-medium text-grayteck-900 dark:text-grayteck-100 mb-2">
                {formatServiceName(service.service_code)}
              </h3>
              <p className="text-sm text-grayteck-600 dark:text-grayteck-400">
                Next {service.pricing.interval} payment:
                {' '}{formatCurrency(service.pricing.interval_price)}
              </p>
            </motion.div>
          ))}
        </div>
      </div>

      {/* No Services Message */}
      {(!services || services.length === 0) && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center py-12"
        >
          <div className="rounded-full bg-grayteck-100 dark:bg-grayteck-900/30 p-3 w-12 h-12 mx-auto mb-4 flex items-center justify-center">
            <Briefcase className="w-6 h-6 text-grayteck-600 dark:text-grayteck-400" />
          </div>
          <h3 className="text-lg font-medium text-grayteck-900 dark:text-grayteck-100 mb-2">
            No Active Services
          </h3>
          <p className="text-grayteck-600 dark:text-grayteck-400">
            You currently don't have any active services. Contact your account manager to learn more about our offerings.
          </p>
        </motion.div>
      )}
    </div>
  );
};

export default ClientPortalServices;