import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useAvailableServices } from '../hooks/useServices';
import { useClients } from '../hooks/useClient';
import { toast } from 'react-toastify';

const AddClientForm = ({ onClose, isOpen }) => {
  const { createClient, isCreatingClient } = useClients();
  const { data: availableServices, isLoading: isLoadingServices } = useAvailableServices();
  const [selectedService, setSelectedService] = useState('');

  const [formData, setFormData] = useState({
    name: '',
    client_type: '',
    status: 'lead',
    source: '',
    first_contact_date: '',    // Changed from first_contacted
    last_contact_date: '',     // Added new field
    contact_info: {
      primary_contact: '',
      email: '',
      phone: '',
      position: ''
    },
    company_info: {
      website: '',
      industry: '',
      size: '',
      location: {
        city: '',
        state: '',
        country: ''
      }
    },
    services: [], // Unified services array
    notes: [],
    engagement_tracking: []
  });

  const services = availableServices || [
    { id: 'voice-ai', name: 'Voice AI' },
    { id: 'sms-ai', name: 'SMS AI' },
    { id: 'wix-website-development', name: 'Wix Website Development' },
    { id: 'web-app-development', name: 'Web App Development' },
    { id: 'custom-website', name: 'Custom Website' }
  ];

  const leadSources = [
    { id: 'cold-outreach', name: 'Cold Outreach' },
    { id: 'warm-outreach', name: 'Warm Outreach' },
    { id: 'website', name: 'Website' },
    { id: 'referral', name: 'Referral' },
    { id: 'social', name: 'Social Media' }
  ];

  const handleInputChange = (e, section, subsection) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      if (section && subsection) {
        return {
          ...prev,
          [section]: {
            ...prev[section],
            [subsection]: {
              ...prev[section][subsection],
              [name]: value
            }
          }
        };
      } else if (section) {
        return {
          ...prev,
          [section]: {
            ...prev[section],
            [name]: value
          }
        };
      } else {
        return {
          ...prev,
          [name]: value
        };
      }
    });
  };
  
  
    // Function to add a service
    const handleAddService = () => {
      if (!selectedService) return;
    
      const serviceObj = services.find((s) => s.id === selectedService);
      if (!serviceObj) return;
    
      // Check if the service is already added
      // const isAlreadyAdded = formData.services.some(
      //   (svc) => svc.available_service_id === serviceObj.id
      // );
      // if (isAlreadyAdded) {
      //   toast.warn('Service already added.');
      //   return;
      // }
    
      // Determine service status based on client status
      const serviceStatus = formData.status === 'client' ? 'active' : 'interested';
    
      // Initialize pricing only if serviceStatus is 'active'
      let initialPricing = null;
      if (serviceStatus === 'active') {
        if (serviceObj.service_type === 'subscription') {
          initialPricing = {
            interval: '',          // Will be set via form
            interval_price: 0,     // Default value
            tier: '',              // Will be set via form
            // Optional fields can be added here if needed
          };
        } else if (serviceObj.service_type === 'project') {
          initialPricing = {
            pay_structure: '',                // Will be set via form
            price: 0,                          // Default value
            pay_structure_details: [],         // Will be set via form
            project_name: '',                  // Will be set via form
            // Optional fields can be added here if needed
          };
        }
      }
    
      // Construct the new service object
      const newService = {
        available_service_id: serviceObj.id,
        status: serviceStatus,
        // Conditionally include pricing only if the service is active
        ...(serviceStatus === 'active' && initialPricing ? { pricing: initialPricing } : {})
      };
    
      // Update the form data with the new service
      setFormData((prev) => ({
        ...prev,
        services: [
          ...prev.services,
          newService
        ]
      }));
    
      setSelectedService(''); // Reset selection
    };


  const handleRemoveService = (serviceId) => {
    setFormData((prev) => ({
      ...prev,
      services: prev.services.filter((svc) => svc.available_service_id !== serviceId)
    }));
  };


  const updatePricingField = (serviceId, field, value) => {
    setFormData((prev) => ({
      ...prev,
      services: prev.services.map((svc) =>
        svc.available_service_id === serviceId
          ? {
              ...svc,
              pricing: {
                ...svc.pricing,
                [field]: value
              }
            }
          : svc
      )
    }));
  };
  
  // Dedicated handler for Pay Structure changes
  const handlePayStructureChange = (serviceId, value) => {
    setFormData((prev) => ({
      ...prev,
      services: prev.services.map((svc) => {
        if (svc.available_service_id === serviceId) {
          if (value === 'one-time') {
            return {
              ...svc,
              pricing: {
                ...svc.pricing,
                pay_structure: value,
                pay_structure_details: [100], // Automatically set to 100%
              },
            };
          } else if (value === 'split') {
            return {
              ...svc,
              pricing: {
                ...svc.pricing,
                pay_structure: value,
                pay_structure_details: [], // Allow user input
              },
            };
          }
        }
        return svc;
      }),
    }));
  };

  // useEffect to handle status changes (lead/prospect ↔ client)
  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      services: prev.services.map((svc) => {
        if (formData.status === 'client') {
          // Convert to active services
          return {
            ...svc,
            status: 'active',
            pricing: svc.pricing || {} // Initialize pricing if not already set
          };
        } else {
          // Convert to interested services
          const { pricing, ...rest } = svc; // Remove pricing
          return {
            ...rest,
            status: 'interested'
          };
        }
      })
    }));
  }, [formData.status]);

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create the initial engagement tracking entry
    const initialEngagement = {
      type: 'meeting',
      date: formData.first_contact_date
        ? new Date(formData.first_contact_date).toISOString()
        : new Date().toISOString(),
      description: `Lead contacted via ${formData.source || 'unknown source'}`,
      outcome: 'Positive - decided to proceed with implementation',
      next_steps: {status:'Completed', step:'Follow up as per schedule'}
      // next_steps: 'Follow up as per schedule'

    };

    // Prepare services array for submission
    const preparedServices = formData.services.map((svc) => ({
      available_service_id: svc.available_service_id,
      pricing: svc.pricing || null
    }));

    const submissionData = {
      ...formData,
      services: preparedServices,
      engagement_tracking: [initialEngagement],
      created_at: new Date().toISOString(),
      last_contact_date: formData.last_contact_date || formData.first_contact_date
    };

    try {
        await createClient(submissionData);
        toast.success('Client created successfully!');
        onClose();
      } catch (error) {
        console.error('Failed to create client:', error);
        // Display the specific error message from the backend if available
        toast.error(error.detail || 'Failed to create client');
      }
    };

    // Framer Motion variants for animation
    const backdropVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
        exit: { opacity: 0 }
      };
    
      const modalVariants = {
        hidden: { opacity: 0, y: -50 },
        visible: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: 50 }
      }

 
//   const handleSubmit = (e) => {
//     e.preventDefault();

//     // Create the initial engagement tracking entry
//     const initialEngagement = {
//       type: 'first-contact',
//       date: formData.first_contacted
//         ? new Date(formData.first_contacted).toISOString()
//         : new Date().toISOString(),
//       description: `Lead contacted via ${formData.lead_source || 'unknown source'}`,
//       outcome: 'Initial contact made',
//       next_steps: 'Follow up scheduled'
//     };

//     const submissionData = {
//       ...formData,
//       engagement_tracking: [initialEngagement],
//       created_at: new Date().toISOString(),
//       last_contact_date: formData.last_contact_date || formData.first_contact_date

//     };

//     console.log('Form submitted:', submissionData);
//     // API call logic here
//     onClose();
//   };

useEffect(() => {
  setFormData((prev) => ({
    ...prev,
    services: prev.services.map((svc) => {
      if (formData.status === 'client') {
        // Convert to active services
        return {
          ...svc,
          status: 'active',
          pricing: svc.pricing || {} // Initialize pricing if not already set
        };
      } else {
        // Convert to interested services
        const { pricing, ...rest } = svc; // Remove pricing
        return {
          ...rest,
          status: 'interested'
        };
      }
    })
  }));
}, [formData.status]);


// Render the services section based on status
const renderServicesSection = () => {
  const isClient = formData.status === 'client';
  const title = isClient ? 'Active Services' : 'Service Interests';

  return (
    <div className="space-y-4">
      <h4 className="text-lg font-semibold text-grayteck-900 dark:text-grayteck-100">
        {title}
      </h4>
      
          {/* Service Selection Dropdown */}
      <div className="flex space-x-2">
        <select
          value={selectedService}
          onChange={(e) => setSelectedService(e.target.value)}
          className="flex-1 p-2 border rounded-md bg-white dark:bg-darkbg-700 border-grayteck-300 dark:border-grayteck-600 text-grayteck-900 dark:text-grayteck-100"
        >
          <option value="">Select a service...</option>
          {services.map((service) => (
            <option key={service.id} value={service.id}>
              {service.name}
            </option>
          ))}
        </select>
        <button
          type="button"
          onClick={handleAddService}
          disabled={!selectedService}
          className="px-4 py-2 bg-grayteck-600 text-white rounded-md hover:bg-grayteck-700 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Add
        </button>
      </div>

        {/* Active Services List with Dynamic Pricing Inputs */}
        <div className="space-y-4">
          {formData.services.map((service) => {
            const serviceInfo = services.find(
              (s) => s.id === service.available_service_id
            );
          if (!serviceInfo) return null;

          return (
            <div
                key={service.available_service_id}
                className="border p-4 rounded-md bg-white dark:bg-darkbg-700"
            >
                <div className="flex items-center justify-between">
                <h5 className="font-medium text-grayteck-900 dark:text-grayteck-100">
                    {serviceInfo.name}
                </h5>
                  <button
                    type="button"
                    onClick={() => handleRemoveService(service.available_service_id)}
                    className="text-red-500 hover:text-red-700"
                  >
                    Remove
                  </button>
                </div>

                {/* Render Pricing Fields Only if Service is Active */}
                {isClient && service.status === 'active' && (
                  <div className="mt-4 space-y-4">
                    {serviceInfo.service_type === 'subscription' ? (
                  <>
                        <div>
                      <label className="block mb-1 text-gray-700 dark:text-gray-300">Billing Interval</label>
                    <select
                        className="w-full p-2 border rounded-md bg-white dark:bg-darkbg-600 border-grayteck-300 dark:border-grayteck-600 text-grayteck-900 dark:text-grayteck-100"
                            value={service.pricing.interval || ''}
                            onChange={(e) =>
                              updatePricingField(service.available_service_id, 'interval', e.target.value)
                            }
                        required
                      >
                        <option value="">Select Interval</option>
                        <option value="monthly">Monthly</option>
                        <option value="quarterly">Quarterly</option>
                        <option value="annual">Annual</option>
                      </select>
                    </div>
                        <div>
                      <label className="block mb-1 text-gray-700 dark:text-gray-300">Interval Price</label>
                      <input
                        type="number"
                        className="w-full p-2 border rounded-md bg-white dark:bg-darkbg-600 border-grayteck-300 dark:border-grayteck-600 text-grayteck-900 dark:text-grayteck-100"
                            value={service.pricing.interval_price || ''}
                        onChange={(e) =>
                              updatePricingField(
                                service.available_service_id,
                                'interval_price',
                                parseFloat(e.target.value) || ''
                              )
                        }
                        min="0"
                        step="0.01"
                        required
                      />
                    </div>
                        <div>
                      <label className="block mb-1 text-gray-700 dark:text-gray-300">Subscription Tier</label>
                      <select
                        className="w-full p-2 border rounded-md bg-white dark:bg-darkbg-600 border-grayteck-300 dark:border-grayteck-600 text-grayteck-900 dark:text-grayteck-100"
                            value={service.pricing.tier || ''}
                            onChange={(e) =>
                              updatePricingField(service.available_service_id, 'tier', e.target.value)
                            }
                        required
                    >
                        <option value="">Select Tier</option>
                          <option value="starter">Starter</option>
                        <option value="standard">Standard</option>
                        <option value="premium">Premium</option>
                          <option value="custom">Custom</option>
                      </select>
                    </div>

                    <div>
                        <label className="block mb-1 text-gray-700 dark:text-gray-300">
                          Next Billing Date
                        </label>
                        <input
                          type="date"
                          className="w-full p-2 border rounded-md bg-white dark:bg-darkbg-600 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100"
                          value={service.pricing.next_billing_date || ''}
                          onChange={(e) =>
                            updatePricingField(service.available_service_id, 'next_billing_date', e.target.value)
                          }
                        />
                      </div>
                  </>
                ) : (
                  // Project-based service pricing inputs
                  <>
                        <div>
                      <label className="block mb-1 text-gray-700 dark:text-gray-300">Project Name</label>
                      <input
                        type="text"
                        className="w-full p-2 border rounded-md bg-white dark:bg-darkbg-600 border-grayteck-300 dark:border-grayteck-600 text-grayteck-900 dark:text-grayteck-100"
                            value={service.pricing.project_name || ''}
                            onChange={(e) =>
                              updatePricingField(service.available_service_id, 'project_name', e.target.value)
                            }
                        required
                      />
                    </div>
                        <div>
                      <label className="block mb-1 text-gray-700 dark:text-gray-300">Pay Structure</label>
                      <select
                        className="w-full p-2 border rounded-md bg-white dark:bg-darkbg-600 border-grayteck-300 dark:border-grayteck-600 text-grayteck-900 dark:text-grayteck-100"
                            value={service.pricing.pay_structure || ''}
                            onChange={(e) =>
                              handlePayStructureChange(service.available_service_id, e.target.value)
                            }
                        required
                      >
                        <option value="">Select Pay Structure</option>
                        <option value="one-time">One-Time</option>
                        <option value="split">Split</option>
                    </select>
                  </div>
                        <div>
                      <label className="block mb-1 text-gray-700 dark:text-gray-300">Total Price</label>
                      <input
                        type="number"
                        className="w-full p-2 border rounded-md bg-white dark:bg-darkbg-600 border-grayteck-300 dark:border-grayteck-600 text-grayteck-900 dark:text-grayteck-100"
                            value={service.pricing.price || ''}
                        onChange={(e) =>
                              updatePricingField(
                                service.available_service_id,
                                'price',
                                parseFloat(e.target.value) || ''
                              )
                        }
                        min="0"
                        step="0.01"
                        required
                      />
                    </div>
                      {/* Conditionally Render Payment Milestones */}
                  {service.pricing.pay_structure === 'SPLIT' && (
                    <div>
                      <label className="block mb-1 text-gray-700 dark:text-gray-300">
                        Payment Milestones (%)
                      </label>
                      <input
                        type="text"
                        className="w-full p-2 border rounded-md bg-white dark:bg-darkbg-600 border-gray-300 dark:border-darkbg-600 text-gray-900 dark:text-gray-200"
                        placeholder="e.g., 50,50 or 30,30,40"
                        value={
                          service.pricing.pay_structure_details
                            ? service.pricing.pay_structure_details.join(',')
                            : ''
                        }
                        onChange={(e) =>
                          updatePricingField(
                            service.available_service_id,
                            'pay_structure_details',
                            e.target.value
                              .split(',')
                              .map((v) => parseFloat(v.trim()))
                          )
                        }
                        required={service.pricing.pay_structure === 'SPLIT'}
                      />
                    </div>
                  )}

                  </>
                )}
            </div>
                )}

                {/* If not client, display status */}
                {!isClient && (
                  <div className="mt-2">
                    <span className="text-sm text-grayteck-500 dark:text-grayteck-400">
                      Status: {service.status.charAt(0).toUpperCase() + service.status.slice(1)}
                    </span>
                  </div>
                )}
    </div>
              );
            })}
          </div>
        </div>
      );
  };

  return (
    // Removed the fixed overlay here since the Modal handles it
    <div className="p-6">
          <form onSubmit={handleSubmit} className="space-y-6">
        {/* Update title styles to adapt to dark mode */}
        <h2 className="text-xl font-semibold text-grayteck-900 dark:text-grayteck-100">
          Add New Client
        </h2>

            {/* Client Type */}
            <div>
              <label className="block mb-1 text-gray-700 dark:text-gray-300">Client Type</label>
              <select
                name="client_type"
                value={formData.client_type}
                onChange={handleInputChange}
                required
                className="
                  w-full p-2 border rounded-md 
                  bg-white text-gray-900 border-gray-300
                  dark:bg-darkbg-700 dark:text-gray-200 dark:border-darkbg-600
                  focus:outline-none focus:ring-1 focus:ring-blue-500
                "
              >
                <option value="">Select Client Type</option>
                <option value="business">Business</option>
                <option value="individual">Individual</option>
              </select>
            </div>

            {/* Status */}
            <div>
              <label className="block mb-1 text-gray-700 dark:text-gray-300">Status</label>
              <select
                name="status"
                value={formData.status}
                onChange={handleInputChange}
                className="
                  w-full p-2 border rounded-md 
                  bg-white text-gray-900 border-gray-300
                  dark:bg-darkbg-700 dark:text-gray-200 dark:border-darkbg-600
                  focus:outline-none focus:ring-1 focus:ring-blue-500
                "
              >
                <option value="lead">Lead</option>
                <option value="prospect">Prospect</option>
                <option value="client">Client</option>
              </select>
            </div>

            {/* Source */}
            <div>
            <label className="block mb-1 text-gray-700 dark:text-gray-300">Source</label>
            <select
                name="source"
                value={formData.source}
                onChange={handleInputChange}
                className="
                w-full p-2 border rounded-md 
                bg-white text-gray-900 border-gray-300
                dark:bg-darkbg-700 dark:text-gray-200 dark:border-darkbg-600
                focus:outline-none focus:ring-1 focus:ring-blue-500
                "
            >
                <option value="">Select Source</option>
                {leadSources.map((source) => (
                <option key={source.id} value={source.id}>
                    {source.name}
                </option>
                ))}
            </select>
            </div>
            {/* Contact Dates */}
            {/* First Contacted */}
            <div className="grid grid-cols-2 gap-4">
            <div>
                <label className="block mb-1 text-gray-700 dark:text-gray-300">First Contact Date</label>
                <input
                type="date"
                name="first_contact_date"
                value={formData.first_contact_date}
                onChange={handleInputChange}
                className="
                w-full p-2 border rounded-md 
                bg-white text-gray-900 border-gray-300 placeholder-gray-400
                dark:bg-darkbg-700 dark:text-gray-200 dark:border-darkbg-600
                focus:outline-none focus:ring-1 focus:ring-blue-500
                "
                />
            </div>
            {/* Last Contacted */}
            <div>
                <label className="block mb-1 text-gray-700 dark:text-gray-300">Last Contact Date</label>
                <input
                type="date"
                name="last_contact_date"
                value={formData.last_contact_date}
                onChange={handleInputChange}
                className="
                    w-full p-2 border rounded-md 
                    bg-white text-gray-900 border-gray-300 placeholder-gray-400
                    dark:bg-darkbg-700 dark:text-gray-200 dark:border-darkbg-600
                    focus:outline-none focus:ring-1 focus:ring-blue-500
                "
                />
            </div>
            </div>

            {/* Name or Company */}
            <div>
              <label className="block mb-1 text-gray-700 dark:text-gray-300">
                {formData.client_type === 'business' ? 'Company Name' : 'Full Name'}
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="
                  w-full p-2 border rounded-md 
                  bg-white text-gray-900 border-gray-300 placeholder-gray-400
                  dark:bg-darkbg-700 dark:text-gray-200 dark:border-darkbg-600
                  focus:outline-none focus:ring-1 focus:ring-blue-500
                "
                required
              />
            </div>

            {/* Contact Info */}
            <h3 className="text-lg font-semibold mt-4 text-gray-800 dark:text-gray-100">
              Contact Information
            </h3>

            {formData.client_type === 'business' && (
        <>
              <div className="mt-2">
                <input
                  placeholder="Point of Contact Name"
                  name="primary_contact"
                  value={formData.contact_info.primary_contact}
                  onChange={(e) => handleInputChange(e, 'contact_info')}
                  className="
                    w-full p-2 border rounded-md 
                    bg-white text-gray-900 border-gray-300 placeholder-gray-400
                    dark:bg-darkbg-700 dark:text-gray-200 dark:border-darkbg-600
                    focus:outline-none focus:ring-1 focus:ring-blue-500
                  "
                />
              </div>
                <div className="mt-2">
                <input
                placeholder="Position"
                name="position"
                value={formData.contact_info.position}
                onChange={(e) => handleInputChange(e, 'contact_info')}
                className="
                    w-full p-2 border rounded-md 
                    bg-white text-gray-900 border-gray-300 placeholder-gray-400
                    dark:bg-darkbg-700 dark:text-gray-200 dark:border-darkbg-600
                    focus:outline-none focus:ring-1 focus:ring-blue-500
                "
                />
            </div>
        </>
            )}


            <div className="grid grid-cols-2 gap-4">
              <input
                type="email"
                placeholder="Email"
                name="email"
                value={formData.contact_info.email}
                onChange={(e) => handleInputChange(e, 'contact_info')}
                className="
                  p-2 border rounded-md 
                  bg-white text-gray-900 border-gray-300 placeholder-gray-400
                  dark:bg-darkbg-700 dark:text-gray-200 dark:border-darkbg-600
                  focus:outline-none focus:ring-1 focus:ring-blue-500
                "
          required
              />
              <input
                type="tel"
                placeholder="Phone"
                name="phone"
                value={formData.contact_info.phone}
                onChange={(e) => handleInputChange(e, 'contact_info')}
                className="
                  p-2 border rounded-md 
                  bg-white text-gray-900 border-gray-300 placeholder-gray-400
                  dark:bg-darkbg-700 dark:text-gray-200 dark:border-darkbg-600
                  focus:outline-none focus:ring-1 focus:ring-blue-500
                "
              />
            </div>            

            {/* Company Information */}
            {formData.client_type === 'business' && (
              <>
                <h3 className="text-lg font-semibold mt-4 text-gray-800 dark:text-gray-100">
                  Company Information
                </h3>
                <input
                  type="url"
                  placeholder="Website"
                  name="website"
                  value={formData.company_info.website}
                  onChange={(e) => handleInputChange(e, 'company_info')}
                  className="
                    w-full p-2 border rounded-md 
                    bg-white text-gray-900 border-gray-300 placeholder-gray-400
                    dark:bg-darkbg-700 dark:text-gray-200 dark:border-darkbg-600
                    focus:outline-none focus:ring-1 focus:ring-blue-500
                  "
                />
                <input
                  type="text"
                  placeholder="Industry"
                  name="industry"
                  value={formData.company_info.industry}
                  onChange={(e) => handleInputChange(e, 'company_info')}
                  className="
                    w-full p-2 mt-2 border rounded-md 
                    bg-white text-gray-900 border-gray-300 placeholder-gray-400
                    dark:bg-darkbg-700 dark:text-gray-200 dark:border-darkbg-600
                    focus:outline-none focus:ring-1 focus:ring-blue-500
                  "
                />
                <input
                  type="text"
                  placeholder="Company Size"
                  name="size"
                  value={formData.company_info.size}
                  onChange={(e) => handleInputChange(e, 'company_info')}
                  className="
                    w-full p-2 mt-2 border rounded-md 
                    bg-white text-gray-900 border-gray-300 placeholder-gray-400
                    dark:bg-darkbg-700 dark:text-gray-200 dark:border-darkbg-600
                    focus:outline-none focus:ring-1 focus:ring-blue-500
                  "
                />
                <div className="grid grid-cols-2 gap-4 mt-2">
                  <input
                    type="text"
                    placeholder="City"
                    name="city"
                    value={formData.company_info.location.city}
                    onChange={(e) => handleInputChange(e, 'company_info', 'location')}
                    className="
                      p-2 border rounded-md 
                      bg-white text-gray-900 border-gray-300 placeholder-gray-400
                      dark:bg-darkbg-700 dark:text-gray-200 dark:border-darkbg-600
                      focus:outline-none focus:ring-1 focus:ring-blue-500
                    "
                  />
                  <input
                    type="text"
                    placeholder="State"
                    name="state"
                    value={formData.company_info.location.state}
                    onChange={(e) => handleInputChange(e, 'company_info', 'location')}
                    className="
                      p-2 border rounded-md 
                      bg-white text-gray-900 border-gray-300 placeholder-gray-400
                      dark:bg-darkbg-700 dark:text-gray-200 dark:border-darkbg-600
                      focus:outline-none focus:ring-1 focus:ring-blue-500
                    "
                  />
                  <input
                    type="text"
                    placeholder="Country"
                    name="country"
                    value={formData.company_info.location.country}
                    onChange={(e) => handleInputChange(e, 'company_info', 'location')}
                    className="
                      p-2 border rounded-md col-span-2
                      bg-white text-gray-900 border-gray-300 placeholder-gray-400
                      dark:bg-darkbg-700 dark:text-gray-200 dark:border-darkbg-600
                      focus:outline-none focus:ring-1 focus:ring-blue-500
                    "
                  />
                </div>
              </>
            )}

            {/* Service Interests */}
            {/* Dynamic Services Section */}
              {renderServicesSection()}
            {/* Notes */}
            <div className="mt-4">
              <label className="block mb-1 text-gray-700 dark:text-gray-300">Notes</label>
              <textarea
                name="notes"
                rows="3"
                placeholder="Add any notes..."
                className="
                  w-full p-2 border rounded-md 
                  bg-white text-gray-900 border-gray-300 placeholder-gray-400
                  dark:bg-darkbg-700 dark:text-gray-200 dark:border-darkbg-600
                  focus:outline-none focus:ring-1 focus:ring-blue-500
                "
                onChange={(e) => {
            const noteContent = e.target.value;
            setFormData((prev) => ({
              ...prev,
              notes: noteContent
                ? [{ date: new Date().toISOString(), content: noteContent }]
                : []
            }));
                }}
              ></textarea>
            </div>

            {/* Submit Buttons */}
            <div className="flex justify-end space-x-2 mt-4">
              <button
                type="button"
                onClick={onClose}
                className="
                  border p-2 rounded-md 
                  bg-white text-gray-900 border-gray-300
                  dark:bg-darkbg-700 dark:text-gray-200 dark:border-darkbg-600
                  hover:bg-gray-100 dark:hover:bg-darkbg-600
                  focus:outline-none focus:ring-1 focus:ring-blue-500
                "
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isCreatingClient}
                className="
                  bg-blue-600 text-white p-2 rounded-md hover:bg-blue-700
                  focus:outline-none focus:ring-2 focus:ring-blue-500 
                  focus:ring-offset-2 focus:ring-offset-white dark:focus:ring-offset-darkbg-800
                "
              >
                {isCreatingClient ? 'Adding Client...' : 'Add Client'}
                </button>
            </div>
          </form>
        </div>
  );
};

export default AddClientForm;