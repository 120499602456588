import React, { useState, useEffect } from "react";
import Modal from "./Modal";
import {
  MessageSquare,
  ChevronDown,
  ChevronUp,
  BookOpen,
  Edit,
  Save,
  Plus,
  Trash2,
} from "lucide-react";

const EngagementNotesModal = ({ isOpen, onClose, engagement, onSave }) => {
  const [expandedTopics, setExpandedTopics] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  // Initialize with default empty structure
  const getInitialNotes = () => {
    if (engagement?.structured_notes) {
      try {
        // Parse the structured notes from the engagement
        return JSON.parse(engagement.structured_notes);
      } catch (e) {
        console.error("Failed to parse structured notes:", e);
        // Return fallback structure
        return {
          summary: engagement.human_notes || "No structured notes available.",
          topics: [
            {
              id: 1,
              title: "Notes",
              notes: ["Unable to parse structured notes"],
            },
          ],
        };
      }
    } else if (engagement?.human_notes) {
      // If we only have human_notes (legacy format), create simple structure
      return {
        summary: engagement.human_notes,
        topics: [
          {
            id: 1,
            title: "Meeting Notes",
            notes: ["See summary for details"],
          },
        ],
      };
    } else {
      // No notes available
      return {
        summary: "No notes available for this engagement.",
        topics: [
          {
            id: 1,
            title: "No Content",
            notes: ["This engagement has no notes"],
          },
        ],
      };
    }
  };

  // State for editable notes
  const [editableNotes, setEditableNotes] = useState(() => getInitialNotes());

  // Reset notes when engagement changes
  useEffect(() => {
    setEditableNotes(getInitialNotes());
    // Auto-expand all topics when viewing
    const allTopics = {};
    getInitialNotes().topics.forEach((topic) => {
      allTopics[topic.id] = true;
    });
    setExpandedTopics(allTopics);
  }, [engagement]);

  const toggleTopic = (topicId) => {
    setExpandedTopics((prev) => ({
      ...prev,
      [topicId]: !prev[topicId],
    }));
  };

  // Toggle between view mode and edit mode
  const toggleEditMode = () => {
    if (!isEditMode) {
      // Enter edit mode
      setIsEditMode(true);
      // Expand all topics when entering edit mode for better visibility
      const allExpanded = {};
      editableNotes.topics.forEach((topic) => {
        allExpanded[topic.id] = true;
      });
      setExpandedTopics(allExpanded);
    }
  };

  // Handle cancel - discard changes and exit edit mode
  const handleCancel = () => {
    if (window.confirm("Discard all changes?")) {
      // Revert changes
      setEditableNotes(getInitialNotes());
      setIsEditMode(false);
    }
  };

  // Update summary text
  const handleSummaryChange = (e) => {
    setEditableNotes({
      ...editableNotes,
      summary: e.target.value,
    });
  };

  // Update topic title
  const handleTopicTitleChange = (topicId, newTitle) => {
    setEditableNotes({
      ...editableNotes,
      topics: editableNotes.topics.map((topic) =>
        topic.id === topicId ? { ...topic, title: newTitle } : topic
      ),
    });
  };

  // Update note item text
  const handleNoteChange = (topicId, noteIndex, newText) => {
    setEditableNotes({
      ...editableNotes,
      topics: editableNotes.topics.map((topic) => {
        if (topic.id === topicId) {
          const updatedNotes = [...topic.notes];
          updatedNotes[noteIndex] = newText;
          return { ...topic, notes: updatedNotes };
        }
        return topic;
      }),
    });
  };

  // Add a new note to a topic
  const addNoteToTopic = (topicId) => {
    setEditableNotes({
      ...editableNotes,
      topics: editableNotes.topics.map((topic) => {
        if (topic.id === topicId) {
          return {
            ...topic,
            notes: [...topic.notes, "New note - click to edit"],
          };
        }
        return topic;
      }),
    });
  };

  // Remove a note from a topic
  const removeNoteFromTopic = (topicId, noteIndex) => {
    setEditableNotes({
      ...editableNotes,
      topics: editableNotes.topics.map((topic) => {
        if (topic.id === topicId) {
          const updatedNotes = topic.notes.filter(
            (_, idx) => idx !== noteIndex
          );
          return { ...topic, notes: updatedNotes };
        }
        return topic;
      }),
    });
  };

  // Add a new topic
  const addNewTopic = () => {
    const newId = Math.max(0, ...editableNotes.topics.map((t) => t.id)) + 1;
    const newTopic = {
      id: newId,
      title: "New Topic",
      notes: ["Add notes here"],
    };
    setEditableNotes({
      ...editableNotes,
      topics: [...editableNotes.topics, newTopic],
    });
    // Expand the newly added topic
    setExpandedTopics({
      ...expandedTopics,
      [newId]: true,
    });
  };

  // Remove a topic
  const removeTopic = (topicId) => {
    if (window.confirm("Are you sure you want to remove this topic?")) {
      setEditableNotes({
        ...editableNotes,
        topics: editableNotes.topics.filter((topic) => topic.id !== topicId),
      });
    }
  };

  // Handle save with actual API call
  const handleSave = async () => {
    setIsSaving(true);
    try {
      // If onSave prop exists, call it with the updated notes
      if (onSave && typeof onSave === "function") {
        await onSave({
          engagementId: engagement.id,
          structuredNotes: editableNotes,
        });
      } else {
        // If no onSave prop, just log the notes for debugging
        console.log("Would save notes:", editableNotes);
        console.warn("No onSave handler provided to EngagementNotesModal");
      }

      // Update UI state
      setIsEditMode(false);
    } catch (error) {
      console.error("Error saving notes:", error);
      alert("Failed to save notes. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="p-6 max-w-3xl mx-auto w-full">
        {/* Header with Edit/Save/Cancel Buttons */}
        <div className="mb-6 flex justify-between items-center">
          <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 flex items-center">
            <BookOpen className="w-5 h-5 mr-2" />
            Meeting Notes
            {engagement && (
              <span className="ml-2 text-sm font-normal text-gray-500 dark:text-gray-400">
                ({new Date(engagement.date).toLocaleDateString()})
              </span>
            )}
          </h2>

          <div className="flex items-center space-x-2">
            {/* Cancel Button (Edit Mode Only) */}
            {isEditMode && (
              <button
                onClick={handleCancel}
                disabled={isSaving}
                className="px-4 py-2 rounded-md flex items-center space-x-2 bg-gray-100 hover:bg-gray-200 text-gray-700 dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-gray-300 transition-colors"
              >
                <span>Cancel</span>
              </button>
            )}

            {/* Edit/Save Button */}
            <button
              onClick={isEditMode ? handleSave : toggleEditMode}
              disabled={isSaving}
              className={`px-4 py-2 rounded-md flex items-center space-x-2 ${
                isEditMode
                  ? "bg-green-600 hover:bg-green-700 text-white"
                  : "bg-blue-100 hover:bg-blue-200 text-blue-800 dark:bg-blue-900/40 dark:hover:bg-blue-800/60 dark:text-blue-300"
              }`}
            >
              {isEditMode ? (
                isSaving ? (
                  <>
                    <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin mr-2"></div>
                    <span>Saving...</span>
                  </>
                ) : (
                  <>
                    <Save className="w-4 h-4" />
                    <span>Save Notes</span>
                  </>
                )
              ) : (
                <>
                  <Edit className="w-4 h-4" />
                  <span>Edit Notes</span>
                </>
              )}
            </button>
          </div>
        </div>

        {/* Summary Section */}
        <div className="mb-8">
          <h3 className="flex items-center text-lg font-medium text-gray-900 dark:text-gray-100 mb-3">
            <MessageSquare className="w-5 h-5 mr-2 text-blue-500" />
            Summary
          </h3>
          <div
            className={`${
              isEditMode
                ? "bg-white dark:bg-darkbg-800"
                : "bg-blue-50 dark:bg-blue-900/30"
            } rounded-lg border ${
              isEditMode
                ? "border-gray-300 dark:border-gray-600"
                : "border-blue-200 dark:border-blue-800"
            }`}
          >
            {isEditMode ? (
              <textarea
                value={editableNotes.summary}
                onChange={handleSummaryChange}
                rows={5}
                className="w-full p-4 bg-transparent border-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-600 rounded-lg text-gray-800 dark:text-gray-200 resize-none"
                placeholder="Enter summary notes here..."
              />
            ) : (
              <p className="p-4 text-gray-800 dark:text-gray-200 leading-relaxed whitespace-pre-wrap">
                {editableNotes.summary}
              </p>
            )}
          </div>
        </div>

        {/* Topics Section */}
        <div>
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">
              Key Topics
            </h3>
            {isEditMode && (
              <button
                onClick={addNewTopic}
                className="flex items-center space-x-1 text-sm px-3 py-1 bg-gray-100 hover:bg-gray-200 dark:bg-gray-800 dark:hover:bg-gray-700 text-gray-700 dark:text-gray-300 rounded-md transition-colors"
              >
                <Plus className="w-4 h-4" />
                <span>Add Topic</span>
              </button>
            )}
          </div>

          <div className="space-y-3">
            {editableNotes.topics.map((topic) => (
              <div
                key={topic.id}
                className="border border-gray-200 dark:border-gray-700 rounded-lg overflow-hidden"
              >
                {/* Topic Header - Clickable */}
                <div
                  className={`w-full flex justify-between items-center p-4 text-left ${
                    isEditMode
                      ? "bg-gray-50 dark:bg-darkbg-900"
                      : "bg-white dark:bg-darkbg-800 hover:bg-gray-50 dark:hover:bg-darkbg-700"
                  } transition-colors`}
                >
                  {isEditMode ? (
                    <input
                      type="text"
                      value={topic.title}
                      onChange={(e) =>
                        handleTopicTitleChange(topic.id, e.target.value)
                      }
                      className="flex-grow font-medium py-1 px-2 border border-gray-300 dark:border-gray-600 rounded bg-white dark:bg-darkbg-700 text-gray-900 dark:text-gray-100 focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-600"
                    />
                  ) : (
                    <span className="font-medium text-gray-900 dark:text-gray-100">
                      {topic.title}
                    </span>
                  )}

                  <div className="flex items-center space-x-2">
                    {isEditMode && (
                      <button
                        onClick={() => removeTopic(topic.id)}
                        className="p-1 text-red-500 hover:text-red-700 dark:hover:text-red-400 rounded-md"
                        title="Remove Topic"
                      >
                        <Trash2 className="w-4 h-4" />
                      </button>
                    )}
                    <button
                      onClick={() => toggleTopic(topic.id)}
                      className="p-1 text-gray-500 hover:text-gray-700 dark:hover:text-gray-300 rounded-md"
                    >
                      {expandedTopics[topic.id] ? (
                        <ChevronUp className="w-5 h-5" />
                      ) : (
                        <ChevronDown className="w-5 h-5" />
                      )}
                    </button>
                  </div>
                </div>

                {/* Topic Content - Expandable */}
                {expandedTopics[topic.id] && (
                  <div className="p-4 bg-gray-50 dark:bg-darkbg-900 border-t border-gray-200 dark:border-gray-700">
                    <ul className="space-y-3">
                      {topic.notes.map((note, idx) => (
                        <li key={idx} className="flex items-start group">
                          <span className="inline-block w-2 h-2 mt-1.5 mr-2 bg-blue-500 rounded-full flex-shrink-0"></span>

                          {isEditMode ? (
                            <div className="flex-grow flex items-center space-x-2">
                              <textarea
                                value={note}
                                onChange={(e) =>
                                  handleNoteChange(
                                    topic.id,
                                    idx,
                                    e.target.value
                                  )
                                }
                                rows={2}
                                className="flex-grow py-1 px-2 border border-gray-300 dark:border-gray-600 rounded bg-white dark:bg-darkbg-700 text-gray-700 dark:text-gray-200 focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-600 min-h-[60px]"
                              />
                              <button
                                onClick={() =>
                                  removeNoteFromTopic(topic.id, idx)
                                }
                                className="p-1 text-red-500 hover:text-red-700 dark:hover:text-red-400 rounded-md opacity-0 group-hover:opacity-100 transition-opacity"
                                title="Remove Note"
                              >
                                <Trash2 className="w-4 h-4" />
                              </button>
                            </div>
                          ) : (
                            <span className="text-gray-700 dark:text-gray-200 whitespace-pre-wrap">
                              {note}
                            </span>
                          )}
                        </li>
                      ))}

                      {/* Add Note Button (Edit Mode Only) */}
                      {isEditMode && (
                        <li>
                          <button
                            onClick={() => addNoteToTopic(topic.id)}
                            className="ml-4 flex items-center space-x-1 text-sm px-2 py-1 bg-gray-100 hover:bg-gray-200 dark:bg-gray-800 dark:hover:bg-gray-700 text-gray-700 dark:text-gray-300 rounded transition-colors"
                          >
                            <Plus className="w-3 h-3" />
                            <span>Add Note</span>
                          </button>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Footer - Just a divider line */}
        <div className="mt-8 pt-4 border-t border-gray-200 dark:border-gray-700">
          {/* Footer content could go here if needed in the future */}
        </div>
      </div>
    </Modal>
  );
};

export default EngagementNotesModal;
