import React, { useState } from "react";
import { toast } from "react-toastify";

import { motion } from "framer-motion";

import {
  Edit2,
  Plus,
  AlertCircle,
  CheckCircle,
  Upload,
  FileText,
  BookOpen,
  Trash2,
} from "lucide-react";

import TranscriptsMenu from "./TransciptMenu";

import EngagementNotesModal from "./EngagementNotesModal";

const EngagementSection = ({
  client,

  onAddEngagementClick,

  onEdit,

  onUploadTranscript,

  openTranscript,

  formatDate,

  getStatusColor,
  updateEngagement,
  onDelete, // Add this prop for handling delete
}) => {
  const [selectedEngagement, setSelectedEngagement] = useState(null);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [engagementToDelete, setEngagementToDelete] = useState(null);

  if (!client) return null;

  // We'll assume your timeline data is in client.engagement_tracking

  const engagements = client.engagement_tracking || [];

  const handleViewNotes = (engagement) => {
    setSelectedEngagement(engagement);

    setShowNotesModal(true);
  };

  // Add this function to handle saving notes
  const handleSaveNotes = async ({ engagementId, structuredNotes }) => {
    try {
      if (updateEngagement) {
        await updateEngagement({
          clientId: client._id,
          engagementId: engagementId,
          engagementData: {
            structured_notes: JSON.stringify(structuredNotes),
          },
        });
        toast.success("Notes saved successfully");
      } else {
        console.warn("No updateEngagement function provided");
        toast.warning("Could not save notes - function not available");
      }
    } catch (error) {
      console.error("Error saving notes:", error);
      toast.error("Failed to save notes");
    }
  };
  
  // Handle delete click
  const handleDeleteClick = (engagement) => {
    setEngagementToDelete(engagement);
  };

  return (
    <div className="space-y-6">
      <h3 className="text-xl font-semibold text-grayteck-900 dark:text-grayteck-100">
        Engagement Timeline
      </h3>

      <div className="relative">
        {/* The vertical timeline line */}

        <div className="absolute left-6 top-0 bottom-0 w-0.5 bg-grayteck-200 dark:bg-grayteck-700" />

        {engagements

          .sort((a, b) => new Date(b.date) - new Date(a.date))

          .map((engagement, index) => {
            // Use engagement.id if it exists, otherwise fall back to map index

            const eId = engagement.id || index;

            // Provide a fallback if type is missing

            const eType = engagement.type || "unknown";

            return (
              <motion.div
                key={eId}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
                className="relative pl-16 pb-8 last:pb-0"
                style={{ zIndex: 50 - index }} // Higher index cards have lower z-index
              >
                {/* The timeline dot */}

                <div
                  className="absolute left-4 top-3 w-4 h-4 rounded-full bg-grayteck-600 dark:bg-grayteck-400 

                             transform -translate-x-1/2 border-4 border-white dark:border-darkbg-800"
                />

                {/* VIEW MODE ONLY */}

                <div className="bg-white dark:bg-darkbg-800 rounded-lg p-6 shadow-md">
                  {/* Top row: Type badge, optional "Service" tag, date, edit icon */}

                  <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center space-x-3">
                      <span
                        className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusColor(
                          engagement.type
                        )}`}
                      >
                        {eType.charAt(0).toUpperCase() + eType.slice(1)}
                      </span>

                      {/* If there's an engagement.service code, show it */}

                      {engagement.service && (
                        <span className="text-sm text-grayteck-600 dark:text-grayteck-400">
                          Service:{" "}
                          {engagement.service

                            .split("-")

                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )

                            .join(" ")}
                        </span>
                      )}
                    </div>

                    <div className="flex items-center space-x-3">
                      <span className="text-sm text-grayteck-500 dark:text-grayteck-400">
                        {formatDate(engagement.date)}
                      </span>

                      {/* Action buttons group */}

                      <div className="flex items-center space-x-2">
                        {/* View Notes button */}

                        {/* View Notes button - Only show if notes are available */}
                        {(engagement.structured_notes ||
                          engagement.human_notes) && (
                          <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={() => handleViewNotes(engagement)}
                            className="p-2 text-grayteck-600 hover:text-grayteck-900
                                      dark:text-grayteck-400 dark:hover:text-grayteck-200
                                      transition-colors rounded-md"
                            title="View notes"
                          >
                            <BookOpen className="w-5 h-5" />
                          </motion.button>
                        )}
                        {/* Edit button */}

                        <motion.button
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          onClick={() => onEdit(engagement)}
                          className="p-2 text-grayteck-600 hover:text-grayteck-900 

                                     dark:text-grayteck-400 dark:hover:text-grayteck-200 

                                     transition-colors rounded-md"
                          title="Edit engagement"
                        >
                          <Edit2 className="w-5 h-5" />
                        </motion.button>

                        {/* Upload Transcript button */}

                        <motion.button
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          onClick={() => onUploadTranscript(engagement)}
                          className="p-2 text-grayteck-600 hover:text-grayteck-900 

                                     dark:text-grayteck-400 dark:hover:text-grayteck-200 

                                     transition-colors rounded-md"
                          title="Upload transcript"
                        >
                          <Upload className="w-5 h-5" />
                        </motion.button>

                        {/* Delete button */}
                        <motion.button
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          onClick={() => handleDeleteClick(engagement)}
                          className="p-2 text-red-500 hover:text-red-700
                                dark:text-red-400 dark:hover:text-red-300
                                transition-colors rounded-md"
                          title="Delete engagement"
                        >
                          <Trash2 className="w-5 h-5" />
                        </motion.button>

                        {/* Transcripts Menu */}

                        <div style={{ position: "relative", zIndex: 100 }}>
                          {engagement.transcripts?.length > 0 && (
                            <TranscriptsMenu
                              transcripts={engagement.transcripts}
                              openTranscript={(transcript) =>
                                openTranscript?.(engagement, transcript)
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Description */}

                  <p className="text-grayteck-900 dark:text-grayteck-100 mb-4">
                    {engagement.description}
                  </p>

                  {/* Outcome and Next Steps */}

                  <div className="space-y-2">
                    {engagement.outcome && (
                      <div className="flex items-start space-x-2">
                        <CheckCircle className="w-5 h-5 text-green-500 mt-0.5" />

                        <span className="text-sm text-grayteck-600 dark:text-grayteck-400">
                          Outcome: {engagement.outcome}
                        </span>
                      </div>
                    )}

                    {engagement.next_steps && (
                      <div className="flex items-start space-x-2">
                        <AlertCircle className="w-5 h-5 text-blue-500 mt-0.5" />

                        <span className="text-sm text-grayteck-600 dark:text-grayteck-400">
                          Next Steps: {engagement.next_steps.step}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </motion.div>
            );
          })}
      </div>

      {/* "Add Engagement" Button at bottom */}

      <div className="flex justify-center mt-8 relative">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => onAddEngagementClick && onAddEngagementClick()}
          className="px-6 py-3 bg-grayteck-600 text-white rounded-md hover:bg-grayteck-700 

                     flex items-center space-x-2 transition-colors duration-200 shadow-md"
        >
          <Plus className="w-5 h-5" />

          <span>Add Engagement</span>
        </motion.button>
      </div>

      {/* Notes Modal */}

      {showNotesModal && selectedEngagement && (
        <EngagementNotesModal
          isOpen={showNotesModal}
          onClose={() => setShowNotesModal(false)}
          engagement={selectedEngagement}
          onSave={handleSaveNotes}
        />
      )}

      {/* Delete Confirmation Modal */}
      {engagementToDelete && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-darkbg-800 p-6 rounded-lg shadow-lg max-w-md w-full">
            <h3 className="text-lg font-semibold mb-4 text-grayteck-900 dark:text-grayteck-100">Confirm Deletion</h3>
            <p className="mb-6 text-grayteck-700 dark:text-grayteck-300">Are you sure you want to delete this engagement? This action cannot be undone.</p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setEngagementToDelete(null)}
                className="px-4 py-2 text-grayteck-600 hover:text-grayteck-900 dark:text-grayteck-400 dark:hover:text-grayteck-200 border border-grayteck-300 dark:border-grayteck-600 rounded"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  onDelete?.(engagementToDelete);
                  setEngagementToDelete(null);
                }}
                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EngagementSection;
