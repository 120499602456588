import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Building2, User2, Mail, Phone, Globe, MapPin, Clock,
  CalendarCheck, MessageSquare, FileCheck, CheckCircle,
  XCircle, Trash2, Edit, MoreHorizontal, Briefcase, 
  Plus, Eye, ChevronLeft, ChevronRight,
} from 'lucide-react';
import EditClientForm from './EditClientForm';

/**
 * OverViewSection - Renders the "Overview" tab portion of the Client Detail page.
 *
 * Props you must pass in:
 * --------------------------------------------------------
 * @param {object}   client - The full client object (from backend).
 * @param {boolean}  isActionsOpen - Whether the "actions" dropdown is open.
 * @param {function} setIsActionsOpen - Setter to open/close the actions dropdown.
 * @param {function} handleDeleteClient - Callback to delete the client (can also handle nav).
 * @param {function} handlePortalAccessToggle - Callback to enable/disable portal.
 * @param {function} getStatusColor - Helper that returns a styling class for a given status.
 * @param {function} calculateDaysSince - Helper to compute day difference.
 * @param {function} getLastStatusChangeDate - Helper to get the last status-change date for the client.
 * @param {function} capitalize - Helper to capitalize a string.
 * @param {function} formatDate - Helper to nicely format a date (string).
 * @param {function} nav - The react-router "useNavigate()" result (if you want to navigate).
 *
 * @param {boolean}  isLoadingDocuments - Whether documents are currently loading.
 * @param {object}   documentsError - Error object from documents query, if any.
 * @param {array}    clientDocuments - Array of the client's documents.
 * @param {number}   documentPage - The current page number for documents pagination.
 * @param {function} setDocumentPage - State setter for the pagination page number.
 * --------------------------------------------------------
 */
const OverViewSection = ({
  client,
  isActionsOpen,
  setIsActionsOpen,
  handleDeleteClient,
  handlePortalAccessToggle,
  getStatusColor,
  calculateDaysSince,
  getLastStatusChangeDate,
  capitalize,
  formatDate,
  nav,
  isLoadingDocuments,
  documentsError,
  clientDocuments,
  documentPage,
  setDocumentPage,
  onUpdateClient,
}) => {
  const [showEditForm, setShowEditForm] = useState(false);
  
  const handleEditClient = (updatedClientData) => {
    if (onUpdateClient) {
      onUpdateClient({
        clientId: client._id,
        clientData: updatedClientData
      });
    }
    setShowEditForm(false);
  };
  
  if (!client) return null;

  // Example of deriving "Active Services" from `client.services` if needed for the "Quick Stats"
  const services = client.services || [];
  const activeServices = services.filter(svc => svc.status === 'active');

  return (
    <div className="space-y-6">
      {/* ---------------------------------------------------------------------- */}
      {/* Client Status Card */}
      {/* ---------------------------------------------------------------------- */}
      <div className="bg-white dark:bg-darkbg-800 rounded-lg p-6 shadow-md">
        <div className="flex items-center justify-between mb-4">
          {/* Left side: icon + name + status */}
          <div className="flex items-center">
            {client.client_type === 'business' ? (
              <Building2 className="h-8 w-8 text-grayteck-600 dark:text-grayteck-400 mr-3" />
            ) : (
              <User2 className="h-8 w-8 text-grayteck-600 dark:text-grayteck-400 mr-3" />
            )}
            <div>
              <h2 className="text-xl font-semibold text-grayteck-900 dark:text-grayteck-100">
                {client.name}
              </h2>
              <span
                className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${getStatusColor(
                  client.status
                )}`}
              >
                {client.status.charAt(0).toUpperCase() + client.status.slice(1)}
              </span>
            </div>
          </div>

          {/* Right side: Actions + Portal Access Indicator */}
          <div className="flex items-center space-x-4">
            {/* Actions Menu */}
            <div className="relative">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setIsActionsOpen(!isActionsOpen)}
                className="p-2 rounded-full hover:bg-grayteck-100 dark:hover:bg-darkbg-700 
                           transition-colors duration-200"
              >
                <MoreHorizontal className="w-5 h-5 text-grayteck-600 dark:text-grayteck-400" />
              </motion.button>

              {/* Dropdown Menu */}
              <AnimatePresence>
                {isActionsOpen && (
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    className="absolute right-0 mt-2 w-48 bg-white dark:bg-darkbg-800 
                               rounded-md shadow-lg z-50 py-1 border border-grayteck-200 
                               dark:border-grayteck-700"
                  >
                    {/* Edit Client button */}
                    <button
                      onClick={() => {
                        setIsActionsOpen(false);
                        setShowEditForm(true);
                      }}
                      className="flex items-center w-full px-4 py-2 text-sm text-grayteck-700 
                                 dark:text-grayteck-300 hover:bg-grayteck-100 
                                 dark:hover:bg-darkbg-700"
                    >
                      <Edit className="w-4 h-4 mr-2" />
                      Edit Client
                    </button>

                    {/* Delete Client */}
                    <button
                      onClick={() => {
                        if (
                          window.confirm(
                            'Are you sure you want to delete this client? This action cannot be undone.'
                          )
                        ) {
                          handleDeleteClient()
                            .then(() => {
                              // If your handleDeleteClient or parent performs navigation
                              nav('/clients');
                            })
                            .catch(error => {
                              console.error('Error deleting client:', error);
                            });
                        }
                      }}
                      className="flex items-center w-full px-4 py-2 text-sm text-red-600 
                                 dark:text-red-400 hover:bg-red-50 dark:hover:bg-red-900/30"
                    >
                      <Trash2 className="w-4 h-4 mr-2" />
                      Delete Client
                    </button>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>

            {/* Client Portal Status */}
            <div className="flex items-center">
              {client.portal_access?.enabled ? (
                <div className="flex items-center text-green-600 dark:text-green-400">
                  <CheckCircle className="w-5 h-5 mr-2" />
                  Portal Access Enabled
                </div>
              ) : (
                <div className="flex items-center text-grayteck-400">
                  <XCircle className="w-5 h-5 mr-2" />
                  No Portal Access
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Contact Information Grid */}
        <div className="grid grid-cols-2 gap-6 mt-6">
          {/* Left: Contact Info */}
          <div className="space-y-4">
            <h3 className="text-lg font-medium text-grayteck-900 dark:text-grayteck-100">
              Contact Information
            </h3>
            <div className="space-y-3">
              {client.contact_info?.primary_contact && (
                <div className="flex items-center text-grayteck-600 dark:text-grayteck-400">
                  <User2 className="w-5 h-5 mr-2" />
                  <span>{client.contact_info.primary_contact}</span>
                  {client.contact_info?.position && (
                    <span className="ml-2 text-grayteck-400">
                      ({client.contact_info.position})
                    </span>
                  )}
                </div>
              )}
              {client.contact_info?.email && (
                <div className="flex items-center text-grayteck-600 dark:text-grayteck-400">
                  <Mail className="w-5 h-5 mr-2" />
                  <span>{client.contact_info.email}</span>
                </div>
              )}
              {client.contact_info?.phone && (
                <div className="flex items-center text-grayteck-600 dark:text-grayteck-400">
                  <Phone className="w-5 h-5 mr-2" />
                  <span>{client.contact_info.phone}</span>
                </div>
              )}
            </div>
          </div>

          {/* Right: Company Info (if business) */}
          {client.client_type === 'business' && (
            <div className="space-y-4">
              <h3 className="text-lg font-medium text-grayteck-900 dark:text-grayteck-100">
                Company Information
              </h3>
              <div className="space-y-3">
                {client.company_info?.website && (
                  <div className="flex items-center text-grayteck-600 dark:text-grayteck-400">
                    <Globe className="w-5 h-5 mr-2" />
                    <a
                      href={`https://${client.company_info.website}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:text-grayteck-900 dark:hover:text-grayteck-200 transition-colors"
                    >
                      {client.company_info.website}
                    </a>
                  </div>
                )}
                {client.company_info?.industry && (
                  <div className="flex items-center text-grayteck-600 dark:text-grayteck-400">
                    <Briefcase className="w-5 h-5 mr-2" />
                    <span>{client.company_info.industry}</span>
                  </div>
                )}
                {client.company_info?.size && (
                  <div className="flex items-center text-grayteck-600 dark:text-grayteck-400">
                    <Building2 className="w-5 h-5 mr-2" />
                    <span>{client.company_info.size} employees</span>
                  </div>
                )}
                {client.company_info?.location && (
                  <div className="flex items-center text-grayteck-600 dark:text-grayteck-400">
                    <MapPin className="w-5 h-5 mr-2" />
                    <span>
                      {[
                        client.company_info.location.city,
                        client.company_info.location.state,
                        client.company_info.location.country,
                      ]
                        .filter(Boolean)
                        .join(', ')}
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* ---------------------------------------------------------------------- */}
      {/* Quick Stats */}
      {/* ---------------------------------------------------------------------- */}
      <div className="grid grid-cols-4 gap-6" />

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        {/* Active Services Stat */}
        <div className="bg-white dark:bg-darkbg-800 rounded-lg p-6 shadow-md">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-grayteck-600 dark:text-grayteck-400">
                Active Services
              </p>
              <p className="mt-1 text-2xl font-semibold text-grayteck-900 dark:text-grayteck-100">
                {activeServices?.length || 0}
              </p>
            </div>
            <div className="p-3 bg-blue-100 dark:bg-blue-900/30 rounded-full">
              <Briefcase className="w-6 h-6 text-blue-600 dark:text-blue-400" />
            </div>
          </div>
        </div>

        {/* Recent Engagements */}
        <div className="bg-white dark:bg-darkbg-800 rounded-lg p-6 shadow-md">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-grayteck-600 dark:text-grayteck-400">
                Recent Engagements
              </p>
              <p className="mt-1 text-2xl font-semibold text-grayteck-900 dark:text-grayteck-100">
                {client.engagement_tracking?.length || 0}
              </p>
            </div>
            <div className="p-3 bg-green-100 dark:bg-green-900/30 rounded-full">
              <MessageSquare className="w-6 h-6 text-green-600 dark:text-green-400" />
            </div>
          </div>
        </div>

        {/* Days as X (dynamic status) */}
        <div className="bg-white dark:bg-darkbg-800 rounded-lg p-6 shadow-md">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-grayteck-600 dark:text-grayteck-400">
                Days as {capitalize(client.status)}
              </p>
              <p className="mt-1 text-2xl font-semibold text-grayteck-900 dark:text-grayteck-100">
                {calculateDaysSince(getLastStatusChangeDate(client))}
              </p>
            </div>
            <div className="p-3 bg-grayteck-100 dark:bg-grayteck-900/30 rounded-full">
              <CalendarCheck
                className="w-6 h-6 text-grayteck-600 dark:text-grayteck-400"
                aria-label={`Days as ${capitalize(client.status)}`}
                title={`Days as ${capitalize(client.status)}`}
                data-tip={`Days as ${capitalize(client.status)}`}
              />
              {/* <ReactTooltip place="top" type="dark" effect="solid" /> */}
            </div>
          </div>
        </div>

        {/* Latest Note */}
        <div className="bg-white dark:bg-darkbg-800 rounded-lg p-6 shadow-md">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-grayteck-600 dark:text-grayteck-400">
                Latest Note
              </p>
              <p className="mt-1 text-sm text-grayteck-500 dark:text-grayteck-400">
                {formatDate(client.notes?.[0]?.date || client.created_at)}
              </p>
            </div>
            <div className="p-3 bg-yellow-100 dark:bg-yellow-900/30 rounded-full">
              <FileCheck className="w-6 h-6 text-yellow-600 dark:text-yellow-400" />
            </div>
          </div>
        </div>
      </div>

      {/* ---------------------------------------------------------------------- */}
      {/* Client Health & Engagement */}
      {/* ---------------------------------------------------------------------- */}
      <div className="grid grid-cols-2 gap-6">
        {/* Client Health Overview */}
        <div className="bg-white dark:bg-darkbg-800 rounded-lg p-6 shadow-md">
          <h3 className="text-lg font-medium text-grayteck-900 dark:text-grayteck-100 mb-4">
            Client Health Overview
          </h3>
          <div className="space-y-4">
            {/* Engagement Rate */}
            <div>
              <div className="flex items-center justify-between mb-2">
                <span className="text-sm text-grayteck-600 dark:text-grayteck-400">
                  Engagement Rate
                </span>
                <span className="text-sm font-medium text-green-600 dark:text-green-400">
                  Good
                </span>
              </div>
              <div className="h-2 bg-grayteck-200 dark:bg-grayteck-700 rounded-full overflow-hidden">
                <div className="h-full bg-green-500 rounded-full" style={{ width: '85%' }} />
              </div>
            </div>

            {/* Service Usage */}
            <div>
              <div className="flex items-center justify-between mb-2">
                <span className="text-sm text-grayteck-600 dark:text-grayteck-400">
                  Service Usage
                </span>
                <span className="text-sm font-medium text-yellow-600 dark:text-yellow-400">
                  Moderate
                </span>
              </div>
              <div className="h-2 bg-grayteck-200 dark:bg-grayteck-700 rounded-full overflow-hidden">
                <div className="h-full bg-yellow-500 rounded-full" style={{ width: '65%' }} />
              </div>
            </div>

            {/* Response Time */}
            <div>
              <div className="flex items-center justify-between mb-2">
                <span className="text-sm text-grayteck-600 dark:text-grayteck-400">
                  Response Time
                </span>
                <span className="text-sm font-medium text-blue-600 dark:text-blue-400">
                  Excellent
                </span>
              </div>
              <div className="h-2 bg-grayteck-200 dark:bg-grayteck-700 rounded-full overflow-hidden">
                <div className="h-full bg-blue-500 rounded-full" style={{ width: '95%' }} />
              </div>
            </div>
          </div>
        </div>

        {/* Recent Activity Timeline (mini) */}
        <div className="bg-white dark:bg-darkbg-800 rounded-lg p-6 shadow-md">
          <h3 className="text-lg font-medium text-grayteck-900 dark:text-grayteck-100 mb-4">
            Recent Activity
          </h3>
          <div className="space-y-4">
            {client.engagement_tracking?.slice(0, 3).map((engagement, index) => (
              <div key={index} className="flex items-start space-x-3">
                <div
                  className={`p-2 rounded-full ${
                    engagement.type === 'meeting'
                      ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400'
                      : 'bg-green-100 dark:bg-green-900/30 text-green-600 dark:text-green-400'
                  }`}
                >
                  {engagement.type === 'meeting' ? (
                    <MessageSquare className="w-4 h-4" />
                  ) : (
                    <CheckCircle className="w-4 h-4" />
                  )}
                </div>
                <div>
                  <p className="text-sm text-grayteck-900 dark:text-grayteck-100">
                    {engagement.description}
                  </p>
                  <p className="text-xs text-grayteck-500 dark:text-grayteck-400">
                    {formatDate(engagement.date)}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* ---------------------------------------------------------------------- */}
      {/* Source Information */}
      {/* ---------------------------------------------------------------------- */}
      {client.source && (
        <div className="bg-white dark:bg-darkbg-800 rounded-lg p-6 shadow-md">
          <h3 className="text-lg font-medium text-grayteck-900 dark:text-grayteck-100 mb-4">
            Client Source Information
          </h3>
          <div className="grid grid-cols-3 gap-6">
            <div>
              <p className="text-sm text-grayteck-600 dark:text-grayteck-300">Source</p>
              <p className="font-medium text-grayteck-900 dark:text-grayteck-100 mt-1">
                {client.source.charAt(0).toUpperCase() + client.source.slice(1)}
              </p>
            </div>
            <div>
              <p className="text-sm text-grayteck-600 dark:text-grayteck-300">Created By</p>
              <p className="font-medium text-grayteck-900 dark:text-grayteck-100 mt-1">
                {client.created_by}
              </p>
            </div>
            <div>
              <p className="text-sm text-grayteck-600 dark:text-grayteck-300">Created Date</p>
              <p className="font-medium text-grayteck-900 dark:text-grayteck-100 mt-1">
                {formatDate(client.created_at)}
              </p>
            </div>
          </div>
        </div>
      )}

      {/* ---------------------------------------------------------------------- */}
      {/* Portal Access Status */}
      {/* ---------------------------------------------------------------------- */}
      <div className="bg-white dark:bg-darkbg-800 rounded-lg p-6 shadow-md">
        <div className="flex items-center justify-between mb-6">
          <h3 className="text-lg font-medium text-grayteck-900 dark:text-grayteck-100">
            Portal Access Details
          </h3>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handlePortalAccessToggle}
            className={`px-4 py-2 rounded-md text-sm font-medium transition-colors
              ${
                client.portal_access?.enabled
                  ? 'bg-red-100 text-red-600 dark:bg-red-900/30 dark:text-red-400 hover:bg-red-200'
                  : 'bg-green-100 text-green-600 dark:bg-green-900/30 dark:text-green-400 hover:bg-green-200'
              }`}
          >
            {client.portal_access?.enabled ? 'Disable Access' : 'Enable Access'}
          </motion.button>
        </div>

        <div className="grid grid-cols-3 gap-6">
          {/* Access Status */}
          <div>
            <p className="text-sm text-grayteck-600 dark:text-grayteck-400">Access Status</p>
            <div className="flex items-center mt-1">
              {client.portal_access?.enabled ? (
                <>
                  <CheckCircle className="w-5 h-5 text-green-500 mr-2" />
                  <span className="font-medium text-green-600 dark:text-green-400">
                    Enabled
                  </span>
                </>
              ) : (
                <>
                  <XCircle className="w-5 h-5 text-red-500 mr-2" />
                  <span className="font-medium text-red-600 dark:text-red-400">Disabled</span>
                </>
              )}
            </div>
          </div>

          {/* Last Access */}
          <div>
            <p className="text-sm text-grayteck-600 dark:text-grayteck-400">Last Access</p>
            <p className="font-medium text-grayteck-900 dark:text-grayteck-100 mt-1">
              {client.portal_access?.last_access
                ? formatDate(client.portal_access.last_access)
                : 'Never accessed'}
            </p>
          </div>

          {/* Access Code Status */}
          <div>
            <p className="text-sm text-grayteck-600 dark:text-grayteck-400">
              Access Code Status
            </p>
            <div className="flex items-center mt-1">
              {client.portal_access?.access_code ? (
                <>
                  <Clock className="w-5 h-5 text-yellow-500 mr-2" />
                  <span className="font-medium text-yellow-600 dark:text-yellow-400">
                    Code Active
                  </span>
                </>
              ) : (
                <>
                  <XCircle className="w-5 h-5 text-grayteck-500 mr-2" />
                  <span className="font-medium text-grayteck-600 dark:text-grayteck-400">
                    No Active Code
                  </span>
                </>
              )}
            </div>
          </div>
        </div>

        {/* If we have a code, show it */}
        {client.portal_access?.access_code && (
          <div className="mt-4 p-4 bg-grayteck-50 dark:bg-darkbg-700 rounded-lg">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-grayteck-600 dark:text-grayteck-400">
                  Current Access Code
                </p>
                <p className="font-mono text-xl font-medium text-grayteck-900 dark:text-grayteck-100 mt-1">
                  {client.portal_access.access_code}
                </p>
              </div>
              <div>
                <p className="text-sm text-grayteck-600 dark:text-grayteck-400">Expires</p>
                <p className="text-sm text-grayteck-900 dark:text-grayteck-100 mt-1">
                  {formatDate(client.portal_access.access_code_expiry)}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* ---------------------------------------------------------------------- */}
      {/* Recent Documents Overview */}
      {/* ---------------------------------------------------------------------- */}
      <div className="bg-white dark:bg-darkbg-800 rounded-lg p-6 shadow-md">
        <div className="flex items-center justify-between mb-6">
          <h3 className="text-lg font-medium text-grayteck-900 dark:text-grayteck-100">
            Recent Documents
          </h3>
          <Link
            to={`/create?clientId=${client._id}`}
            className="px-4 py-2 bg-grayteck-600 text-white rounded-md hover:bg-grayteck-700 
                       flex items-center space-x-2 transition-colors duration-200"
          >
            <Plus className="w-5 h-5" />
            <span>New Document</span>
          </Link>
        </div>

        {/* Documents Grid */}
        <div className="space-y-4">
          {isLoadingDocuments ? (
            <div className="text-center py-4">
              <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-grayteck-600 mx-auto" />
            </div>
          ) : documentsError ? (
            <div className="text-center py-4 text-red-500">
              Error loading documents: {documentsError.message}
            </div>
          ) : clientDocuments?.length === 0 ? (
            <div className="text-center py-4 text-grayteck-500">
              No documents found for this client.
            </div>
          ) : (
            <>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {clientDocuments
                  ?.slice(documentPage * 4, (documentPage + 1) * 4)
                  .map(doc => (
                    <div
                      key={doc._id}
                      className="bg-grayteck-50 dark:bg-darkbg-700 rounded-lg p-4 hover:bg-grayteck-100 
                                 dark:hover:bg-darkbg-600 transition-colors duration-200"
                    >
                      <div className="flex items-center justify-between mb-2">
                        <div className="flex items-center space-x-3">
                          <FileCheck className="w-5 h-5 text-grayteck-600 dark:text-grayteck-400" />
                          <span className="font-medium text-grayteck-900 dark:text-grayteck-100">
                            {doc.title}
                          </span>
                        </div>
                        <span
                          className={`px-2 py-1 text-xs rounded-full ${
                            doc.signed_by
                              ? 'bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-300'
                              : doc.shareable_link
                              ? 'bg-blue-100 text-blue-800 dark:bg-blue-900/30 dark:text-blue-300'
                              : 'bg-grayteck-200 text-grayteck-800 dark:bg-grayteck-900/30 dark:text-grayteck-300'
                          }`}
                        >
                          {doc.signed_by
                            ? 'Signed'
                            : doc.shareable_link
                            ? 'Shared'
                            : 'Draft'}
                        </span>
                      </div>
                      <p className="text-sm text-grayteck-600 dark:text-grayteck-400 mb-3">
                        {formatDate(doc.created_at)}
                      </p>
                      <div className="flex justify-end space-x-2">
                        <Link
                          to={doc.signed_by ? `/view/${doc._id}` : `/edit/${doc._id}`}
                          className="p-2 text-grayteck-600 hover:text-grayteck-900 dark:text-grayteck-400 
                                     dark:hover:text-grayteck-200"
                        >
                          {doc.signed_by ? (
                            <Eye className="w-5 h-5" />
                          ) : (
                            <Edit className="w-5 h-5" />
                          )}
                        </Link>
                      </div>
                    </div>
                  ))}
              </div>

              {/* Pagination Controls */}
              {clientDocuments?.length > 4 && (
                <div className="flex items-center justify-between mt-6 border-t pt-4 border-grayteck-200 dark:border-grayteck-700">
                  <button
                    onClick={() => setDocumentPage(prev => Math.max(0, prev - 1))}
                    disabled={documentPage === 0}
                    className={`flex items-center px-3 py-1 rounded-md text-sm ${
                      documentPage === 0
                        ? 'text-grayteck-400 cursor-not-allowed'
                        : 'text-grayteck-600 hover:text-grayteck-900 dark:text-grayteck-400 dark:hover:text-grayteck-200'
                    }`}
                  >
                    <ChevronLeft className="w-4 h-4 mr-1" />
                    Previous
                  </button>

                  <span className="text-sm text-grayteck-600 dark:text-grayteck-400">
                    Page {documentPage + 1} of {Math.ceil(clientDocuments.length / 4)}
                  </span>

                  <button
                    onClick={() =>
                      setDocumentPage(prev =>
                        Math.min(Math.ceil(clientDocuments.length / 4) - 1, prev + 1)
                      )
                    }
                    disabled={
                      documentPage >= Math.ceil(clientDocuments.length / 4) - 1
                    }
                    className={`flex items-center px-3 py-1 rounded-md text-sm ${
                      documentPage >= Math.ceil(clientDocuments.length / 4) - 1
                        ? 'text-grayteck-400 cursor-not-allowed'
                        : 'text-grayteck-600 hover:text-grayteck-900 dark:text-grayteck-400 dark:hover:text-grayteck-200'
                    }`}
                  >
                    Next
                    <ChevronRight className="w-4 h-4 ml-1" />
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      
      {/* Edit Client Modal */}
      {showEditForm && (
        <EditClientForm 
          client={client}
          onClose={() => setShowEditForm(false)}
          onSubmit={handleEditClient}
        />
      )}
    </div>
  );
};

export default OverViewSection;
