// src/components/PaginatedInvoiceTable.jsx

import React from 'react';
import { motion } from 'framer-motion';
import { ChevronLeft, ChevronRight, Receipt, ExternalLink, Download, Eye } from 'lucide-react'; // Added ExternalLink and Download icons
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const PaginatedInvoiceTable = ({
  invoices = [],
  isLoading,
  isPublishing = false,
  isUnpublishing = false,
  publishingInvoiceId = null,
  unpublishingInvoiceId = null,
  pagination,
  onPageChange,
  onViewDetails,
  onUnpublish,
  onSendInvoice,
  onOpenExternalLink, // New prop for opening external link (client portal)
  onDownloadInvoice,  // New prop for downloading invoice (client portal)
  getInvoiceStatusColor,
  context = 'admin' // New prop to determine the context ('admin' or 'client')
}) => {
  const MIN_ITEMS_FOR_PAGINATION = 20;

  const renderPaginationControls = () => (
    <div className="flex items-center justify-between space-x-4 mt-6 pb-4">
      <button
        onClick={() => onPageChange(pagination.currentPage - 1)}
        disabled={pagination.currentPage === 0}
        className={`flex items-center space-x-2 px-4 py-2 text-sm font-medium rounded-md shadow-sm
          ${pagination.currentPage === 0 
            ? 'bg-gray-100 text-gray-400 cursor-not-allowed dark:bg-darkbg-800'
            : 'bg-white dark:bg-darkbg-800 text-grayteck-600 dark:text-grayteck-300 hover:bg-gray-50 dark:hover:bg-darkbg-700'}`}
      >
        <ChevronLeft className="w-4 h-4" /> Previous
      </button>
      
      <span className="text-sm text-grayteck-600 dark:text-grayteck-400">
        Page {pagination.currentPage + 1} of {pagination.totalPages} ({pagination.totalItems} total invoices)
      </span>
      
      <button
        onClick={() => onPageChange(pagination.currentPage + 1)}
        disabled={!pagination.hasMore}
        className={`flex items-center space-x-2 px-4 py-2 text-sm font-medium rounded-md shadow-sm
          ${!pagination.hasMore
            ? 'bg-gray-100 text-gray-400 cursor-not-allowed dark:bg-darkbg-800'
            : 'bg-white dark:bg-darkbg-800 text-grayteck-600 dark:text-grayteck-300 hover:bg-gray-50 dark:hover:bg-darkbg-700'}`}
      >
        Next <ChevronRight className="w-4 h-4" />
      </button>
    </div>
  );

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-2 border-grayteck-600 border-t-transparent" />
      </div>
    );
  }

  if (!invoices.length) {
    return (
      <div className="text-center py-12">
        <Receipt className="mx-auto h-12 w-12 text-grayteck-400" />
        <h3 className="mt-2 text-sm font-medium text-grayteck-900 dark:text-grayteck-100">No invoices</h3>
        <p className="mt-1 text-sm text-grayteck-500 dark:text-grayteck-400">
          Get started by creating a new invoice
        </p>
      </div>
    );
  }

  return (
    <>
      <div className="bg-white dark:bg-darkbg-800 rounded-lg shadow-md overflow-hidden">
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-grayteck-200 dark:divide-grayteck-700">
          <thead className="bg-grayteck-50 dark:bg-darkbg-700">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-grayteck-500 dark:text-grayteck-400 uppercase tracking-wider">
                  Invoice
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-grayteck-500 dark:text-grayteck-400 uppercase tracking-wider">
                Issued
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-grayteck-500 dark:text-grayteck-400 uppercase tracking-wider">
                Due
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-grayteck-500 dark:text-grayteck-400 uppercase tracking-wider">
                Amount
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-grayteck-500 dark:text-grayteck-400 uppercase tracking-wider">
                Service
              </th>
              <th className="px-6 py-3 text-right text-xs font-medium text-grayteck-500 dark:text-grayteck-400 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-darkbg-800 divide-y divide-grayteck-200 dark:divide-grayteck-700">
            {invoices.map((invoice) => (
              <motion.tr
                key={invoice._id}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div
                      className={`inline-block w-2.5 h-2.5 rounded-full mr-2 ${
                        invoice.status === 'draft' ? 'bg-gray-400 dark:bg-gray-500' :
                        invoice.status === 'pending' ? 'bg-yellow-400 dark:bg-yellow-500' :
                        invoice.status === 'opened' ? context === 'admin' ? 'bg-blue-400 dark:bg-blue-500' : 'bg-yellow-400 dark:bg-yellow-500' :
                        invoice.status === 'paid' ? 'bg-green-400 dark:bg-green-500' :
                        invoice.status === 'overdue' ? 'bg-red-400 dark:bg-red-500' :
                        'bg-gray-400 dark:bg-gray-500'
                      }`}
                      data-tooltip-id={`invoice-${invoice._id}`}
                      data-tooltip-content={new Date(invoice.created_at).toLocaleString()}
                    />
                    <span className="text-grayteck-800 dark:text-grayteck-300">{invoice.invoice_number}</span>
                    <Tooltip
                      id={`invoice-${invoice._id}`}
                      className="bg-gray-800 text-white p-2 rounded shadow-md"
                    />
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-grayteck-500 dark:text-grayteck-400">
                  {invoice.issued_at ? new Date(invoice.issued_at).toLocaleDateString() : 'N/A'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-grayteck-500 dark:text-grayteck-400">
                    {invoice.due_date ? new Date(invoice.due_date).toLocaleDateString() : <span className="italic">Due on receipt</span>}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-grayteck-500 dark:text-grayteck-400">
                  ${invoice.total.toFixed(2)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-grayteck-500 dark:text-grayteck-400">
                  {invoice.service_code || 'N/A'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <div className="flex justify-end items-center space-x-4">
                      {/* Admin Dashboard Actions */}
                      {context === 'admin' && (
                        <>
                          {/* Unpublish Button */}
                            {(invoice.status === 'pending' || invoice.status === 'opened') && (
                            <button
                                onClick={() => onUnpublish(invoice)}
                                disabled={isUnpublishing && unpublishingInvoiceId === invoice._id}
                                className="text-red-600 hover:text-red-700 dark:text-red-400 
                                        dark:hover:text-red-300 inline-flex items-center space-x-1"
                            >
                                {isUnpublishing && unpublishingInvoiceId === invoice._id ? (
                                  <span className="flex items-center">
                                    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-red-600 dark:text-red-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    Unpublishing...
                                  </span>
                                ) : (
                                  <span>Unpublish</span>
                                )}
                            </button>
                            )}
                          {/* Send Button */}
                          {invoice.status === 'draft' && (
                      <button
                        onClick={() => onSendInvoice(invoice)}
                        disabled={isPublishing && publishingInvoiceId === invoice._id}
                        className="text-blue-600 hover:text-blue-700 dark:text-blue-400 
                                 dark:hover:text-blue-300 inline-flex items-center space-x-1"
                      >
                        {isPublishing && publishingInvoiceId === invoice._id ? (
                          <span className="flex items-center">
                            <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-blue-600 dark:text-blue-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            Processing...
                          </span>
                        ) : (
                          <span>Send</span>
                        )}
                      </button>
                    )}
                        </>
                      )}

                      {/* Client Portal Actions */}
                      {context === 'client' && (
                        <>
                          {/* Open External Link Button */}
                          {invoice.shareable_link && (
                            <button
                              onClick={() => onOpenExternalLink(invoice.document_id)}
                              className="text-grayteck-600 hover:text-grayteck-900 dark:text-grayteck-400 
                                       dark:hover:text-grayteck-200 inline-flex items-center"
                              data-tooltip-id={`open-link-tooltip-${invoice._id}`}
                              data-tooltip-content="Open External Link"
                            >
                              <ExternalLink className="w-5 h-5" />
                              <Tooltip
                                id={`open-link-tooltip-${invoice._id}`}
                                place="top"
                                effect="solid"
                              />
                            </button>
                          )}
                          {/* Download Invoice Button */}
                          <button
                            onClick={() => onDownloadInvoice(invoice.document_id)}
                            className="text-grayteck-600 hover:text-grayteck-900 dark:text-grayteck-400 
                                     dark:hover:text-grayteck-200 inline-flex items-center"
                            data-tooltip-id={`download-tooltip-${invoice._id}`}
                            data-tooltip-content="Download Invoice"
                          >
                            <Download className="w-5 h-5" />
                            <Tooltip
                              id={`download-tooltip-${invoice._id}`}
                              place="top"
                              effect="solid"
                            />
                          </button>
                        </>
                      )}

                      {/* Common Action: View Details */}
                    <button
                        onClick={() => context === 'client' 
                            ? onOpenExternalLink(invoice.document_id)
                            : onViewDetails(invoice)
                        }
                      className="text-grayteck-600 hover:text-grayteck-900 dark:text-grayteck-400 
                                 dark:hover:text-grayteck-200 inline-flex items-center"
                        data-tooltip-id={`view-details-tooltip-${invoice._id}`}
                        data-tooltip-content={context === 'client' ? 'View Invoice' : 'View Details'}                      >
                        {context === 'client' ? (
                        <ExternalLink className="w-5 h-5" />
                        ) : (
                        <Eye className="w-5 h-5" />
                        )}
                        <Tooltip
                          id={`view-details-tooltip-${invoice._id}`}
                          place="top"
                          effect="solid"
                        />
                    </button>
                  </div>
                </td>
              </motion.tr>
            ))}
          </tbody>
        </table>
      </div>
      </div>

      {invoices.length > MIN_ITEMS_FOR_PAGINATION && renderPaginationControls()}
    </>
  );
};

export default PaginatedInvoiceTable;