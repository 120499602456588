import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Plus, X } from 'lucide-react';
import { useClient } from '../hooks/useClient';
import { useAvailableServices } from '../hooks/useServices';
import { toast } from 'react-toastify';

const ServiceModal = ({ isOpen, onClose, client, editingService, onSubmit }) => {
  const [serviceFormData, setServiceFormData] = useState({
    serviceId: editingService?.available_service_id || '',
    // status: editingService?.status || 'active',
    status: editingService?.status || (client?.status === 'lead' || client?.status === 'prospect' ? 'interested' : 'active'),
    pricingModel: editingService?.pricing?.interval ? 'subscription' : 'project',
    // Subscription fields
    interval: editingService?.pricing?.interval || '',
    intervalPrice: editingService?.pricing?.interval_price || '',
    tier: editingService?.pricing?.tier || 'starter',
    nextBillingDate: editingService?.pricing?.next_billing_date || '',
    // Project fields
    payStructure: editingService?.pricing?.pay_structure || '',
    price: editingService?.pricing?.price || '',
    projectName: editingService?.pricing?.project_name || '',
    pay_structure_details: editingService?.pricing?.pay_structure_details || [],
    notes: editingService?.notes || ''
  });

  const { data: availableServices = [], isLoading: isAvailServicesLoading } = useAvailableServices();
  const { addService, isAddingService } = useClient(client._id);

  // Reset form when editingService changes
  useEffect(() => {
    if (editingService) {
      setServiceFormData({
        serviceId: editingService.available_service_id || '',
        status: editingService?.status || (client?.status === 'lead' || client?.status === 'prospect' ? 'interested' : 'active'),
        pricingModel: editingService.pricing?.interval ? 'subscription' : 'project',
        interval: editingService.pricing?.interval || '',
        intervalPrice: editingService.pricing?.interval_price || '',
        tier: editingService.pricing?.tier || 'starter',
        nextBillingDate: editingService.pricing?.next_billing_date ? 
          new Date(editingService.pricing.next_billing_date).toISOString().split('T')[0] : '',
        payStructure: editingService.pricing?.pay_structure || '',
        price: editingService.pricing?.price || '',
        projectName: editingService.pricing?.project_name || '',
        pay_structure_details: editingService.pricing?.pay_structure_details || [],
        notes: editingService.notes || ''
      });
    }
  }, [editingService]);

  const handleSplitChange = (index, value) => {
    const newDetails = [...serviceFormData.pay_structure_details];
    newDetails[index] = parseFloat(value) || 0;
    setServiceFormData({ ...serviceFormData, pay_structure_details: newDetails });
  };

  const handleSelectAvailableService = (id) => {
    setServiceFormData({
      ...serviceFormData,
      serviceId: id
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Initial validation
    if (!serviceFormData.serviceId) {
      toast.error('Please select a service');
      return;
    }
  
    // Validate split percentages
    if (
      ['active', 'pending', 'paused'].includes(serviceFormData.status) &&
      serviceFormData.pricingModel === 'project' &&
      serviceFormData.payStructure === 'split'
    ) {
      const totalPercentage = serviceFormData.pay_structure_details.reduce(
        (acc, curr) => acc + Number(curr || 0),
        0
      );
      if (totalPercentage !== 100) {
        toast.error('Total split percentages must equal 100%');
        return;
      }
    }
  
    // Build the new service object
    const newService = {
      available_service_id: serviceFormData.serviceId,
      status: serviceFormData.status || 'active',
      notes: serviceFormData.notes || '',
      service_code: serviceFormData.serviceName, // You'll need to add this to your form state
      pricing: undefined
    };
  
    // Add pricing based on status and pricing model
    if (['active', 'pending', 'paused'].includes(serviceFormData.status)) {
      if (serviceFormData.pricingModel === 'subscription') {
        newService.pricing = {
          interval: serviceFormData.interval,
          interval_price: parseFloat(serviceFormData.intervalPrice) || 0,
          tier: serviceFormData.tier,
          next_billing_date: serviceFormData.nextBillingDate || null
        };
      } else if (serviceFormData.pricingModel === 'project') {
        newService.pricing = {
          pay_structure: serviceFormData.payStructure,
          price: parseFloat(serviceFormData.price) || 0,
          project_name: serviceFormData.projectName,
          pay_structure_details: serviceFormData.payStructure === 'split'
            ? serviceFormData.pay_structure_details.map((percentage) => Number(percentage))
            : serviceFormData.payStructure === 'one-time'
              ? [100]
              : []
        };
      }
    }
  
    try {
        console.log('handle submit', newService);
      await onSubmit(newService);
      
      // Reset form
      setServiceFormData({
        serviceId: '',
        serviceName: '',
        pricingModel: '',
        status: 'active',
        interval: '',
        intervalPrice: 0,
        tier: '',
        nextBillingDate: '',
        payStructure: '',
        pay_structure_details: [0, 0],
        price: 0,
        projectName: '',
        notes: ''
      });
  
      onClose();
    } catch (error) {
      console.error('Error submitting service:', error);
      toast.error(error.message || 'Failed to submit service');
    }
  };


  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 h-full w-full overflow-y-auto p-4"
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        className="bg-white dark:bg-darkbg-800 rounded-lg p-6 max-w-2xl w-full relative overflow-y-auto max-h-[90vh] my-auto"
      >
        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 p-2 text-grayteck-500 hover:text-grayteck-700 
                   dark:text-grayteck-400 dark:hover:text-grayteck-200"
        >
          <X className="w-5 h-5" />
        </button>

        <h3 className="text-xl font-semibold mb-4 text-grayteck-900 dark:text-grayteck-100">
          {editingService ? 'Edit Service' : 'Add New Service'}
        </h3>

        {isAvailServicesLoading || isAddingService ? (
          <p className="text-grayteck-600 dark:text-grayteck-400">
            {isAvailServicesLoading ? "Loading services..." : "Adding service..."}
          </p>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-4">
            {/* Available Service Selection */}
            <div>
              <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-1">
                Available Service
              </label>
              <select 
                value={serviceFormData.serviceId}
                onChange={(e) => handleSelectAvailableService(e.target.value)}
                className="w-full p-2 border rounded-md bg-white dark:bg-darkbg-700 
                         border-grayteck-300 dark:border-grayteck-600 
                         text-grayteck-900 dark:text-grayteck-100"
                disabled={!!editingService}
                required
              >
                <option value="">Select a service...</option>
                {availableServices.map((svc) => (
                  <option key={svc.id} value={svc.id}>
                    {svc.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Service Status */}
            <div>
              <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-1">
                Service Status
              </label>
              <select
                value={serviceFormData.status}
                onChange={(e) =>
                  setServiceFormData({ ...serviceFormData, status: e.target.value })
                }
                className="w-full p-2 border rounded-md bg-white dark:bg-darkbg-700 
                         border-grayteck-300 dark:border-grayteck-600 
                         text-grayteck-900 dark:text-grayteck-100"
                required
              >
                <option value="active">Active</option>
                <option value="pending">Pending</option>
                <option value="in_review">In Review</option>
                <option value="interested">Interested</option>
                <option value="paused">Paused</option>
                <option value="completed">Completed</option>
              </select>
            </div>

            {/* Pricing Model */}
            {['active', 'pending', 'paused', "in_review"].includes(serviceFormData.status) && (
              <>
                <div>
                  <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-1">
                    Pricing Model
                  </label>
                  <select 
                    value={serviceFormData.pricingModel}
                    onChange={(e) =>
                      setServiceFormData({ ...serviceFormData, pricingModel: e.target.value })
                    }
                    className="w-full p-2 border rounded-md bg-white dark:bg-darkbg-700 
                             border-grayteck-300 dark:border-grayteck-600 
                             text-grayteck-900 dark:text-grayteck-100"
                    required
                  >
                    <option value="">Select pricing type</option>
                    <option value="subscription">Subscription</option>
                    <option value="project">Project</option>
                  </select>
                </div>

                {/* Subscription Pricing Fields */}
                {serviceFormData.pricingModel === 'subscription' && (
                  <div className="grid grid-cols-2 gap-4 border rounded-md p-4 bg-gray-50 dark:bg-darkbg-700">
                    <div>
                      <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-1">
                        Interval
                      </label>
                      <select 
                        value={serviceFormData.interval}
                        onChange={(e) =>
                          setServiceFormData({ ...serviceFormData, interval: e.target.value })
                        }
                        className="w-full p-2 border rounded-md bg-white dark:bg-darkbg-700 
                                 border-grayteck-300 dark:border-grayteck-600 
                                 text-grayteck-900 dark:text-grayteck-100"
                        required
                      >
                        <option value="">Select Interval</option>
                        <option value="monthly">Monthly</option>
                        <option value="quarterly">Quarterly</option>
                        <option value="annual">Annual</option>
                      </select>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-1">
                        Interval Price
                      </label>
                      <input
                        type="number"
                        value={serviceFormData.intervalPrice}
                        onChange={(e) =>
                          setServiceFormData({
                            ...serviceFormData,
                            intervalPrice: e.target.value
                          })
                        }
                        className="w-full p-2 border rounded-md bg-white dark:bg-darkbg-700 
                                 border-grayteck-300 dark:border-grayteck-600 
                                 text-grayteck-900 dark:text-grayteck-100"
                        min="0"
                        step="0.01"
                        required
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-1">
                        Tier
                      </label>
                      <select 
                        value={serviceFormData.tier}
                        onChange={(e) =>
                          setServiceFormData({ ...serviceFormData, tier: e.target.value })
                        }
                        className="w-full p-2 border rounded-md bg-white dark:bg-darkbg-700 
                                 border-grayteck-300 dark:border-grayteck-600 
                                 text-grayteck-900 dark:text-grayteck-100"
                        required
                      >
                        <option value="starter">Starter</option>
                        <option value="standard">Standard</option>
                        <option value="premium">Premium</option>
                      </select>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-1">
                        Next Billing Date
                      </label>
                      <input
                        type="date"
                        value={serviceFormData.nextBillingDate}
                        onChange={(e) =>
                          setServiceFormData({
                            ...serviceFormData,
                            nextBillingDate: e.target.value
                          })
                        }
                        className="w-full p-2 border rounded-md bg-white dark:bg-darkbg-700 
                                 border-grayteck-300 dark:border-grayteck-600 
                                 text-grayteck-900 dark:text-grayteck-100"
                        required
                      />
                    </div>
                  </div>
                )}

                {/* Project Pricing Fields */}
                {serviceFormData.pricingModel === 'project' && (
                  <div className="grid grid-cols-2 gap-4 border rounded-md p-4 bg-gray-50 dark:bg-darkbg-700">
                    <div>
                      <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-1">
                        Pay Structure
                      </label>
                      <select 
                        value={serviceFormData.payStructure}
                        onChange={(e) =>
                          setServiceFormData({
                            ...serviceFormData,
                            payStructure: e.target.value,
                            pay_structure_details: e.target.value === 'split' ? [0, 0] : []
                          })
                        }
                        className="w-full p-2 border rounded-md bg-white dark:bg-darkbg-700 
                                 border-grayteck-300 dark:border-grayteck-600 
                                 text-grayteck-900 dark:text-grayteck-100"
                        required
                      >
                        <option value="">Select Pay Structure</option>
                        <option value="one-time">One-Time</option>
                        <option value="split">Split</option>
                      </select>
                    </div>

                    {serviceFormData.payStructure === 'split' && (
                      <div className="col-span-2 space-y-4">
                        <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-1">
                          Pay Structure Details (%)
                        </label>
                        <div className="space-y-4">
                          {/* Split 1 */}
                          <div className="flex items-center space-x-2">
                            <input
                              type="number"
                              min="0"
                              max="100"
                              value={serviceFormData.pay_structure_details[0]}
                              onChange={(e) => handleSplitChange(0, e.target.value)}
                              placeholder="Deposit Percentage"
                              className="w-1/2 p-2 border rounded-md bg-white dark:bg-darkbg-700 
                                       border-grayteck-300 dark:border-grayteck-600 
                                       text-grayteck-900 dark:text-grayteck-100"
                              required
                            />
                          </div>
                          
                          {/* Split 2 */}
                          <div className="flex items-center space-x-2">
                            <input
                              type="number"
                              min="0"
                              max="100"
                              value={serviceFormData.pay_structure_details[1]}
                              onChange={(e) => handleSplitChange(1, e.target.value)}
                              placeholder="Completion Percentage"
                              className="w-1/2 p-2 border rounded-md bg-white dark:bg-darkbg-700 
                                       border-grayteck-300 dark:border-grayteck-600 
                                       text-grayteck-900 dark:text-grayteck-100"
                              required
                            />
                          </div>
                          
                          {/* Display Total Percentage */}
                          <p className="text-sm text-grayteck-600 dark:text-grayteck-400">
                            Total: {serviceFormData.pay_structure_details.reduce((acc, curr) => acc + curr, 0)}%
                          </p>
                        </div>
                      </div>
                    )}

                    <div>
                      <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-1">
                        Price
                      </label>
                      <input
                        type="number"
                        value={serviceFormData.price}
                        onChange={(e) =>
                          setServiceFormData({
                            ...serviceFormData,
                            price: e.target.value
                          })
                        }
                        className="w-full p-2 border rounded-md bg-white dark:bg-darkbg-700 
                                 border-grayteck-300 dark:border-grayteck-600 
                                 text-grayteck-900 dark:text-grayteck-100"
                        min="0"
                        step="0.01"
                        required
                      />
                    </div>

                    <div className="col-span-2">
                      <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-1">
                        Project Name
                      </label>
                      <input
                        type="text"
                        value={serviceFormData.projectName}
                        onChange={(e) =>
                          setServiceFormData({
                            ...serviceFormData,
                            projectName: e.target.value
                          })
                        }
                        className="w-full p-2 border rounded-md bg-white dark:bg-darkbg-700 
                                 border-grayteck-300 dark:border-grayteck-600 
                                 text-grayteck-900 dark:text-grayteck-100"
                        required
                      />
                    </div>
                  </div>
                )}
              </>
            )}

            {/* Notes */}
            <div>
              <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-1">
                Service Notes
              </label>
              <textarea
                value={serviceFormData.notes}
                onChange={(e) =>
                  setServiceFormData({ ...serviceFormData, notes: e.target.value })
                }
                rows={3}
                className="w-full p-2 border rounded-md bg-white dark:bg-darkbg-700 
                         border-grayteck-300 dark:border-grayteck-600 
                         text-grayteck-900 dark:text-grayteck-100"
                placeholder="Add any additional notes or requirements..."
              />
            </div>

            {/* Action Buttons */}
            <div className="flex justify-end space-x-4 mt-6">
              <motion.button
                type="button"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={onClose}
                className="px-4 py-2 text-grayteck-600 hover:text-grayteck-900 
                         dark:text-grayteck-400 dark:hover:text-grayteck-200"
              >
                Cancel
              </motion.button>
              <motion.button
                type="submit"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className="px-4 py-2 bg-grayteck-600 text-white rounded-md hover:bg-grayteck-700 
                         flex items-center space-x-2"
                disabled={isAddingService}
              >
                {isAddingService ? (
                  <>
                    <div className="animate-spin rounded-full h-5 w-5 border-2 border-white border-t-transparent" />
                    <span>{editingService ? 'Updating...' : 'Adding...'}</span>
                  </>
                ) : (
                  <>
                    <Plus className="w-5 h-5" />
                    <span>{editingService ? 'Update Service' : 'Add Service'}</span>
                  </>
                )}
              </motion.button>
            </div>
          </form>
        )}
      </motion.div>
    </motion.div>
  );
};

export default ServiceModal;