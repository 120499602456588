// src/context/HeaderContext.js
import React, { createContext, useState, useContext } from 'react';

const HeaderContext = createContext();

export const HeaderProvider = ({ children }) => {
  const [extraHeaderContent, setExtraHeaderContent] = useState(null);

  return (
    <HeaderContext.Provider value={{ extraHeaderContent, setExtraHeaderContent }}>
      {children}
    </HeaderContext.Provider>
  );
};

export const useHeader = () => useContext(HeaderContext);
