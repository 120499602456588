import React from 'react';
import { motion } from 'framer-motion';
import { AlertCircle, Plus } from 'lucide-react';

const NotesSection = ({
  client,
  newNote,
  setNewNote,
  handleAddNote,
  isCreatingNote,
  createNoteError,
  formatDate,        // or import your own local formatDate
  getStatusColor     // or pass a simpler function if you only do 'active'
}) => {
  if (!client) return null;

  return (
    <div className="space-y-6">
      {/* Notes Header - Add New Note */}
      <div className="bg-white dark:bg-darkbg-800 rounded-lg p-6 shadow-md">
        <h3 className="text-xl font-semibold text-grayteck-900 dark:text-grayteck-100 mb-4">
          Add Note
        </h3>

        <div className="space-y-4">
          {/* The new note textarea */}
        <textarea
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
            placeholder="Enter your note here..."
            className="w-full h-32 p-3 border border-grayteck-300 dark:border-grayteck-600 rounded-md
                       bg-white dark:bg-darkbg-700 text-grayteck-900 dark:text-grayteck-100
                       focus:outline-none focus:ring-2 focus:ring-grayteck-500"
        />
          
          {/* "Add Note" button */}
          <div className="flex justify-end">
          <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            onClick={handleAddNote}
              disabled={isCreatingNote || !newNote.trim()}
              className={`px-4 py-2 bg-grayteck-600 text-white rounded-md hover:bg-grayteck-700 
                         flex items-center space-x-2 transition-colors duration-200
                         ${isCreatingNote || !newNote.trim() ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
              {isCreatingNote ? (
                <>
                  <div className="animate-spin rounded-full h-5 w-5 border-2 border-white border-t-transparent" />
                  <span>Adding Note...</span>
                </>
              ) : (
                <>
                  <Plus className="w-5 h-5" />
                  <span>Add Note</span>
                </>
              )}
          </motion.button>
        </div>
        </div>

        {/* Show status messages */}
        {isCreatingNote && (
          <p className="text-sm text-grayteck-500 mt-2">
            Adding note...
          </p>
        )}
        {createNoteError && (
          <p className="text-sm text-red-500 mt-2">
            Error adding note: {createNoteError.message}
          </p>
        )}
      </div>

      {/* Existing Notes */}
      <div className="space-y-4">
        {client.notes
          ?.sort((a, b) => new Date(b.date) - new Date(a.date))
          .map((note, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-white dark:bg-darkbg-800 rounded-lg p-6 shadow-md"
            >
              {/* Top line: date, type, etc. */}
              <div className="flex justify-between items-start mb-4">
                <div className="flex items-center space-x-3">
                  {/* If note.type is defined, show a type badge */}
                  {note.type && (
                    <span
                      className={`px-3 py-1 rounded-full text-sm font-medium 
                                  ${getStatusColor ? getStatusColor(note.type) : ''}`}
                    >
                      {note.type.charAt(0).toUpperCase() + note.type.slice(1)}
                    </span>
                  )}

                  {/* The note date/time */}
                  <span className="text-sm text-grayteck-500 dark:text-grayteck-400">
                    {formatDate ? formatDate(note.date) : note.date}
                  </span>
                </div>
              </div>

              {/* The note content */}
              <p className="text-grayteck-900 dark:text-grayteck-100 whitespace-pre-wrap">
                {note.content}
              </p>

              {/* If note.follow_up is present */}
              {note.follow_up && (
                <div className="mt-4 flex items-start space-x-2 bg-grayteck-50 dark:bg-darkbg-700 p-3 rounded-md">
                  <AlertCircle className="w-5 h-5 text-yellow-500 mt-0.5" />
                  <div>
                    <p className="text-sm font-medium text-grayteck-900 dark:text-grayteck-100">
                      Follow-up: {formatDate ? formatDate(note.follow_up.date) : note.follow_up.date}
                    </p>
                    <p className="text-sm text-grayteck-600 dark:text-grayteck-400">
                      {note.follow_up.note}
                    </p>
            </div>
                </div>
              )}
            </motion.div>
          ))
        }
      </div>
    </div>
  );
};

export default NotesSection;
