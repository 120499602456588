// src/components/InvoiceProgressTracker.jsx

import React from 'react';
import { motion } from 'framer-motion';
import {
  FileText,
  Send,
  Eye,
  DollarSign,
  Clock,
  Trash2,
} from 'lucide-react';

const InvoiceProgressTracker = ({ invoice }) => {
  const steps = [
    {
      id: 'draft',
      title: 'Draft',
      icon: FileText,
      description: 'Invoice is being created',
      isCompleted: invoice.status !== 'draft',
      date: invoice.created_at,
    },
    {
      id: 'sent',
      title: 'Sent',
      icon: Send,
      description: 'Invoice has been sent to the client',
      isCompleted: ['pending', 'opened', 'paid', 'overdue', 'cancelled'].includes(invoice.status),
      date: invoice.issued_at,
    },
    {
      id: 'viewed',
      title: 'Viewed',
      icon: Eye,
      description: 'Client has viewed the invoice',
      isCompleted: !!invoice.viewed_at,
      date: invoice.viewed_at,
    },
    {
      id: 'paid',
      title: 'Paid',
      icon: DollarSign,
      description: 'Invoice has been paid',
      isCompleted: invoice.status === 'paid',
      date: invoice.paid_at,
    },
    {
      id: 'overdue',
      title: 'Overdue',
      icon: Clock,
      description: 'Invoice payment is overdue',
      isCompleted: invoice.status === 'overdue',
      date: invoice.due_date,
    },
    {
      id: 'cancelled',
      title: 'Cancelled',
      icon: Trash2,
      description: 'Invoice has been cancelled',
      isCompleted: invoice.status === 'cancelled',
      date: invoice.cancelled_at,
    },
  ];

  // Determine the current step based on invoice status
  const getCurrentStepIndex = () => {
    switch (invoice.status) {
      case 'draft':
        return 0;
      case 'pending':
        return 1;
      case 'opened':
        return 2;
      case 'paid':
        return 3;
      case 'overdue':
        return 4;
      case 'cancelled':
        return 5;
      default:
        return 0;
    }
  };

  const currentStepIndex = getCurrentStepIndex();

  // Calculate progress percentage
  const progressPercentage = (currentStepIndex / (steps.length - 1)) * 100;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="w-full bg-white dark:bg-darkbg-800 rounded-lg shadow-md p-6 mb-8"
    >
      <div className="max-w-4xl mx-auto">
        <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-6 text-center">
          Invoice Progress
        </h3>

        <div className="relative">
          {/* Background Line */}
          <div className="absolute left-0 top-1/2 w-full h-0.5 bg-gray-200 dark:bg-gray-700 transform -translate-y-1/2" />

          {/* Animated Progress Line */}
          <motion.div
            style={{ width: `${progressPercentage}%` }}
            className="absolute left-0 top-1/2 h-0.5 bg-gradient-to-r from-blue-500 to-blue-400 transform -translate-y-1/2"
            transition={{ duration: 0.8, ease: 'easeInOut' }}
          />

          {/* Steps */}
          <div className="relative flex justify-between">
            {steps.map((step, index) => {
              const StepIcon = step.icon;
              const isCompleted = step.isCompleted;
              const isCurrent = index === currentStepIndex;

              return (
                <div
                  key={step.id}
                  className="flex flex-col items-center relative"
                  style={{ width: '100px' }}
                >
                  {/* Step Circle with Icon */}
                  <div
                    className={`relative w-10 h-10 rounded-full flex items-center justify-center
                      ${
                        isCompleted || isCurrent
                          ? 'bg-gradient-to-r from-blue-500 to-blue-400'
                          : 'bg-gray-200 dark:bg-gray-700'
                      }
                      ${isCurrent ? 'ring-4 ring-blue-100 dark:ring-blue-900' : ''}
                      transition-colors duration-200
                    `}
                  >
                    <StepIcon
                      className={`w-5 h-5 ${
                        isCompleted || isCurrent
                          ? 'text-white'
                          : 'text-gray-400 dark:text-gray-500'
                      }`}
                    />
                  </div>

                  {/* Step Title */}
                  <p
                    className={`mt-2 text-sm font-medium text-center
                      ${isCompleted || isCurrent
                        ? 'text-gray-900 dark:text-gray-100'
                        : 'text-gray-500 dark:text-gray-400'}
                    `}
                  >
                    {step.title}
                  </p>

                  {/* Step Description and Date */}
                  <div className="mt-1 text-center">
                    <p className="text-xs text-gray-500 dark:text-gray-400 max-w-[100px]">
                      {step.description}
                    </p>
                    {step.date && (
                      <span className="block text-xs italic mt-1 text-gray-400 dark:text-gray-500">
                        {new Date(step.date).toLocaleDateString()}
                      </span>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default InvoiceProgressTracker;
