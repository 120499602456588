import React, { useContext, useEffect, useState } from "react";
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  Outlet,
  useNavigate,
} from "react-router-dom";
import { ThemeProvider, ThemeContext } from "./context/ThemeContext";
import { AuthProvider, useAuthContext } from "./context/AuthContext";
import { SunIcon, MoonIcon } from "@heroicons/react/24/solid";
import gt_logo from "./imgs/logo_transparent_background_edit.png";
import Login from "./components/Login";
import Register from "./components/Register";
import HomeScreen from "./components/HomeScreen";
import DocumentEditor from "./components/DocumentEditor";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PublicDocumentView from "./components/PublicDocViewer";
import SignedDocumentPreview from "./components/SignedDocumentPreview";
import ComingSoon from "./components/ComingSoon";
import { useAuth } from "./hooks/useAuth";
import ErrorPage from "./pages/ErrorPage";
import TokenRefreshTest from "./pages/TokenTestPage";
import Modal from "./components/Modal";
import AddClientForm from "./components/AddClientForm";
import ClientList from "./components/ClientsList";
import Navbar from "./components/NavBar";
import SettingsPage from "./pages/SettingsPage";
import ClientPortalLogin from "./components/ClientPortalLogin";
import ClientPortalLayout from "./components/ClientPortalLayout.js";
import ClientPortalDashboard from "./components/ClientPortalDash";
import ClientPortalSettings from "./components/ClientPortalSettings";
import ClientPortalSupport from "./components/ClientPortalSupport.js";
import ClientDetail from "./components/ClientDetail.js";
import ClientDetailMod from "./components/ClientDetailMod.js";
import ClientPortalServices from "./components/ClientPortalServicesPage.js";
import PreviewDocument from "./components/PreviewDoc.js";
import ClientPortalInvoiceDashboard from "./components/ClientPortalInvoiceDash.js";

// Services
import authService from "./services/AuthService";

// Context
import { HeaderProvider } from "./context/HeaderContext.js";

// UI Components
import "react-toastify/dist/ReactToastify.css";

const Logo = () => (
  <div className="flex items-center">
    <img src={gt_logo} alt="GrayTeck Logo" className="h-8 w-auto mr-2" />

    <span className="font-bold text-xl">GrayTeck</span>
  </div>
);

const ThemeToggle = () => {
  const { darkMode, toggleDarkMode } = useContext(ThemeContext);

  return (
    <button
      onClick={toggleDarkMode}
      className="p-2 rounded-full bg-grayteck-200 dark:bg-darkbg-600"
    >
      {darkMode ? (
        <SunIcon className="h-6 w-6 text-yellow-500" />
      ) : (
        <MoonIcon className="h-6 w-6 text-grayteck-800" />
      )}
    </button>
  );
};

// Updated PrivateRoute component using the new auth context

const PrivateRoute = ({ children }) => {
  const auth = useAuthContext();

  const location = useLocation();

  // State to prevent infinite loading

  const [loadingTimeout, setLoadingTimeout] = React.useState(false);

  // Debug logging

  console.log("PrivateRoute Check:", {
    isLoading: auth.isLoading,
    isRefreshing: authService.isRefreshing,
    loadingTimeout,
    isAuthenticated: auth.isAuthenticated(),
    location: location.pathname,
  });

  // Set a timeout to prevent infinite loading

  React.useEffect(() => {
    let timeoutId;

    if ((auth.isLoading || authService.isRefreshing) && !loadingTimeout) {
      console.log("Setting loading timeout of 3 seconds");

      timeoutId = setTimeout(() => {
        console.log("Loading timeout reached, forcing continuation");

        setLoadingTimeout(true);
      }, 3000); // Shorter 3 second timeout to avoid long waits
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [auth.isLoading, authService.isRefreshing, loadingTimeout]);

  // Show loading state while checking authentication or refreshing token

  if ((auth.isLoading || authService.isRefreshing) && !loadingTimeout) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-grayteck-100 dark:bg-darkbg-900">
        <div className="flex flex-col items-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-grayteck-600 mb-4"></div>

          <div className="text-grayteck-600 dark:text-grayteck-300">
            Verifying your session...
          </div>
        </div>
      </div>
    );
  }

  // Redirect to login if not authenticated

  if (!auth.isAuthenticated()) {
    console.log("Not authenticated, redirecting to login");

    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }

  // Render protected content if authenticated

  console.log("Authenticated, rendering children");
  return children;
};

const AdminRoute = ({ children }) => {
  const auth = useAuthContext();
  const location = useLocation();
  const [loadingTimeout, setLoadingTimeout] = useState(false);

  // Set a timeout to prevent infinite loading
  useEffect(() => {
    let timeoutId;
    if ((auth.isLoading || authService.isRefreshing) && !loadingTimeout) {
      timeoutId = setTimeout(() => {
        setLoadingTimeout(true);
      }, 3000); // Shorter 3 second timeout
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [auth.isLoading, authService.isRefreshing, loadingTimeout]);

  // Show loading state while checking authentication
  if ((auth.isLoading || authService.isRefreshing) && !loadingTimeout) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-grayteck-100 dark:bg-darkbg-900">
        <div className="flex flex-col items-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-grayteck-600 mb-4"></div>
          <div className="text-grayteck-600 dark:text-grayteck-300">
            Verifying your session...
          </div>
        </div>
      </div>
    );
  }

  if (!auth.isAuthenticated() || !auth.isAdmin()) {
    console.log(auth.isAuthenticated(), auth.isAdmin());
    console.log("Not authenticated or not admin, redirecting to login");
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }

  return children;
};

const ClientRoute = ({ children }) => {
  const auth = useAuthContext();
  const location = useLocation();
  const [loadingTimeout, setLoadingTimeout] = useState(false);

  // Set a timeout to prevent infinite loading
  useEffect(() => {
    let timeoutId;
    if ((auth.isLoading || authService.isRefreshing) && !loadingTimeout) {
      timeoutId = setTimeout(() => {
        setLoadingTimeout(true);
      }, 3000); // Shorter 3 second timeout
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [auth.isLoading, authService.isRefreshing, loadingTimeout]);

  // Show loading state while checking authentication
  if ((auth.isLoading || authService.isRefreshing) && !loadingTimeout) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-grayteck-100 dark:bg-darkbg-900">
        <div className="flex flex-col items-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-grayteck-600 mb-4"></div>
          <div className="text-grayteck-600 dark:text-grayteck-300">
            Verifying your session...
          </div>
        </div>
      </div>
    );
  }

  if (!auth.isAuthenticated() || !auth.isClient()) {
    return (
      <Navigate
        to="/client-portal-login"
        state={{ from: location.pathname }}
        replace
      />
    );
  }

  return children;
};

const AppContent = () => {
  const { darkMode } = useContext(ThemeContext);
  const auth = useAuthContext();
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await auth.logout();
    } catch (error) {
      console.error("Logout failed:", error);
      // Still navigate to login
      navigate("/login");
    }
  };

  useEffect(() => {
    const handleUnauthorized = () => {
      auth.logout();
      navigate("/login");
    };

    window.addEventListener("unauthorized", handleUnauthorized);

    return () => {
      window.removeEventListener("unauthorized", handleUnauthorized);
    };
  }, [navigate, auth]);

  return (
    <div className="min-h-screen dark:bg-darkbg-900">
      <Navbar
        user={auth.user()}
        onLogout={handleLogout}
        onAddClient={() => setModalOpen(true)}
      />
      <main className="p-4">
        <Outlet />
      </main>
      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        <AddClientForm onClose={() => setModalOpen(false)} />
      </Modal>
    </div>
  );
};

function AppInner() {
  // Access dark mode state from context
  const { darkMode } = useContext(ThemeContext);
  const auth = useAuthContext();

  return (
    <div className={darkMode ? "dark min-h-screen" : "min-h-screen"}>
      <ToastContainer />

      <Routes>
        {/* Public routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/share/:token" element={<PublicDocumentView />} />
        <Route path="/client-portal" element={<ComingSoon />} />
        <Route path="/client-portal-dash" element={<ClientPortalDashboard />} />
        <Route path="/token/test" element={<TokenRefreshTest />} />

        {/* Client Portal Routes */}
        <Route
          element={
            <ClientRoute>
              <ClientPortalLayout />
            </ClientRoute>
          }
        >
          <Route
            path="/client-portal-services"
            element={<ClientPortalServices />}
          />
          <Route
            path="/client-portal-settings"
            element={<ClientPortalSettings />}
          />
          <Route
            path="/client-portal-dash"
            element={<ClientPortalDashboard />}
          />
          <Route
            path="/client-invoices"
            element={<ClientPortalInvoiceDashboard />}
          />
          <Route path="/support" element={<ClientPortalSupport />} />
        </Route>

        {/* Admin/CRM Routes */}
        <Route
          element={
            <AdminRoute>
              <AppContent />
            </AdminRoute>
          }
        >
          <Route path="/" element={<HomeScreen />} />
          <Route path="/create" element={<DocumentEditor />} />
          <Route path="/edit/:id" element={<DocumentEditor />} />
          <Route path="/view/:id" element={<SignedDocumentPreview />} />
          <Route path="/clients" element={<ClientList />} />
          <Route path="/clients/:clientId" element={<ClientDetailMod />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route
            path="/documents/:documentId/preview"
            element={<PreviewDocument />}
          />
        </Route>

        {/* 404 route - should be last */}
        <Route
          path="*"
          element={
            <ErrorPage
              title="Page Not Found"
              message="The page you're looking for doesn't exist."
              errorCode="404"
              showHomeButton={false}
              showBackButton={false}
            />
          }
        />
      </Routes>
    </div>
  );
}

function App() {
  return (
    <ThemeProvider>
      <AuthProvider>
        <HeaderProvider>
          <AppInner />
        </HeaderProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
