import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDocument } from "../hooks/useDocument";
import SignatureSuccess from "./SignatureSucess";
import SignatureConfirmationPopup from "./SignatureConfirmationPopup";
import { toast } from "react-toastify";
import ErrorPage from "../pages/ErrorPage";
import CapabilitiesStyles from "./CapabilitiesStyles";
import { useMediaQuery } from "react-responsive";
import { loadStripe } from "@stripe/stripe-js";
import InvoiceContent from "./InvoiceContent";
import PaymentForm from "./PaymentForm";

const PublicDocumentView = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 500px)",
  });
  const { token } = useParams();
  const navigate = useNavigate();
  const [showSignatureConfirm, setShowSignatureConfirm] = useState(false);
  const [showSuccessPage, setShowSuccessPage] = useState(false);
  const [pendingSignature, setPendingSignature] = useState(null);
  const [documentId, setDocumentId] = useState(null); // Add this state
  const [isSubmitting, setIsSubmitting] = useState(false);
  const documentRef = useRef(null);
  const stripeElementsRef = useRef(null);
  // Add state for Stripe
  const [stripePromise] = useState(() =>
    loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
  );
  // console.log(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);

  // Add these handler functions
  const handleCardChange = (event) => {
    const displayError = documentRef.current?.querySelector("#card-errors");
    if (displayError) {
      displayError.textContent = event.error ? event.error.message : "";
    }
  };

  const handlePaymentSubmit = async (event) => {
    event.preventDefault();
    console.log("handlePaymentSubmit triggered.");

    if (!stripeElementsRef.current) {
      console.error(
        "StripeElements reference is missing at payment submission."
      );
      return;
    }
    const { stripe, card } = stripeElementsRef.current;
    if (!card) {
      console.error("Card element is not mounted at payment submission.");
      return;
    }

    // Log the card element container again to check if it still contains the card element.
    const cardElementContainer =
      documentRef.current.querySelector("#card-element");
    console.log("Card element container at submission:", cardElementContainer);
    console.log(
      "Child nodes of card element container at submission:",
      cardElementContainer.childNodes
    );

    console.log(
      "Stripe and card element available for payment submission:",
      stripe,
      card
    );

    // Retrieve form elements.
    const form = documentRef.current?.querySelector("#payment-form");
    const processingDiv = documentRef.current?.querySelector(
      "#payment-processing"
    );
    const successDiv = documentRef.current?.querySelector("#payment-success");
    const errorDiv = documentRef.current?.querySelector("#payment-error");
    const clientSecret =
      documentRef.current?.querySelector("#client-secret")?.value;
    const setupIntentSecret = documentRef.current?.querySelector(
      "#setup-intent-secret"
    )?.value;
    const invoiceId = documentRef.current?.querySelector("#invoice-id")?.value;
    const invoiceType =
      documentRef.current?.querySelector("#invoice-type")?.value;

    if (!form || !processingDiv || !successDiv || !clientSecret) {
      console.error("Required payment elements not found on submission.");
      return;
    }

    setIsProcessingPayment(true);
    form.classList.add("hidden");
    processingDiv.classList.remove("hidden");

    try {
      // Create the payment method.
      const { paymentMethod, error: paymentMethodError } =
        await stripe.createPaymentMethod({
          type: "card",
          card,
          billing_details: {
            email: documentRef.current?.querySelector("#receipt-email")?.value,
            name: documentRef.current?.querySelector("#client-name")?.value,
          },
        });

      if (paymentMethodError) {
        throw paymentMethodError;
      }
      console.log("Payment method created:", paymentMethod);

      if (invoiceType === "subscription") {
        // Handle subscription payment
        // First confirm the initial payment
        const { paymentIntent, error: paymentError } =
          await stripe.confirmCardPayment(clientSecret, {
            payment_method: paymentMethod.id,
          });

        if (paymentError) throw paymentError;
        console.log("Subscription payment confirmed:", paymentIntent);

        // Then set up future payments if setup intent exists
        if (setupIntentSecret) {
          const { setupIntent, error: setupError } =
            await stripe.confirmCardSetup(setupIntentSecret, {
              payment_method: paymentMethod.id,
            });

          if (setupError) throw setupError;
          console.log("Card setup confirmed:", setupIntent);
        }

        // Update backend about subscription setup
        await fetch(`/api/invoices/${invoiceId}/payment-status`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            payment_intent_id: paymentIntent.id,
            payment_method_id: paymentMethod.id,
            status: paymentIntent.status,
            type: "subscription",
          }),
        });
      } else {
        // Handle one-time payment
        const { paymentIntent, error: paymentError } =
          await stripe.confirmCardPayment(clientSecret, {
            payment_method: paymentMethod.id,
          });

        if (paymentError) throw paymentError;
        console.log("One-time payment confirmed:", paymentIntent);

        // Update backend
        await fetch(`/api/invoices/${invoiceId}/payment-status`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            payment_intent_id: paymentIntent.id,
            status: paymentIntent.status,
            type: "one-time",
          }),
        });
      }

      // Show success state
      processingDiv.classList.add("hidden");
      successDiv.classList.remove("hidden");
      console.log("Payment processed successfully.");
      setTimeout(() => window.location.reload(), 3000);
    } catch (error) {
      console.error("Payment error:", error);
      processingDiv.classList.add("hidden");
      form.classList.remove("hidden");

      if (errorDiv) {
        errorDiv.textContent =
          error.message || "An error occurred during payment processing.";
        errorDiv.classList.remove("hidden");
      }

      setIsProcessingPayment(false);
    }
  };

  // Add function to initialize Stripe Elements
  const initializeStripeElements = async () => {
    if (!documentRef.current) return;

    const paymentForm = documentRef.current.querySelector("#payment-form");
    if (!paymentForm) {
      console.error("Payment form not found.");
      return;
    }

    const stripe = await stripePromise;
    if (!stripe) {
      console.error("Stripe failed to load.");
      return;
    }

    // Look for the container that should host the card element.
    const cardElementContainer =
      documentRef.current.querySelector("#card-element");
    if (!cardElementContainer) {
      console.error(
        "Card element container (#card-element) not found in the DOM."
      );
      return;
    } else {
      console.log("Card element container found:", cardElementContainer);
      console.log(
        "Child nodes of card element container:",
        cardElementContainer.childNodes
      );
      if (cardElementContainer.childNodes.length > 0) {
        console.warn(
          "Warning: The card element container is not empty. It has child nodes:",
          cardElementContainer.childNodes
        );
      }
    }

    // Get the hidden input values
    const clientSecret =
      documentRef.current.querySelector("#client-secret")?.value;
    const setupIntentSecret = documentRef.current.querySelector(
      "#setup-intent-secret"
    )?.value;
    const invoiceId = documentRef.current.querySelector("#invoice-id")?.value;
    const invoiceType =
      documentRef.current.querySelector("#invoice-type")?.value;
    // const cardElement = documentRef.current.querySelector('#card-element');

    if (!clientSecret || !cardElementContainer) {
      console.error("Required payment elements not found");
      return;
    }

    const elements = stripe.elements();
    const card = elements.create("card", {
      style: {
        base: {
          fontSize: "16px",
          color: "#32325d",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      },
    });

    // Mount the card element and log the mounting status.
    try {
      card.mount(cardElementContainer);
      console.log("Card element mounted successfully.", card);
    } catch (e) {
      console.error("Error mounting card element:", e);
    }

    // Save the stripe elements reference.
    stripeElementsRef.current = { stripe, elements, card };

    // Add error handling for card changes
    card.on("change", function (event) {
      console.log("Card element change event:", event);
      const displayError = documentRef.current?.querySelector("#card-errors");
      if (displayError) {
        displayError.textContent = event.error ? event.error.message : "";
      }
    });

    // Handle form submission
    paymentForm.addEventListener("submit", async (event) => {
      event.preventDefault();
      console.log("handlePaymentSubmit triggered.");

      if (!stripeElementsRef.current) {
        console.error(
          "StripeElements reference is missing at payment submission."
        );
        return;
      }

      const { stripe, card } = stripeElementsRef.current;
      if (!card) {
        console.error("Card element is not mounted at payment submission.");
        return;
      }

      const form = documentRef.current?.querySelector("#payment-form");
      const processingDiv = documentRef.current?.querySelector(
        "#payment-processing"
      );
      const successDiv = documentRef.current?.querySelector("#payment-success");
      const errorDiv = documentRef.current?.querySelector("#payment-error");
      const clientSecret =
        documentRef.current?.querySelector("#client-secret")?.value;
      const setupIntentSecret = documentRef.current?.querySelector(
        "#setup-intent-secret"
      )?.value;
      const invoiceId =
        documentRef.current?.querySelector("#invoice-id")?.value;
      const invoiceType =
        documentRef.current?.querySelector("#invoice-type")?.value;

      // Verify required elements are present
      if (!form || !processingDiv || !successDiv || !clientSecret) {
        console.error("Required payment elements not found on submission.");
        return;
      }

      setIsProcessingPayment(true);

      if (form) form.classList.add("hidden");
      if (processingDiv) processingDiv.classList.remove("hidden");
      if (processingDiv) {
        processingDiv.querySelector("p").textContent =
          invoiceType === "subscription"
            ? "Setting up subscription..."
            : "Processing payment...";
      }

      try {
        // First create the payment method
        const { paymentMethod, error: pmError } =
          await stripe.createPaymentMethod({
            type: "card",
            card,
            billing_details: {
              email: documentRef.current.querySelector("#receipt-email")?.value,
              name: documentRef.current.querySelector("#client-name")?.value,
            },
          });

        if (pmError) {
          throw new Error(pmError.message);
        }
        console.log("Payment method created:", paymentMethod);

        if (invoiceType === "subscription") {
          // Handle subscription payment
          const { paymentIntent, error: paymentError } =
            await stripe.confirmCardPayment(clientSecret, {
              payment_method: paymentMethod.id,
            });

          if (paymentError) throw paymentError;
          console.log("Subscription payment confirmed:", paymentIntent);

          // Setup future payments if setup intent exists
          if (setupIntentSecret) {
            const { setupIntent, error: setupError } =
              await stripe.confirmCardSetup(setupIntentSecret, {
                payment_method: paymentMethod.id,
              });

            if (setupError) throw setupError;
            console.log("Card setup confirmed:", setupIntent);
          }

          // Update subscription status
          await fetch(`/api/invoices/${invoiceId}/payment-status`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              payment_method_id: paymentMethod.id,
              status: "succeeded",
              type: "subscription",
            }),
          });
        } else {
          // Handle one-time payment
          const { paymentIntent, error: paymentError } =
            await stripe.confirmCardPayment(clientSecret, {
              payment_method: paymentMethod.id,
            });
          if (paymentError) throw paymentError;
          console.log("One-time payment confirmed:", paymentIntent);

          await fetch(`/api/invoices/${invoiceId}/payment-status`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              payment_method_id: paymentMethod.id,
              status: "succeeded",
              type: "one-time",
            }),
          });
        }

        // Show success state
        if (processingDiv) processingDiv.classList.add("hidden");
        if (successDiv) successDiv.classList.remove("hidden");
        console.log("Payment processed successfully.");

        // Refresh after success
        setTimeout(() => window.location.reload(), 3000);
      } catch (error) {
        console.error("Payment error:", error);
        if (processingDiv) processingDiv.classList.add("hidden");
        if (form) form.classList.remove("hidden");

        if (errorDiv) {
          errorDiv.textContent =
            error.message || "An error occurred during payment processing.";
        }

        setIsProcessingPayment(false);
      }
    });
    console.log("Payment form submission handler attached.");
  };

  const FloatingDownloadButton = ({ isDownloading, onDownload }) => (
    <div className="fixed bottom-8 right-8 z-50">
      <button
        onClick={onDownload}
        disabled={isDownloading}
        className="px-4 py-2 bg-grayteck-600 text-white rounded-md hover:bg-grayteck-700 shadow-lg flex items-center space-x-2 transition-colors duration-200"
      >
        {isDownloading ? (
          <>
            <span className="animate-spin">⏳</span>
            <span>Downloading...</span>
          </>
        ) : (
          <>
            <svg
              className="w-5 h-5 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
              />
            </svg>
            <span>Download PDF</span>
          </>
        )}
      </button>
    </div>
  );

  // Function to check if document has signature box
  const hasSignatureBox = (content) => {
    return content?.includes("signature-container") || false;
  };

  // Get the signDocument mutation from our hook
  const {
    document,
    isLoading,
    isError,
    error,
    signDocument,
    isSigningDocument,
    signError,
    downloadDocument, // Add this
    isDownloading, // Add this
  } = useDocument(undefined, token);

  useEffect(() => {
    if (document?._id) {
      setDocumentId(document._id);
      // Also store in sessionStorage as backup
      sessionStorage.setItem("lastDocumentId", document._id);
    }
  }, [document]);

  // Add download handler function
  const handleDownload = async () => {
    try {
      // Pass the document ID directly to the download function
      if (document?.id) {
        await downloadDocument(document.id);
      } else {
        console.log("No document ID available for download");
        toast.error("Unable to download document at this time");
      }
    } catch (error) {
      console.error("Download error:", error);
    }
  };

  // Handle signature confirmation
  const handleSignConfirm = async () => {
    if (!pendingSignature || isSubmitting || isSigningDocument) return;

    setIsSubmitting(true);

    const { name, signaturePad } = pendingSignature;

    try {
      // Get signature image as base64
      const signatureImg = signaturePad.toDataURL();

      // Create signature data object
      const signatureData = {
        signer_name: name,
        signature_img: signatureImg,
        signature_date: new Date().toISOString(),
      };

      // No need to pass token - it's handled by the hook
      await signDocument(signatureData);

      setShowSignatureConfirm(false);
      setPendingSignature(null);

      // Show success page instead of reloading
      setShowSuccessPage(true);
    } catch (error) {
      console.error("Signing error:", error);
      toast.error("Failed to sign document. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const loadSignaturePadScript = () => {
    if (typeof window !== "undefined" && window.document) {
      const script = window.document.createElement("script");
      script.src =
        "https://cdnjs.cloudflare.com/ajax/libs/signature_pad/1.5.3/signature_pad.min.js";
      script.async = true;
      window.document.body.appendChild(script);

      script.onload = initializeSignaturePad;

      return () => {
        if (script.parentNode) {
          script.parentNode.removeChild(script);
        }
      };
    }
  };

  const initializeSignaturePad = () => {
    if (!documentRef.current || !window.SignaturePad) return;

    const canvas = documentRef.current.querySelector("#signature-pad");
    if (!canvas) {
      console.error("Signature pad canvas not found");
      return;
    }

    const signaturePad = new window.SignaturePad(canvas, {
      minWidth: 1,
      maxWidth: 3,
      penColor: "black",
    });

    // Add event listeners
    const clearButton = documentRef.current.querySelector("#clear-button");
    const signButton = documentRef.current.querySelector("#sign-button");
    const nameInput = documentRef.current.querySelector("#name-input");
    const signDateElement = documentRef.current.querySelector("#sign-date");

    // Check if document is already signed
    if (nameInput?.disabled) {
      const name = nameInput.value;
      if (name) {
        const ctx = canvas.getContext("2d");
        ctx.font = "italic 30px 'Brush Script MT', cursive";
        ctx.fillStyle = "black";
        const textWidth = ctx.measureText(name).width;
        const xPosition = (canvas.width - textWidth) / 2;
        const yPosition = canvas.height / 2;
        ctx.fillText(name, xPosition, yPosition);

        // Disable the signature pad
        signaturePad.off();
        canvas.style.opacity = "0.9"; // Slightly dim the signature to indicate it's final

        // Make sure buttons are disabled
        if (clearButton) clearButton.disabled = true;
        if (signButton) signButton.disabled = true;

        return; // Exit early since document is already signed
      }
    }

    const generateSignature = (name) => {
      if (!canvas) {
        console.error("Canvas not found when generating signature");
        return;
      }
      const ctx = canvas.getContext("2d");
      if (!ctx) {
        console.error("Unable to get 2D context from canvas");
        return;
      }

      // Clear the canvas
      signaturePad.clear();

      // Configure the signature style
      ctx.font = "italic 30px 'Brush Script MT', cursive";
      ctx.fillStyle = "black";

      // Calculate text positioning for center alignment
      const textWidth = ctx.measureText(name).width;
      const xPosition = (canvas.width - textWidth) / 2;
      const yPosition = canvas.height / 2;

      // Draw the signature
      ctx.fillText(name, xPosition, yPosition);
    };

    if (clearButton) {
      clearButton.addEventListener("click", () => {
        signaturePad.clear();
        if (signDateElement) signDateElement.textContent = "";
        if (nameInput) {
          nameInput.value = "";
          nameInput.disabled = false;
        }
        if (signButton) signButton.disabled = true;
      });
    }

    if (nameInput && signButton) {
      nameInput.addEventListener("input", function () {
        const name = this.value.trim();
        signButton.disabled = name === "";
        if (name) {
          generateSignature(name);
        } else {
          signaturePad.clear();
        }
      });
    }

    if (signButton) {
      signButton.addEventListener("click", () => {
        const name = nameInput.value.trim();
        if (name) {
          setPendingSignature({
            name,
            signaturePad,
          });
          setShowSignatureConfirm(true);
        }
      });
    }

    const downloadButton =
      documentRef.current.querySelector("#download-button");
    if (downloadButton) {
      console.log("Download btn found adding listener");
      downloadButton.addEventListener("click", handleDownload); // Update this line
    } else {
      console.log("no download btn not adding listneer");
    }
  };

  // useEffect(() => {
  //   if (document?.content && documentRef.current) {
  //     if (document.content.includes('signature-pad')) {
  //       loadSignaturePadScript();
  //     } else {

  //       // Initialize Stripe Elements if payment form exists
  //       const paymentForm = documentRef.current.querySelector('#payment-form');
  //       if (paymentForm) {
  //         initializeStripeElements();
  //       }

  //       const downloadButton = documentRef.current.querySelector('#download-button');
  //       if (downloadButton) {
  //         console.log("Download button found, adding listener");
  //         downloadButton.addEventListener('click', handleDownload);
  //       } else {
  //         console.log('No download button found, not adding listener');
  //       }
  //     }
  //   }
  // }, [document?.content, stripePromise, handlePaymentSubmit]);

  useEffect(() => {
    if (document?.content && documentRef.current) {
      if (document.content.includes("signature-pad")) {
        loadSignaturePadScript();
      } else {
        const downloadButton =
          documentRef.current.querySelector("#download-button");
        if (downloadButton) {
          console.log("Download button found, adding listener");
          downloadButton.addEventListener("click", handleDownload);
        } else {
          console.log("No download button found, not adding listener");
        }
      }
    }
  }, [document?.content]);

  // Your existing styles...
  const previewStyles = `
    body {
      margin: 0;
      padding: 20px;
      background-color: #f0f0f0;
      min-height: 100vh;
    }

    .document-wrapper {
      max-width: 850px;
      margin: 40px auto;
      background: none;
    }

    .document-container {
      background-color: white;
      box-shadow: 0 4px 10px rgba(0,0,0,0.1);
      border-radius: 8px;
      overflow: hidden;
      margin: 0 auto;
      position: relative;
    }

    .document-content {
      font-family: Arial, sans-serif;
      line-height: 1.6;
      color: #333;
    }

    .document-banner {
      width: 100%;
      height: 200px;
      overflow: hidden;
      margin-bottom: 0;
    }

    .document-banner img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .document-title {
      color: #4a5568;
      font-size: 24px;
      margin: 30px 0;
      text-align: center;
      padding: 0 40px;
    }
    h2 {
      color: #4a5568;
      font-size: 20px;
      margin-top: 1.5em;
      margin-bottom: 0.8em;
    }
    p {
      margin-bottom: 1em;
    }
    strong {
      font-weight: bold;
    }
    ol, ul {
      margin-bottom: 1em;
      padding-left: 2em;
    }
    ol {
      list-style-type: decimal;
    }
    ul {
      list-style-type: disc;
    }
    li {
      margin-bottom: 0.5em;
      padding-left: 0.5em;
    }
    ol ol, ul ul, ol ul, ul ol {
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }
    .footer-divider {
      border: 0;
      background: rgba(0, 0, 0, 0.1);
      margin: 20px 0;
    }
    .company-branding {
      text-align: center;
      padding-top: 20px;
      border-top: 1px solid #e2e8f0;
      margin-top: 40px;
    }
    .company-name {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 4px;
    }
    .company-tagline {
      color: #718096;
      font-style: italic;
    }
    .signature-container {
      margin-top: 30px;
      border-top: 1px solid #e2e8f0;
      padding-top: 20px;
    }

    .signature-container.signed .signature-image-container {
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    padding: 10px;
    background: #fff;
    margin: 15px 0;
  }

  .signature-image {
    width: 100%;
    max-height: 100px;
    object-fit: contain;
  }

  .signature-date {
    margin-top: 10px;
    color: #4a5568;
    font-size: 0.9em;
  }
  .poc-input:disabled {
    background-color: #f3f4f6;
    color: #4a5568;
    border-color: #e2e8f0;
  }


    .input-group {
      margin-bottom: 15px;
    }

    .poc-input {
      width: 100%;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 16px;
    }

    #signature-pad {
      border: 1px solid #000;
      width: 100%;
      margin-bottom: 10px;
    }
    .button-group {
      display: flex;
      justify-content: space-between;
      gap: 10px;
    }
    .action-button {
      padding: 10px 15px;
      background-color: #4a5568;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      transition: background-color 0.3s;
    }
    .action-button:hover {
      background-color: #2d3748;
    }
    .action-button:disabled {
      background-color: #a0aec0;
      cursor: not-allowed;
    }
    .company-branding {
      margin-top: 40px;
      padding-top: 20px;
      border-top: 1px solid #e2e8f0;
      text-align: center;
      font-size: 14px;
      color: #718096;
    }
    .document-banner {
      width: 100%;
      height: 200px;
      overflow: hidden;
    }
    .document-banner img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .document-body {
      padding: 0 40px 40px 40px;
      color: #333;
    }
    h1 {
      color: #4a5568;
      font-size: 24px;
      margin: 0 0 20px 0;
      text-align: center;
    }
  h2 {
    color: #4a5568;
    font-size: 20px;
    margin: 1.5em 0 0.8em 0;
  }
  
  p {
    margin: 0 0 1em 0;
  }
    p, ul, ol, li {
      color: #333;
    }
    .footer-divider {
      border: 0;
      background: rgba(0, 0, 0, 0.1);
      margin: 20px 0 10px;
    }
    .company-branding {
      text-align: center;
      margin-top: 10px;
      color: #555;
      font-size: 14px;
    }
    .company-name {
      font-weight: bold;
      margin-bottom: 2px;
    }
    .company-tagline {
      font-style: italic;
    }
    .signature-container {
      margin-top: 30px;
      border-top: 1px solid #e2e8f0;
      padding-top: 20px;
    }
    .input-group {
      margin-bottom: 10px;
    }
    .poc-input {
      width: 100%;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 16px;
      margin-bottom: 15px;
    }
    #signature-pad {
      border: 1px solid #000;
      width: 100%;
      margin-bottom: 10px;
      background-color: white;
    }

    .button-group {
      display: flex;
      justify-content: space-between;
      gap: 10px;
    }

    .action-button {
      padding: 10px 15px;
      background-color: #4a5568;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      transition: background-color 0.3s;
    }

    .action-button:hover:not(:disabled) {
      background-color: #2d3748;
    }

    .action-button:disabled {
      background-color: #a0aec0;
      cursor: not-allowed;
      opacity: 0.7;
    }

      .action-button.downloading {
      position: relative;
      padding-left: 30px; /* Make room for the spinner */
    }
      
    .action-button.downloading::before {
      content: '';
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 12px;
      height: 12px;
      border: 2px solid #fff;
      border-radius: 50%;
      border-top-color: transparent;
      animation: spin 1s linear infinite;
    }

    @keyframes spin {
      to {
        transform: translateY(-50%) rotate(360deg);
      }
    }
    #sign-date {
      margin-top: 10px;
      font-style: italic;
      color: #4a5568;
    }
    .poc-input:disabled {
      background-color: #f3f4f6;
      cursor: not-allowed;
    }
    @media print {
      body {
        background: none;
      }
      .document-wrapper {
        margin: 0;
        max-width: none;
      }
      .document-container {
        box-shadow: none;
      }
    }
    /* Capabilities-specific styles */
    .capabilities-wrapper {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0;
    }

    .capabilities-container {
      background: none;
      box-shadow: none;
      overflow: visible;
    }

    .capabilities-content {
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      line-height: 1.6;
    }

    /* Cover page specific styles */
    .cover-page {
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
      color: white;
      padding: 2rem;
      margin: 0;
      border-radius: 8px;
    }

    .cover-page h1 {
      font-size: 3rem;
      color: white;
      border: none !important;
      margin-bottom: 1rem;
    }

    .cover-page h2 {
      font-size: 2rem;
      color: white ;
      margin-bottom: 2rem;
    }

    /* Company branding for capabilities */
    .capabilities-content .company-branding {
      position: relative;
      margin-top: 40px;
      padding-top: 20px;
      border-top: 1px solid var(--accent-color);
      text-align: center;
    }

    .capabilities-content .company-name {
      font-size: 24px;
      font-weight: bold;
      color: var(--primary-color);
      margin-bottom: 4px;
    }

    .capabilities-content .company-tagline {
      font-size: 16px;
      color: var(--secondary-color);
      font-style: italic;
    }
    
    div.cover-page h1, h2{
      color: black !important;
    }
   

    /* Print styles for capabilities */
    @media print {
      .capabilities-wrapper {
        margin: 0;
        padding: 0;
      }
      
      .capabilities-container {
        padding: 0;
      }

      .cover-page {
        page-break-after: always;
      }
    }
  `;

  // Update the download button HTML structure when rendering the document content
  useEffect(() => {
    if (document?.content) {
      const content = document.content.replace(
        '<button id="download-button" class="action-button">Download as PDF</button>',
        `<button id="download-button" class="action-button ${
          isDownloading ? "downloading" : ""
        }" ${isDownloading ? "disabled" : ""}>
          ${isDownloading ? "Downloading..." : "Download as PDF"}
        </button>`
      );

      if (documentRef.current) {
        const downloadButton =
          documentRef.current.querySelector("#download-button");
        if (downloadButton) {
          downloadButton.className = `action-button ${
            isDownloading ? "downloading" : ""
          }`;
          downloadButton.disabled = isDownloading;
          downloadButton.textContent = isDownloading
            ? "Downloading..."
            : "Download as PDF";
        }
      }
    }
  }, [isDownloading, document?.content]);

  // // Add this useEffect after your existing useEffects
  // useEffect(() => {
  //   const initializeStripe = async () => {
  //     if (!documentRef.current) return;

  //     // Check if we have payment elements and haven't initialized Stripe yet
  //     const paymentForm = documentRef.current.querySelector('#payment-form');
  //     const cardElement = documentRef.current.querySelector('#card-element');
  //     const clientSecret = documentRef.current.querySelector('#client-secret')?.value;

  //     if (paymentForm && cardElement && clientSecret && !stripeElementsRef.current) {
  //       console.log('Initializing Stripe Elements');

  //       const stripe = await stripePromise;
  //       if (!stripe) {
  //         console.error('Failed to load Stripe');
  //         return;
  //       }

  //       const elements = stripe.elements();
  //       const card = elements.create('card', {
  //         style: {
  //           base: {
  //             fontSize: '16px',
  //             color: '#32325d',
  //             '::placeholder': {
  //               color: '#aab7c4'
  //             }
  //           },
  //           invalid: {
  //             color: '#fa755a',
  //             iconColor: '#fa755a'
  //           }
  //         }
  //       });

  //       // Mount the card element
  //       card.mount(cardElement);
  //       stripeElementsRef.current = { stripe, elements, card };

  //       // Set up form submission handler
  //       paymentForm.addEventListener('submit', handlePaymentSubmit);

  //       // Set up card change handler
  //       card.on('change', handleCardChange);
  //     }
  //   };

  //   if (document?.content) {
  //     // Small delay to ensure DOM is ready
  //     setTimeout(initializeStripe, 100);
  //   }

  //   // Cleanup function
  //   return () => {
  //     if (stripeElementsRef.current?.card) {
  //       console.log('Unmounting card element as component is unmounting.');
  //       stripeElementsRef.current.card.unmount();
  //       stripeElementsRef.current = null;
  //     }
  //   };
  // }, [document?.content, stripePromise]);

  if (isLoading) return <div className="text-center mt-8">Loading...</div>;
  if (isError)
    return (
      <ErrorPage
        title="Document Error"
        message={error?.message || "Failed to load document"}
        errorCode={error?.status || "500"}
      />
    );
  if (!document)
    return (
      <ErrorPage
        title="Document Not Found"
        message="The document you're looking for might have been deleted or is no longer accessible."
        errorCode="404"
      />
    );

  // If showing success page, pass the document metadata
  if (showSuccessPage) {
    // Use stored documentId or get from sessionStorage as fallback
    const currentDocId = documentId || sessionStorage.getItem("lastDocumentId");

    return (
      <SignatureSuccess
        documentUrl={window.location.href}
        documentTitle={document?.title}
        expiryDate={document?.link_expires_at}
        documentId={currentDocId}
      />
    );
  }

  // Determine if this is an invoice that needs payment form
  const isInvoice = document.document_type === "invoice";
  // Only show payment form if template is specifically set to "stripe"
  const showPaymentForm =
    isInvoice && document.client_secret && document.template === "stripe";

  return (
    <>
      {/* Use previewStyles for mobile, regardless of document type */}
      {isMobile ? (
        <style>{previewStyles}</style>
      ) : document.document_type === "capabilities" ||
        document.document_type === "proposal" ? (
        <CapabilitiesStyles />
      ) : (
        <style>{previewStyles}</style>
      )}

      {!hasSignatureBox(document.content) && (
        <FloatingDownloadButton
          isDownloading={isDownloading}
          onDownload={handleDownload}
        />
      )}

      <div
        className={`document-wrapper ${
          !isMobile &&
          (document.document_type === "capabilities" ||
            document.document_type === "proposal")
            ? "capabilities-wrapper"
            : ""
        }`}
      >
        <div
          className={`document-container ${
            !isMobile &&
            (document.document_type === "capabilities" ||
              document.document_type === "proposal")
              ? "capabilities-container"
              : ""
          }`}
        >
          <div
            ref={documentRef}
            className={`document-content ${
              !isMobile &&
              (document.document_type === "capabilities" ||
                document.document_type === "proposal")
                ? "capabilities-content"
                : ""
            }`}
          >
            {/* Use InvoiceContent component to handle copy functionality */}
            <InvoiceContent htmlContent={document.content} />
          </div>

          {/* Payment form for invoices */}
          {showPaymentForm && (
            <PaymentForm
              clientSecret={document.client_secret}
              setupIntentSecret={document.setup_intent_secret}
              invoiceId={document.invoice_id}
              invoiceType={document.document_type}
              email={document.client_email}
              name={document.client_name}
              amount={document.total}
              stripePk={document.stripe_pk} // Add this
            />
          )}
        </div>
      </div>

      <SignatureConfirmationPopup
        isOpen={showSignatureConfirm}
        onClose={() => setShowSignatureConfirm(false)}
        onConfirm={handleSignConfirm}
        name={pendingSignature?.name || ""}
        error={signError?.message}
        isLoading={isSigningDocument}
      />
    </>
  );
};

export default PublicDocumentView;
