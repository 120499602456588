import React, { useEffect, useRef } from 'react';
import { useClipboard } from '../hooks/useClipboard';
import { toast } from 'react-toastify';

const InvoiceContent = ({ htmlContent }) => {
  const contentRef = useRef(null);
  const { copyToClipboard, copyStatus } = useClipboard();

  useEffect(() => {
    if (!contentRef.current || !htmlContent) return;

    // Find all payment ID elements with data attributes
    const paymentIdElements = contentRef.current.querySelectorAll('[data-payment-type]');

    // Process each element
    paymentIdElements.forEach(element => {
      const paymentType = element.getAttribute('data-payment-type');
      const paymentValue = element.getAttribute('data-payment-value');

      if (!paymentValue) return;

      // Make the whole element clickable and style it
      element.style.cursor = 'pointer';
      element.style.position = 'relative';
      element.style.transition = 'all 0.2s ease';
      
      // Add a subtle indicator that this is clickable
      element.setAttribute('title', 'Click to copy');
      
      // Optional: Add a small indicator icon next to the value
      const copyIndicator = document.createElement('span');
      copyIndicator.innerHTML = ' <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="display: inline-block; vertical-align: middle; margin-left: 4px;"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>';
      copyIndicator.style.opacity = '0.6';
      copyIndicator.className = 'copy-indicator';
      element.appendChild(copyIndicator);

      // Add hover effect
      element.addEventListener('mouseover', () => {
        element.style.background = 'rgba(59, 130, 246, 0.08)';
        element.style.borderRadius = '4px';
      });
      
      element.addEventListener('mouseout', () => {
        element.style.background = 'transparent';
      });

      // Add click handler to the element itself
      element.addEventListener('click', () => {
        copyToClipboard(paymentValue, paymentType)
          .then(() => {
            // Visual feedback when copied
            const originalBackground = element.style.background;
            element.style.background = 'rgba(16, 185, 129, 0.15)';
            
            // Add a temporary checkmark
            const feedbackIcon = document.createElement('span');
            feedbackIcon.innerHTML = ' <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="display: inline-block; vertical-align: middle; margin-left: 4px;"><polyline points="20 6 9 17 4 12"></polyline></svg>';
            feedbackIcon.style.color = '#10b981';
            feedbackIcon.className = 'copied-indicator';
            
            // Replace the copy indicator with the checkmark
            const existingIndicator = element.querySelector('.copy-indicator');
            if (existingIndicator) {
              existingIndicator.style.display = 'none';
            }
            element.appendChild(feedbackIcon);
            
            // Show toast notification
            let label = '';
            switch(paymentType) {
              case 'cash-app':
                label = 'Cash App ID';
                break;
              case 'paypal':
                label = 'PayPal email';
                break;
              case 'bank-transfer':
                label = 'Bank transfer email';
                break;
              default:
                label = 'Information';
            }
            
            toast.success(`${label} copied to clipboard`);
            
            // Reset element appearance after 2 seconds
            setTimeout(() => {
              element.style.background = originalBackground;
              if (existingIndicator) {
                existingIndicator.style.display = 'inline-block';
              }
              const addedFeedbackIcon = element.querySelector('.copied-indicator');
              if (addedFeedbackIcon) {
                addedFeedbackIcon.remove();
              }
            }, 2000);
          });
      });
    });

    // Cleanup function
    return () => {
      // Remove event listeners on unmount
      const elements = contentRef.current?.querySelectorAll('[data-payment-type]');
      elements?.forEach(element => {
        element.removeAttribute('style');
        element.removeAttribute('title');
        const copyIndicator = element.querySelector('.copy-indicator');
        if (copyIndicator) copyIndicator.remove();
        const copiedIndicator = element.querySelector('.copied-indicator');
        if (copiedIndicator) copiedIndicator.remove();
        // Need to properly clone to remove event listeners
        const newElement = element.cloneNode(true);
        element.parentNode.replaceChild(newElement, element);
      });
    };
  }, [htmlContent, copyToClipboard]);

  return (
    <div 
      ref={contentRef}
      className="invoice-content"
      dangerouslySetInnerHTML={{ __html: htmlContent }} 
    />
  );
};

export default InvoiceContent;