// src/hooks/useAuth.js
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAuthContext } from "../context/AuthContext";
import {
  getCurrentUser,
  sendAccessCode,
  verifyAccessCode,
} from "../functions/auth_fethcers";
import { authStorage } from "../functions/auth";
import authService from "../services/AuthService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const useAuth = (onLogoutComplete) => {
  const queryClient = useQueryClient();
  const auth = useAuthContext();
  const navigate = useNavigate();

  // Get current user query
  const { data: user, isLoading: isLoadingUser } = useQuery(
    "user",
    getCurrentUser,
    {
      enabled: auth.isAuthenticated(),
      retry: false,
      // staleTime: 300000, // 5 minutes
      staleTime: 30000, // 1/2 minute
      onError: (error) => {
        console.error("User fetch error:", error);
      },
    }
  );

  // Login mutation that uses the auth context
  const login = useMutation(
    async ({ username, password }) => {
      return auth.login(username, password);
    },
    {
      onSuccess: () => {
        // Invalidate and refetch user data
        queryClient.invalidateQueries("user");
      },
    }
  );

  // Send access code mutation
  const sendAccessCodeMutation = useMutation(
    async ({ email }) => {
      return await sendAccessCode(email);
    },
    {
      onSuccess: () => {
        toast.success("Access code sent to your email!");
      },
      onError: (error) => {
        console.error("Send access code error:", error);
        toast.error(
          error.detail || "Failed to send access code. Please try again."
        );
      },
    }
  );

  // Verify access code mutation
  const verifyAccessCodeMutation = useMutation(
    async ({ email, code }) => {
      return await verifyAccessCode(email, code);
    },
    {
      onSuccess: (data) => {
        console.log("Access code verified:", data);
        const userData = {
          email: data.email || "user@example.com", // Adjust if your API returns user info
          name: data.name || "emmpty", // Adjust if your API returns user info
          userType: data.user_type || "user", // Adjust if your API returns user info
          lastLogin: new Date().toISOString(),
        };
        authStorage.setUserData(userData);

        queryClient.invalidateQueries("user");

        toast.success("Access granted!");
        navigate("/");
      },
      onError: (error) => {
        console.error("Verify access code error:", error);
        toast.error(error.detail || "Invalid access code. Please try again.");
        authStorage.clear();
      },
    }
  );

  // Refresh token mutation
  // const refresh = useMutation(
  //   async () => {
  //     const currentRefreshToken = authStorage.getRefreshToken();
  //     if (!currentRefreshToken) {
  //       throw new Error("No refresh token available");
  //     }
  //     const response = await refreshAccessToken(currentRefreshToken);
  //     return response;
  //   },
  //   {
  //     onSuccess: (data) => {
  //       const { access_token, refresh_token, expires_in } = data;
  //       const expiresAt = new Date(
  //         Date.now() + expires_in * 1000
  //       ).toISOString();

  //       // Update storage with new tokens
  //       authStorage.set({
  //         accessToken: access_token,
  //         refreshToken: refresh_token,
  //         expiresAt,
  //       });

  //       // Invalidate user query to trigger refetch with new token
  //       queryClient.invalidateQueries("user");

  //       toast.success("Token refreshed successfully");
  //     },
  //     onError: (error) => {
  //       console.error("Token refresh error:", error);
  //       toast.error("Failed to refresh token");

  //       // If refresh fails, log out the user
  //       authStorage.clear();
  //       queryClient.clear();
  //       navigate("/login");
  //     },
  //   }
  // );

  // Logout mutation
  const logout = useMutation(() => auth.logout(), {
    onSuccess: () => {
      // Clear all cached queries
      queryClient.clear();

      // Call the callback if provided
      if (typeof onLogoutComplete === "function") {
        onLogoutComplete();
      }
    },
  });

  // Refresh token mutation
  const refresh = useMutation(() => auth.refreshToken(), {
    onSuccess: () => {
      // Invalidate user query to trigger refetch with new token
      queryClient.invalidateQueries("user");
    },
  });

  // Helper functions to maintain API compatibility with existing code
  const getUser = () => {
    return authStorage.getUserData();
  };

  const setUser = (userData) => {
    if (userData) {
      authStorage.setUserData(userData);
    } else {
      authStorage.clearUserData();
    }
  };

  const getToken = () => {
    return authStorage.getAccessToken();
  };

  const isAuthenticated = () => {
    return auth.isAuthenticated();
  };

  return {
    login,
    logout,
    refresh,

    // Helper methods
    isAuthenticated,
    getUser,
    setUser,
    getToken,

    // Loading states
    isLoggingIn: login.isLoading,
    isLoggingOut: logout.isLoading,
    isRefreshing: refresh.isLoading || authService.isRefreshing,

    // Error states
    loginError: login.error,
    logoutError: logout.error,
    refreshError: refresh.error,
    refreshError: refresh.error,

    // New properties for access code flow
    sendAccessCode: sendAccessCodeMutation,
    verifyAccessCode: verifyAccessCodeMutation,
    isSendingCode: sendAccessCodeMutation.isLoading,
    sendCodeError: sendAccessCodeMutation.error,
    isVerifyingCode: verifyAccessCodeMutation.isLoading,
    verifyCodeError: verifyAccessCodeMutation.error,
  };
};
