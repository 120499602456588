// src/pages/SettingsPage.js
import React, { useState, useEffect } from 'react';
import AvailableServicesManager from '../components/AvailableServicesManager';
import { motion } from 'framer-motion';
import { 
  Settings as SettingsIcon, 
  Briefcase,
  Shield,
  Bell,
  Users,
  Palette
} from 'lucide-react';

// Navigation items configuration
const navItems = [
  { id: 'general', label: 'General', icon: SettingsIcon },
  { id: 'services', label: 'Services', icon: Briefcase },
  { id: 'security', label: 'Security', icon: Shield },
  { id: 'notifications', label: 'Notifications', icon: Bell },
  { id: 'team', label: 'Team', icon: Users },
  { id: 'appearance', label: 'Appearance', icon: Palette },
];



const NavButton = ({ item, isActive, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`relative flex items-center px-1 py-4 font-medium text-sm whitespace-nowrap transition-colors ${
        isActive
          ? 'text-grayteck-900 dark:text-grayteck-100'
          : 'text-grayteck-500 hover:text-grayteck-700 dark:text-grayteck-400 dark:hover:text-grayteck-200'
      }`}
    >
      <item.icon className="w-4 h-4 mr-2" />
      {item.label}
    </button>
  );
};



const Section = ({ title, icon: Icon, children }) => (
  <div className="bg-white dark:bg-darkbg-800 rounded-lg shadow-sm p-6 mb-6">
    <div className="flex items-center mb-4 pb-2 border-b border-grayteck-200 dark:border-grayteck-700">
      <Icon className="w-5 h-5 text-grayteck-600 dark:text-grayteck-400 mr-2" />
      <h2 className="text-lg font-semibold text-grayteck-900 dark:text-grayteck-100">{title}</h2>
    </div>
    {children}
  </div>
);

const SettingsPage = () => {
  const [activeSection, setActiveSection] = useState('general');

  // Handle smooth scrolling to section
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      setActiveSection(sectionId);
    }
  };

  // Update active section based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      const sections = navItems.map(item => ({
        id: item.id,
        element: document.getElementById(item.id)
      }));

      const currentSection = sections.find(section => {
        if (section.element) {
          const rect = section.element.getBoundingClientRect();
          return rect.top <= 100 && rect.bottom >= 100;
        }
        return false;
      });

      if (currentSection) {
        setActiveSection(currentSection.id);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="min-h-screen bg-white dark:bg-darkbg-900">
      {/* Header */}
      <div className="sticky top-0 z-10 bg-white dark:bg-darkbg-900 border-b border-grayteck-200 dark:border-grayteck-700" style={{zIndex:0}}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-2xl font-bold text-grayteck-900 dark:text-grayteck-100 py-4">
            Settings
          </h1>
          
          {/* Navigation */}
          <nav className="relative flex space-x-8 overflow-x-auto">
            {navItems.map((item) => (
              <NavButton
                key={item.id}
                item={item}
                isActive={activeSection === item.id}
                onClick={() => scrollToSection(item.id)}
              />
            ))}
            {/* Animated Underline */}
            <motion.div
              className="absolute bottom-0 h-0.5 bg-grayteck-600 dark:bg-grayteck-400"
              initial={false}
              animate={{
                left: document.querySelector(`button:nth-child(${navItems.findIndex(item => item.id === activeSection) + 1})`)?.offsetLeft || 0,
                width: document.querySelector(`button:nth-child(${navItems.findIndex(item => item.id === activeSection) + 1})`)?.offsetWidth || 0
              }}
              transition={{
                type: "spring",
                stiffness: 500,
                damping: 30
              }}
            />
          </nav>
        </div>
        </div>

        {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 space-y-8">
        {/* General Settings */}
        <div id="general">
          <Section title="General Settings" icon={SettingsIcon}>
            <p className="text-grayteck-600 dark:text-grayteck-400">
              General settings and configuration options will go here.
            </p>
          </Section>
        </div>

        {/* Available Services */}
        <div id="services">
          <Section title="Available Services" icon={Briefcase}>
            <AvailableServicesManager />
          </Section>
        </div>

        {/* Security Settings */}
        <div id="security">
          <Section title="Security Settings" icon={Shield}>
            <p className="text-grayteck-600 dark:text-grayteck-400">
              Security and authentication settings will go here.
            </p>
          </Section>
        </div>

        {/* Notification Settings */}
        <div id="notifications">
          <Section title="Notification Settings" icon={Bell}>
            <p className="text-grayteck-600 dark:text-grayteck-400">
              Notification preferences and settings will go here.
            </p>
          </Section>
        </div>

        {/* Team Settings */}
        <div id="team">
          <Section title="Team Management" icon={Users}>
            <p className="text-grayteck-600 dark:text-grayteck-400">
              Team member management and permissions will go here.
            </p>
          </Section>
        </div>

        {/* Appearance Settings */}
        <div id="appearance">
          <Section title="Appearance Settings" icon={Palette}>
            <p className="text-grayteck-600 dark:text-grayteck-400">
              Theme and display preferences will go here.
            </p>
          </Section>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;