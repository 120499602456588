export function detectSpeakers(transcriptText) {
    // Pattern: `[A: 00:05.123]` etc.
    const pattern = /\[([A-Z]): \d{2}:\d{2}\.\d+\]/g;
    const speakers = new Set();
    let match;
    while ((match = pattern.exec(transcriptText)) !== null) {
      speakers.add(match[1]);
    }
    return Array.from(speakers);
  }
  