// src/components/layouts/ClientPortalLayout.js
import React, { useState } from 'react';
import { Outlet, useNavigate, NavLink, useLocation } from 'react-router-dom';
import { 
  FileSignature, 
  MessageCircle, 
  Settings, 
  LogOut,
  MoreVertical,
  Receipt,
  Briefcase
} from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { toast } from 'react-toastify';
import { useHeader } from '../context/HeaderContext';

const ClientPortalLayout = () => {
  const { logout, getUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem('docusign_user')) || {};
  const { extraHeaderContent } = useHeader();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const mainNavLinks = [
    { path: '/client-portal-dash', icon: FileSignature, text: 'Documents' },
    { path: '/client-portal-services', icon: Briefcase, text: 'Services' },
    { path: '/client-invoices', icon: Receipt, text: 'Invoices' }
  ];

  const dropdownNavLinks = [
    { path: '/support', icon: MessageCircle, text: 'Support' },
    { path: '/client-portal-settings', icon: Settings, text: 'Settings' }
  ];

  const isActive = (path) => location.pathname === path;

  const handleLogout = async () => {
    try {
      await logout.mutateAsync();
      toast.success('Successfully logged out');
    navigate('/client-portal-login', { replace: true }); // Use replace to prevent back navigation
    } catch (error) {
      console.error('Logout failed:', error);
      toast.error('Error during logout');
      navigate('/client-portal-login', { replace: true });
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-grayteck-100 to-grayteck-200 dark:from-darkbg-900 dark:to-darkbg-800">
      {/* Header with Gradient Border */}
      <div className="bg-white dark:bg-darkbg-800 border-b border-grayteck-200 dark:border-grayteck-700 relative">
        <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-grayteck-600 via-blue-500 to-grayteck-600"></div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            {/* Left section - Navigation Links */}
            <div className="flex items-center space-x-6">
              {mainNavLinks.map((link) => {
                const Icon = link.icon;
                return (
                  <NavLink
                    key={link.path}
                    to={link.path}
                    className={`flex items-center font-medium transition-colors ${
                      isActive(link.path)
                        ? 'text-grayteck-900 dark:text-grayteck-100'
                        : 'text-grayteck-600 dark:text-grayteck-400 hover:text-grayteck-900 dark:hover:text-grayteck-200'
                    }`}
                  >
                    <Icon className="w-5 h-5 mr-2" />
                    {link.text}
                  </NavLink>
                );
              })}
            </div>

            {/* Center section - Welcome Message */}
            <div className="flex-1 flex justify-center">
              <h2 className="text-lg font-medium text-grayteck-700 dark:text-grayteck-300">
                Welcome, {user?.name || 'Guest'}
              </h2>
          </div>

            {/* Right section - Extra Header Content and Logout */}
          <div className="flex items-center space-x-4">
              {/* Injected Extra Header Content */}
              {extraHeaderContent}

              {/* Three Dot Menu */}
              <div className="relative">
                <button
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  className="p-2 text-grayteck-600 hover:text-grayteck-900 dark:text-grayteck-400 dark:hover:text-grayteck-200 rounded-md hover:bg-grayteck-100 dark:hover:bg-darkbg-700 transition-colors"
                >
                  <MoreVertical className="w-5 h-5" />
                </button>

                {/* Dropdown Menu */}
                {isDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-darkbg-700 rounded-md shadow-lg py-1 z-10">
                    {dropdownNavLinks.map((link) => {
                      const Icon = link.icon;
                      return (
                        <NavLink
                          key={link.path}
                          to={link.path}
                          onClick={() => setIsDropdownOpen(false)}
                          className={({ isActive }) => `
                            flex items-center px-4 py-2 text-sm
                            ${isActive
                              ? 'text-grayteck-900 dark:text-grayteck-100 bg-grayteck-100 dark:bg-darkbg-600'
                              : 'text-grayteck-600 dark:text-grayteck-400 hover:bg-grayteck-50 dark:hover:bg-darkbg-600'
                            }
                          `}
                        >
                          <Icon className="w-4 h-4 mr-2" />
                          {link.text}
                        </NavLink>
                      );
                    })}
                  </div>
                )}
              </div>

              {/* Logout Button */}
            <button
              onClick={handleLogout}
                className="flex items-center px-4 py-2 text-sm font-medium text-grayteck-600 hover:text-grayteck-900 dark:text-grayteck-300 dark:hover:text-grayteck-100 bg-grayteck-100 dark:bg-darkbg-700 hover:bg-grayteck-200 dark:hover:bg-darkbg-600 rounded-md transition-all duration-200"
            >
                <LogOut className="w-4 h-4 mr-2" />
              Sign Out
            </button>
          </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <Outlet />
      </main>

      {/* Optional Footer */}
      <footer className="bg-transparent dark:bg-darkbg-800 border-t border-gray-200 dark:border-darkbg-700">
        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 text-center text-sm text-grayteck-600 dark:text-grayteck-400">
          © {new Date().getFullYear()} GrayTecknologies LLC. All rights reserved.
        </div>
      </footer>
    </div>
  );
};

export default ClientPortalLayout;