import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Search, Download, Check, Eye, FileSignature, Settings, MessageCircle } from 'lucide-react';

const ClientPortalDashboard = () => {
  const [activeTab, setActiveTab] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');

  const documents = [
    {
      id: '1',
      title: 'Service Agreement Contract',
      createdDate: 'Apr 14, 2023 09:45',
      signedAt: null,
      status: 'pending_signature'
    },
    {
      id: '2',
      title: 'Non-Disclosure Agreement',
      createdDate: 'Apr 16, 2023 10:45',
      signedAt: 'Apr 16, 2023 15:30',
      status: 'signed'
    },
    {
      id: '3',
      title: 'Project Proposal',
      createdDate: 'Nov 24, 2023 10:45',
      signedAt: null,
      status: 'pending_signature'
    }
  ];

  const stats = {
    all: documents.length,
    pending: documents.filter(d => d.status === 'pending_signature').length,
    signed: documents.filter(d => d.status === 'signed').length
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'pending_signature':
        return 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/30 dark:text-yellow-300';
      case 'signed':
        return 'bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-300';
      default:
        return 'bg-grayteck-100 text-grayteck-800 dark:bg-grayteck-900/30 dark:text-grayteck-300';
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-grayteck-100 to-grayteck-200 dark:from-darkbg-900 dark:to-darkbg-800">
      {/* Header with Gradient Border */}
      <div className="bg-white dark:bg-darkbg-800 border-b border-grayteck-200 dark:border-grayteck-700 relative">
        <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-grayteck-600 via-blue-500 to-grayteck-600"></div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center space-x-6">
              <span className="text-grayteck-900 dark:text-grayteck-100 font-medium flex items-center">
                <FileSignature className="w-5 h-5 mr-2" />
                Documents
              </span>
              <span className="text-grayteck-600 dark:text-grayteck-400 font-medium hover:text-grayteck-900 cursor-pointer transition-colors">
                <MessageCircle className="w-5 h-5 inline-block mr-1" />
                Support
              </span>
              <span className="text-grayteck-600 dark:text-grayteck-400 font-medium hover:text-grayteck-900 cursor-pointer transition-colors">
                <Settings className="w-5 h-5 inline-block mr-1" />
                Settings
              </span>
            </div>
            
            {/* Search with Glow Effect */}
            <div className="relative">
              <div className="absolute -inset-0.5 bg-gradient-to-r from-grayteck-600 to-blue-500 rounded-lg blur opacity-30 group-hover:opacity-100 transition duration-1000"></div>
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search Documents"
                  className="w-64 pl-10 pr-4 py-2 rounded-lg border border-grayteck-300 dark:border-grayteck-600
                           bg-white dark:bg-darkbg-700 text-grayteck-900 dark:text-grayteck-100
                           focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300"
                />
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-grayteck-400" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Stats Cards */}
        <div className="grid grid-cols-3 gap-6 mb-8">
          {[
            { id: 'all', title: 'All Documents', count: stats.all, icon: FileSignature, color: 'blue' },
            { id: 'pending', title: 'Pending Signatures', count: stats.pending, icon: Eye, color: 'yellow' },
            { id: 'signed', title: 'Signed Documents', count: stats.signed, icon: Check, color: 'green' }
          ].map((stat) => (
            <motion.button
              key={stat.id}
              onClick={() => setActiveTab(stat.id)}
              whileHover={{ y: -4 }}
              whileTap={{ y: 0 }}
              className={`group relative overflow-hidden rounded-lg ${
                activeTab === stat.id 
                  ? 'border-2 border-blue-500 dark:border-blue-400' 
                  : 'border border-grayteck-200 dark:border-grayteck-700'
              }`}
            >
              {/* Active Indicator */}
              {activeTab === stat.id && (
                <motion.div 
                  layoutId="activeIndicator"
                  className="absolute left-0 top-0 w-1 h-full bg-blue-500"
                />
              )}
              
              <div className={`
                relative p-6 transition-all duration-200
                ${activeTab === stat.id 
                  ? 'bg-white dark:bg-darkbg-800' 
                  : 'bg-grayteck-50 dark:bg-darkbg-700 hover:bg-white dark:hover:bg-darkbg-800'
                }
              `}>
                {/* Card Content */}
                <div className="flex items-center justify-between">
                  <div>
                    <p className={`text-sm font-medium mb-1 transition-colors
                      ${activeTab === stat.id 
                        ? 'text-grayteck-900 dark:text-grayteck-100' 
                        : 'text-grayteck-600 dark:text-grayteck-400'
                      }`}>
                      {stat.title}
                    </p>
                    <p className="text-3xl font-bold text-grayteck-900 dark:text-grayteck-100">
                      {stat.count}
                    </p>
                  </div>
                  
                  {/* Icon Container */}
                  <div className={`
                    p-3 rounded-lg transition-colors
                    ${activeTab === stat.id
                      ? `bg-${stat.color}-100 dark:bg-${stat.color}-900/30`
                      : 'bg-white dark:bg-darkbg-600 group-hover:bg-grayteck-100 dark:group-hover:bg-darkbg-700'
                    }
                  `}>
                    <stat.icon className={`
                      w-6 h-6 transition-colors
                      ${activeTab === stat.id
                        ? `text-${stat.color}-600 dark:text-${stat.color}-400`
                        : 'text-grayteck-400 dark:text-grayteck-500'
                      }
                    `} />
                  </div>
                </div>

                {/* Click Indicator */}
                <div className="absolute bottom-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity">
                  <span className="text-xs text-grayteck-400 dark:text-grayteck-500">
                    Click to filter
                  </span>
                </div>
              </div>
            </motion.button>
          ))}
        </div>

        {/* Documents Table */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="relative"
        >
          <div className="absolute -inset-0.5 bg-gradient-to-r from-grayteck-600 to-blue-500 rounded-lg blur opacity-30"></div>
          <div className="relative bg-white dark:bg-darkbg-800 rounded-lg shadow-lg overflow-hidden">
            <table className="min-w-full">
              <thead>
                <tr className="bg-grayteck-50 dark:bg-darkbg-700">
                  <th className="px-6 py-3 text-left text-xs font-medium text-grayteck-500 dark:text-grayteck-400 uppercase tracking-wider">
                    Title
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-grayteck-500 dark:text-grayteck-400 uppercase tracking-wider">
                    Created Date
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-grayteck-500 dark:text-grayteck-400 uppercase tracking-wider">
                    Signed At
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-grayteck-500 dark:text-grayteck-400 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 text-right text-xs font-medium text-grayteck-500 dark:text-grayteck-400 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-grayteck-200 dark:divide-grayteck-700">
                {documents.map((doc, index) => (
                  <motion.tr
                    key={doc.id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className="hover:bg-grayteck-50 dark:hover:bg-darkbg-700 transition-colors duration-200"
                  >
                    <td className="px-6 py-4 text-sm text-grayteck-900 dark:text-grayteck-100">
                      <div className="flex items-center">
                        <FileSignature className="w-5 h-5 text-grayteck-400 mr-3" />
                        {doc.title}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-grayteck-500 dark:text-grayteck-400">
                      {doc.createdDate}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-grayteck-500 dark:text-grayteck-400">
                      {doc.signedAt || '-'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-3 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(doc.status)}`}>
                        {doc.status === 'pending_signature' ? 'Pending Signature' : 'Signed'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        className="text-grayteck-600 dark:text-grayteck-400 hover:text-blue-500 mr-4 transition-colors"
                      >
                        <Download className="w-5 h-5" />
                      </motion.button>
                      <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        className="text-grayteck-600 dark:text-grayteck-400 hover:text-blue-500 transition-colors"
                      >
                        <Eye className="w-5 h-5" />
                      </motion.button>
                    </td>
                  </motion.tr>
                ))}
              </tbody>
            </table>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default ClientPortalDashboard;