import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Search, X } from 'lucide-react';

const AnimatedSearchBar = ({ onChange }) => {  // Changed from onSearch to onChange
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const handleExpandToggle = () => {
    setIsExpanded(true);
  };

  const handleClose = () => {
    setIsExpanded(false);
    setSearchValue('');
    onChange({ target: { value: '' } }); // Modified to match event structure
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    onChange(e); // Pass the entire event object
  };

  return (
    <div className="relative h-10 flex items-center">
      <div className="absolute left-0 top-1/2 -translate-y-1/2">
        <AnimatePresence initial={false} mode="wait">
          {!isExpanded ? (
            <motion.button
              key="search-icon"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleExpandToggle}
              className="p-2 rounded-md border shadow-sm hover:shadow-md transition-all duration-200 
                        bg-white dark:bg-darkbg-700 border-grayteck-200 dark:border-grayteck-600"
            >
              <Search className="w-5 h-5 text-grayteck-600 dark:text-grayteck-300" />
            </motion.button>
          ) : (
            <motion.div
              key="search-bar"
              initial={{ width: 40, opacity: 0 }}
              animate={{ 
                width: 300,
                opacity: 1,
                transition: {
                  width: {
                    type: "spring",
                    stiffness: 200,
                    damping: 25
                  },
                  opacity: { duration: 0.2 }
                }
              }}
              exit={{ 
                width: 40,
                opacity: 0,
                transition: {
                  width: {
                    type: "spring",
                    stiffness: 200,
                    damping: 25
                  },
                  opacity: { duration: 0.2 }
                }
              }}
              className="flex items-center rounded-md border shadow-md origin-left
                        bg-white dark:bg-darkbg-700 border-grayteck-200 dark:border-grayteck-600"
            >
              <div className="flex-grow flex items-center px-3 py-2">
                <Search className="w-5 h-5 mr-2 flex-shrink-0 text-grayteck-500 dark:text-grayteck-400" />
                <input
                  type="text"
                  value={searchValue}
                  onChange={handleSearch}
                  placeholder="Search documents..."
                  className="w-full bg-transparent focus:outline-none
                           text-grayteck-900 dark:text-grayteck-100 
                           placeholder-grayteck-400 dark:placeholder-grayteck-500"
                  autoFocus
                />
                <motion.button
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={handleClose}
                  className="ml-2 p-1 rounded-full flex-shrink-0 transition-colors duration-200
                           hover:bg-grayteck-100 dark:hover:bg-darkbg-600"
                >
                  <X className="w-4 h-4 text-grayteck-500 dark:text-grayteck-400" />
                </motion.button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className="w-[300px] h-[40px] opacity-0 pointer-events-none">
        {/* Placeholder */}
      </div>
    </div>
  );
};

export default AnimatedSearchBar;