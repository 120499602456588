// src/components/InvoiceFormModal.js
import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  AlertCircle,
  Plus,
  X,
  Trash2,
  DollarSign,
  CalendarClock,
  Settings,
  ArrowLeft,
  CreditCard,
  DollarSign as CashApp,
  BadgeDollarSign as PayPal,
  Building as Bank,
} from "lucide-react";
import { useAvailableServices } from "../hooks/useServices";

const InvoiceFormModal = ({
  isOpen,
  onClose,
  client,
  onSubmit,
  initialData = null,
}) => {
  const [items, setItems] = useState(initialData?.items || []);
  const [dueDate, setDueDate] = useState(initialData?.due_date || "");
  const [nextBillingDate, setNextBillingDate] = useState(
    initialData?.next_billing_date || ""
  );
  const [notes, setNotes] = useState(initialData?.notes || "");
  const [invoiceType, setInvoiceType] = useState(
    initialData?.is_recurring ? "recurring" : "one_time"
  );
  const [serviceCode, setServiceCode] = useState(
    initialData?.service_code || ""
  );
  const [isDueOnReceipt, setIsDueOnReceipt] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const wasPublished =
    initialData?.status === "pending" ||
    Boolean(initialData?.document_id && initialData?.shareable_link);

  // Payment options state
  const [paymentOptions, setPaymentOptions] = useState({
    payment_terms_enabled:
      initialData?.payment_options?.payment_terms_enabled ?? true,
    late_fees_enabled: initialData?.payment_options?.late_fees_enabled ?? true,
    payment_methods: {
      credit_card:
        initialData?.payment_options?.payment_methods?.credit_card ?? false,
      cash_app:
        initialData?.payment_options?.payment_methods?.cash_app ?? false,
      paypal: initialData?.payment_options?.payment_methods?.paypal ?? false,
      bank_transfer:
        initialData?.payment_options?.payment_methods?.bank_transfer ?? false,
    },
    // Default values for payment details
    cash_app_id: initialData?.payment_options?.cash_app_id || "$grayteck",
    paypal_email:
      initialData?.payment_options?.paypal_email ||
      "contact@graytecknologies.com",
    bank_email:
      initialData?.payment_options?.bank_email || "cjames@graytecknologies.com",
  });

  // Custom invoice settings with defaults
  const [invoiceSettings, setInvoiceSettings] = useState({
    template: initialData?.template || "standard",
    logoPosition: initialData?.logoPosition || "top",
    showTaxId: initialData?.showTaxId ?? true,
    showItemNumbers: initialData?.showItemNumbers ?? true,
    accentColor: initialData?.accentColor || "#3056D3",
    footerText: initialData?.footerText || "",
    paymentInstructions: initialData?.paymentInstructions || "",
    currency: initialData?.currency || "USD",
    taxLabel: initialData?.taxLabel || "Tax",
    taxRate: initialData?.tax_rate || 10, // Default 10% tax rate
    discount_percentage: initialData?.discount_percentage || 0, // Percentage discount (0-100)
  });

  const {
    data: availableServices,
    isLoading: servicesLoading,
    isError: servicesError,
  } = useAvailableServices();

  const defaultSubscriptionSettings = {
    frequency: "monthly",
    start_date: "",
    trial_end_date: null,
    auto_collection: true,
  };

  const defaultRecurringOptions = {
    frequency: "monthly",
    interval: 1,
    start_date: "",
    end_date: undefined,
    active: true,
  };

  const [subscriptionSettings, setSubscriptionSettings] = useState(
    initialData?.subscription_settings || defaultSubscriptionSettings
  );

  const [recurringOptions, setRecurringOptions] = useState(
    initialData?.recurring_settings || defaultRecurringOptions
  );

  // Validation state
  const [validationErrors, setValidationErrors] = useState({
    serviceCode: false,
    dueDate: false,
    items: false,
    subscription: false,
    recurring: false,
    paymentMethods: false,
    messages: {}, // For storing specific error messages
  });

  const validateForm = () => {
    const errors = {
      serviceCode: !serviceCode,
      dueDate: !isDueOnReceipt && !dueDate, // Only require due date if not due on receipt
      items:
        items.length === 0 ||
        items.some(
          (item) => !item.description || !item.quantity || !item.unit_price
        ),
      subscription: false,
      recurring: false,
      paymentMethods: false,
      messages: {},
    };

    // Add specific error messages
    if (errors.serviceCode) {
      errors.messages.serviceCode = "Please select a service";
    }
    if (errors.dueDate) {
      errors.messages.dueDate = "Please select a due date";
    }
    if (errors.items) {
      errors.messages.items =
        "Please add at least one item with complete details";
    }

    // Type-specific validation
    if (invoiceType === "subscription") {
      if (!subscriptionSettings?.start_date) {
        errors.subscription = true;
        errors.messages.subscription = "Please set a subscription start date";
      }
      if (
        subscriptionSettings?.trial_end_date &&
        new Date(subscriptionSettings.trial_end_date) <=
          new Date(subscriptionSettings.start_date)
      ) {
        errors.subscription = true;
        errors.messages.subscription_trial =
          "Trial end date must be after start date";
      }
    }

    if (invoiceType === "recurring") {
      if (!recurringOptions?.start_date) {
        errors.recurring = true;
        errors.messages.recurring = "Please set a recurring start date";
      }
      if (
        recurringOptions?.end_date &&
        new Date(recurringOptions.end_date) <=
          new Date(recurringOptions.start_date)
      ) {
        errors.recurring = true;
        errors.messages.recurring_end = "End date must be after start date";
      }
    }

    // Payment method validation
    if (
      paymentOptions.payment_methods.cash_app &&
      !paymentOptions.cash_app_id.includes("$")
    ) {
      errors.paymentMethods = true;
      errors.messages.cash_app = "Cash App ID must include a $ sign";
    }

    if (
      paymentOptions.payment_methods.paypal &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(paymentOptions.paypal_email)
    ) {
      errors.paymentMethods = true;
      errors.messages.paypal = "Please enter a valid PayPal email address";
    }

    if (
      paymentOptions.payment_methods.bank_transfer &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(paymentOptions.bank_email)
    ) {
      errors.paymentMethods = true;
      errors.messages.bank = "Please enter a valid bank email address";
    }

    setValidationErrors(errors);

    // Return true if no errors
    return !Object.values({ ...errors, messages: undefined }).some(
      (error) => error
    );
  };

  useEffect(() => {
    if (initialData) {
      // Set items and basic fields
      setItems(initialData.items || []);
      setNotes(initialData.notes || "");
      setServiceCode(initialData.service_code || "");
      setInvoiceType(initialData.invoice_type || "one_time");

      // Handle due date - if it's falsy, set isDueOnReceipt to true
      if (!initialData.due_date) {
        setIsDueOnReceipt(true);
        setDueDate("");
      } else {
        setIsDueOnReceipt(false);
        // Convert ISO string to YYYY-MM-DD format
        const dueDateTime = new Date(initialData.due_date)
          .toISOString()
          .split("T")[0];
        setDueDate(dueDateTime);
      }

      // Initialize payment options
      if (initialData.payment_options) {
        setPaymentOptions({
          payment_terms_enabled:
            initialData.payment_options.payment_terms_enabled ?? true,
          late_fees_enabled:
            initialData.payment_options.late_fees_enabled ?? true,
          payment_methods: {
            credit_card:
              initialData.payment_options.payment_methods?.credit_card ?? false,
            cash_app:
              initialData.payment_options.payment_methods?.cash_app ?? false,
            paypal:
              initialData.payment_options.payment_methods?.paypal ?? false,
            bank_transfer:
              initialData.payment_options.payment_methods?.bank_transfer ??
              false,
          },
          cash_app_id: initialData.payment_options.cash_app_id || "$grayteck",
          paypal_email:
            initialData.payment_options.paypal_email ||
            "contact@graytecknologies.com",
          bank_email:
            initialData.payment_options.bank_email ||
            "cjames@graytecknologies.com",
        });
      }

      // Handle type-specific settings
      switch (initialData.invoice_type) {
        case "subscription":
          if (initialData.subscription_settings) {
            setSubscriptionSettings({
              frequency:
                initialData.subscription_settings.frequency || "monthly",
              start_date: initialData.subscription_settings.start_date
                ? new Date(initialData.subscription_settings.start_date)
                    .toISOString()
                    .split("T")[0]
                : "",
              trial_end_date: initialData.subscription_settings.trial_end_date
                ? new Date(initialData.subscription_settings.trial_end_date)
                    .toISOString()
                    .split("T")[0]
                : null,
              auto_collection:
                initialData.subscription_settings.auto_collection ?? true,
            });
          }
          break;

        case "recurring":
          if (initialData.recurring_settings) {
            setRecurringOptions({
              frequency: initialData.recurring_settings.frequency || "monthly",
              interval: initialData.recurring_settings.interval || 1,
              start_date: initialData.recurring_settings.start_date
                ? new Date(initialData.recurring_settings.start_date)
                    .toISOString()
                    .split("T")[0]
                : "",
              end_date: initialData.recurring_settings.end_date
                ? new Date(initialData.recurring_settings.end_date)
                    .toISOString()
                    .split("T")[0]
                : undefined,
              active: initialData.recurring_settings.active ?? true,
            });

            // Handle next billing date
            if (initialData.recurring_settings.next_invoice_date) {
              setNextBillingDate(
                new Date(initialData.recurring_settings.next_invoice_date)
              );
            }
          }
          break;
      }
    }
  }, [initialData]);

  // Update credit card payment method when template changes
  useEffect(() => {
    // When template changes, update credit card payment method (it's only available for Stripe)
    setPaymentOptions((prev) => ({
      ...prev,
      payment_methods: {
        ...prev.payment_methods,
        credit_card: invoiceSettings.template === "stripe",
      },
    }));
  }, [invoiceSettings.template]);

  const titleText = initialData ? "Edit Invoice" : "Create New Invoice";

  // Invoice Settings UI Component - Use React.memo to prevent unnecessary re-renders
  const InvoiceSettingsUI = React.memo(() => (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-medium text-grayteck-900 dark:text-grayteck-100">
          Invoice Customization
        </h3>
        <button
          type="button"
          onClick={() => setShowSettings(false)}
          className="flex items-center text-sm font-medium text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300"
        >
          <ArrowLeft className="w-4 h-4 mr-1" /> Back to Invoice
        </button>
      </div>

      <div className="border-t border-grayteck-200 dark:border-grayteck-700 pt-4">
        <h4 className="font-medium text-grayteck-700 dark:text-grayteck-300 mb-3">
          Template & Appearance
        </h4>
        <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-4">
          <div>
            <label
              htmlFor="template"
              className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300"
            >
              Invoice Template
            </label>
            <select
              id="template"
              name="template"
              value={invoiceSettings.template}
              onChange={(e) =>
                setInvoiceSettings({
                  ...invoiceSettings,
                  template: e.target.value,
                })
              }
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base bg-white dark:bg-darkbg-700 border-grayteck-300 dark:border-grayteck-600 text-grayteck-900 dark:text-grayteck-100 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            >
              <option value="standard">Standard (PDF only)</option>
              <option value="stripe">Stripe (with payment elements)</option>
            </select>
            <p className="mt-1 text-xs text-grayteck-500 dark:text-grayteck-400">
              {invoiceSettings.template === "standard"
                ? "Standard template generates a PDF invoice without online payment options."
                : "Stripe template includes payment elements for clients to pay online directly."}
            </p>

            {invoiceSettings.template === "stripe" && (
              <div className="mt-3 p-3 bg-blue-50 dark:bg-blue-900/20 rounded border border-blue-200 dark:border-blue-800">
                <h5 className="text-sm font-medium text-blue-700 dark:text-blue-300 mb-1">
                  Stripe Payment Integration
                </h5>
                <p className="text-xs text-blue-600 dark:text-blue-400">
                  This invoice will include integrated Stripe payment elements,
                  allowing clients to pay directly through the invoice.
                </p>
              </div>
            )}
          </div>

          <div>
            <label
              htmlFor="logoPosition"
              className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300"
            >
              Logo Position
            </label>
            <select
              id="logoPosition"
              name="logoPosition"
              value={invoiceSettings.logoPosition}
              onChange={(e) =>
                setInvoiceSettings({
                  ...invoiceSettings,
                  logoPosition: e.target.value,
                })
              }
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base bg-white dark:bg-darkbg-700 border-grayteck-300 dark:border-grayteck-600 text-grayteck-900 dark:text-grayteck-100 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            >
              <option value="top">Top</option>
              <option value="left">Left Side</option>
              <option value="right">Right Side</option>
              <option value="none">No Logo</option>
            </select>
          </div>

          <div>
            <label
              htmlFor="accentColor"
              className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300"
            >
              Accent Color
            </label>
            <div className="mt-1 flex items-center">
              <input
                type="color"
                id="accentColor"
                name="accentColor"
                value={invoiceSettings.accentColor}
                onChange={(e) =>
                  setInvoiceSettings({
                    ...invoiceSettings,
                    accentColor: e.target.value,
                  })
                }
                className="h-8 w-8 rounded-md border border-grayteck-300 dark:border-grayteck-600 cursor-pointer"
              />
              <input
                type="text"
                defaultValue={invoiceSettings.accentColor}
                onBlur={(e) =>
                  setInvoiceSettings({
                    ...invoiceSettings,
                    accentColor: e.target.value,
                  })
                }
                className="ml-2 flex-1 rounded-md border border-grayteck-300 dark:border-grayteck-600 bg-white dark:bg-darkbg-700 text-grayteck-900 dark:text-grayteck-100 px-3 py-2 text-sm"
                placeholder="#000000"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="currency"
              className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300"
            >
              Currency
            </label>
            <select
              id="currency"
              name="currency"
              value={invoiceSettings.currency}
              onChange={(e) =>
                setInvoiceSettings({
                  ...invoiceSettings,
                  currency: e.target.value,
                })
              }
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base bg-white dark:bg-darkbg-700 border-grayteck-300 dark:border-grayteck-600 text-grayteck-900 dark:text-grayteck-100 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            >
              <option value="USD">USD ($)</option>
              <option value="EUR">EUR (€)</option>
              <option value="GBP">GBP (£)</option>
              <option value="CAD">CAD ($)</option>
              <option value="AUD">AUD ($)</option>
            </select>
          </div>
        </div>
      </div>

      <div className="border-t border-grayteck-200 dark:border-grayteck-700 pt-4">
        <h4 className="font-medium text-grayteck-700 dark:text-grayteck-300 mb-3">
          Content Options
        </h4>
        <div className="space-y-4">
          <div className="flex items-center">
            <input
              id="showTaxId"
              name="showTaxId"
              type="checkbox"
              checked={invoiceSettings.showTaxId}
              onChange={(e) =>
                setInvoiceSettings({
                  ...invoiceSettings,
                  showTaxId: e.target.checked,
                })
              }
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-grayteck-300 dark:border-grayteck-600 rounded"
            />
            <label
              htmlFor="showTaxId"
              className="ml-2 block text-sm text-grayteck-700 dark:text-grayteck-300"
            >
              Show Tax ID/Business Number
            </label>
          </div>

          <div className="flex items-center">
            <input
              id="showItemNumbers"
              name="showItemNumbers"
              type="checkbox"
              checked={invoiceSettings.showItemNumbers}
              onChange={(e) =>
                setInvoiceSettings({
                  ...invoiceSettings,
                  showItemNumbers: e.target.checked,
                })
              }
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-grayteck-300 dark:border-grayteck-600 rounded"
            />
            <label
              htmlFor="showItemNumbers"
              className="ml-2 block text-sm text-grayteck-700 dark:text-grayteck-300"
            >
              Show Item Numbers
            </label>
          </div>

          <div>
            <label
              htmlFor="taxLabel"
              className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300"
            >
              Tax Label
            </label>
            <input
              type="text"
              id="taxLabel"
              name="taxLabel"
              defaultValue={invoiceSettings.taxLabel}
              onBlur={(e) =>
                setInvoiceSettings({
                  ...invoiceSettings,
                  taxLabel: e.target.value,
                })
              }
              className="mt-1 block w-full shadow-sm sm:text-sm bg-white dark:bg-darkbg-700 border-grayteck-300 dark:border-grayteck-600 text-grayteck-900 dark:text-grayteck-100 rounded-md"
              placeholder="Tax"
            />
          </div>

          <div>
            <label
              htmlFor="taxRate"
              className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300"
            >
              Tax Rate (%)
            </label>
            <input
              type="number"
              id="taxRate"
              name="taxRate"
              min="0"
              max="100"
              step="0.01"
              defaultValue={invoiceSettings.taxRate}
              onBlur={(e) =>
                setInvoiceSettings({
                  ...invoiceSettings,
                  taxRate: parseFloat(e.target.value) || 0,
                })
              }
              className="mt-1 block w-full shadow-sm sm:text-sm bg-white dark:bg-darkbg-700 border-grayteck-300 dark:border-grayteck-600 text-grayteck-900 dark:text-grayteck-100 rounded-md"
              placeholder="10"
            />
          </div>
        </div>
      </div>

      <div className="border-t border-grayteck-200 dark:border-grayteck-700 pt-4">
        <h4 className="font-medium text-grayteck-700 dark:text-grayteck-300 mb-3">
          Discount Options
        </h4>
        <div className="space-y-4">
          <div>
            <label
              htmlFor="discount_percentage"
              className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300"
            >
              Discount Percentage (%)
            </label>
            <input
              type="number"
              id="discount_percentage"
              name="discount_percentage"
              min="0"
              max="100"
              step="0.1"
              defaultValue={invoiceSettings.discount_percentage}
              onBlur={(e) =>
                setInvoiceSettings({
                  ...invoiceSettings,
                  discount_percentage: parseFloat(e.target.value) || 0,
                })
              }
              className="mt-1 block w-full shadow-sm sm:text-sm bg-white dark:bg-darkbg-700 border-grayteck-300 dark:border-grayteck-600 text-grayteck-900 dark:text-grayteck-100 rounded-md"
              placeholder="0"
            />
            <p className="mt-1 text-xs text-grayteck-500 dark:text-grayteck-400">
              Enter a value between 0-100. The discount amount will be
              calculated automatically.
            </p>
          </div>
        </div>
      </div>

      <div className="border-t border-grayteck-200 dark:border-grayteck-700 pt-4">
        <h4 className="font-medium text-grayteck-700 dark:text-grayteck-300 mb-3">
          Additional Text
        </h4>
        <div className="space-y-4">
          <div>
            <label
              htmlFor="footerText"
              className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300"
            >
              Footer Text
            </label>
            <textarea
              id="footerText"
              name="footerText"
              rows="2"
              defaultValue={invoiceSettings.footerText}
              onBlur={(e) =>
                setInvoiceSettings({
                  ...invoiceSettings,
                  footerText: e.target.value,
                })
              }
              className="mt-1 block w-full shadow-sm sm:text-sm border bg-white dark:bg-darkbg-700 border-grayteck-300 dark:border-grayteck-600 text-grayteck-900 dark:text-grayteck-100 rounded-md"
              placeholder="Thank you for your business"
            />
          </div>

          <div>
            <label
              htmlFor="paymentInstructions"
              className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300"
            >
              Payment Instructions
            </label>
            <textarea
              id="paymentInstructions"
              name="paymentInstructions"
              rows="3"
              defaultValue={invoiceSettings.paymentInstructions}
              onBlur={(e) =>
                setInvoiceSettings({
                  ...invoiceSettings,
                  paymentInstructions: e.target.value,
                })
              }
              className="mt-1 block w-full shadow-sm sm:text-sm border bg-white dark:bg-darkbg-700 border-grayteck-300 dark:border-grayteck-600 text-grayteck-900 dark:text-grayteck-100 rounded-md"
              placeholder="Payment details or instructions for client"
            />
          </div>
        </div>
      </div>

      <div className="border-t border-grayteck-200 dark:border-grayteck-700 pt-4">
        <h4 className="font-medium text-grayteck-700 dark:text-grayteck-300 mb-3">
          Payment Options
        </h4>

        {/* Payment Terms Toggles */}
        <div className="space-y-4">
          <div className="flex items-center">
            <input
              id="payment_terms_enabled"
              name="payment_terms_enabled"
              type="checkbox"
              checked={paymentOptions.payment_terms_enabled}
              onChange={(e) =>
                setPaymentOptions({
                  ...paymentOptions,
                  payment_terms_enabled: e.target.checked,
                })
              }
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-grayteck-300 dark:border-grayteck-600 rounded"
            />
            <label
              htmlFor="payment_terms_enabled"
              className="ml-2 block text-sm text-grayteck-700 dark:text-grayteck-300"
            >
              Include Payment Terms Section
            </label>
          </div>

          <div className="flex items-center">
            <input
              id="late_fees_enabled"
              name="late_fees_enabled"
              type="checkbox"
              checked={paymentOptions.late_fees_enabled}
              onChange={(e) =>
                setPaymentOptions({
                  ...paymentOptions,
                  late_fees_enabled: e.target.checked,
                })
              }
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-grayteck-300 dark:border-grayteck-600 rounded"
            />
            <label
              htmlFor="late_fees_enabled"
              className="ml-2 block text-sm text-grayteck-700 dark:text-grayteck-300"
            >
              Include Late Fees Policy
            </label>
          </div>
        </div>

        {/* Payment Methods Section */}
        <div className="mt-6">
          <h5 className="text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-3">
            Accepted Payment Methods
          </h5>

          <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 gap-x-4">
            {/* Credit Card */}
            <div className="space-y-2">
              <div className="flex items-center">
                <input
                  id="credit_card"
                  name="credit_card"
                  type="checkbox"
                  checked={paymentOptions.payment_methods.credit_card}
                  disabled={invoiceSettings.template !== "stripe"}
                  onChange={(e) =>
                    setPaymentOptions({
                      ...paymentOptions,
                      payment_methods: {
                        ...paymentOptions.payment_methods,
                        credit_card: e.target.checked,
                      },
                    })
                  }
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-grayteck-300 dark:border-grayteck-600 rounded"
                />
                <label
                  htmlFor="credit_card"
                  className="ml-2 flex items-center text-sm text-grayteck-700 dark:text-grayteck-300"
                >
                  <CreditCard className="w-4 h-4 mr-1" />
                  Credit/Debit Card
                </label>
              </div>
              <p className="text-xs text-grayteck-500 dark:text-grayteck-400 ml-6">
                Requires Stripe template
              </p>
            </div>

            {/* Cash App */}
            <div className="space-y-2">
              <div className="flex items-center">
                <input
                  id="cash_app"
                  name="cash_app"
                  type="checkbox"
                  checked={paymentOptions.payment_methods.cash_app}
                  onChange={(e) =>
                    setPaymentOptions({
                      ...paymentOptions,
                      payment_methods: {
                        ...paymentOptions.payment_methods,
                        cash_app: e.target.checked,
                      },
                    })
                  }
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-grayteck-300 dark:border-grayteck-600 rounded"
                />
                <label
                  htmlFor="cash_app"
                  className="ml-2 flex items-center text-sm text-grayteck-700 dark:text-grayteck-300"
                >
                  <CashApp className="w-4 h-4 mr-1" />
                  Cash App
                </label>
              </div>
              {paymentOptions.payment_methods.cash_app && (
                <div>
                  <input
                    type="text"
                    value={paymentOptions.cash_app_id}
                    onChange={(e) =>
                      setPaymentOptions({
                        ...paymentOptions,
                        cash_app_id: e.target.value,
                      })
                    }
                    placeholder="$grayteck"
                    className="w-full mt-1 text-sm p-2 border rounded-md bg-white dark:bg-darkbg-700 border-grayteck-300 dark:border-grayteck-600 text-grayteck-900 dark:text-grayteck-100"
                  />
                  {validationErrors.paymentMethods &&
                    validationErrors.messages.cash_app && (
                      <p className="mt-1 text-xs text-red-500">
                        {validationErrors.messages.cash_app}
                      </p>
                    )}
                </div>
              )}
            </div>

            {/* PayPal */}
            <div className="space-y-2">
              <div className="flex items-center">
                <input
                  id="paypal"
                  name="paypal"
                  type="checkbox"
                  checked={paymentOptions.payment_methods.paypal}
                  onChange={(e) =>
                    setPaymentOptions({
                      ...paymentOptions,
                      payment_methods: {
                        ...paymentOptions.payment_methods,
                        paypal: e.target.checked,
                      },
                    })
                  }
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-grayteck-300 dark:border-grayteck-600 rounded"
                />
                <label
                  htmlFor="paypal"
                  className="ml-2 flex items-center text-sm text-grayteck-700 dark:text-grayteck-300"
                >
                  <PayPal className="w-4 h-4 mr-1" />
                  PayPal
                </label>
              </div>
              {paymentOptions.payment_methods.paypal && (
                <div>
                  <input
                    type="email"
                    value={paymentOptions.paypal_email}
                    onChange={(e) =>
                      setPaymentOptions({
                        ...paymentOptions,
                        paypal_email: e.target.value,
                      })
                    }
                    placeholder="contact@graytecknologies.com"
                    className="w-full mt-1 text-sm p-2 border rounded-md bg-white dark:bg-darkbg-700 border-grayteck-300 dark:border-grayteck-600 text-grayteck-900 dark:text-grayteck-100"
                  />
                  {validationErrors.paymentMethods &&
                    validationErrors.messages.paypal && (
                      <p className="mt-1 text-xs text-red-500">
                        {validationErrors.messages.paypal}
                      </p>
                    )}
                </div>
              )}
            </div>

            {/* Bank Transfer */}
            <div className="space-y-2">
              <div className="flex items-center">
                <input
                  id="bank_transfer"
                  name="bank_transfer"
                  type="checkbox"
                  checked={paymentOptions.payment_methods.bank_transfer}
                  onChange={(e) =>
                    setPaymentOptions({
                      ...paymentOptions,
                      payment_methods: {
                        ...paymentOptions.payment_methods,
                        bank_transfer: e.target.checked,
                      },
                    })
                  }
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-grayteck-300 dark:border-grayteck-600 rounded"
                />
                <label
                  htmlFor="bank_transfer"
                  className="ml-2 flex items-center text-sm text-grayteck-700 dark:text-grayteck-300"
                >
                  <Bank className="w-4 h-4 mr-1" />
                  Bank Transfer/Zelle
                </label>
              </div>
              {paymentOptions.payment_methods.bank_transfer && (
                <div>
                  <input
                    type="email"
                    value={paymentOptions.bank_email}
                    onChange={(e) =>
                      setPaymentOptions({
                        ...paymentOptions,
                        bank_email: e.target.value,
                      })
                    }
                    placeholder="cjames@graytecknologies.com"
                    className="w-full mt-1 text-sm p-2 border rounded-md bg-white dark:bg-darkbg-700 border-grayteck-300 dark:border-grayteck-600 text-grayteck-900 dark:text-grayteck-100"
                  />
                  {validationErrors.paymentMethods &&
                    validationErrors.messages.bank && (
                      <p className="mt-1 text-xs text-red-500">
                        {validationErrors.messages.bank}
                      </p>
                    )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ));

  //   useEffect(() => {
  //     const nextDate = new Date(initialData?.recurring_settings?.next_invoice_date) || calculateNextInvoiceDate();
  //     // console.log(typeof initialData?.recurring_settings?.next_invoice_date )
  //     if (nextDate) {
  //       setNextBillingDate(nextDate);
  //     } else {
  //       setNextBillingDate('');
  //     }
  //   }, [recurringOptions]);

  useEffect(() => {
    // Only calculate next billing date if there's a start date
    if (recurringOptions.start_date) {
      const nextDate = calculateNextInvoiceDate();
      // recurringOptions.start_date,
      // recurringOptions.frequency,
      // recurringOptions.interval
      if (nextDate) {
        setNextBillingDate(nextDate);
      }
    }
  }, [
    recurringOptions.start_date,
    recurringOptions.frequency,
    recurringOptions.interval,
  ]);

  const calculateNextInvoiceDate = () => {
    if (!recurringOptions.start_date) return null;

    const startDate = new Date(recurringOptions.start_date);
    let nextDate = new Date(startDate);

    switch (recurringOptions.frequency) {
      case "weekly":
        nextDate.setDate(startDate.getDate() + 7 * recurringOptions.interval);
        break;
      case "monthly":
        nextDate.setMonth(startDate.getMonth() + recurringOptions.interval);
        break;
      case "quarterly":
        nextDate.setMonth(startDate.getMonth() + 3 * recurringOptions.interval);
        break;
      case "annually":
        nextDate.setFullYear(
          startDate.getFullYear() + recurringOptions.interval
        );
        break;
      default:
        return null;
    }

    return nextDate;
  };

  const handleAddItem = () => {
    setItems([
      ...items,
      {
        description: "",
        quantity: undefined,
        unit_price: undefined,
        amount: undefined,
      },
    ]);

    // Clear items validation error when adding an item
    setValidationErrors((prev) => ({ ...prev, items: false }));
  };

  const handleRemoveItem = (index) => {
    setItems(items.filter((_, i) => i !== index));
  };

  const handleItemChange = (index, field, value) => {
    const newItems = [...items];
    newItems[index][field] = value;

    // Calculate amount
    if (field === "quantity" || field === "unit_price") {
      newItems[index].amount =
        newItems[index].quantity * newItems[index].unit_price;
    }

    setItems(newItems);
  };

  const calculateTotals = () => {
    const subtotal = items.reduce(
      (sum, item) => sum + item.quantity * item.unit_price,
      0
    );

    // Calculate discount amount based on percentage
    const discount_percentage = invoiceSettings.discount_percentage || 0;
    const discount_amount = (subtotal * discount_percentage) / 100;

    // Calculate taxable amount after discount
    const discountedSubtotal = subtotal - discount_amount;

    // Use the tax rate from settings (convert from percentage to decimal)
    const taxRate = invoiceSettings.taxRate / 100;
    const tax = discountedSubtotal * taxRate;
    const total = discountedSubtotal + tax;

    return {
      subtotal,
      discount_percentage,
      discount_amount,
      discountedSubtotal,
      tax,
      total,
      taxRate,
    };
  };

  const handleSubmit = () => {
    // Validate form before submission
    if (!validateForm()) {
      return;
    }

    const { subtotal, discount_percentage, tax, total } = calculateTotals();

    // Prepare payment options
    let finalPaymentOptions = {
      payment_terms_enabled: paymentOptions.payment_terms_enabled,
      late_fees_enabled: paymentOptions.late_fees_enabled,
      payment_methods: {
        credit_card: paymentOptions.payment_methods.credit_card,
        cash_app: paymentOptions.payment_methods.cash_app,
        paypal: paymentOptions.payment_methods.paypal,
        bank_transfer: paymentOptions.payment_methods.bank_transfer,
      },
    };

    // Add payment method details only if the corresponding method is enabled
    if (paymentOptions.payment_methods.cash_app) {
      finalPaymentOptions.cash_app_id =
        paymentOptions.cash_app_id || "$grayteck";
    }

    if (paymentOptions.payment_methods.paypal) {
      finalPaymentOptions.paypal_email =
        paymentOptions.paypal_email || "contact@graytecknologies.com";
    }

    if (paymentOptions.payment_methods.bank_transfer) {
      finalPaymentOptions.bank_email =
        paymentOptions.bank_email || "cjames@graytecknologies.com";
    }

    const baseInvoiceData = {
      client_id: client._id,
      service_code: serviceCode,
      items,
      due_date: dueDate,
      notes,
      subtotal,
      tax,
      total,
      invoice_type: invoiceType,
      // Include discount percentage (exactly as backend expects)
      discount_percentage: discount_percentage,
      // Include the custom invoice settings
      template: invoiceSettings.template,
      logoPosition: invoiceSettings.logoPosition,
      showTaxId: invoiceSettings.showTaxId,
      showItemNumbers: invoiceSettings.showItemNumbers,
      accentColor: invoiceSettings.accentColor,
      footerText: invoiceSettings.footerText,
      paymentInstructions: invoiceSettings.paymentInstructions,
      currency: invoiceSettings.currency,
      taxLabel: invoiceSettings.taxLabel,
      tax_rate: invoiceSettings.taxRate,
    };

    // Create the API request structure
    // Set is_stripe based on the template selection
    const is_stripe = invoiceSettings.template === "stripe";

    let invoiceData;

    switch (invoiceType) {
      case "subscription":
        invoiceData = {
          invoice: {
            ...baseInvoiceData,
            subscription_settings: subscriptionSettings,
          },
          is_stripe,
          payment_options: finalPaymentOptions,
        };
        break;
      case "recurring":
        let formattedNextBillingDate = null;
        if (nextBillingDate instanceof Date) {
          formattedNextBillingDate = nextBillingDate.toISOString();
        } else if (recurringOptions.start_date) {
          // Calculate next billing date if not already set
          const calculatedDate = calculateNextInvoiceDate();
          // recurringOptions.start_date,
          // recurringOptions.frequency,
          // recurringOptions.interval
          formattedNextBillingDate = calculatedDate?.toISOString();
        }

        invoiceData = {
          invoice: {
            ...baseInvoiceData,
            recurring_settings: {
              ...recurringOptions,
              next_invoice_date: nextBillingDate.toISOString(),
            },
          },
          is_stripe,
          payment_options: finalPaymentOptions,
        };
        break;
      default: // one_time
        invoiceData = {
          invoice: baseInvoiceData,
          is_stripe,
          payment_options: finalPaymentOptions,
        };
    }

    try {
      if (wasPublished) {
        const confirmed = window.confirm(
          "This invoice was previously published. Updating it will unpublish the invoice and you'll need to publish it again. Continue?"
        );
        if (!confirmed) {
          return;
        }
      }

      onSubmit(invoiceData);

      if (wasPublished) {
        // toast.info(
        //   'Invoice has been unpublished. You\'ll need to publish it again to share it.',
        //   { autoClose: 6000 }
        // );
      }
    } catch (error) {
      // toast.error(`Failed to ${initialData ? 'update' : 'create'} invoice`);
      console.error("Failed to submit invoice:", error);
    }
  };

  if (!isOpen) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4"
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        className="bg-white dark:bg-darkbg-800 rounded-lg p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto"
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-grayteck-900 dark:text-grayteck-100">
            {showSettings ? "Invoice Settings" : titleText}
          </h2>
          <div className="flex items-center space-x-2">
            {!showSettings && (
              <button
                onClick={() => setShowSettings(true)}
                type="button"
                className="flex items-center text-sm font-medium text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300"
              >
                <Settings className="w-4 h-4 mr-1" />
                Settings
              </button>
            )}
            <button
              onClick={onClose}
              className="text-grayteck-500 hover:text-grayteck-700 dark:text-grayteck-400 dark:hover:text-grayteck-200"
            >
              <X className="w-6 h-6" />
            </button>
          </div>
        </div>

        <AnimatePresence mode="wait">
          {showSettings ? (
            <motion.div
              key="settings"
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              transition={{ duration: 0.2 }}
            >
              <InvoiceSettingsUI />
            </motion.div>
          ) : (
            <motion.div
              key="form"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 20 }}
              transition={{ duration: 0.2 }}
              className="space-y-6"
            >
              {/* Service Selection */}
              <div className="mb-6">
                <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-1">
                  Service
                </label>
                <select
                  value={serviceCode}
                  onChange={(e) => {
                    setServiceCode(e.target.value);
                    // Clear service validation error when a service is selected
                    setValidationErrors((prev) => ({
                      ...prev,
                      serviceCode: false,
                    }));
                  }}
                  className={`w-full p-2 border rounded-md bg-white dark:bg-darkbg-700 
                        border-grayteck-300 dark:border-grayteck-600 
                        text-grayteck-900 dark:text-grayteck-100 focus:outline-none focus:ring-2 focus:ring-grayteck-500
                        ${
                          validationErrors.serviceCode
                            ? "border-red-500 focus:ring-red-500"
                            : "focus:ring-grayteck-500"
                        }`}
                >
                  <option value="" disabled>
                    Select a service
                  </option>
                  {availableServices &&
                    availableServices.map((service) => (
                      <option key={service.id} value={service.service_code}>
                        {service.service_code} - {service.name}
                      </option>
                    ))}
                </select>
                {validationErrors.serviceCode && (
                  <div className="flex items-center text-red-500 text-sm mt-1">
                    <AlertCircle className="w-4 h-4 mr-2" />
                    <span>Please select a service</span>
                  </div>
                )}
              </div>

              <div className="space-y-6">
                {/* Items Section */}
                <div>
                  <div className="flex justify-between items-center mb-4">
                    <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300">
                      Invoice Items
                    </label>
                    <button
                      type="button"
                      onClick={handleAddItem}
                      className="px-3 py-1 text-sm bg-grayteck-600 text-white rounded-md hover:bg-grayteck-700 
                         flex items-center space-x-2"
                    >
                      <Plus className="w-4 h-4" />
                      <span>Add Item</span>
                    </button>
                  </div>
                  {validationErrors.items && (
                    <div className="flex items-center text-red-500 text-sm mb-2">
                      <AlertCircle className="w-4 h-4 mr-2" />
                      <span>
                        Please add at least one item with complete details
                      </span>
                    </div>
                  )}

                  <div className="space-y-4">
                    {items.map((item, index) => (
                      <div
                        key={index}
                        className={`grid grid-cols-12 gap-4 items-center 
                        ${
                          items.length > 0 &&
                          (!item.description ||
                            !item.quantity ||
                            !item.unit_price)
                            ? "border-2 border-red-500 rounded-md p-2"
                            : ""
                        }`}
                      >
                        <div className="col-span-5">
                          <input
                            type="text"
                            value={item.description}
                            onChange={(e) =>
                              handleItemChange(
                                index,
                                "description",
                                e.target.value
                              )
                            }
                            placeholder="Description"
                            className="w-full p-2 border rounded-md bg-white dark:bg-darkbg-700 
                               border-grayteck-300 dark:border-grayteck-600 
                               text-grayteck-900 dark:text-grayteck-100"
                            aria-label={`Item ${index + 1} Description`}
                          />
                        </div>
                        <div className="col-span-2">
                          <input
                            type="number"
                            value={item.quantity}
                            onChange={(e) =>
                              handleItemChange(
                                index,
                                "quantity",
                                parseInt(e.target.value)
                              )
                            }
                            placeholder="Qty"
                            className="w-full p-2 border rounded-md bg-white dark:bg-darkbg-700 
                               border-grayteck-300 dark:border-grayteck-600 
                               text-grayteck-900 dark:text-grayteck-100"
                          />
                        </div>
                        <div className="col-span-2">
                          <input
                            type="number"
                            value={item.unit_price}
                            onChange={(e) =>
                              handleItemChange(
                                index,
                                "unit_price",
                                parseFloat(e.target.value) || 0
                              )
                            }
                            placeholder="0.00"
                            min="0"
                            step="0.01"
                            className="w-full p-2 border rounded-md bg-white dark:bg-darkbg-700 
                               border-grayteck-300 dark:border-grayteck-600 
                               text-grayteck-900 dark:text-grayteck-100"
                          />
                        </div>
                        <div className="col-span-2">
                          <input
                            type="number"
                            value={item.amount}
                            className="w-full p-2 border rounded-md bg-grayteck-50 dark:bg-darkbg-600
                               text-grayteck-900 dark:text-grayteck-100"
                            readOnly
                          />
                        </div>
                        <div className="col-span-1 flex justify-end">
                          <button
                            type="button"
                            onClick={() => handleRemoveItem(index)}
                            className="text-red-500 hover:text-red-700"
                          >
                            <Trash2 className="w-4 h-4" />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Invoice Type */}
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-1">
                      Invoice Type
                    </label>
                    <select
                      value={invoiceType}
                      onChange={(e) => {
                        setInvoiceType(e.target.value);
                        // Reset recurring-specific fields when switching types
                        //   if (e.target.value !== 'recurring') {
                        //     setRecurringOptions({
                        //       frequency: 'monthly',
                        //       interval: 1,
                        //       start_date: '',
                        //       end_date: ''
                        //     });
                        //   }
                        // Reset type-specific settings when changing types
                        if (e.target.value === "subscription") {
                          setRecurringOptions(defaultRecurringOptions);
                          setSubscriptionSettings(defaultSubscriptionSettings);
                        } else if (e.target.value === "recurring") {
                          setSubscriptionSettings(defaultSubscriptionSettings);
                          setRecurringOptions(defaultRecurringOptions);
                        } else {
                          // For one-time invoices
                          setSubscriptionSettings(defaultSubscriptionSettings);
                          setRecurringOptions(defaultRecurringOptions);
                        }
                      }}
                      className="w-full p-2 border rounded-md bg-white dark:bg-darkbg-700 
                         border-grayteck-300 dark:border-grayteck-600 
                         text-grayteck-900 dark:text-grayteck-100"
                    >
                      <option value="one_time">One-Time Invoice</option>
                      <option value="recurring">Recurring Invoice</option>
                      <option value="subscription">Subscription Invoice</option>
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-1">
                      Due Date
                    </label>
                    <input
                      type="date"
                      value={dueDate}
                      onChange={(e) => {
                        setDueDate(e.target.value);
                        setValidationErrors((prev) => ({
                          ...prev,
                          dueDate: false,
                        }));
                      }}
                      className={`w-full p-2 border rounded-md bg-white dark:bg-darkbg-700 
                border-grayteck-300 dark:border-grayteck-600 
                text-grayteck-900 dark:text-grayteck-100
                ${
                  validationErrors.dueDate
                    ? "border-red-500 focus:ring-red-500"
                    : "focus:ring-grayteck-500"
                }`}
                      disabled={isDueOnReceipt}
                    />
                    <div className="mt-2">
                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          checked={isDueOnReceipt}
                          onChange={(e) => {
                            setIsDueOnReceipt(e.target.checked);
                            if (e.target.checked) {
                              setDueDate(""); // Clear due date if due on receipt
                            }
                          }}
                          className="rounded border-grayteck-300 text-grayteck-600 focus:ring-grayteck-500"
                        />
                        <span className="ml-2 text-sm text-grayteck-600 dark:text-grayteck-400">
                          Due on Receipt
                        </span>
                      </label>
                    </div>
                    {validationErrors.dueDate && !isDueOnReceipt && (
                      <div className="flex items-center text-red-500 text-sm mt-1">
                        <AlertCircle className="w-4 h-4 mr-2" />
                        <span>
                          Please select a due date or mark as due on receipt
                        </span>
                      </div>
                    )}
                    {/* </div> */}
                  </div>
                </div>

                {invoiceType === "subscription" && (
                  <div className="space-y-4 p-4 bg-grayteck-50 dark:bg-darkbg-700 rounded-lg border border-grayteck-200 dark:border-grayteck-600">
                    <h3 className="text-sm font-medium text-grayteck-900 dark:text-grayteck-100">
                      Subscription Settings
                    </h3>

                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-1">
                          Billing Frequency
                        </label>
                        <select
                          value={subscriptionSettings.frequency}
                          onChange={(e) =>
                            setSubscriptionSettings((prev) => ({
                              ...prev,
                              frequency: e.target.value,
                            }))
                          }
                          className="w-full p-2 border rounded-md bg-white dark:bg-darkbg-700 
                            border-grayteck-300 dark:border-grayteck-600 
                            text-grayteck-900 dark:text-grayteck-100"
                        >
                          <option value="weekly">Weekly</option>
                          <option value="monthly">Monthly</option>
                          <option value="quarterly">Quarterly</option>
                          <option value="annually">Annually</option>
                        </select>
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-1">
                          Start Date
                        </label>
                        <input
                          type="date"
                          value={subscriptionSettings.start_date}
                          onChange={(e) =>
                            setSubscriptionSettings((prev) => ({
                              ...prev,
                              start_date: e.target.value,
                            }))
                          }
                          className="w-full p-2 border rounded-md bg-white dark:bg-darkbg-700 
                            border-grayteck-300 dark:border-grayteck-600 
                            text-grayteck-900 dark:text-grayteck-100"
                        />
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-1">
                          Trial End Date (Optional)
                        </label>
                        <input
                          type="date"
                          value={subscriptionSettings.trial_end_date || ""}
                          onChange={(e) =>
                            setSubscriptionSettings((prev) => ({
                              ...prev,
                              trial_end_date: e.target.value || null,
                            }))
                          }
                          className="w-full p-2 border rounded-md bg-white dark:bg-darkbg-700 
                            border-grayteck-300 dark:border-grayteck-600 
                            text-grayteck-900 dark:text-grayteck-100"
                        />
                      </div>
                    </div>

                    <div className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        id="auto_collection"
                        checked={subscriptionSettings.auto_collection}
                        onChange={(e) =>
                          setSubscriptionSettings((prev) => ({
                            ...prev,
                            auto_collection: e.target.checked,
                          }))
                        }
                        className="rounded border-grayteck-300 text-grayteck-600 focus:ring-grayteck-500"
                      />
                      <label
                        htmlFor="auto_collection"
                        className="text-sm text-grayteck-700 dark:text-grayteck-300"
                      >
                        Enable automatic collection
                      </label>
                    </div>
                    {validationErrors.subscription && (
                      <div className="mt-2 p-2 bg-red-50 dark:bg-red-900/20 rounded border border-red-200 dark:border-red-800">
                        <div className="flex items-center text-red-600 dark:text-red-400">
                          <AlertCircle className="w-4 h-4 mr-2" />
                          <span className="text-sm">
                            {validationErrors.messages.subscription}
                          </span>
                        </div>
                        {validationErrors.messages.subscription_trial && (
                          <div className="flex items-center text-red-600 dark:text-red-400 mt-1">
                            <AlertCircle className="w-4 h-4 mr-2" />
                            <span className="text-sm">
                              {validationErrors.messages.subscription_trial}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}

                {/* Recurring Invoice Options */}
                {invoiceType === "recurring" && (
                  <div className="space-y-4 p-4 bg-grayteck-50 dark:bg-darkbg-700 rounded-lg border border-grayteck-200 dark:border-grayteck-600">
                    <h3 className="text-sm font-medium text-grayteck-900 dark:text-grayteck-100">
                      Recurring Invoice Settings
                    </h3>

                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-1">
                          Billing Cycle
                        </label>
                        <select
                          value={recurringOptions.frequency}
                          onChange={(e) =>
                            setRecurringOptions((prev) => ({
                              ...prev,
                              frequency: e.target.value,
                            }))
                          }
                          className="w-full p-2 border rounded-md bg-white dark:bg-darkbg-700 
                             border-grayteck-300 dark:border-grayteck-600 
                             text-grayteck-900 dark:text-grayteck-100"
                        >
                          <option value="weekly">Weekly</option>
                          <option value="monthly">Monthly</option>
                          <option value="quarterly">Quarterly</option>
                          <option value="annually">Annually</option>
                        </select>
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-1">
                          First Invoice Date
                        </label>
                        <input
                          type="date"
                          value={recurringOptions.start_date}
                          onChange={(e) => {
                            const newStartDate = e.target.value;
                            setRecurringOptions((prev) => ({
                              ...prev,
                              start_date: newStartDate, // Ensure the key matches the state structure
                            }));
                            // calculateNextInvoiceDate(); // Call the method with the new start date
                          }}
                          //   onBlur={(e) => {
                          //     const newStartDate = e.target.value;
                          //     calculateNextInvoiceDate(newStartDate); // Call the method when the input loses focus
                          //   }}
                          className="w-full p-2 border rounded-md bg-white dark:bg-darkbg-700 
                             border-grayteck-300 dark:border-grayteck-600 
                             text-grayteck-900 dark:text-grayteck-100"
                        />
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-1">
                        End Date (Optional)
                      </label>
                      <input
                        type="date"
                        value={recurringOptions.end_date}
                        onChange={(e) =>
                          setRecurringOptions((prev) => ({
                            ...prev,
                            end_date: e.target.value,
                          }))
                        }
                        className="w-full p-2 border rounded-md bg-white dark:bg-darkbg-700 
                             border-grayteck-300 dark:border-grayteck-600 
                             text-grayteck-900 dark:text-grayteck-100"
                      />
                      <p className="mt-1 text-xs text-grayteck-500 dark:text-grayteck-400">
                        Leave blank for indefinite billing
                      </p>
                    </div>

                    <div className="flex items-center mt-2">
                      <CalendarClock className="w-4 h-4 text-grayteck-500 dark:text-grayteck-400 mr-2" />
                      <span className="text-sm text-grayteck-600 dark:text-grayteck-400">
                        {/* Next invoice will be generated: {calculateNextInvoiceDate()} */}
                        Next invoice will be generated:{" "}
                        {nextBillingDate
                          ? nextBillingDate.toLocaleDateString()
                          : "N/A"}
                        {/* Next invoice will be generated: {nextBillingDate} */}
                      </span>
                    </div>
                    {validationErrors.recurring && (
                      <div className="mt-2 p-2 bg-red-50 dark:bg-red-900/20 rounded border border-red-200 dark:border-red-800">
                        <div className="flex items-center text-red-600 dark:text-red-400">
                          <AlertCircle className="w-4 h-4 mr-2" />
                          <span className="text-sm">
                            {validationErrors.messages.recurring}
                          </span>
                        </div>
                        {validationErrors.messages.recurring_end && (
                          <div className="flex items-center text-red-600 dark:text-red-400 mt-1">
                            <AlertCircle className="w-4 h-4 mr-2" />
                            <span className="text-sm">
                              {validationErrors.messages.recurring_end}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}

                {/* Notes */}
                <div>
                  <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-1">
                    Notes
                  </label>
                  <textarea
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    rows={3}
                    className="w-full p-2 border rounded-md bg-white dark:bg-darkbg-700 
                       border-grayteck-300 dark:border-grayteck-600 
                       text-grayteck-900 dark:text-grayteck-100"
                    placeholder="Add any notes or special instructions..."
                  />
                </div>

                {/* Totals */}
                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between text-sm">
                    <span className="text-grayteck-600 dark:text-grayteck-400">
                      Subtotal:
                    </span>
                    <span className="text-grayteck-900 dark:text-grayteck-100">
                      ${calculateTotals().subtotal.toFixed(2)}
                    </span>
                  </div>

                  {/* Discount row - only show if has a value > 0 */}
                  {calculateTotals().discount_percentage > 0 && (
                    <div className="flex justify-between text-sm">
                      <span className="text-green-600 dark:text-green-400">
                        Discount ({calculateTotals().discount_percentage}%):
                      </span>
                      <span className="text-green-600 dark:text-green-400">
                        -${calculateTotals().discount_amount.toFixed(2)}
                      </span>
                    </div>
                  )}

                  {/* Show discounted subtotal if there's a discount */}
                  {calculateTotals().discount_percentage > 0 && (
                    <div className="flex justify-between text-sm">
                      <span className="text-grayteck-600 dark:text-grayteck-400">
                        Subtotal after discount:
                      </span>
                      <span className="text-grayteck-900 dark:text-grayteck-100">
                        ${calculateTotals().discountedSubtotal.toFixed(2)}
                      </span>
                    </div>
                  )}

                  <div className="flex justify-between text-sm">
                    <span className="text-grayteck-600 dark:text-grayteck-400">
                      {invoiceSettings.taxLabel} ({invoiceSettings.taxRate}%):
                    </span>
                    <span className="text-grayteck-900 dark:text-grayteck-100">
                      ${calculateTotals().tax.toFixed(2)}
                    </span>
                  </div>
                  <div className="flex justify-between text-base font-bold">
                    <span className="text-grayteck-900 dark:text-grayteck-100">
                      Total:
                    </span>
                    <span className="text-grayteck-900 dark:text-grayteck-100">
                      ${calculateTotals().total.toFixed(2)}
                    </span>
                  </div>
                </div>

                {/* Action Buttons */}
                <div className="flex justify-end space-x-4 mt-6">
                  <button
                    onClick={onClose}
                    className="px-4 py-2 text-grayteck-600 hover:text-grayteck-900 
                       dark:text-grayteck-400 dark:hover:text-grayteck-200"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleSubmit}
                    className="px-4 py-2 bg-grayteck-600 text-white rounded-md hover:bg-grayteck-700 
                       flex items-center space-x-2"
                  >
                    <DollarSign className="w-5 h-5" />
                    <span>{titleText}</span>
                  </button>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </motion.div>
  );
};

export default InvoiceFormModal;
