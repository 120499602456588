// hooks/useClientDocs.js

import { useQuery, useMutation, useQueryClient } from 'react-query';
import { 
  getMyDocuments, // API fetcher to get client's documents
  createDocument, 
  updateDocument, 
  deleteDocument, 
  publishDocument,
  unpublishDocument,
  downloadDocumentAsPDF,
  signDocument,
  getDocumentShareUrl
} from '../functions/api_fethcers'; // Ensure correct path
import { toast } from 'react-toastify';

/**
 * Custom hook to manage client's documents.
 * Handles fetching, creating, updating, deleting, publishing, unpublishing, downloading, and signing documents.
 */
export const useClientDocs = (excludeTypes = []) => {
  const queryClient = useQueryClient();

  // **1. Fetch My Documents**
  const {
    data: myDocuments,
    isLoading: isLoadingMyDocuments,
    isError: isErrorMyDocuments,
    error: myDocumentsError,
    refetch: refetchMyDocuments,
  } = useQuery(
    ['myDocuments', { excludeTypes }], // Include in query key
    () => getMyDocuments(excludeTypes),
    {
      staleTime: 300000, // 5 minutes
      cacheTime: 3600000, // 1 hour
      retry: 1,
      onError: (error) => {
        toast.error(error.message || 'Failed to fetch your documents');
      },
      onSuccess: (data) => {
        console.log('Fetched My Documents:', data);
      }
    }
  );

  
  const deriveStatus = (doc) => {
    if (doc.signed_at) {
      return 'signed';
    } else if (doc.signature_doc) {
      return 'pending_signature';
    } else {
      return 'draft'; // You can customize this based on additional logic
    }
  };

  // **Process Documents to Add 'status' Field**
  const processedDocuments = myDocuments
    ? myDocuments.map(doc => ({
        ...doc,
        status: deriveStatus(doc),
      }))
    : [];

  // **2. Create Document Mutation**
  const createDocumentMutation = useMutation(createDocument, {
    onSuccess: (newDocument) => {
      // Update 'myDocuments' cache by appending the new document
      queryClient.setQueryData('myDocuments', (old) => {
        return old ? [...old, newDocument] : [newDocument];
      });

      toast.success('Document created successfully!');
    },
    onError: (error) => {
      toast.error(error.message || 'Failed to create document');
    }
  });

  // **3. Update Document Mutation**
  const updateDocumentMutation = useMutation(updateDocument, {
    onSuccess: (updatedDocument) => {
      // Update the specific document in 'myDocuments'
      queryClient.setQueryData('myDocuments', (old) => {
        return old.map(doc => doc._id === updatedDocument._id ? updatedDocument : doc);
      });

      toast.success('Document updated successfully!');
    },
    onError: (error) => {
      toast.error(error.message || 'Failed to update document');
    }
  });

  // **4. Delete Document Mutation**
  const deleteDocumentMutation = useMutation(deleteDocument, {
    onMutate: async (deletedDocId) => {
      await queryClient.cancelQueries('myDocuments');

      // Snapshot the previous value
      const previousDocuments = queryClient.getQueryData('myDocuments');

      // Optimistically remove the document from 'myDocuments'
      queryClient.setQueryData('myDocuments', (old) =>
        old ? old.filter((doc) => doc._id !== deletedDocId) : []
      );

      return { previousDocuments };
    },
    onError: (error, deletedDocId, context) => {
      // Rollback to the previous state if mutation fails
      queryClient.setQueryData('myDocuments', context.previousDocuments);
      toast.error(error.message || 'Failed to delete document');
    },
    onSuccess: () => {
      toast.success('Document deleted successfully!');
    },
    onSettled: () => {
      queryClient.invalidateQueries('myDocuments');
    },
  });

  // **5. Publish Document Mutation**
  const publishDocumentMutation = useMutation(publishDocument, {
    onMutate: async (documentId) => {
      await queryClient.cancelQueries('myDocuments');

      // Snapshot the previous documents
      const previousDocuments = queryClient.getQueryData('myDocuments');

      // Optimistically update the document's shareable_link
      queryClient.setQueryData('myDocuments', (old) =>
        old.map(doc => 
          doc._id === documentId ? { ...doc, shareable_link: 'generating...' } : doc
        )
      );

      return { previousDocuments };
    },
    onError: (error, documentId, context) => {
      // Rollback if mutation fails
      queryClient.setQueryData('myDocuments', context.previousDocuments);
      toast.error(error.message || 'Failed to publish document');
    },
    onSuccess: (publishedDocument) => {
      // Update the specific document with the response
      queryClient.setQueryData('myDocuments', (old) =>
        old.map(doc => 
          doc._id === publishedDocument._id ? publishedDocument : doc
        )
      );
      toast.success('Document published successfully!');
    },
    onSettled: () => {
      queryClient.invalidateQueries('myDocuments');
    },
  });

  // **6. Unpublish Document Mutation**
  const unpublishDocumentMutation = useMutation(unpublishDocument, {
    onMutate: async (documentId) => {
      await queryClient.cancelQueries('myDocuments');

      // Snapshot the previous documents
      const previousDocuments = queryClient.getQueryData('myDocuments');

      // Optimistically remove the shareable_link and related fields
      queryClient.setQueryData('myDocuments', (old) =>
        old.map(doc => 
          doc._id === documentId ? { 
            ...doc, 
            shareable_link: null,
            first_opened_at: null,
            last_opened_at: null,
            open_count: null 
          } : doc
        )
      );

      return { previousDocuments };
    },
    onError: (error, documentId, context) => {
      // Rollback if mutation fails
      queryClient.setQueryData('myDocuments', context.previousDocuments);
      toast.error(error.message || 'Failed to unpublish document');
    },
    onSuccess: (unpublishedDocument) => {
      // Update the specific document with the response
      queryClient.setQueryData('myDocuments', (old) =>
        old.map(doc => 
          doc._id === unpublishedDocument._id ? unpublishedDocument : doc
        )
      );
      toast.success('Document unpublished successfully!');
    },
    onSettled: () => {
      queryClient.invalidateQueries('myDocuments');
    },
  });

  // **7. Download Document Mutation**
  const downloadDocumentMutation = useMutation(
    (documentId) => downloadDocumentAsPDF(documentId),
    {
      onError: (error) => {
        toast.error(error.message || 'Failed to download document');
      },
      onSuccess: () => {
        toast.success('Document downloaded successfully!');
      },
      retry: 0,
    }
  );

  // **8. Sign Document Mutation**
  const signDocumentMutation = useMutation(
    (signatureData) => signDocument(signatureData),
    {
      onMutate: async (signatureData) => {
        await queryClient.cancelQueries('myDocuments');

        // Snapshot the previous documents
        const previousDocuments = queryClient.getQueryData('myDocuments');

        // Optimistically update the signed document
        queryClient.setQueryData('myDocuments', (old) =>
          old.map(doc => 
            doc._id === signatureData.documentId ? { 
              ...doc, 
              signed_by: signatureData.signer_name,
              signature_data: signatureData.signature_img,
              signed_at: signatureData.signature_date,
              status: 'signed'
            } : doc
          )
        );

        return { previousDocuments };
      },
      onError: (error, signatureData, context) => {
        // Rollback if mutation fails
        queryClient.setQueryData('myDocuments', context.previousDocuments);
        const errorMessage = error.message || 'Failed to sign document';
        if (errorMessage.includes('already signed')) {
          toast.error('This document has already been signed');
        } else if (errorMessage.includes('expired')) {
          toast.error('This signing link has expired');
        } else {
          toast.error('Failed to sign document. Please try again.');
        }
      },
      onSuccess: (signedDocument) => {
        // Update the specific document with the response
        queryClient.setQueryData('myDocuments', (old) =>
          old.map(doc => 
            doc._id === signedDocument._id ? signedDocument : doc
          )
        );
        toast.success('Document signed successfully!');
      },
      onSettled: () => {
        queryClient.invalidateQueries('myDocuments');
      },
    }
  );

   // **11. Get Shareable Link Mutation**
   const getShareableLinkMutation = useMutation(
    (documentId) => getDocumentShareUrl(documentId),
    {
      onError: (error) => {
        toast.error(error.response?.data?.detail || error.message || 'Failed to fetch shareable link');
      },
      onSuccess: (data) => {
        toast.success('Shareable link fetched successfully!');
        // You can perform additional actions here if needed
      }
    }
  );

  return {
    // **Queries**
    myDocuments: processedDocuments,
    isLoadingMyDocuments,
    isErrorMyDocuments,
    myDocumentsError,
    refetchMyDocuments,

    // **Mutations**
    createDocument: createDocumentMutation.mutateAsync,
    updateDocument: updateDocumentMutation.mutateAsync,
    deleteDocument: deleteDocumentMutation.mutateAsync,
    publishDocument: publishDocumentMutation.mutateAsync,
    unpublishDocument: unpublishDocumentMutation.mutateAsync,
    downloadDocument: downloadDocumentMutation.mutateAsync,
    signDocument: signDocumentMutation.mutateAsync,
    getShareableLink: getShareableLinkMutation.mutateAsync, // New mutation


  // Add loading states
  isDownloading: downloadDocumentMutation.isLoading,
  downloadError: downloadDocumentMutation.error,
  isFetchingShareableLink: getShareableLinkMutation.isLoading,
  fetchShareableLinkError: getShareableLinkMutation.error
};
};
