import React, { useState, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useClients } from '../hooks/useClient';
import { format } from 'date-fns'; // Optional: For formatting dates
import { 
  Building2, 
  User2, 
  Phone, 
  Mail, 
  Globe, 
  MapPin, 
  ChevronDown, 
  ChevronUp,
  Search,
  SlidersHorizontal,
  Plus, // Importing Plus icon for adding notes
  Note, // Importing Note icon if needed
  Eye, // Importing Eye icon for "View Details" button
  ChevronLeft,
  ChevronRight
} from 'lucide-react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const ClientList = () => {
  const [expandedClient, setExpandedClient] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    client_type: 'all',
    status: 'all'
  });
  const [showFilters, setShowFilters] = useState(false);
  const [page, setPage] = useState(0);
  const ITEMS_PER_PAGE = 10;


  const { 
    clients, 
    pagination,
    isLoadingClients, 
    isErrorClients, 
    createNote, 
    isCreatingNote, 
    createNoteError 
  } = useClients({
    page,
    limit: ITEMS_PER_PAGE,
    filters: {
      search: searchTerm,
      client_type: filters.client_type,
      status: filters.status
    }
  });
  
  const [noteInputs, setNoteInputs] = useState({});

  const statusOptions = [
    { value: 'all', label: 'All Statuses' },
    { value: 'lead', label: 'Leads' },
    { value: 'prospect', label: 'Prospects' },
    { value: 'client', label: 'Active Clients' }
  ];

  const clientTypeOptions = [
    { value: 'all', label: 'All Types' },
    { value: 'business', label: 'Business' },
    { value: 'individual', label: 'Individual' }
  ];

  const getStatusColor = (status) => {
    const colors = {
      lead: 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/30 dark:text-yellow-300',
      client: 'bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-300',
      prospect: 'bg-blue-100 text-blue-800 dark:bg-blue-900/30 dark:text-blue-300'
    };
    return colors[status] || 'bg-gray-100 text-gray-800 dark:bg-gray-900/30 dark:text-gray-300';
  };

  const handleAddNote = async (clientId) => {
    const noteContent = noteInputs[clientId]?.trim();
    if (!noteContent) {
      alert('Please enter a note.');
      return;
    }

    try {
      await createNote({ clientId, noteContent });
      setNoteInputs(prev => ({ ...prev, [clientId]: '' }));
      // Optionally, you can show a success message
    } catch (error) {
      alert(`Error adding note: ${error.message}`);
    }
  };


  // Filter and search logic
  const filteredClients = useMemo(() => {
    if (!clients) return [];
    
    return clients.filter(client => {
      // Apply search term filter
      const searchMatch = searchTerm === '' || 
        client.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (client.contact_info?.primary_contact || '').toLowerCase().includes(searchTerm.toLowerCase());

      // Apply status filter
      const statusMatch = filters.status === 'all' || client.status === filters.status;

      // Apply client type filter
      const typeMatch = filters.client_type === 'all' || client.client_type === filters.client_type;

      return searchMatch && statusMatch && typeMatch;
    });
  }, [clients, searchTerm, filters]);

  
  const handlePageChange = (newPage) => {
    setPage(newPage);
    setExpandedClient(null); // Close any expanded clients when changing pages
    window.scrollTo(0, 0); // Scroll to top when page changes
  };


  const renderPaginationControls = () => (
    <div className="flex items-center justify-between mt-6 pb-4">
      <button
        onClick={() => handlePageChange(page - 1)}
        disabled={page === 0}
        className={`flex items-center px-4 py-2 rounded-md 
          ${page === 0 
            ? 'bg-gray-100 text-gray-400 cursor-not-allowed dark:bg-gray-800'
            : 'bg-white text-gray-700 hover:bg-gray-50 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600'
          } border border-gray-300 dark:border-gray-600`}
      >
        <ChevronLeft className="h-4 w-4 mr-2" />
        Previous
      </button>
      
      <span className="text-sm text-gray-600 dark:text-gray-400">
      Page {pagination?.currentPage + 1} of {pagination?.totalPages} ({pagination?.totalItems} total clients)
      </span>
      
      <button
        onClick={() => handlePageChange(page + 1)}
        disabled={!pagination?.hasMore}
        className={`flex items-center px-4 py-2 rounded-md 
        ${!pagination?.hasMore
            ? 'bg-gray-100 text-gray-400 cursor-not-allowed dark:bg-gray-800'
            : 'bg-white text-gray-700 hover:bg-gray-50 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600'
          } border border-gray-300 dark:border-gray-600`}
      >
        Next
        <ChevronRight className="h-4 w-4 ml-2" />
      </button>
    </div>
  );

  if (isLoadingClients) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-grayteck-600"></div>
      </div>
    );
  }

  if (isErrorClients) {
    return (
      <div className="text-center py-12">
        <p className="text-red-600 dark:text-red-400">Error loading clients. Please try again later.</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold text-grayteck-900 dark:text-grayteck-100">
          My Clients
        </h1>
      </div>

      {/* Search and Filter Section */}
      <div className="mb-6 space-y-4">
        {/* Search Bar */}
        <div className="relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-grayteck-400" />
          <input
            type="text"
            placeholder="Search by name or company..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border border-grayteck-300 dark:border-grayteck-600 rounded-lg 
                     bg-white dark:bg-darkbg-700 text-grayteck-900 dark:text-grayteck-100
                     focus:outline-none focus:ring-2 focus:ring-grayteck-500"
          />
        </div>

        {/* Filter Toggle Button */}
        <button
          onClick={() => setShowFilters(!showFilters)}
          className="flex items-center space-x-2 text-grayteck-600 dark:text-grayteck-400 hover:text-grayteck-800 dark:hover:text-grayteck-200"
        >
          <SlidersHorizontal className="h-4 w-4" />
          <span>Filters</span>
          <ChevronDown className={`h-4 w-4 transform transition-transform ${showFilters ? 'rotate-180' : ''}`} />
        </button>

        {/* Filter Options */}
        <AnimatePresence>
          {showFilters && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              className="grid grid-cols-1 sm:grid-cols-2 gap-4 overflow-hidden"
            >
              {/* Status Filter */}
              <div>
                <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-1">
                  Status
                </label>
                <select
                  value={filters.status}
                  onChange={(e) => setFilters(prev => ({ ...prev, status: e.target.value }))}
                  className="w-full p-2 border border-grayteck-300 dark:border-grayteck-600 rounded-md
                           bg-white dark:bg-darkbg-700 text-grayteck-900 dark:text-grayteck-100"
                >
                  {statusOptions.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>

              {/* Client Type Filter */}
              <div>
                <label className="block text-sm font-medium text-grayteck-700 dark:text-grayteck-300 mb-1">
                  Client Type
                </label>
                <select
                  value={filters.client_type}
                  onChange={(e) => setFilters(prev => ({ ...prev, client_type: e.target.value }))}
                  className="w-full p-2 border border-grayteck-300 dark:border-grayteck-600 rounded-md
                           bg-white dark:bg-darkbg-700 text-grayteck-900 dark:text-grayteck-100"
                >
                  {clientTypeOptions.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {/* Results Count */}
      <div className="text-sm text-grayteck-600 dark:text-grayteck-400 mb-4">
        Showing {filteredClients.length} {filteredClients.length === 1 ? 'client' : 'clients'}
      </div>

      {/* Client Cards */}
      <div className="grid gap-6">
        <AnimatePresence>
          {filteredClients.map((client) => (
            <motion.div
              key={client._id}
              layout
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="bg-white dark:bg-darkbg-800 rounded-lg shadow-md overflow-hidden"
            >
              {/* Client Card Header */}
              <div 
                className="p-6 cursor-pointer"
                onClick={() => setExpandedClient(expandedClient === client._id ? null : client._id)}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-4">
                    {client.client_type === 'business' ? (
                      <Building2 className="h-6 w-6 text-grayteck-600 dark:text-grayteck-400" />
                    ) : (
                      <User2 className="h-6 w-6 text-grayteck-600 dark:text-grayteck-400" />
                    )}
                    <div>
                      <h3 className="text-lg font-semibold text-grayteck-900 dark:text-grayteck-100">
                        {client.name}
                      </h3>
                      {client.client_type === 'business' && client.contact_info?.primary_contact && (
                        <p className="text-sm text-grayteck-600 dark:text-grayteck-400">
                          Contact: {client.contact_info.primary_contact}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="flex items-center space-x-4">
                    <span className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusColor(client.status)}`}>
                      {client.status.charAt(0).toUpperCase() + client.status.slice(1)}
                    </span>
                    {expandedClient === client._id ? (
                      <ChevronUp className="h-5 w-5 text-grayteck-400" />
                    ) : (
                      <ChevronDown className="h-5 w-5 text-grayteck-400" />
                    )}
                  </div>
                </div>
              </div>

              {/* Expanded Content */}
              <AnimatePresence>
                {expandedClient === client._id && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    className="border-t border-gray-200 dark:border-darkbg-700"
                  >
                    <div className="p-6 space-y-4">
                      {/* Contact Information */}
                      <div>
                        <h4 className="font-semibold text-grayteck-900 dark:text-grayteck-100 mb-2">
                          Contact Information
                        </h4>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          {client.contact_info?.email && (
                            <div className="flex items-center space-x-2">
                              <Mail className="h-4 w-4 text-grayteck-500" />
                              <span className="text-grayteck-600 dark:text-grayteck-400">
                                {client.contact_info.email}
                              </span>
                            </div>
                          )}
                          {client.contact_info?.phone && (
                            <div className="flex items-center space-x-2">
                              <Phone className="h-4 w-4 text-grayteck-500" />
                              <span className="text-grayteck-600 dark:text-grayteck-400">
                                {client.contact_info.phone}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>

                      {/* Company Information (for business clients) */}
                      {client.client_type === 'business' && client.company_info && (
                        <div>
                          <h4 className="font-semibold text-grayteck-900 dark:text-grayteck-100 mb-2">
                            Company Information
                          </h4>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {client.company_info.website && (
                              <div className="flex items-center space-x-2">
                                <Globe className="h-4 w-4 text-grayteck-500" />
                                <span className="text-grayteck-600 dark:text-grayteck-400">
                                  {client.company_info.website}
                                </span>
                              </div>
                            )}
                            {client.company_info.industry && (
                              <div className="flex items-center space-x-2">
                                <Building2 className="h-4 w-4 text-grayteck-500" />
                                <span className="text-grayteck-600 dark:text-grayteck-400">
                                  {client.company_info.industry}
                                </span>
                              </div>
                            )}
                            {client.company_info.location && (
                              <div className="flex items-center space-x-2">
                                <MapPin className="h-4 w-4 text-grayteck-500" />
                                <span className="text-grayteck-600 dark:text-grayteck-400">
                                  {[
                                    client.company_info.location.city,
                                    client.company_info.location.state,
                                    client.company_info.location.country
                                  ].filter(Boolean).join(', ')}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      {/* Service Interests */}
                      {client.service_interests && client.service_interests.length > 0 && (
                        <div>
                          <h4 className="font-semibold text-grayteck-900 dark:text-grayteck-100 mb-2">
                            Service Interests
                          </h4>
                          <div className="flex flex-wrap gap-2">
                            {client.service_interests.map((interest, index) => (
                              <span
                                key={index}
                                className="px-3 py-1 bg-grayteck-100 dark:bg-darkbg-700 text-grayteck-700 dark:text-grayteck-300 rounded-full text-sm"
                              >
                                {interest.service.split('-').map(word => 
                                  word.charAt(0).toUpperCase() + word.slice(1)
                                ).join(' ')}
                              </span>
                            ))}
                          </div>
                        </div>
                      )}

                      {/* Notes */}
                      {client.notes && client.notes.length > 0 && (
                        <div>
                          <h4 className="font-semibold text-grayteck-900 dark:text-grayteck-100 mb-2">
                            Notes
                          </h4>
                          <div className="bg-grayteck-50 dark:bg-darkbg-700 rounded-lg p-4 space-y-2">
                            {client.notes
                              .sort((a, b) => new Date(b.date) - new Date(a.date))
                              .slice(0, 2)
                              .map((note, index) => (
                              <div key={index} className="text-grayteck-600 dark:text-grayteck-400">
                                  <p className="text-sm">
                                    <strong>{format(new Date(note.date), 'PPP')}</strong> {/* Optional: Display date */}
                                  </p>
                                  <p>{note.content}</p>
                              </div>
                              ))
                            }
                          </div>
                        </div>
                      )}
                      <div className="mt-4">
                        <h4 className="font-semibold text-grayteck-900 dark:text-grayteck-100 mb-2">
                          Add a Note
                        </h4>
                        <div className="flex items-center space-x-2">
                          <input
                            type="text"
                            placeholder="Enter your note here..."
                            value={noteInputs[client._id] || ''}
                            onChange={(e) => setNoteInputs(prev => ({ ...prev, [client._id]: e.target.value }))}
                            className="flex-grow p-2 border border-grayteck-300 dark:border-grayteck-600 rounded-md
                                     bg-white dark:bg-darkbg-700 text-grayteck-900 dark:text-grayteck-100
                                     focus:outline-none focus:ring-2 focus:ring-grayteck-500"
                          />
                          <button
                            onClick={() => handleAddNote(client._id)}
                            className="flex items-center justify-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700
                                       focus:outline-none focus:ring-2 focus:ring-blue-500"
                          >
                            <Plus className="h-4 w-4 mr-1" />
                            Add Note
                          </button>
                        </div>
                        {isCreatingNote && (
                          <p className="text-sm text-grayteck-500 mt-1">Adding note...</p>
                        )}
                        {createNoteError && (
                          <p className="text-sm text-red-500 mt-1">Error adding note: {createNoteError.message}</p>
                        )}
                      </div>

                      {/* View Details Button */}
                      <div className="mt-4">
                        <Link 
                          to={`/clients/${client._id}`}
                          className="inline-flex items-center px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
                        >
                          <Eye className="h-4 w-4 mr-2" />
                          View Details
                        </Link>
                      </div>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
        </AnimatePresence>

        {filteredClients.length === 0 && (
          <div className="text-center py-12 bg-white dark:bg-darkbg-800 rounded-lg shadow-md">
            <p className="text-grayteck-600 dark:text-grayteck-400">
              {searchTerm || filters.status !== 'all' || filters.client_type !== 'all'
                ? 'No clients found matching your search criteria.'
                : 'No clients found. Add your first client to get started!'}
            </p>
          </div>
        )}
      </div>
      {renderPaginationControls()}
    </div>
  );
};

export default ClientList;