import React, { useState } from "react";
import Modal from "./Modal"; // Reuse your existing modal wrapper
import { Upload, FileText, Loader } from "lucide-react";
import { detectSpeakers } from "../functions/detectSpeakers";
import { toast } from "react-toastify";

const TranscriptModal = ({
  isOpen,
  onClose,
  engagement, // The engagement we're uploading a transcript for
  onSubmitTranscript, // Callback for saving to backend
  isUploadingTranscript = false, // Loading state
  uploadError = null, // Error state
}) => {
  const [transcriptFile, setTranscriptFile] = useState(null);
  const [transcriptText, setTranscriptText] = useState("");
  const [speakers, setSpeakers] = useState([]);
  const [processingStep, setProcessingStep] = useState("");

  const hasTranscript = !!transcriptFile && !!transcriptText;

  // Handle user selecting a file
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setTranscriptFile(file);
    setProcessingStep("Reading file...");

    const reader = new FileReader();
    reader.onload = (evt) => {
      const text = evt.target.result;
      setTranscriptText(text);
      setProcessingStep("Detecting speakers...");

      // 1) detect raw speaker labels (like ["A","B","C"])
      const rawLabels = detectSpeakers(text);

      // 2) build an array of objects so user can rename them
      const newSpeakers = rawLabels.map((label) => ({
        label, // e.g. "A"
        name: `Speaker ${label}`, // default name
      }));
      setSpeakers(newSpeakers);
      setProcessingStep("");
    };
    reader.readAsText(file);
  };

  // If user types in a rename, store it
  const handleSpeakerNameChange = (index, newName) => {
    setSpeakers((prev) => {
      const updated = [...prev];
      updated[index] = { ...updated[index], name: newName };
      return updated;
    });
  };

  // Handle generating notes from transcript
  // const handleGenerateNotes = () => {
  //   if (!hasTranscript || !engagement || !onSubmitTranscript) {
  //     toast.error("Please upload a transcript file first");
  //     return;
  //   }

  //   // Step 1: Convert speakers array to required format for the backend
  //   const speakersMap = {};
  //   speakers.forEach(speaker => {
  //     speakersMap[speaker.label] = speaker.name;
  //   });

  //   // Step 2: Save transcript (first part of two-step process)
  //   onSubmitTranscript({
  //     engagementId: engagement.id,
  //     transcriptFile,
  //     speakers: speakersMap
  //   });

  //   // Note: The second step (generating notes) will be implemented later
  //   // after we get the transcript ID from the save response
  // };

  // Handle generating notes from transcript
  const handleGenerateNotes = () => {
    if (!hasTranscript || !engagement || !onSubmitTranscript) {
      toast.error("Please upload a transcript file first");
      return;
    }

    // Step 1: Convert speakers array to required format for the backend
    const speakersMap = {};
    speakers.forEach((speaker) => {
      speakersMap[speaker.label] = speaker.name;
    });

    // Step 2: Save transcript and then generate notes
    onSubmitTranscript({
      engagementId: engagement.id,
      transcriptFile,
      speakers: speakersMap,
      generateNotes: true, // Flag to indicate we want to generate notes after upload
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {/* Outer container with auto margin, max width, plus padding */}
      <div className="p-6 md:p-8 w-full max-w-lg mx-auto flex flex-col items-center text-center space-y-6">
        <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
          Upload Transcript for "{engagement?.type ?? "Engagement"}"
        </h2>

        {/* File Upload Section */}
        <div className="w-full flex flex-col items-center">
          <label className="mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
            Choose a .txt transcript file
          </label>
          <input
            type="file"
            accept=".txt"
            onChange={handleFileChange}
            className="mb-4 p-2 border rounded-md 
                     bg-white dark:bg-darkbg-700 
                       border-gray-300 dark:border-gray-600 
                       text-gray-900 dark:text-gray-100
                       w-full max-w-sm"
          />
        </div>

        {/* Processing indicator */}
        {processingStep && (
          <div className="flex items-center space-x-2 text-gray-600 dark:text-gray-400">
            <Loader className="w-4 h-4 animate-spin" />
            <span>{processingStep}</span>
          </div>
        )}

        {/* Preview the transcript text if present */}
        {transcriptText && (
          <div className="w-full flex flex-col items-center">
            <label className="mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
              Transcript Preview
            </label>
            <textarea
              readOnly
              value={transcriptText}
              rows={6}
              className="w-full max-w-sm p-3 border rounded-md 
                         bg-white dark:bg-darkbg-700
                         border-gray-300 dark:border-gray-600 
                         text-gray-900 dark:text-gray-100 
                         focus:ring-2 focus:ring-gray-500 focus:border-transparent
                         overflow-y-auto"
            />
          </div>
        )}

        {/* If we have speaker labels, show text fields for renaming */}
        {speakers.length > 0 && (
          <div className="space-y-2">
            <div className="text-sm font-medium text-gray-700 dark:text-gray-300">
              Detected Speakers
            </div>
            <div className="flex flex-col space-y-2">
              {speakers.map((spk, idx) => (
                <div key={spk.label} className="flex items-center space-x-2">
                  <label className="text-gray-700 dark:text-gray-300 w-16 text-sm">
                    {spk.label}:
                  </label>
                  <input
                    type="text"
                    value={spk.name}
                    onChange={(e) =>
                      handleSpeakerNameChange(idx, e.target.value)
                    }
                    className="flex-1 p-2 border rounded-md 
                               bg-white dark:bg-darkbg-700 
                               border-gray-300 dark:border-gray-600 
                               text-gray-900 dark:text-gray-100
                               focus:ring-1 focus:ring-gray-500 focus:border-transparent"
                  />
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Error message if applicable */}
        {uploadError && (
          <div className="w-full max-w-sm px-4 py-2 bg-red-50 text-red-700 rounded-md text-sm">
            {typeof uploadError === "string"
              ? uploadError
              : "An error occurred while saving transcript"}
          </div>
        )}

        {/* Action Buttons (centered, space between) */}
        <div className="w-full flex justify-center space-x-4">
          <button
            disabled={!hasTranscript || isUploadingTranscript}
            onClick={() => console.log("Add Human Notes clicked")}
            className={`px-4 py-2 rounded-md text-sm font-medium
              ${
                !hasTranscript || isUploadingTranscript
                  ? "bg-gray-200 text-gray-500 cursor-not-allowed"
                  : "bg-blue-600 text-white hover:bg-blue-700"
              }`}
          >
            Add Human Notes
          </button>

          <button
            disabled={!hasTranscript || isUploadingTranscript}
            onClick={handleGenerateNotes}
            className={`px-4 py-2 rounded-md text-sm font-medium flex items-center space-x-2
              ${
                !hasTranscript || isUploadingTranscript
                  ? "bg-gray-200 text-gray-500 cursor-not-allowed"
                  : "bg-green-600 text-white hover:bg-green-700"
              }`}
          >
            {isUploadingTranscript ? (
              <>
                <Loader className="w-4 h-4 animate-spin" />
                <span>Saving...</span>
              </>
            ) : (
              "Generate Notes"
            )}
          </button>
        </div>

        {/* Bottom "Close" row */}
        <div className="w-full flex justify-center mt-4">
          <button
            onClick={onClose}
            disabled={isUploadingTranscript}
            className="px-4 py-2 text-sm text-gray-700 hover:text-gray-900 
                       dark:text-gray-300 dark:hover:text-gray-100
                       border border-gray-300 dark:border-gray-600 rounded-md"
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default TranscriptModal;
