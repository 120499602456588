// src/context/AuthContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import authService from "../services/AuthService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { authStorage } from "../functions/auth";

// Create context
export const AuthContext = createContext(null);

// Context provider component
export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [authState, setAuthState] = useState({
    isAuthenticated: authService.isAuthenticated(),
    isLoading: false,
    user: authService.getCurrentUser(),
    error: null,
  });

  // Listen for auth events
  useEffect(() => {
    const handleLogin = (e) => {
      setAuthState((prev) => ({
        ...prev,
        isAuthenticated: true,
        error: null,
      }));
      toast.success("Successfully logged in");
    };

    const handleLogout = (e) => {
      setAuthState({
        isAuthenticated: false,
        isLoading: false,
        user: null,
        error: null,
      });

      // Only show toast if this was a manual logout
      if (
        e.detail?.reason !== "refresh-failed" &&
        e.detail?.reason !== "no-refresh-token"
      ) {
        toast.info("Logged out");
      }

      // Redirect to login unless we're already there
      if (window.location.pathname !== "/login") {
        navigate("/login");
      }
    };

    const handleRefreshSuccess = () => {
      setAuthState((prev) => ({
        ...prev,
        isAuthenticated: true,
        error: null,
      }));
    };

    const handleRefreshError = (e) => {
      const errorMsg = e.detail?.error?.message || "Session expired";
      toast.error(errorMsg);
    };

    // Subscribe to auth service events
    window.addEventListener(authService.events.LOGIN, handleLogin);
    window.addEventListener(authService.events.LOGOUT, handleLogout);
    window.addEventListener(
      authService.events.REFRESH_SUCCESS,
      handleRefreshSuccess
    );
    window.addEventListener(
      authService.events.REFRESH_ERROR,
      handleRefreshError
    );

    // Subscribe to auth state changes from service
    const unsubscribe = authService.subscribeToAuthState((state) => {
      setAuthState((prev) => ({ ...prev, ...state }));
    });

    return () => {
      // Clean up event listeners
      window.removeEventListener(authService.events.LOGIN, handleLogin);
      window.removeEventListener(authService.events.LOGOUT, handleLogout);
      window.removeEventListener(
        authService.events.REFRESH_SUCCESS,
        handleRefreshSuccess
      );
      window.removeEventListener(
        authService.events.REFRESH_ERROR,
        handleRefreshError
      );
      unsubscribe();
    };
  }, [navigate]);

  // Provide context value
  const contextValue = {
    ...authState,
    user: () => {
      const userData = authStorage.getUserData();
      return userData;
    },
    isAuthenticated: () => {
      console.log(authService.isAuthenticated());
      return authService.isAuthenticated();
    },
    // Add isAdmin method
    isAdmin: () => {
      // Get user from auth state
      console.log(authState.user);
      console.log(authState);

      const userData = authStorage.getUserData();
      // Check for admin type - check multiple possible properties
      // return true;
      return (
        userData?.userType === "admin" ||
        userData?.role === "admin" ||
        userData?.isAdmin === true
      );
    },
    // Add isClient method
    isClient: () => {
      console.log(authState.user);
      // Get user from auth state
      const userData = authState.user;
      // Check for client type - check multiple possible properties
      return (
        userData?.userType === "user" ||
        userData?.userType === "client" ||
        userData?.role === "client" ||
        userData?.isClient === true
      );
    },
    login: async (username, password) => {
      setAuthState((prev) => ({ ...prev, isLoading: true, error: null }));
      try {
        await authService.login(username, password);
        return true;
      } catch (error) {
        setAuthState((prev) => ({
          ...prev,
          isLoading: false,
          error: error.message || "Login failed",
        }));
        toast.error(error.message || "Login failed");
        return false;
      } finally {
        setAuthState((prev) => ({ ...prev, isLoading: false }));
      }
    },
    logout: async () => {
      setAuthState((prev) => ({ ...prev, isLoading: true }));
      try {
        await authService.logout({ reason: "user-initiated" });
      } catch (error) {
        toast.error("Logout error");
      } finally {
        setAuthState((prev) => ({ ...prev, isLoading: false }));
      }
    },
    refreshToken: async () => {
      // Note: we don't need to set loading state here since AuthService
      // will notify state changes via the subscribeToAuthState mechanism
      try {
        await authService.refreshToken();
        return true;
      } catch (error) {
        return false;
      }
    },
    // Expose information about whether a refresh is in progress
    isRefreshing: authService.isRefreshing,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

// Custom hook to use auth context
export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error("useAuthContext must be used within an AuthProvider");
  }
  return context;
};
