// src/hooks/useInvoices.js
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { createAxiosInstance } from '../functions/axiosInstance';
import { createInvoice, deleteInvoice, getClientInvoices, getDocumentShareUrl, getInvoices, getInvoicesForPortal, updateInvoice } from '../functions/api_fethcers';


export const useInvoices = (clientId, {
    adminOptions = { page: 0, limit: 20, status: 'all' },
    clientPortalOptions = { page: 0, limit: 20 }
  } = {}) => {
  const queryClient = useQueryClient();
  const { page: adminPage, limit: adminLimit, status } = adminOptions;
  const { page: clientPortalPage, limit: clientPortalLimit } = clientPortalOptions;


   // -------------------------------------------
  // 1) Query: General/Admin Invoices
 // -------------------------------------------

  const {
    data,
    isLoading,
    isError,
    error,
    refetch
  } = useQuery(
    ['invoices', clientId, adminPage, adminLimit, status],
    () => getInvoices({ clientId, page: adminPage, limit: adminLimit, status }),
    {
      enabled: !!clientId,
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
      refetchOnWindowFocus: false
    }
  );

 // -------------------------------------------
  // 2) New Query for Client Portal Invoices
  // -------------------------------------------
  //   - Only invoices that are public (have shareable links)
  //   - Statuses: 'pending', 'paid', 'overdue'
  //
  //   Using the new `getClientInvoices` fetcher
  // -------------------------------------------
  const {
    data: clientPortalData,
    isLoading: isLoadingClientPortal,
    isError: isErrorClientPortal,
    error: errorClientPortal,
    refetch: refetchClientPortal
  } = useQuery(
    ['clientPortalInvoices', clientId, clientPortalPage, clientPortalLimit],
    () => 
      getClientInvoices({ page: clientPortalPage, limit: clientPortalLimit }),
    {
      enabled: false,
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
      refetchOnWindowFocus: false
    }
  );

  // Parse out the client portal invoices
  const clientPortalInvoices = clientPortalData?.items || [];



// Mutations
const createInvoiceMutation = useMutation(createInvoice, {
    onSuccess: () => {
      queryClient.invalidateQueries(['invoices', clientId]);
      queryClient.invalidateQueries(['invoiceStats', clientId]);
      toast.success('Invoice created successfully');
    },
    onError: (error) => toast.error(error.message || 'Failed to create invoice')
  });


  // Update invoice mutation
  const updateInvoiceMutation = useMutation(updateInvoice, {
    onMutate: async ({ id, ...updateData }) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries(['invoices', clientId]);

      // Snapshot the previous value
    const previousInvoices = queryClient.getQueryData(['invoices', clientId, adminPage, adminLimit, status]);

      // Get the existing invoice to check if it was published
      const existingInvoice = previousInvoices?.items?.find(inv => inv.id === id);
      const wasPublished = existingInvoice?.status === 'pending' || 
                          Boolean(existingInvoice?.document_id && existingInvoice?.shareable_link);

      // Optimistically update to the new value, ensuring unpublished state if needed
    queryClient.setQueryData(['invoices', clientId, adminPage, adminLimit, status], old => {
        if (!old?.items) return old;
        return {
          ...old,
          items: old.items.map(invoice => 
            invoice.id === id ? {
              ...invoice,
              ...updateData,
              // If it was published, ensure it's marked as draft and remove shareable link
              ...(wasPublished ? {
                status: 'draft',
                shareable_link: null
              } : {})
            } : invoice
          )
        };
      });

      return { previousInvoices, wasPublished };
    },
    onSuccess: (updatedInvoice, variables, context) => {
      toast.success('Invoice updated successfully');
      
      // If it was previously published, show additional info message
      if (context?.wasPublished) {
        toast.info(
          'Invoice has been unpublished. You\'ll need to publish it again to share it.',
          { autoClose: 6000 }
        );
      }
      
      // Invalidate queries to ensure data consistency
      queryClient.invalidateQueries(['invoices', clientId]);
      queryClient.invalidateQueries(['invoiceStats', clientId]);
    },
    onError: (err, variables, context) => {
      // Rollback on error
      if (context?.previousInvoices) {
        queryClient.setQueryData(
        ['invoices', clientId, adminPage, adminLimit, status],
          context.previousInvoices
        );
      }
      toast.error(err.message || 'Failed to update invoice');
    },
    onSettled: () => {
      // Always refetch after error or success
      queryClient.invalidateQueries(['invoices', clientId]);
    }
  });


  // Delete invoice mutation
  const deleteInvoiceMutation = useMutation(deleteInvoice, {
    onMutate: async (invoiceId) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries(['invoices', clientId]);

      // Snapshot the previous value
      const previousInvoices = queryClient.getQueryData(['invoices', clientId, adminPage, adminLimit, status]);

      // Optimistically update to remove the deleted invoice
      queryClient.setQueryData(['invoices', clientId, adminPage, adminLimit, status], old => {
        if (!old?.items) return old;
        return {
          ...old,
          items: old.items.filter(invoice => invoice.id !== invoiceId),
          total: Math.max(0, (old.total || 0) - 1)
        };
      });

      return { previousInvoices };
    },
    onSuccess: (_, invoiceId) => {
      toast.success('Invoice deleted successfully');
      
      // Invalidate queries to ensure data consistency
      queryClient.invalidateQueries(['invoices', clientId]);
      queryClient.invalidateQueries(['invoiceStats', clientId]);
    },
    onError: (err, invoiceId, context) => {
      // Rollback on error
      if (context?.previousInvoices) {
        queryClient.setQueryData(
            ['invoices', clientId, adminPage, adminLimit, status],
            context.previousInvoices
        );
      }
      toast.error(err.message || 'Failed to delete invoice');
    },
    onSettled: () => {
      // Always refetch after error or success
      queryClient.invalidateQueries(['invoices', clientId]);
    }
  });

  


//   // Create invoice mutation
//   const createInvoice = useMutation(
//     async (invoiceData) => {
//       const response = await apiAxios.post('/invoices', invoiceData);
//       return response.data;
//     },
//     {
//       onSuccess: (data) => {
//         queryClient.invalidateQueries(['invoices', clientId]);
//         toast.success('Invoice created successfully');
//       },
//       onError: (error) => {
//         toast.error(error.response?.data?.detail || 'Failed to create invoice');
//       }
//     }
//   );

  // Create payment intent mutation
//   const createPaymentIntent = useMutation(
//     async (invoiceId) => {
//       const response = await apiAxios.post(`/invoices/${invoiceId}/payment-intent`);
//       return response.data;
//     },
//     {
//       onError: (error) => {
//         toast.error(error.response?.data?.detail || 'Failed to create payment intent');
//       }
//     }
//   );


  return {
    invoices: data?.items || [],
    pagination: {
      currentPage: data?.page || 0,
      totalPages: data?.total_pages || 0,
      totalItems: data?.total || 0,
      hasMore: data?.has_more || false
    },
    isLoading,
    isError,
    error,
    refetch,

    // Client Portal Invoices
    clientPortalInvoices,
    clientPortalPagination: {
      currentPage: clientPortalData?.page || 0,
      totalPages: clientPortalData?.total_pages || 0,
      totalItems: clientPortalData?.total || 0,
      hasMore: clientPortalData?.has_more || false
    },
    isLoadingClientPortal,
    isErrorClientPortal,
    errorClientPortal,
    refetchClientPortal,
    
    // Mutations
    createInvoice: createInvoiceMutation.mutateAsync,
    updateInvoice: updateInvoiceMutation.mutateAsync,
    deleteInvoice: deleteInvoiceMutation.mutateAsync,


    // Loading States
    isCreating: createInvoiceMutation.isLoading,
    isUpdating: updateInvoiceMutation.isLoading,
    isDeleting: deleteInvoiceMutation.isLoading,

    
    // Error States
    createError: createInvoiceMutation.error,
    updateError: updateInvoiceMutation.error,
    deleteError: deleteInvoiceMutation.error
  };
};


export function useClientInvoices({ page = 0, limit = 20 } = {}) {
    // Build the query key to track caching / data
    const queryKey = ['clientPortalInvoices', page, limit];
  
    // Execute React Query to fetch client portal invoices
    const {
      data,
      isLoading,
      isError,
      error,
      refetch
    } = useQuery(
      queryKey,
      () => getClientInvoices({ page, limit }),  // your fetcher call
      {
        keepPreviousData: true,       // Preserve old data while fetching new page
        staleTime: 5 * 60 * 1000,     // 5 minutes
        refetchOnWindowFocus: false   // Don’t refetch automatically on window focus
      }
    );
  
    // Extract the items array and pagination info from the data, if available
    const items = data?.items || [];
    const pagination = {
      currentPage: data?.page || 0,
      totalPages: data?.total_pages || 0,
      totalItems: data?.total || 0,
      hasMore: data?.has_more || false
    };
  
    // Return the data and helper states
    return {
      clientInvoices: items,
      pagination,
      isLoading,
      isError,
      error,
      refetch
    };
  }