import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { 
  MessageCircle, 
  Mail, 
  Phone, 
  ChevronDown, 
  ChevronUp,
  HelpCircle,
  Crown,
  Bot,
  ArrowRight
} from 'lucide-react';
import { useAuth } from '../hooks/useAuth';

// FAQ Section component
const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <motion.div 
      className="border-b border-grayteck-200 dark:border-grayteck-700 last:border-0"
      initial={false}
    >
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full py-4 flex items-center justify-between text-left"
      >
        <span className="text-grayteck-900 dark:text-grayteck-100 font-medium">{question}</span>
        {isOpen ? (
          <ChevronUp className="w-5 h-5 text-grayteck-500" />
        ) : (
          <ChevronDown className="w-5 h-5 text-grayteck-500" />
        )}
      </button>
      <motion.div
        initial="collapsed"
        animate={isOpen ? "open" : "collapsed"}
        variants={{
          open: { opacity: 1, height: "auto", marginBottom: "1rem" },
          collapsed: { opacity: 0, height: 0, marginBottom: "0rem" }
        }}
        transition={{ duration: 0.3 }}
        className="overflow-hidden"
      >
        <p className="text-grayteck-600 dark:text-grayteck-400 pb-4">
          {answer}
        </p>
      </motion.div>
    </motion.div>
  );
};

const ClientPortalSupport = () => {
  const user = JSON.parse(localStorage.getItem('docusign_user')) || {};
//   const isPremiumSupport = user.supportTier === 'premium'; // You'll need to add this to your user data
  const isPremiumSupport = true // You'll need to add this to your user data

  const faqs = [
    {
      question: "How do I sign a document?",
      answer: "To sign a document, open the document from your dashboard and click the 'Sign' button. You can either draw your signature or type it. Once you're satisfied, click 'Submit' to complete the signing process."
    },
    {
      question: "Can I download a signed document?",
      answer: "Yes, you can download any signed document. Navigate to the document in your dashboard and click the download icon. The document will be downloaded as a PDF with all signatures included."
    },
    {
      question: "What do I do if I need to make changes to a signed document?",
      answer: "Once a document has been signed, it cannot be modified. If changes are needed, you'll need to create a new document and have it signed again. This ensures the integrity of signed documents."
    },
    {
      question: "How long are documents stored in the system?",
      answer: "Documents are stored securely in our system for 7 years from the date of signing. You can access and download them at any time during this period."
    }
  ];

  return (
    <div className="max-w-4xl mx-auto">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
        className="space-y-8"
      >
        {/* Support Level Banner */}
        <div className={`relative overflow-hidden rounded-lg p-6 ${
          isPremiumSupport 
            ? 'bg-gradient-to-r from-amber-500 to-amber-600' 
            : 'bg-gradient-to-r from-grayteck-600 to-grayteck-700'
        }`}>
          <div className="relative z-10">
            <div className="flex items-center space-x-2">
              {isPremiumSupport ? (
                <Crown className="w-6 h-6 text-white" />
              ) : (
                <HelpCircle className="w-6 h-6 text-white" />
              )}
              <h2 className="text-xl font-bold text-white">
                {isPremiumSupport ? 'Premium Support' : 'Standard Support'}
              </h2>
          </div>
            <p className="mt-2 text-white/90">
              {isPremiumSupport 
                ? 'You have access to premium support features including our AI-powered chat assistant.'
                : 'You have access to our standard support features.'}
            </p>
          </div>
          <div className="absolute right-0 top-0 -mt-8 -mr-8">
            <div className="text-white/5 transform rotate-12">
              {isPremiumSupport ? (
                <Crown className="w-32 h-32" />
              ) : (
                <HelpCircle className="w-32 h-32" />
              )}
            </div>
          </div>
        </div>

        {/* Premium Support Chat Bot */}
        {isPremiumSupport && (
        <div className="bg-white dark:bg-darkbg-800 rounded-lg shadow-md p-6">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center space-x-2">
              <Bot className="w-6 h-6 text-amber-500" />
              <h2 className="text-xl font-semibold text-grayteck-900 dark:text-grayteck-100">
                    GT Support Assistant
          </h2>
            </div>
                <span className="px-3 py-1 bg-amber-100 text-amber-800 dark:bg-amber-900/30 dark:text-amber-300 text-sm font-medium rounded-full">
                  Coming Soon
                </span>
              </div>
              
              <p className="text-grayteck-600 dark:text-grayteck-400 mb-6">
                Experience a new level of personalized support with our AI-powered assistant. 
                Built specifically for your business, it understands your unique setup, from 
                custom automations to project specifics, ensuring you get accurate, 
                contextual help instantly.
            </p>

              <div className="bg-grayteck-50 dark:bg-darkbg-700 rounded-lg p-8 text-center">
                <Bot className="w-16 h-16 text-amber-500 mx-auto mb-6" />
                <h3 className="text-lg font-semibold text-grayteck-900 dark:text-grayteck-100 mb-3">
                  Launching Early 2025
                </h3>
                <p className="text-grayteck-600 dark:text-grayteck-400 mb-4 max-w-md mx-auto">
                  Our intelligent chat interface is being fine-tuned with your business data 
                  to provide seamless, accurate support 24/7. Stay tuned for this 
                  premium feature that will revolutionize how you get support.
                </p>
                <div className="inline-flex items-center text-sm text-amber-600 dark:text-amber-400">
                  Premium Support Feature
                  <Crown className="w-4 h-4 ml-2" />
                </div>
            </div>
        </div>
        )}

        {/* FAQ Section */}
        <div className="bg-white dark:bg-darkbg-800 rounded-lg shadow-md p-6">
          <h2 className="text-xl font-semibold text-grayteck-900 dark:text-grayteck-100 mb-6 flex items-center">
            <HelpCircle className="w-6 h-6 mr-2 text-grayteck-600" />
            Frequently Asked Questions
          </h2>
          <div className="divide-y divide-grayteck-200 dark:divide-grayteck-700">
            {faqs.map((faq, index) => (
              <FAQItem key={index} question={faq.question} answer={faq.answer} />
            ))}
          </div>
        </div>

        {/* Contact Information */}
        <div className="bg-white dark:bg-darkbg-800 rounded-lg shadow-md p-6">
          <h2 className="text-xl font-semibold text-grayteck-900 dark:text-grayteck-100 mb-6 flex items-center">
            <MessageCircle className="w-6 h-6 mr-2 text-grayteck-600" />
            Contact Information
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="flex items-start space-x-3">
              <Mail className="w-5 h-5 text-grayteck-600 dark:text-grayteck-400 mt-1" />
              <div>
                <h3 className="text-sm font-medium text-grayteck-900 dark:text-grayteck-100">Email</h3>
                <p className="text-grayteck-600 dark:text-grayteck-400">support@graytecknologies.com</p>
                <p className="text-sm text-grayteck-500">Response within 24 hours</p>
                {isPremiumSupport && (
                  <p className="text-sm text-amber-500 mt-1">
                    Priority response within 4 hours
                  </p>
                )}
              </div>
            </div>
            <div className="flex items-start space-x-3">
              <Phone className="w-5 h-5 text-grayteck-600 dark:text-grayteck-400 mt-1" />
              <div>
                <h3 className="text-sm font-medium text-grayteck-900 dark:text-grayteck-100">Phone</h3>
                <p className="text-grayteck-600 dark:text-grayteck-400">+1 (555) 123-4567</p>
                <p className="text-sm text-grayteck-500">Mon-Fri, 9am-5pm EST</p>
                {isPremiumSupport && (
                  <p className="text-sm text-amber-500 mt-1">
                    24/7 emergency support available
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Upgrade Banner (shown only for standard support users) */}
        {!isPremiumSupport && (
          <motion.div 
            className="bg-gradient-to-r from-amber-500 to-amber-600 rounded-lg shadow-md p-6 cursor-pointer hover:shadow-lg transition-shadow"
            whileHover={{ scale: 1.01 }}
            whileTap={{ scale: 0.99 }}
          >
            <div className="flex items-center justify-between">
              <div>
                <h2 className="text-xl font-semibold text-white mb-2 flex items-center">
                  <Crown className="w-6 h-6 mr-2" />
                  Upgrade to Premium Support
                </h2>
                <p className="text-white/90">
                  Get access to our AI chat assistant, priority support, and 24/7 emergency assistance.
                </p>
              </div>
              <ArrowRight className="w-6 h-6 text-white" />
            </div>
          </motion.div>
        )}
      </motion.div>
    </div>
  );
};

export default ClientPortalSupport;