import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import banner_logo from '../imgs/logo_transparent_background (1).png';
import { useAuthContext } from '../context/AuthContext';

const Login = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const auth = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [redirectChecked, setRedirectChecked] = useState(false);

  // Redirect if already authenticated, but only check once
  useEffect(() => {
    if (!redirectChecked) {
      setRedirectChecked(true);
      if (auth.isAuthenticated() && !auth.isLoading && !auth.isRefreshing) {
        const from = location.state?.from || '/';
        navigate(from, { replace: true });
      }
    }
  }, [auth, navigate, location, redirectChecked]);

  const onSubmit = async (data) => {
    const success = await auth.login(data.username, data.password);
    if (success) {
      const from = location.state?.from || '/';
      navigate(from, { replace: true });
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-grayteck-100 dark:bg-darkbg-900 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img className="mx-auto h-24 w-auto" src={banner_logo} alt="GrayTeck Logo" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-grayteck-900 dark:text-grayteck-100">
            GT DocSign
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="username" className="sr-only">Username</label>
              <input
                id="username"
                name="username"
                type="text"
                {...register('username', { required: 'Username is required' })}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-grayteck-300 placeholder-grayteck-500 text-grayteck-900 rounded-t-md focus:outline-none focus:ring-grayteck-500 focus:border-grayteck-500 focus:z-10 sm:text-sm"
                placeholder="Username"
                disabled={auth.isLoading}
              />
              {errors.username && (
                <p className="mt-2 text-sm text-red-600">{errors.username.message}</p>
              )}
            </div>
            <div>
              <label htmlFor="password" className="sr-only">Password</label>
              <input
                id="password"
                name="password"
                type="password"
                {...register('password', { required: 'Password is required' })}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-grayteck-300 placeholder-grayteck-500 text-grayteck-900 rounded-b-md focus:outline-none focus:ring-grayteck-500 focus:border-grayteck-500 focus:z-10 sm:text-sm"
                placeholder="Password"
                disabled={auth.isLoading}
              />
              {errors.password && (
                <p className="mt-2 text-sm text-red-600">{errors.password.message}</p>
              )}
            </div>
          </div>

          {auth.error && (
            <div className="rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">
                    {auth.error || 'An error occurred during login'}
                  </h3>
                </div>
              </div>
            </div>
          )}

          <div>
            <button
              type="submit"
              disabled={auth.isLoading}
              className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-grayteck-600 hover:bg-grayteck-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-grayteck-500 ${
                auth.isLoading ? 'opacity-75 cursor-not-allowed' : ''
              }`}
            >
              {auth.isLoading ? (
                <>
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    ></path>
                  </svg>
                  Signing in...
                </>
              ) : (
                'Sign in'
              )}
            </button>
          </div>
        </form>
        <div className="text-sm text-center">
          <Link
            to="/register"
            className="font-medium text-grayteck-600 hover:text-grayteck-500"
          >
            Don't have an account? Register
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;